import React, { useState, useEffect, useLayoutEffect, useCallback } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  icons,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from "syklone/ui/index.js";
import { CubicLabelSemantics } from "syklone/graphics/lib/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

// imports: local
import { WidgetLabelInput } from "../../widgets/index.js";

const CustomAlert = styled(Alert)(() => ({
  fontSize: "14px",
  marginBottom: "10px",
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  "&::before": {
    opacity: "1 !important",
  },
}));

function removePngExtension(filename) {
  if (filename.endsWith(".png")) {
    return filename.slice(0, -4);
  }
  return filename;
}

function transformStructure(data) {
  function transformItem(item) {
    // eslint-disable-next-line
    const { name, id, lotId, ...rest } = item;
    // eslint-disable-next-line
    return { ...rest, name: name, part_id: id };
  }

  const transformedData = {};

  for (const key in data) {
    if (data[key].length > 0) {
      transformedData[key] = data[key].map(transformItem);
    } else {
      transformedData[key] = [];
    }
  }

  return transformedData;
}

function extractProperties(data, buildFile) {
  let filteredData = [];
  const couponIds = new Set();

  if (buildFile.coupons && Array.isArray(buildFile.coupons)) {
    buildFile.coupons.forEach((coupon) => {
      if (coupon.partSession && coupon.partSession.id) {
        couponIds.add(coupon.partSession.id);
      }
    });
  }

  data.forEach((item) => {
    const { id, name, labels } = item;
    let existingEntry = filteredData.find((d) => d.id === id && d.name === name);
    const hasCoupon = couponIds.has(id);

    if (labels && labels.length > 0) {
      labels.forEach((label) => {
        const {
          properties: { semantics },
        } = label;
        if (existingEntry) {
          existingEntry.label.push(semantics);
        } else {
          filteredData.push({
            id: id,
            name: name,
            label: [semantics],
            coupon: hasCoupon,
          });
          existingEntry = filteredData[filteredData.length - 1];
        }
      });
    } else {
      if (!existingEntry) {
        filteredData.push({
          id: id,
          name: name,
          label: [],
          coupon: hasCoupon,
        });
      }
    }
  });

  return filteredData;
}

function trimOrPadProductId(str, length) {
  if (length < 0) {
    return "";
  } else if (length > str.length) {
    return str.padEnd(length, "0");
  } else {
    return str.substring(0, length);
  }
}

function DialogScheduleBuildFile({
  alert,
  setAlert,
  isOpen,
  setIsOpen,
  machineData,
  releasedBuildFiles,
  urBuildFiles,
  rdBuildFiles,
  onClickScheduleBuildFile,
  getLabelSemantics,
  labelLoading,
  getSnapshotBlobById,
}) {
  const [buildFile, setBuildFile] = useState({ id: "", machineValidity: [] });
  const [machine, setMachine] = useState([]);
  const [label, setLabel] = useState([]);
  const [labelValidation, setLabelValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [isMultiplePlatforms, setIsMultiplePlatforms] = useState(false);
  const [multiplePlatforms, setMultiplePlatforms] = useState(null);
  const [inputData, setInputData] = useState({ serial: [], unique: [], coupons: [] });
  const [expanded, setExpanded] = useState(false);
  const [splrId, setSplrId] = useState("");
  const [platformData, setPlatformData] = useState(null);
  const [snapshotId, setSnapshotId] = useState("");
  const [imagePlatform, setImagePlatform] = useState(null);
  const [key, setKey] = useState(0);
  const [keySelect, setKeySelect] = useState(0);
  const [isSelectedBuildFile, setIsSelectedBuildFile] = useState(false);

  const bfTypes = ["Research and development", "Under review", "Released"];
  const [bfType, setBfType] = useState("Released");

  const getFilteredBuildFiles = useCallback(() => {
    if (bfType === bfTypes[0]) {
      return rdBuildFiles.filter((file) => file.platforms && file.platforms.length > 0);
    } else if (bfType === bfTypes[1]) {
      return urBuildFiles.filter((file) => file.platforms && file.platforms.length > 0);
    } else if (bfType === bfTypes[2]) {
      return releasedBuildFiles.filter((file) => file.platforms && file.platforms.length > 0);
    }
    return [];
  }, [bfType]);

  let filteredBuildFiles =
    bfType === ""
      ? releasedBuildFiles.filter((file) => file.platforms && file.platforms.length > 0)
      : getFilteredBuildFiles();

  const getIfMultiplePlatforms = useCallback((array, id) => {
    if (id === null || id === undefined) {
      return;
    }
    const item = array.filter((item) => item.id === id);
    if (item[0].platforms?.length > 1) {
      setLabel([]);
      setIsMultiplePlatforms(true);
      setMultiplePlatforms(item[0].platforms);
      setPlatformData(null);
    } else {
      setLabel([]);
      setIsMultiplePlatforms(false);
      setMultiplePlatforms(null);
    }
  }, []);

  const resetAutocomplete = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const resetSelect = () => {
    setPlatformData(null);
    setImagePlatform(null);
    resetAutocomplete();
    setIsMultiplePlatforms(false);
    setBuildFile({ id: "", machineValidity: [] });
    setAlert({ message: "", severity: "", snackBar: false, component: "" });
    setIsSelectedBuildFile(false);
    setKeySelect((prevKey) => prevKey + 1);
    setLabelValidation(false);
  };

  const reset = () => {
    setKey(0);
    setKeySelect(0);
    setBuildFile("");
    setMachine("");
    setLabel([]);
    setFilteredData([]);
    setIsInitialLoad(true);
    setBuildFile({ id: "", machineValidity: [] });
    setAlert({ message: "", severity: "", snackBar: false, component: "" });
    setIsMultiplePlatforms(false);
    setMultiplePlatforms(null);
    setInputData([]);
    setSplrId("");
    setSnapshotId("");
    setImagePlatform(null);
    setPlatformData(null);
    setExpanded(false);
    setBfType("");
    setIsSelectedBuildFile(false);
    setLabelValidation(false);
  };

  /* eslint-disable */
  const onHandleAddBuildFiles = async () => {
    setIsLoading(true);

    let status = "";
    if (bfType === "Research and development") {
      status = "R&D";
    } else if (bfType === "Under review") {
      status = "Under Review";
    } else if (bfType === "Released" || bfType === "") {
      status = "Released";
    }

    const data = {
      bfm_id: buildFile.id,
      machine_id: `${machine}`,
      label_text: transformStructure(label),
      platform: splrId,
      coupon_data: null,
      lot_id: label?.serial?.[0]?.lotId ? label.serial[0].lotId : null,
      status: status,
    };

    await onClickScheduleBuildFile(data);

    setIsLoading(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (buildFile.machineValidity?.length === 0 && isSelectedBuildFile === true) {
      setAlert({
        message: "Selected build file missing machine validity.",
        severity: "error",
        snackBar: false,
        component: "DialogScheduleBuildFile",
      });
      setMachine("");
      setFilteredData([]);
    } else {
      setAlert({ message: "", severity: "", snackBar: false, component: "" });

      const filteredData = machineData.filter((machine) =>
        buildFile.machineValidity.some(
          (validMachine) => validMachine.name === machine.name && validMachine.onHold === false
        )
      );

      setFilteredData(filteredData);
    }
  }, [buildFile]);

  useLayoutEffect(() => {
    reset();
  }, [isOpen]);

  useEffect(() => {
    if (!platformData) {
      return;
    }

    const uniqueLabels = new Map();

    const newInputData = platformData
      .map(({ id, name, label, coupon }) => {
        if (!label) {
          return null;
        }
        const labels = Array.isArray(label) ? label : [label];
        const parsedData = labels
          .map((singleLabel) => {
            const semantics = new CubicLabelSemantics();
            semantics.setInputString(singleLabel);
            const parsedSchema = semantics.getSegments();

            const initialValues = parsedSchema.map((schemaField) => {
              if (schemaField.actionType === "auto") {
                return Array(schemaField.actionLength)
                  .fill(0)
                  .map((_, idx, arr) => (idx === arr.length - 1 ? 1 : 0));
              } else if (schemaField.actionType === "ctx") {
                const productId = buildFile.productId || "";
                return Array(schemaField.actionLength)
                  .fill("")
                  .map((_, idx) => productId[idx] || "");
              } else {
                return Array(schemaField.actionLength).fill("");
              }
            });

            const initialErrors = parsedSchema.map((schemaField) => Array(schemaField.actionLength).fill(""));

            const isUnique = singleLabel.includes("{uniq:");

            return {
              cleanedLabel: singleLabel,
              parsedSchema,
              inputValues: initialValues,
              inputErrors: initialErrors,
              isUnique,
            };
          })
          .filter((item) => item != null);

        if (!parsedData.length) {
          return null;
        }

        return {
          id,
          name,
          parsedData,
          isSerial: coupon || !parsedData.some((data) => data.isUnique),
          isCoupon: coupon,
        };
      })
      .filter((item) => item != null);

    newInputData.forEach((item) => {
      item.parsedData.forEach((data) => {
        const key = `${item.id}-${data.cleanedLabel}`;
        if (!uniqueLabels.has(key)) {
          uniqueLabels.set(key, { ...item, label: data.cleanedLabel });
        }
      });
    });

    const grouped = { serial: [], unique: [] };
    uniqueLabels.forEach((value, key) => {
      const { isSerial } = value;
      if (isSerial) {
        grouped.serial.push(value);
      } else {
        grouped.unique.push(value);
      }
    });

    setInputData(grouped);
  }, [platformData]);

  const handleInputChange = useCallback((partId, index, newValues, newErrors, group) => {
    setInputData((prevData) => ({
      ...prevData,
      [group]: prevData[group].map((part) =>
        part.id === partId
          ? {
              ...part,
              parsedData: part.parsedData.map((data, idx) =>
                idx === index ? { ...data, inputValues: newValues, inputErrors: newErrors } : data
              ),
            }
          : part
      ),
    }));
  }, []);

  const handleBfTypeChange = (event) => {
    setBfType(event.target.value);
  };

  function computeOutput() {
    const outputByCategory = {};

    Object.keys(inputData).forEach((category) => {
      const items = inputData[category];
      const outputMap = {};

      if (!Array.isArray(items) || items.length === 0) {
        outputByCategory[category] = [];
        return;
      }

      items.forEach((item) => {
        if (!Array.isArray(item.parsedData) || item.parsedData.length === 0) {
          return;
        }

        item.parsedData.forEach((parsedEntry) => {
          if (!Array.isArray(parsedEntry.parsedSchema) || !Array.isArray(parsedEntry.inputValues)) {
            return;
          }

          const outputSegments = [];
          let lotIdValue = "";

          parsedEntry.parsedSchema.forEach((schema, index) => {
            const values = parsedEntry.inputValues[index];
            if (!Array.isArray(values)) {
              return "";
            }

            let segmentOutput;
            switch (schema.actionType) {
              case "dyn":
                segmentOutput = "0".repeat(schema.actionLength);
                break;
              case "ctx":
                if (buildFile.productId) {
                  let pid = buildFile.productId;
                  let productLabel = trimOrPadProductId(pid, schema.actionLength);
                  segmentOutput = productLabel;
                } else {
                  segmentOutput = " ".repeat(schema.actionLength);
                }
                break;
              case "auto":
                const number = values.reduce((acc, val) => acc * 10 + val, 0);
                segmentOutput = String(number).padStart(schema.actionLength, "0");
                break;
              case "man":
                if (values.includes("")) {
                  setLabelValidation(true);
                } else {
                  setLabelValidation(false);
                }
                segmentOutput = values.join("");
                break;
              case "fixed":
                segmentOutput = schema.actionParameter;
                break;
              case "uniq":
                if (values.includes("")) {
                  setLabelValidation(true);
                } else {
                  setLabelValidation(false);
                }
                segmentOutput = values.join("");
                segmentOutput = segmentOutput.padStart(schema.actionLength, "0");
                break;
              default:
                segmentOutput = "";
            }

            outputSegments.push(segmentOutput);

            if (schema.actionParameter === "LOTID") {
              lotIdValue = segmentOutput;
            }
          });

          const labelOutput = outputSegments.filter((segment) => segment !== "").join("-");

          if (!outputMap[item.id]) {
            outputMap[item.id] = {
              name: item.name,
              labels: [],
              id: item.id,
              lotId: lotIdValue,
            };
          }

          if (!outputMap[item.id].labels.includes(labelOutput)) {
            outputMap[item.id].labels.push(labelOutput);
          }

          if (lotIdValue) {
            outputMap[item.id].lotId = lotIdValue;
          }
        });
      });

      outputByCategory[category] = Object.values(outputMap);
    });

    return outputByCategory;
  }

  useEffect(() => {
    setLabel(computeOutput());
  }, [inputData]);

  useEffect(() => {
    const fetchImage = async () => {
      if (platformData !== null) {
        const imageStr = await getSnapshotBlobById(snapshotId);
        setImagePlatform(imageStr);
      }
    };

    fetchImage();
  }, [platformData]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <DialogCommonBase
      title="Schedule build file"
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false);
        reset();
      }}
      dialogPaperSx={{ maxWidth: "100%" }}
      data-syklone="dialog-schedule-build-file"
    >
      {alert.severity === "error" &&
      alert.component === "DialogScheduleBuildFile" &&
      isInitialLoad === false &&
      labelLoading === false ? (
        <CustomAlert severity={alert.severity || "success"} sx={{ width: "100%" }}>
          {alert.message}
        </CustomAlert>
      ) : null}
      {labelLoading ? <Box sx={{ mb: 0.5 }}>Loading labels...</Box> : null}
      {imagePlatform && (
        <Box
          sx={{
            position: "relative",
            display: "flex",
            backgroundColor: "#000000",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "6px",
            marginBottom: "0.5rem",
          }}
        >
          <img
            alt={`Platform image snapshot ${snapshotId}`}
            src={imagePlatform}
            width={"560px"}
            style={{ borderRadius: "6px" }}
          />
        </Box>
      )}
      <Stack direction="column" spacing={2} sx={{ minWidth: "550px" }}>
        <div style={{ width: "100%", display: "flex", alignItems: "end", gap: "1rem" }}>
          <FormControl data-syklone="select-build-file-type" sx={{ width: "200px" }}>
            <InputLabel id="dialog-schedule-bf-type-label">Build file type</InputLabel>
            <Select
              labelId="dialog-schedule-bf-type-label"
              id="dialog-schedule-bf-type"
              value={bfType === "" ? bfTypes[2] : bfType}
              label="Build file type"
              onChange={(event) => {
                resetSelect();
                handleBfTypeChange(event);
              }}
            >
              {bfTypes.map((bf) => {
                return (
                  <MenuItem key={bf} value={bf}>
                    {bf}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl
            data-syklone="select-build-file"
            sx={{
              "& > :not(style)": { mt: 1 },
            }}
            fullWidth
          >
            <Autocomplete
              key={keySelect}
              disableClearable
              options={filteredBuildFiles}
              getOptionLabel={(filteredBuildFiles) => filteredBuildFiles.id}
              onChange={async (_event, newValue) => {
                if (_event.target.nodeName === "svg") {
                  setMultiplePlatforms(null);
                  setIsMultiplePlatforms(false);
                }
                setImagePlatform(null);
                setBuildFile(
                  newValue?.id
                    ? {
                        id: newValue.id,
                        productId: newValue.productId ? newValue.productId : null,
                        machineValidity: newValue.machineValidity,
                        partId: newValue.partSession.id,
                        ...(newValue.coupons && { coupons: newValue.coupons }),
                      }
                    : ""
                );
                setMultiplePlatforms(null);
                setIsInitialLoad(false);
                getIfMultiplePlatforms(filteredBuildFiles, newValue?.id);
                resetAutocomplete();
                setIsSelectedBuildFile(true);
                setLabelValidation(false);

                if (newValue.platforms.length === 1) {
                  let platform = await getLabelSemantics(newValue.platforms[0].platformRecipe.id);
                  setPlatformData(extractProperties(platform, buildFile));
                  setSplrId(newValue.platforms[0].platformRecipe.id);
                  setSnapshotId(removePngExtension(newValue.platforms[0].snapshots[0].file));
                }
              }}
              renderOption={(props, option) => {
                return (
                  <Grid
                    container
                    {...props}
                    key={option.id}
                    direction="column"
                    sx={{ alignItems: "baseline!important" }}
                  >
                    <Grid item>ID: {option.id}</Grid>
                    <Grid item sx={{ opacity: 0.7 }}>
                      Name: {option.name}
                    </Grid>
                  </Grid>
                );
              }}
              renderInput={(params) => <TextField {...params} label="Select build file" />}
            />
          </FormControl>
        </div>

        {isMultiplePlatforms ? (
          <>
            <FormControl data-syklone="select-platform">
              <Autocomplete
                key={key}
                disableClearable
                options={multiplePlatforms || []}
                getOptionLabel={(multiplePlatforms) => multiplePlatforms.platformRecipe.id}
                onChange={async (_event, newValue) => {
                  setSnapshotId(removePngExtension(newValue.snapshots[0].file));
                  setSplrId(newValue.platformRecipe.id);
                  let platform = await getLabelSemantics(newValue.platformRecipe.id);
                  setPlatformData(extractProperties(platform, buildFile));
                }}
                renderOption={(props, option) => {
                  return (
                    <Grid
                      container
                      {...props}
                      key={option.platformRecipe.id}
                      direction="column"
                      sx={{ alignItems: "baseline!important" }}
                    >
                      <Grid item>ID: {option.platformRecipe.id}</Grid>
                      <Grid item sx={{ opacity: 0.7 }}>
                        Name: __NAME__
                      </Grid>
                    </Grid>
                  );
                }}
                renderInput={(params) => <TextField {...params} label="Select platform" />}
              />
            </FormControl>
          </>
        ) : null}

        {buildFile.machineValidity?.length > 0 ? (
          <>
            <FormControl data-syklone="available-machines">
              <Autocomplete
                options={filteredData}
                getOptionLabel={(filteredData) => filteredData.name}
                onChange={(_event, newValue) => {
                  setMachine(newValue?.id ? newValue.id : "");
                }}
                disabled={alert.severity === "error"}
                renderInput={(params) => <TextField {...params} label="Available machines" />}
              />
            </FormControl>

            {platformData !== null && (
              <FormControl data-syklone="label">
                <div>
                  {inputData?.serial?.length > 0 && (
                    <StyledAccordion
                      expanded={expanded === "serial"}
                      onChange={handleAccordionChange("serial")}
                      disableGutters
                    >
                      <AccordionSummary expandIcon={<icons.mui.ExpandMore />}>
                        <Typography variant="h6">Serial labels</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "0px 8px 16px 16px" }}>
                        {(() => {
                          const uniqueIds = new Set();
                          const uniqueParts = inputData.serial.filter((part) => {
                            if (!uniqueIds.has(part.id)) {
                              uniqueIds.add(part.id);
                              return true;
                            }
                            return false;
                          });
                          return uniqueParts.map((part, index) => (
                            <div key={`${part.id}_${index}`} style={{ marginBottom: "1rem" }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                {part.isCoupon ? (
                                  <span>
                                    {part.name} <i>*coupon</i>
                                  </span>
                                ) : (
                                  part.name
                                )}
                              </Typography>
                              {part.parsedData.map((data, dataIndex) => (
                                <WidgetLabelInput
                                  key={dataIndex}
                                  parsedSchema={data.parsedSchema}
                                  inputValues={data.inputValues}
                                  inputErrors={data.inputErrors}
                                  onInputChange={(newValues, newErrors) =>
                                    handleInputChange(part.id, dataIndex, newValues, newErrors, "serial")
                                  }
                                />
                              ))}
                            </div>
                          ));
                        })()}
                      </AccordionDetails>
                    </StyledAccordion>
                  )}

                  {inputData?.unique?.length > 0 && (
                    <StyledAccordion
                      expanded={expanded === "unique"}
                      onChange={handleAccordionChange("unique")}
                      disableGutters
                    >
                      <AccordionSummary expandIcon={<icons.mui.ExpandMore />}>
                        <Typography variant="h6">Unique labels</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={{ padding: "0px 8px 16px 16px" }}>
                        {(() => {
                          const uniqueIds = new Set();
                          const uniqueParts = inputData.unique.filter((part) => {
                            if (!uniqueIds.has(part.id)) {
                              uniqueIds.add(part.id);
                              return true;
                            }
                            return false;
                          });
                          return uniqueParts.map((part, index) => (
                            <div key={`${part.id}_${index}`} style={{ marginBottom: "1rem" }}>
                              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                {part.isCoupon ? `${part.name} *coupon` : part.name}
                              </Typography>
                              {part.parsedData.map((data, dataIndex) => (
                                <WidgetLabelInput
                                  key={dataIndex}
                                  parsedSchema={data.parsedSchema}
                                  inputValues={data.inputValues}
                                  inputErrors={data.inputErrors}
                                  onInputChange={(newValues, newErrors) =>
                                    handleInputChange(part.id, dataIndex, newValues, newErrors, "unique")
                                  }
                                />
                              ))}
                            </div>
                          ));
                        })()}
                      </AccordionDetails>
                    </StyledAccordion>
                  )}
                </div>
              </FormControl>
            )}

            <Button
              variant="contained"
              size="large"
              onClick={onHandleAddBuildFiles}
              data-syklone="schedule-build-file-button"
              disabled={
                buildFile.length === 0 ||
                machine.length === 0 ||
                label.length === 0 ||
                labelValidation === true ||
                isLoading
              }
            >
              {isLoading ? "Loading..." : "Schedule build file"}
            </Button>
          </>
        ) : null}
      </Stack>
    </DialogCommonBase>
  );
}

DialogScheduleBuildFile.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  machineData: PropTypes.array,
  releasedBuildFiles: PropTypes.array,
  urBuildFiles: PropTypes.array,
  rdBuildFiles: PropTypes.array,
  onClickScheduleBuildFile: PropTypes.func,
  getLabelSemantics: PropTypes.func,
  labelLoading: PropTypes.bool,
  getSnapshotBlobById: PropTypes.func,
};

export default DialogScheduleBuildFile;
