import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { useFormik } from "syklone/ui/index.js";

// imports: local
import { DialogCommonConfirmation, DialogResponseMessage } from "../../dialogs/index.js";
import { WidgetEntityAssignment, WidgetElectronicSignature } from "../index.js";

const WidgetEntityAssignmentDialog = ({
  title,
  onSubmitSuccess,
  isOpen,
  setIsOpen,
  error,
  singleEntityFieldName,
  multipleEntityFieldName,
  singleEntityName,
  multipleEntityLabel,
  multipleEntityOptions,
}) => {
  const widgetElectronicSignatureRef = React.useRef(null);
  const formik = useFormik({
    initialValues: {
      username: "",
      userSigned: false,
      userComment: "",
      [multipleEntityFieldName]: [],
    },
    onSubmit: (values) => {
      const { username, userSigned, userComment } = values;
      widgetElectronicSignatureRef.current?.setSignatureError(false);
      if (!values.username || !values.userSigned) {
        widgetElectronicSignatureRef.current?.setSignatureError(true);
        return; // Exit the onSubmit function early
      }
      const payload = { ["user_signed_email"]: username, ["user_signed"]: userSigned, comment: userComment };
      onSubmitSuccess && onSubmitSuccess(payload);
    },
  });

  const { values, touched, errors, setFieldValue, handleChange } = formik;

  React.useEffect(() => {
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleMultipleEntityChange = (event) => {
    setFieldValue(multipleEntityFieldName, event.target.value);
  };

  return (
    <DialogCommonConfirmation
      contentHeight={50}
      title={title}
      onConfirm={() => formik.submitForm()}
      onCancel={handleCloseDialog}
      onClose={handleCloseDialog}
      isOpen={isOpen}
      showClose
      disableBackdropClick
    >
      <form>
        <DialogResponseMessage message={error} widgetName="dialog-permission-map" />
        <WidgetEntityAssignment
          singleEntityName={singleEntityName}
          singleEntityValue={values[singleEntityFieldName]}
          multipleEntityFieldName={multipleEntityFieldName}
          multipleEntityLabel={multipleEntityLabel}
          multipleEntityOptions={multipleEntityOptions}
          multipleEntityValue={values[multipleEntityFieldName]}
          handleMultipleEntityChange={handleMultipleEntityChange}
          multipleEntityError={errors[multipleEntityFieldName]}
          multipleEntityTouched={touched[multipleEntityFieldName]}
          singleEntityFieldName={singleEntityFieldName}
        />
        <WidgetElectronicSignature values={values} setFieldValue={setFieldValue} handleChange={handleChange} />
      </form>
    </DialogCommonConfirmation>
  );
};

WidgetEntityAssignmentDialog.displayName = "WidgetAuthenticationDialog";

WidgetEntityAssignmentDialog.propTypes = {
  onSubmitSuccess: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  title: PropTypes.string,
  error: PropTypes.string,
  singleEntityName: PropTypes.string.isRequired,
  singleEntityValue: PropTypes.string.isRequired,
  multipleEntityFieldName: PropTypes.string.isRequired,
  multipleEntityLabel: PropTypes.string.isRequired,
  multipleEntityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  singleEntityField: PropTypes.object.isRequired,
  multipleEntityField: PropTypes.object.isRequired,
  multipleEntityMeta: PropTypes.object.isRequired,
  singleEntityFieldName: PropTypes.string.isRequired,
};

export default WidgetEntityAssignmentDialog;
