import React from "react";

const DEBOUNCE_TIME = 300;
const SEARCH_TEXT_LENGTH_FILTERING = 3;

const useSearchableData = (data, searchFields) => {
  const [searchText, setSearchText] = React.useState("");
  const [debouncedSearchText, setDebouncedSearchText] = React.useState(searchText);

  const handleSearchChange = (value) => {
    setSearchText(value);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, DEBOUNCE_TIME); // 300ms debounce time

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  const filteredData = React.useMemo(() => {
    if (!debouncedSearchText || debouncedSearchText.length < SEARCH_TEXT_LENGTH_FILTERING) return data;

    const searchRegex = new RegExp(debouncedSearchText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"), "i");
    const warnedFields = new Set();

    const filtered = data.filter((item) => {
      return searchFields.some((field) => {
        if (item[field] !== undefined) {
          // Check if the field exists in the item
          return searchRegex.test(item[field]?.toString());
        } else {
          if (!warnedFields.has(field)) {
            // Only warn once per field across all data
            console.warn(`There is no field with name ${field}`);
            warnedFields.add(field);
          }
          return false;
        }
      });
    });

    return filtered;
  }, [data, debouncedSearchText, searchFields]);

  return { searchText, handleSearchChange, clearSearch, filteredData };
};

export default useSearchableData;
