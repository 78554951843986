import React from "react";
import PropTypes from "prop-types";
import { Typography, styled } from "@mui/material";

const ColoredTypography = styled(({ type, ...rest }) => <Typography {...rest} />)(({ theme, type }) => {
  let color;
  switch (type?.toLowerCase()) {
    case "success":
      color = theme.palette.success.main;
      break;
    case "warning":
      color = theme.palette.warning.dark;
      break;
    case "error":
      color = theme.palette.error.main;
      break;
    case "unlisted":
      color = theme.palette.grey[800];
      break;
    default:
      color = theme.palette.text.primary;
  }

  return {
    color: color,
    fontWeight: 500,
  };
});

function WidgetColoredText({ type, children, ...rest }) {
  return (
    <ColoredTypography type={type} {...rest}>
      {children}
    </ColoredTypography>
  );
}

WidgetColoredText.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

export default WidgetColoredText;
