/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, Box, Grid, icons, Tabs, Tab, styled } from "syklone/ui/index.js";
import {
  arrayMove,
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  SortableContext,
  sortableKeyboardCoordinates,
  useSensor,
  useSensors,
  verticalListSortingStrategy,
} from "syklone/libraries/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { WidgetTitle, WidgetContainer, WidgetMachineItem } from "../../widgets/index.js";
import {
  DialogToggleActivation,
  DialogMachine,
  DialogStartBuildFile,
  DialogElectronicSignature,
} from "../../dialogs/index.js";
import { BuildFileItem, DriftInterface } from "./components/index.js";
import { buildFilesData } from "./data/data.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`machine-build-files-tabpanel-${index}`}
      aria-labelledby={`machine-build-files-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3, pb: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function accessibilityProps(index) {
  return {
    id: `machine-build-files-tab-${index}`,
    "aria-controls": `machine-build-files-tabpanel-${index}`,
  };
}

const CustomGrid = styled(Grid)({
  width: "100%",
});

const CustomBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const TabBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#2F2F2F" : "#E8E8E8"}`,
}));

const CustomBoxInner = styled(Box)({
  background: "#000",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "20px",
  marginTop: "20px",
  borderRadius: "6px",
});

function PageMachine({
  data,
  scheduledBuildFilesData,
  isLoading,
  handleRefreshData,
  error,
  optionsData,
  dialogFn,
  dialogState,
  postBfmPrintApproved,
  postBfmSwitchLockByMachineId,
  postBfmSwitchUnlockByMachineId,
  isMachineLocked,
}) {
  const apiInstance = useApiContext();
  const { showSnackbar } = useAlertSnackbar();
  const machineData = data;
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const [buildFileData, setBuildFileData] = useState([]);
  const dialogMachineRef = React.useRef(null);
  const dialogActivationRef = React.useRef(null);
  const dialogEletronicSignatureDriftRef = React.useRef(null);
  const [showBuildFilePanels, setShowBuildFilePanels] = useState(true);
  const [isDialogueDriftOpen, setIsDialogueDriftOpen] = React.useState(false);

  const { checkElementVisibility } = usePermissionChecks();

  const { setBuildStartDialogOpen } = dialogFn;
  const { buildStartDialogOpen } = dialogState;

  useEffect(() => {
    setBuildFileData(scheduledBuildFilesData?.buildFileData);
  }, [scheduledBuildFilesData]);

  const setIsDialogOpenFn = (val) => {
    dialogMachineRef.current?.setIsDialogOpen(val);
  };

  const setDialogEditDataFn = (val) => {
    dialogMachineRef.current?.setDialogData(val);
  };

  const setDialogEditMachineDataFn = (val) => {
    dialogActivationRef.current?.setDialogData(val);
  };

  const handleDialogSignatureOpen = (value) => {
    dialogActivationRef.current?.setIsDialogOpen(value);
  };

  const setDialogEditIsSubmitting = (value) => {
    dialogActivationRef.current?.setIsSubmitting(value);
  };

  const setDialogResponseErrorMessage = (text) => {
    dialogMachineRef.current?.setResponseMessage({ type: "error", text });
  };

  const handleDialogSignatureDriftOpen = (value) => {
    dialogEletronicSignatureDriftRef.current?.setIsDialogOpen(value);
  };

  const setDialogElectronicSignatureDriftIsSubmitting = (value) => {
    dialogEletronicSignatureDriftRef.current?.setIsSubmitting(value);
  };

  const setDialogElectronicSignatureDriftData = (payload, selectedSize) => {
    dialogEletronicSignatureDriftRef.current?.setDialogData({
      payload,
      selectedSize,
    });
  };

  const setDialogElectronicSignatureDriftErrorMessage = (text) => {
    dialogEletronicSignatureDriftRef.current?.setResponseMessage({ type: "error", text });
  };

  const setIsDialogueDriftOpenFn = (val) => {
    setIsDialogueDriftOpen(val);
    setShowBuildFilePanels(!val);
  };

  const saveMachineDetailsDriftFn = async (esPayload, { payload, selectedSize }) => {
    if (!machineData.drift) {
      machineData.drift = {};
    }

    machineData.drift[selectedSize] = payload;

    const driftPayload = {
      metadata: {
        drift: JSON.stringify(machineData.drift),
      },
      signature: esPayload,
    };

    const machineId = machineData.id;
    try {
      setDialogElectronicSignatureDriftIsSubmitting(true);
      const response = await serviceAuth.putAdminMachineDriftByMachineAccountId(machineId, driftPayload);
      const responseMessage = response?.data?.message ? response.data.message : "Machine drift successfully updated";
      handleDialogSignatureDriftOpen(false);
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e.detail === "string" ? e.detail : "Machine drift was not successfully updated!";
      setDialogElectronicSignatureDriftErrorMessage(responseMessage);
    } finally {
      setDialogElectronicSignatureDriftIsSubmitting(false);
    }
  };

  const handleMachineStatusChange = async (dialogEditData) => {
    const id = dialogEditData?.id;
    if (id) {
      try {
        const isActive = dialogEditData?.isActive;
        let response;
        if (isActive) {
          response = await serviceAuth.postAdminEquipmentDectivateByEquipmentAccountId(id);
        } else {
          response = await serviceAuth.postAdminEquipmentActivateByEquipmentAccountId(id);
        }
        const responseMessage = response?.message ? response.message : "Status was successfully updated!";
        showSnackbar(responseMessage, "success");
        handleDialogSignatureOpen(false);
        handleRefreshData && handleRefreshData();
      } catch (e) {
        const message = typeof e?.detail === "string" ? e.detail : "Status was not successfully updated!";
        showSnackbar(message, "error");
        handleDialogSignatureOpen(false);
      }
    } else {
      showSnackbar("Status was not successfully updated!", "error");
      handleDialogSignatureOpen(false);
    }
  };

  const handleDialogMachineEdit = async (id, payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.putAdminEquipmentByEquipmentAccountId(id, payload);
      const responseMessage = response?.data?.message ? response.data.message : "Machine successfully updated";
      setIsDialogOpenFn(false);
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Machine was not successfully updated!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  const onDriftElectronicSignatureModal = (payload, selectedSize) => {
    handleDialogSignatureDriftOpen(true);
    setDialogElectronicSignatureDriftData(payload, selectedSize);
  };

  const [value, setValue] = useState(0);
  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setBuildFileData((items) => {
        const oldIndex = items.findIndex((item) => item.metadata.jobId === active.id);
        const newIndex = items.findIndex((item) => item.metadata.jobId === over.id);
        console.log(`Moved from position ${oldIndex + 1} to ${newIndex + 1}`);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const onApproveApproved = async (payload) => {
    await postBfmPrintApproved(payload);
  };

  return (
    <>
      {scheduledBuildFilesData ? (
        <DialogStartBuildFile
          onClose={setBuildStartDialogOpen}
          isOpen={buildStartDialogOpen}
          data={scheduledBuildFilesData}
          onApprovePart={() => null}
          onApproveApproved={onApproveApproved}
          postBfmSwitchLockByMachineId={postBfmSwitchLockByMachineId}
          postBfmSwitchUnlockByMachineId={postBfmSwitchUnlockByMachineId}
          isPlayDialogLoading={isLoading}
        />
      ) : null}
      <Grid container rowSpacing={3}>
        <CustomGrid item>
          <WidgetContainer isLoading={isLoading} error={error}>
            <CustomBox>
              <WidgetTitle title={machineData.name} isBackButton={true} />
            </CustomBox>
          </WidgetContainer>
        </CustomGrid>
        <CustomBoxInner>
          <CustomGrid item>
            <WidgetMachineItem
              data={machineData}
              setters={{
                setIsDialogueDriftOpenFn: setIsDialogueDriftOpenFn,
                setDialogEditDataFn: setDialogEditDataFn,
                setIsDialogEditEquipmentOpenFn: setIsDialogOpenFn,
                setIsDialogDeleteEquipmentOpenFn: handleDialogSignatureOpen,
                setDialogEditMachineDataFn: setDialogEditMachineDataFn,
              }}
            />
          </CustomGrid>
          {showBuildFilePanels && buildFileData?.length > 0 && (
            <CustomGrid item>
              <Box>
                <TabBox>
                  <Tabs value={value} onChange={handleChange} aria-label="part promotion tabs">
                    <Tab label="Build file queue" {...accessibilityProps(0)} />
                  </Tabs>
                </TabBox>
                <TabPanel value={value} index={0}>
                  <Box>
                    <Box sx={{ paddingBottom: 2, borderBottom: "1px solid #2F2F2F" }}>
                      <Button
                        variant="contained"
                        startIcon={<icons.mui.PlayArrow />}
                        onClick={() => {
                          setBuildStartDialogOpen(true);
                        }}
                        disabled={
                          !checkElementVisibility(PermissionVisualElements.schedulingSchedulePlayButton) ||
                          isMachineLocked
                        }
                      >
                        Run a printing task
                      </Button>
                    </Box>
                    <Box>
                      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext
                          items={buildFileData?.map((item) => item.metadata?.jobId)}
                          strategy={verticalListSortingStrategy}
                        >
                          {buildFileData?.map((item, index) => (
                            <React.Fragment key={`${item.id}_${item.metadata.jobId}`}>
                              <BuildFileItem data={item} index={index + 1} isDraggable={true} id={item.id} />
                            </React.Fragment>
                          ))}
                        </SortableContext>
                      </DndContext>
                    </Box>
                  </Box>
                </TabPanel>
              </Box>
            </CustomGrid>
          )}
        </CustomBoxInner>
        {isDialogueDriftOpen && (
          <DriftInterface
            buidFileData={buildFilesData[0]}
            machineData={machineData}
            saveMachineDetailsDriftFn={(payload, selectedSize) => {
              onDriftElectronicSignatureModal(payload, selectedSize);
            }}
          />
        )}
      </Grid>

      <DialogMachine ref={dialogMachineRef} optionsData={optionsData} onDialogEdit={handleDialogMachineEdit} />
      <DialogToggleActivation
        ref={dialogActivationRef}
        toggleActionName="machine"
        onConfirm={handleMachineStatusChange}
      />
      <DialogElectronicSignature ref={dialogEletronicSignatureDriftRef} onSubmitSuccess={saveMachineDetailsDriftFn} />
    </>
  );
}

PageMachine.propTypes = {
  data: PropTypes.object,
  scheduledBuildFilesData: PropTypes.object,
  error: PropTypes.object,
  buildFileData: PropTypes.array,
  handleRefreshData: PropTypes.func,
  isLoading: PropTypes.bool,
  optionsData: PropTypes.shape({
    locations: PropTypes.array.isRequired,
    machineDefinitions: PropTypes.array.isRequired,
  }).isRequired,
  dialogFn: PropTypes.object,
  dialogState: PropTypes.object,
  postBfmPrintApproved: PropTypes.func,
  postBfmSwitchLockByMachineId: PropTypes.func,
  postBfmSwitchUnlockByMachineId: PropTypes.func,
  isMachineLocked: PropTypes.bool,
};

export default PageMachine;
