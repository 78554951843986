import React from "react";

// imports: local
import { dataVersions, dataDashboardPodMetrics, dataDashboardNodes } from "../../data/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery } from "syklone/libraries/index.js";
import { convertKubernetesUnits } from "./helpers/index.js";

const withUserAdminStatistics = (Component) => {
  /*
  const UserAdminStatistics = () => {
    const [data] = React.useState({
      dataUsers: dataUsers,
      dataVersions: dataVersions,
      dataResourceHealth: dataResourceHealth,
      dataServiceStatus: dataServiceStatus,
    });
    return (
      <Component
        data={data}
      />
    );
  };
  */
  const UserAdminStatistics = () => {
    const apiInstance = useApiContext();

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;

    const {
      data: dashboardUsers = [],
      isLoading: isLoadingDashboardUsers,
      error: dashboardUsersError,
    } = useQuery(["dashboardUsers"], async () => {
      const response = await serviceAuth.getAdminAccounts();
      const data = response.data.data;
      const counts = data.reduce((acc, item) => {
        if (item.is_active) {
          acc.active = (acc.active || 0) + 1;
        } else {
          acc.inactive = (acc.inactive || 0) + 1;
        }
        return acc;
      }, {});

      return [
        {
          id: "active",
          label: "Active",
          value: counts.active || 0,
          color: "#FFB500",
        },
        {
          id: "inactive",
          label: "Inactive",
          value: counts.inactive || 0,
          color: "#0F6FB5",
        },
      ];
    });

    const {
      data: dashboardVersions = [],
      isLoading: isLoadingDashboardVersions,
      error: dashboardVersionsError,
    } = useQuery(["dashboardVersions"], async () => {
      /*
      const response = await serviceAuth.getAdminEquipment();
      const data = response.data.data;
      const modifiedData = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          site: item.site,
          domain: item["domain_name"],
          description: item.description,
          endpoint: item.endpoint,
          status: item["is_qualified_for_production"],
          isActive: !!item["is_qualified_for_production"],
          meta: item["meta"],
          drift: item["drift"],
          machineDefinition: item["machine_definition_id"],
        };
      });
      */
      return dataVersions;
    });

    const {
      data: dataPodMetrics = [],
      isLoading: isLoadingPodMetrics,
      error: podMetricsError,
    } = useQuery(["dashboardPodMetrics"], async () => {
      const data = dataDashboardPodMetrics;
      const modifiedData = data.map((item, idx) => {
        const { name, status, containers } = item;
        return {
          id: idx + 1,
          name,
          status,
          containers: containers.map((containerItem) => {
            return {
              name: containerItem.name,
              usage: {
                cpu: convertKubernetesUnits(containerItem.usage.cpu, "cpu"),
                memory: convertKubernetesUnits(containerItem.usage.memory, "memory"),
              },
            };
          }),
        };
      });
      return modifiedData;
    });

    const {
      data: dataNodes = [],
      isLoading: isLoadingDataNodes,
      error: dataNodesError,
    } = useQuery(["dashboardNodes"], async () => {
      const data = dataDashboardNodes;
      const modifiedData = data.map((item, idx) => {
        const { name, type, region, cluster, imageversion, usage } = item;
        return {
          id: idx + 1,
          name,
          type,
          region,
          cluster,
          version: imageversion,
          allocatedCpu: convertKubernetesUnits(usage.allocated.cpu, "cpu"),
          allocatedMemory: convertKubernetesUnits(usage.allocated.memory, "memory"),
          capacityCpu: convertKubernetesUnits(usage.capacity.cpu, "cpu"),
          capacityMemory: convertKubernetesUnits(usage.capacity.memory, "memory"),
        };
      });
      return modifiedData;
    });

    return (
      <Component
        users={{
          data: dashboardUsers,
          isLoading: isLoadingDashboardUsers,
          error: dashboardUsersError,
        }}
        versions={{
          data: dashboardVersions,
          isLoading: isLoadingDashboardVersions,
          error: dashboardVersionsError,
        }}
        dataPodMetrics={{
          data: dataPodMetrics,
          isLoading: isLoadingPodMetrics,
          error: podMetricsError,
        }}
        dataNodes={{
          data: dataNodes,
          isLoading: isLoadingDataNodes,
          error: dataNodesError,
        }}
      />
    );
  };
  UserAdminStatistics.displayName = "UserAdminStatistics";
  UserAdminStatistics.propTypes = {};
  return UserAdminStatistics;
};

export default withUserAdminStatistics;
