import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Alert, Box, Button, Stack } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

function DialogAddFiles({ alert, setAlert, id, isLoading, isOpen, setIsOpen, filesList, setFilesList, onSubmit }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");

  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
      const files = e.dataTransfer.files;
      setFilesList(files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target?.files && e.target.files[0]) {
      const files = e.target.files;
      setFilesList(files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    setAlert({ message: "", severity: "" });
    inputRef.current.click();
  };

  // cancels upload
  const cancelUpload = () => {
    setFilesList(null);
    setError("");
    setAlert({ message: "", severity: "", component: "" });
  };

  // on dialog close
  const onClose = (_event, reason) => {
    if (isLoading && reason && reason === "backdropClick" && "escapeKeyDown") {
      return;
    }
    setIsOpen(false);
    setAlert({ message: "", severity: "" });
    setFilesList(null);
    cancelUpload();
  };

  // upload files
  const doUpload = async () => {
    await onSubmit(id);
  };

  useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen]);

  return (
    <DialogCommonBase
      title="Upload files"
      isOpen={isDialogOpen}
      onClose={onClose}
      data-syklone="upload-product-family-files"
    >
      {alert?.severity === "error" && alert?.component === "DialogAddFiles" ? (
        <Alert severity={alert.severity || "success"} sx={{ width: "100%", marginBottom: "0.5rem" }}>
          {alert?.message}
        </Alert>
      ) : null}
      <Box>
        {filesList === null ? (
          <Box
            component="form"
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
            sx={{
              width: "28rem",
              maxWidth: "100%",
              textAlign: "center",
              position: "relative",
            }}
          >
            <Box
              sx={{
                "& > input": {
                  display: "none",
                },
                "& > label": {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0.5rem 0",
                  borderWidth: "2px",
                  borderRadius: "1rem",
                  borderStyle: "dashed",
                  borderColor: "rgba(255, 255, 255, 0.4)",
                  ...(dragActive && {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  }),
                },
              }}
            >
              <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} />
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div>
                  <p>Drag and drop files here or</p>
                  <Button onClick={onButtonClick} variant="text" data-syklone="add-files-button">
                    Select a file
                  </Button>
                </div>
              </label>
            </Box>
            {dragActive && (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: "1rem",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></Box>
            )}
            {error && (
              <Box
                component="p"
                sx={{
                  color: "red",
                  marginTop: "1rem",
                  marginBottom: "0px",
                }}
              >
                {error}
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ width: "28rem" }}>
            {filesList?.length === 1 ? (
              <Box data-syklone="product-files-count-message">1 file added for upload.</Box>
            ) : (
              <Box data-syklone="product-files-count-message">{filesList?.length} files added for upload.</Box>
            )}
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Stack direction="row" spacing={1}>
                <Button variant="text" onClick={cancelUpload} data-syklone="cancel-upload-button" disabled={isLoading}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={doUpload} data-syklone="upload-files-button" disabled={isLoading}>
                  {isLoading ? "Uploading..." : "Upload files"}
                </Button>
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </DialogCommonBase>
  );
}

DialogAddFiles.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  filesList: PropTypes.any,
  setFilesList: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default DialogAddFiles;
