import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { styled, Box } from "syklone/ui";
import SaveDriftButton from "./save_drift_button.jsx";
import { getGridItems } from "../data/grid_items.js";
import _ from "lodash";

const Table = styled("table")({
  marginTop: "30px",
  width: "90%",
  padding: "9px",
  "input[type=number]": {
    WebkitAppearance: "none",
    margin: 0,
    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "&::-ms-clear, &::-ms-reveal": {
      display: "none",
      width: 0,
      height: 0,
    },
    "&::-ms-expand": {
      display: "none",
    },
  },
  "th:first-of-type, td:first-of-type": {
    textAlign: "center",
  },
});

const BoxTableContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#E0E0E0",
  marginTop: "10px",
}));

const DriftTableInput = ({
  saveMachineDetailsDriftFn,
  selectedSize,
  setHoveredItem,
  isUpdate,
  driftValues,
  setDriftValues,
}) => {
  const [driftTableValues, setDriftTableValues] = useState(driftValues);
  const [gridItems, setGridItems] = useState([]);
  const [localValues, setLocalValues] = useState({});
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setDriftTableValues(driftValues);
  }, [driftValues]);

  useEffect(() => {
    const newGridItems = getGridItems(selectedSize);
    setGridItems(newGridItems);

    if (driftTableValues[selectedSize]) {
      const initialLocalValues = driftTableValues[selectedSize].reduce((acc, item) => {
        acc[item.number] = {
          relx: item.relx?.toString() || "",
          rely: item.rely?.toString() || "",
        };
        return acc;
      }, {});
      setLocalValues(initialLocalValues);
    } else {
      setLocalValues({});
    }
  }, [selectedSize, driftTableValues]);

  const handleLocalInputChange = (number, newValue, type) => {
    setLocalValues((prev) => ({
      ...prev,
      [number]: {
        ...prev[number],
        [type]: newValue,
      },
    }));

    debouncedHandleInputChange(number, newValue, type);
  };

  const handleInputChange = (number, newValue, type) => {
    let updatedDriftTableValues = { ...driftTableValues };
    if (!updatedDriftTableValues[selectedSize]) {
      updatedDriftTableValues[selectedSize] = [];
    }

    const itemIndex = updatedDriftTableValues[selectedSize].findIndex((item) => item.number === number);
    if (itemIndex !== -1) {
      updatedDriftTableValues[selectedSize][itemIndex] = {
        ...updatedDriftTableValues[selectedSize][itemIndex],
        [type]: newValue ? parseFloat(newValue) : null,
      };
    } else {
      updatedDriftTableValues[selectedSize].push({
        number: number,
        [type]: newValue ? parseFloat(newValue) : null,
      });
    }
    setHasChanges(true);
    setDriftTableValues(updatedDriftTableValues);
    setDriftValues(updatedDriftTableValues);
  };

  const debouncedHandleInputChange = useCallback(_.debounce(handleInputChange, 300), [
    driftTableValues,
    selectedSize,
    setDriftValues,
  ]);

  const getDriftValue = (itemNumber, type) => {
    if (localValues[itemNumber] && localValues[itemNumber][type] !== undefined) {
      return localValues[itemNumber][type];
    }
    return "";
  };

  const onClickSave = () => {
    saveMachineDetailsDriftFn(driftTableValues[selectedSize], selectedSize);
  };

  return (
    <>
      <BoxTableContainer>
        <Table>
          <thead>
            <tr>
              <th>Order</th>
              <th className="align-left">Relative X (mm)</th>
              <th className="align-left">Relative Y (mm)</th>
            </tr>
          </thead>
          <tbody>
            {gridItems &&
              Array.isArray(gridItems) &&
              gridItems.map((item, index) => (
                <tr
                  key={index}
                  onMouseEnter={() => setHoveredItem(item.number)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <td>{item.number}</td>
                  <td>
                    <input
                      type="number"
                      readOnly={!isUpdate}
                      value={getDriftValue(item.number, "relx")}
                      step="any"
                      data-syklone={`input-relativex-${item.number}`}
                      onChange={(e) => handleLocalInputChange(item.number, e.target.value, "relx")}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      readOnly={!isUpdate}
                      step="any"
                      value={getDriftValue(item.number, "rely")}
                      data-syklone={`input-relativey-${item.number}`}
                      onChange={(e) => handleLocalInputChange(item.number, e.target.value, "rely")}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </BoxTableContainer>
      {isUpdate && <SaveDriftButton onClickSave={onClickSave} disabled={!hasChanges} aria-label="Save drift values" />}
    </>
  );
};

DriftTableInput.propTypes = {
  saveMachineDetailsDriftFn: PropTypes.func.isRequired,
  selectedSize: PropTypes.string.isRequired,
  setHoveredItem: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  driftValues: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.number.isRequired,
        relx: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
        rely: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
      })
    )
  ).isRequired,
  setDriftValues: PropTypes.func.isRequired,
};

export default DriftTableInput;
