export const convertKubernetesUnits = (value, type) => {
  const numericValue = parseInt(value.replace(/\D/g, "")); // Strip non-numeric characters for calculation
  const unit = value.replace(/[0-9]/g, "").trim(); // Extract unit

  if (type === "cpu") {
    // Assume CPU units are in 'n' (nanocores)
    const cores = numericValue / 1e9; // Convert nanocores to cores
    if (cores < 1) {
      return `${(cores * 1000).toFixed(2)}m cores`; // Convert cores to millicores for smaller values
    }
    return `${cores.toFixed(2)} cores`;
  } else if (type === "memory" || type === "storage") {
    // Handle memory and storage similarly as they can use similar units
    if (unit.startsWith("Ki")) {
      const kibibytes = numericValue;
      if (kibibytes < 1024) return `${kibibytes.toFixed(2)} KiB`;
      const mebibytes = kibibytes / 1024;
      if (mebibytes < 1024) return `${mebibytes.toFixed(2)} MiB`;
      const gibibytes = mebibytes / 1024;
      if (gibibytes < 1024) return `${gibibytes.toFixed(2)} GiB`;
      const tebibytes = gibibytes / 1024;
      return `${tebibytes.toFixed(2)} TiB`;
    } else {
      // Assume decimal units (B, KB, MB, GB, TB)
      const bytes =
        numericValue *
        (unit.startsWith("K")
          ? 1000
          : unit.startsWith("M")
          ? 1e6
          : unit.startsWith("G")
          ? 1e9
          : unit.startsWith("T")
          ? 1e12
          : 1);
      if (bytes < 1000) return `${bytes} B`;
      const kilobytes = bytes / 1000;
      if (kilobytes < 1000) return `${kilobytes.toFixed(2)} KB`;
      const megabytes = kilobytes / 1000;
      if (megabytes < 1000) return `${megabytes.toFixed(2)} MB`;
      const gigabytes = megabytes / 1000;
      if (gigabytes < 1000) return `${gigabytes.toFixed(2)} GB`;
      const terabytes = gigabytes / 1000;
      return `${terabytes.toFixed(2)} TB`;
    }
  } else {
    return "Unsupported unit type";
  }
};
