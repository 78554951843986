import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";

const WidgetMessageViewer = ({ children, centered }) => {
  const centeredStyle = centered
    ? {
        display: "flex",
        marginTop: "20px",
        justifyContent: "center",
      }
    : {};
  return <Box sx={{ marginBottom: "20px", ...centeredStyle }}>{children}</Box>;
};

WidgetMessageViewer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  centered: PropTypes.bool,
};

export default WidgetMessageViewer;
