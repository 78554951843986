import React from "react";
import PropTypes from "prop-types";

// imports: local
import { WidgetColoredText } from "../../widgets/index.js";

const TableDataColoredTextCell = ({ condition, trueText, falseText }) => {
  if (typeof condition === "boolean") {
    const type = condition ? "success" : "error";
    const text = condition ? trueText : falseText;
    return <WidgetColoredText type={type}>{text}</WidgetColoredText>;
  } else {
    return null;
  }
};

TableDataColoredTextCell.propTypes = {
  condition: PropTypes.bool.isRequired,
  trueText: PropTypes.string,
  falseText: PropTypes.string,
};

TableDataColoredTextCell.defaultProps = {
  trueText: "true",
  falseText: "false",
};

export default TableDataColoredTextCell;
