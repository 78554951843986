import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, styled } from "syklone/ui";
import { getGridItems } from "../data/grid_items.js";
import PlatformDisplay from "./platform_display.jsx";
import SizeSelector from "./size_selector.jsx";
import DriftTableInput from "./drift_table_input.jsx";

const BoxPlatformHolder = styled(Box)(() => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
}));

const BoxDataThings = styled(Box)(() => ({
  minWidth: "310px",
  marginRight: "20px",
}));

const BoxContainer = styled(Box)(() => ({
  display: "flex",
  height: "100%",
}));

const PlatformDriftViewer = ({ widthMM, heightMM, isUpdate, machineData, saveMachineDetailsDriftFn }) => {
  const [selectedSize, setSelectedSize] = useState("6");
  const containerRef = useRef(null);
  const [gridItems, setGridItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [driftValues, setDriftValues] = useState([]);

  useEffect(() => {
    const newGridItems = getGridItems(selectedSize);
    setGridItems(newGridItems);
  }, [selectedSize]);

  useEffect(() => {
    if (machineData.drift && Object.keys(driftValues).length === 0) {
      setDriftValues(machineData.drift);
    }
  }, [machineData.drift, driftValues]);

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const setNewDriftValues = (newValues) => {
    setDriftValues((prevValues) => ({
      ...prevValues,
      [selectedSize]: newValues[selectedSize],
    }));
  };

  return (
    <BoxContainer ref={containerRef}>
      <BoxDataThings>
        <SizeSelector selectedSize={selectedSize} handleSizeChange={handleSizeChange} />
        <DriftTableInput
          saveMachineDetailsDriftFn={saveMachineDetailsDriftFn}
          selectedSize={selectedSize}
          driftValues={driftValues}
          setHoveredItem={setHoveredItem}
          isUpdate={isUpdate}
          setDriftValues={setNewDriftValues}
        />
      </BoxDataThings>
      <BoxPlatformHolder>
        <PlatformDisplay
          driftValues={driftValues}
          width={widthMM}
          height={heightMM}
          selectedSize={selectedSize}
          gridItems={gridItems}
          hoveredItem={hoveredItem}
        />
      </BoxPlatformHolder>
    </BoxContainer>
  );
};

PlatformDriftViewer.propTypes = {
  widthMM: PropTypes.number.isRequired,
  heightMM: PropTypes.number.isRequired,
  isUpdate: PropTypes.bool,
  machineData: PropTypes.object.isRequired,
  saveMachineDetailsDriftFn: PropTypes.func.isRequired,
};

export default PlatformDriftViewer;
