import { Grid, styled } from "syklone/ui/index.js";

const TableCustomGrid = styled(Grid)(({ theme }) => ({
  width: "calc(100% - 10px)",
  borderRadius: "6px",
  backgroundColor: theme.palette.background.surface,
  height: "700px",
  padding: "10px 20px",
}));

export default TableCustomGrid;
