import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "syklone/ui/index.js";

const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    backgroundColor: "#252525",
    backgroundImage: "none",
  },
});

function WidgetDialogCancel({ message, title, buttonName, isDialogOpen, setIsDialogOpen, fn }) {
  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  return (
    <CustomDialog open={isDialogOpen} onClose={closeDialog} data-syklone="cancel-dialog">
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Close</Button>
        <Button onClick={fn} autoFocus>
          {buttonName}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}

WidgetDialogCancel.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  fn: PropTypes.func,
};

export default WidgetDialogCancel;
