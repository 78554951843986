import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Text, StyleSheet } from "syklone/libraries/index.js";

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
  },
});

function WidgetAuditTrailPagePdfText(props) {
  return <Text style={styles.text}>{props.children}</Text>;
}

WidgetAuditTrailPagePdfText.propTypes = {
  children: PropTypes.node,
};

export default WidgetAuditTrailPagePdfText;
