import React from "react";
import PropTypes from "prop-types";

// imports: local
import { WidgetCustomChip } from "../../widgets/index.js";

const TableDataCounter = ({ counter }) => {
  if (typeof counter === "number") {
    let chipType = counter === 0 ? "unlisted" : "success";
    return <WidgetCustomChip type={chipType} title={`${counter}`} />;
  } else {
    return "";
  }
};

TableDataCounter.propTypes = {
  counter: PropTypes.number,
};

export default TableDataCounter;
