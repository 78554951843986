import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { useFormik, Yup, Box, Typography } from "syklone/ui/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { BUILD_FILES_PROMOTION_TAB_VALUES } from "../../core/index.js";
import { DialogCommonConfirmation, DialogCommonWizardStepsAbove, DialogNotification } from "../../dialogs/index.js";
import {
  WidgetWizardActiveStepContainer,
  WidgetElectronicSignature,
  WidgetEditMachines,
  WidgetEditCustomFields,
} from "../index.js";

const WidgetEditDialogTitle = ({ name, productName, hasMarginTop = false }) => {
  return (
    <Box sx={{ margin: `${hasMarginTop ? 22 : 0}px 0px 11px 0px` }}>
      <Box>
        <Typography sx={{ opacity: ".5", fontSize: 12 }}>{`Name: ${productName}`}</Typography>
      </Box>
      <Box>
        <Typography sx={{ fontSize: 16 }}>{name === "machines" ? "Edit machines" : "Edit metadata"}</Typography>
      </Box>
    </Box>
  );
};

WidgetEditDialogTitle.propTypes = {
  name: PropTypes.string,
  productName: PropTypes.string,
  hasMarginTop: PropTypes.bool,
};

Yup.addMethod(Yup.array, "unique", function (message, path) {
  return this.test("unique", message, function (list) {
    const seen = new Set();
    const mapper = (x) => (path ? x[path] : x);

    for (let i = 0; i < list.length; i++) {
      const item = mapper(list[i]);
      if (seen.has(item)) {
        return this.createError({
          path: `${this.path}[${i}].${path}`,
          message: `${path} must be unique`,
        });
      }
      seen.add(item);
    }
    return true;
  });
});

const validationFirstStepSchema = Yup.object({
  equipmentAccount: Yup.string().nullable().required("A machine account is required"),
});

const validationCustomFieldsSchema = Yup.object({
  customFields: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        val: Yup.string().required("Value is required"),
      })
    )
    .test("unique", "Name must be unique", function (customFields = []) {
      const names = customFields.map((field) => field.name);
      const uniqueNames = new Set(names).size === names.length;
      return uniqueNames;
    }),
});

const authenticationStepSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  userSigned: Yup.boolean().required("Required").oneOf([true], "You must digitally sign the promotion"),
});

const combinedTwoStepCustomFieldsSchema = validationCustomFieldsSchema.shape({
  ...authenticationStepSchema.fields,
});

const combinedTwoStepSchema = validationFirstStepSchema.shape({
  ...authenticationStepSchema.fields,
});

const initialValuesSingle = {
  equipmentAccount: "",
  customFields: [],
};

const initialValuesSteps = {
  ...initialValuesSingle,
  username: "",
  userSigned: false,
  userComment: "",
  machineOnHold: {},
  machineRemove: {},
  site: "Anngrove",
};

const stepsTitle = ["Details", "Authenticate"];

function WidgetEditDialog({
  promotionCollectionData,
  tabValue,
  buildFileId,
  isDialogOpen,
  setIsDialogOpen,
  productName,
  deleteMachineValidity,
  isDeleting,
  component,
  onHoldState,
}) {
  const apiInstance = useApiContext();
  const { showSnackbar } = useAlertSnackbar();
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const serviceBfm = apiInstance.sykloneApi.serviceBfm;

  const widgetElectronicSignatureRef = React.useRef(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [messageStepText, setMessageStepText] = React.useState({ message: "", severity: "" });
  const [customFieldsVisible, setCustomFieldVisible] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isCheckboxStateChanged, setIsCheckboxStateChanged] = React.useState(false);
  const [switchChecked, setSwitchChecked] = React.useState(false);

  const isLastStep = stepsTitle.length === activeStep;
  const hasSteps = tabValue !== BUILD_FILES_PROMOTION_TAB_VALUES[0];

  const queryClient = useQueryClient();

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const clearMessage = () => {
    setMessageStepText({ message: "", severity: "" });
  };

  const previousStep = () => {
    clearMessage();
    widgetElectronicSignatureRef.current?.resetSignature();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClose = () => {
    if (hasSteps) {
      setActiveStep(0);
    }
    clearMessage();
    widgetElectronicSignatureRef.current?.resetSignature();
    formik.resetForm();
    setIsDialogOpen(false);
    setSwitchChecked(false);
    setIsCheckboxStateChanged(false);
  };

  const onTabKeyPropagation = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };

  const { data: equipment, error: equipmentDataError } = useQuery(
    ["equipment"],
    async () => {
      let response = await serviceAuth.getAdminEquipment();
      const modifiedResponse = response.data.data.map((item) => {
        return {
          description: item.description,
          domainName: item["domain_name"],
          endpoint: item.endpoint,
          id: item.id,
          isQualifiedForProduction: item["is_qualified_for_production"],
          location: item.site,
          name: item.name,
        };
      });
      return modifiedResponse;
    },
    {
      refetchOnWindowFocus: true,
    }
  );

  const { data: buildFileData, error: buildFileDataError } = useQuery(
    ["buildFileData", tabValue, buildFileId],
    async () => {
      switch (tabValue) {
        case promotionCollectionData[0].value:
          return serviceBfm.getResearchAndDevelopmentByBuildFileId(buildFileId);
        case promotionCollectionData[1].value:
          return serviceBfm.getUnderReviewByBuildFileId(buildFileId);
        case promotionCollectionData[2].value:
          return serviceBfm.getReleasedByBuildFileId(buildFileId);
        default:
          return null;
      }
    },
    {
      enabled: isDialogOpen,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { customFields } = data.metadata ?? {};

        const transformedCustomFields = Object.entries(customFields || {}).map(([key, value]) => ({
          name: key,
          val: value,
        }));
        if (transformedCustomFields.length !== 0) {
          setCustomFieldVisible(true);
          setFieldValue("customFields", transformedCustomFields);
        } else {
          setCustomFieldVisible(false);
        }
      },
    }
  );

  // const validateElectronicRecord = (values) => {
  //   const { username, userSigned } = values;
  //   if (!username || !userSigned) {
  //     widgetElectronicSignatureRef.current?.setSignatureError(true);
  //     return; // Exit the onSubmit function early
  //   }
  // };

  const filterByName = (name) => {
    return buildFileData?.machineValidity?.find((item) => item.name === name);
  };

  const handleEdit = async () => {
    widgetElectronicSignatureRef.current?.setSignatureError(false);
    const { equipmentAccount, machineOnHold, customFields, username, userSigned, userComment, site } = formik.values;
    const equipmentItem = equipment?.find((item) => item?.name === equipmentAccount);

    const customFieldsPayload = customFields.reduce((obj, item) => {
      obj[item.name] = item.val;
      return obj;
    }, {});

    const dataObj = {
      machineValidity: equipmentItem && {
        name: equipmentItem.name,
        site: equipmentItem.location,
        onHold: machineOnHold[equipmentAccount] ? true : false,
      },
    };

    const customFieldsObj = {
      customFields: customFieldsPayload,
    };

    const removeMachineObj = {
      name: equipmentItem?.name,
      site: site,
      buildFileId: buildFileData.id,
    };

    const electronicSignature = {
      ["user_signed_email"]: username,
      ["user_signed"]: userSigned,
      comment: userComment,
    };

    try {
      setIsSubmitting(true);
      let response;
      switch (tabValue) {
        case BUILD_FILES_PROMOTION_TAB_VALUES[0]: {
          const rndPayload = dataObj.machineValidity;
          const metaDataPayload = { metadata: { customFields: customFieldsObj.customFields } };

          if (values.machineRemove[equipmentAccount]) {
            response = await deleteMachineValidity(
              removeMachineObj.buildFileId,
              {
                name: removeMachineObj.name,
                site: removeMachineObj.site,
              },
              tabValue
            );
          } else if (dataObj.machineValidity) {
            if (filterByName(dataObj.machineValidity.name)) {
              response = await serviceBfm.postResearchAndDevelopmentHoldByBuildFileId(buildFileId, rndPayload);
              const message = response?.data?.message
                ? response?.data?.message
                : "Machine added on hold for this build file.";
              showSnackbar(message, "info");
            } else {
              response = await serviceBfm.postResearchAndDevelopmentMachineValidityByBuildFileId(
                buildFileId,
                rndPayload
              );
              const message = response?.data?.message
                ? response?.data?.message
                : "Machine validity added successfully.";
              showSnackbar(message, "info");
            }
          } else if (component === "metadata") {
            response = await serviceBfm.patchResearchAndDevelopmentByBuildFileId(buildFileId, metaDataPayload);

            const message = response?.data?.message
              ? response?.data?.message
              : "Build file metadata updated sucessfuly";
            showSnackbar(message, "info");
          }

          queryClient.invalidateQueries("dataRdCollection");
          queryClient.invalidateQueries("rdBuildFiles");

          break;
        }
        case BUILD_FILES_PROMOTION_TAB_VALUES[1]: {
          const urPayload = {
            ...dataObj.machineValidity,
            ["electronic_signature"]: electronicSignature,
          };
          const metaDataPayload = {
            metadata: { customFields: customFieldsObj.customFields },
            ["electronic_signature"]: electronicSignature,
          };

          if (values.machineRemove[equipmentAccount]) {
            response = await deleteMachineValidity(
              removeMachineObj.buildFileId,
              {
                name: removeMachineObj.name,
                site: removeMachineObj.site,
              },
              tabValue
            );
            queryClient.invalidateQueries("dataUnderReview");
          } else if (dataObj.machineValidity) {
            if (filterByName(dataObj.machineValidity.name)) {
              response = await serviceBfm.postUnderReviewHoldByBuildFileId(buildFileId, urPayload);
              const message = response?.data?.message
                ? response?.data?.message
                : "Machine added on hold for this build file.";
              showSnackbar(message, "info");
              queryClient.invalidateQueries("dataUnderReview");
            } else {
              response = await serviceBfm.postUnderReviewMachineValidityByBuildFileId(buildFileId, urPayload);
              const message = response?.data?.message
                ? response?.data?.message
                : "Machine validity added successfully.";
              showSnackbar(message, "info");
              queryClient.invalidateQueries("dataUnderReview");
            }
          } else if (component === "metadata") {
            response = await serviceBfm.patchUnderReviewByBuildFileId(buildFileId, metaDataPayload);

            const message = response?.data?.message
              ? response?.data?.message
              : "Build file metadata updated sucessfuly";
            showSnackbar(message, "info");

            queryClient.invalidateQueries("dataUnderReview");
            queryClient.invalidateQueries("urBuildFiles");
          }

          break;
        }
        case BUILD_FILES_PROMOTION_TAB_VALUES[2]: {
          const releasedPayload = {
            ...dataObj.machineValidity,
            ["electronic_signature"]: electronicSignature,
          };
          const metaDataPayload = {
            metadata: { customFields: customFieldsObj.customFields },
            ["electronic_signature"]: electronicSignature,
          };

          if (values.machineRemove[equipmentAccount]) {
            response = await deleteMachineValidity(
              removeMachineObj.buildFileId,
              {
                name: removeMachineObj.name,
                site: removeMachineObj.site,
              },
              tabValue
            );

            queryClient.invalidateQueries("dataReleased");
            queryClient.invalidateQueries("releasedBuildFiles");
          } else if (dataObj.machineValidity) {
            if (filterByName(dataObj.machineValidity.name)) {
              response = await serviceBfm.postReleasedHoldByBuildFileId(buildFileId, releasedPayload);
              const message = response?.data?.message
                ? response?.data?.message
                : "Machine added on hold for this build file.";
              showSnackbar(message, "info");

              queryClient.invalidateQueries("dataReleased");
              queryClient.invalidateQueries("releasedBuildFiles");
            } else {
              response = await serviceBfm.postReleasedMachineValidityByBuildFileId(buildFileId, releasedPayload);
              const message = response?.data?.message
                ? response?.data?.message
                : "Machine validity added successfully.";
              showSnackbar(message, "info");

              queryClient.invalidateQueries("dataReleased");
              queryClient.invalidateQueries("releasedBuildFiles");
            }
          } else if (component === "metadata") {
            response = await serviceBfm.patchReleasedByBuildFileId(buildFileId, metaDataPayload);

            const message = response?.data?.message
              ? response?.data?.message
              : "Build file metadata updated sucessfuly";

            showSnackbar(message, "info");

            queryClient.invalidateQueries("dataReleased");
            queryClient.invalidateQueries("releasedBuildFiles");
          }
          break;
        }
        default:
          break;
      }
      onClose();
    } catch (e) {
      console.log(e);
      setMessageStepText({
        message: typeof e === "string" ? e : "There was an error editing build file",
        severity: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  let handleNext;
  let buttonName;
  let validationSchema;
  let initialValues;

  if (hasSteps) {
    initialValues = initialValuesSteps;
    switch (activeStep) {
      case 0:
        handleNext = nextStep;
        buttonName = "Next";
        validationSchema = component === "metadata" ? validationCustomFieldsSchema : validationFirstStepSchema;
        break;
      case 1:
        handleNext = handleEdit;
        buttonName = "Save Data";
        validationSchema = component === "metadata" ? combinedTwoStepCustomFieldsSchema : combinedTwoStepSchema;
        break;
      default:
        handleNext = onClose;
        buttonName = "Close";
        validationSchema = null;
        break;
    }
  } else {
    initialValues = initialValuesSingle;
    validationSchema = component === "metadata" ? validationCustomFieldsSchema : validationFirstStepSchema;
    handleNext = handleEdit;
    buttonName = "Save Data";
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleNext,
  });

  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = formik;

  let buildFileErrorMessage;

  if (buildFileDataError) {
    buildFileErrorMessage = { message: "There was an error opening the build file.", severity: "error" };
  } else if (equipmentDataError) {
    buildFileErrorMessage = { message: "There was an error with the equipment data.", severity: "error" };
  } else {
    buildFileErrorMessage = messageStepText;
  }

  if (hasSteps) {
    return (
      <DialogCommonWizardStepsAbove
        maxWidth="xs"
        fullWidth
        dataTag="edit-dialog-auth"
        stepsTitle={stepsTitle}
        nextStepTitle={buttonName}
        isOpen={isDialogOpen}
        onClose={() => onClose()}
        activeStep={activeStep}
        handleNext={() => formik.handleSubmit()}
        handleBack={!isLastStep ? previousStep : () => undefined}
        isBackDisabled={isLastStep}
        onKeyDown={onTabKeyPropagation}
        isNextDisabled={isSubmitting}
        showClose
        disableBackdropClick
      >
        <WidgetEditDialogTitle productName={productName} hasMarginTop name={component} />
        <DialogNotification alert={buildFileErrorMessage} />
        <WidgetWizardActiveStepContainer activeStep={activeStep}>
          <div>
            {component === "machines" && (
              <WidgetEditMachines
                values={values}
                equipment={equipment}
                tabValue={tabValue}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                customFieldsVisible={customFieldsVisible}
                setCustomFieldVisible={setCustomFieldVisible}
                buildFileData={buildFileData}
                isDeleting={isDeleting}
                isCheckboxStateChanged={isCheckboxStateChanged}
                setIsCheckboxStateChanged={setIsCheckboxStateChanged}
                switchChecked={switchChecked}
                setSwitchChecked={setSwitchChecked}
                onHoldState={onHoldState}
              />
            )}

            {component === "metadata" && (
              <WidgetEditCustomFields
                values={values}
                equipment={equipment}
                tabValue={tabValue}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
                customFieldsVisible={customFieldsVisible}
                setCustomFieldVisible={setCustomFieldVisible}
                buildFileData={buildFileData}
                deleteMachineValidity={deleteMachineValidity}
                isDeleting={isDeleting}
                isCheckboxStateChanged={isCheckboxStateChanged}
                setIsCheckboxStateChanged={setIsCheckboxStateChanged}
              />
            )}
          </div>
          <WidgetElectronicSignature
            ref={widgetElectronicSignatureRef}
            values={values}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </WidgetWizardActiveStepContainer>
      </DialogCommonWizardStepsAbove>
    );
  } else {
    return (
      <DialogCommonConfirmation
        onConfirm={() => formik.submitForm()}
        showClose={false}
        onCancel={() => onClose()}
        onClose={() => onClose()}
        isOpen={isDialogOpen}
        onConfirmTitle={buttonName}
        fullWidth
        maxWidth="xs"
        dataTag="edit-dialog-single"
        isConfirmDisabled={isSubmitting}
      >
        <WidgetEditDialogTitle productName={productName} name={component} />
        <DialogNotification alert={buildFileErrorMessage} />
        {component === "machines" && (
          <WidgetEditMachines
            values={values}
            equipment={equipment}
            tabValue={tabValue}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            customFieldsVisible={customFieldsVisible}
            setCustomFieldVisible={setCustomFieldVisible}
            buildFileData={buildFileData}
            isDeleting={isDeleting}
            isCheckboxStateChanged={isCheckboxStateChanged}
            setIsCheckboxStateChanged={setIsCheckboxStateChanged}
            switchChecked={switchChecked}
            setSwitchChecked={setSwitchChecked}
            onHoldState={onHoldState}
          />
        )}

        {component === "metadata" && (
          <WidgetEditCustomFields
            values={values}
            equipment={equipment}
            tabValue={tabValue}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            customFieldsVisible={customFieldsVisible}
            setCustomFieldVisible={setCustomFieldVisible}
            buildFileData={buildFileData}
            deleteMachineValidity={deleteMachineValidity}
            isDeleting={isDeleting}
            isCheckboxStateChanged={isCheckboxStateChanged}
            setIsCheckboxStateChanged={setIsCheckboxStateChanged}
          />
        )}
      </DialogCommonConfirmation>
    );
  }
}

WidgetEditDialog.propTypes = {
  promotionCollectionData: PropTypes.array,
  tabValue: PropTypes.string,
  buildFileId: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  productName: PropTypes.string,
  deleteMachineValidity: PropTypes.func,
  isDeleting: PropTypes.bool,
  component: PropTypes.string,
  onHoldState: PropTypes.array,
};

export default WidgetEditDialog;
