import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Box,
  Button,
  Chip,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from "syklone/ui/index.js";
import { WidgetLoader } from "syklone/components/widgets/widget_loader/index.js";

// imports: local
import { WidgetOverflowTip } from "../index.js";

function getRandomNumber() {
  return Math.floor(Math.random() * 10000) + 1;
}

function getProductIds(responseArray) {
  const productIds = [];

  responseArray.forEach((item) => {
    if (item.product_id) {
      productIds.push({ id: item._id, name: item.product_id });
    }
  });

  return productIds;
}

const CardContainer = styled(({ active, ...rest }) => <Box {...rest} />)(({ theme, active }) => {
  return {
    backgroundColor: active
      ? theme.palette.mode === "dark"
        ? "#252525"
        : "#EEEEEE"
      : theme.palette.mode === "dark"
      ? "#111111"
      : "#f8f8f8",
    display: "flex",
    flexDirection: "column",
    borderRadius: "6px",
    padding: "13px",
    gap: "16px",
    minWidth: "210px",
    cursor: "pointer",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px dashed #373737",
    },
  };
});

const ImgContainer = styled(Box)({
  position: "relative",
  minHeight: 145,
  maxHeight: 260,
  height: 185,
  backgroundColor: "#000000",
  borderRadius: "6px",
});

const NoImageBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});

const Img = styled("img")({
  maxWidth: "100%",
  maxHeight: "100%",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "6px",
});

const Title = styled(Typography)({
  fontSize: "16px",
});

const WidgetCustomChip = styled(Chip)({
  position: "absolute",
  top: "8px",
  left: "8px",
});

function WidgetProductFamilyCard({
  data,
  active,
  setActive,
  onClickButton,
  image,
  getProductFamilyImage,
  filterByProductFamily,
  getProductFromFamiliy,
}) {
  const [productID, setProductID] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productIdsForFamily, setProductIdsForFamily] = useState([]);
  const [isProductIdsLoading, setIsProductIdsLoading] = useState(false);

  const handlePidChange = (event) => {
    setProductID(event.target?.value);
  };

  const getProductFamilyImageFn = async () => {
    if (data.image !== null) {
      setIsLoading(true);
      const response = await getProductFamilyImage(data.id, data.image?.name);
      const imgUrl = URL.createObjectURL(response);
      setImageUrl(imgUrl);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductFamilyImageFn();
  }, [data]);

  const activateCard = () => {
    setActive({
      id: data.id,
      assets: data.assets,
      image: imageUrl,
      title: data.title,
      productID: data.productID,
    });
  };

  const checkStatesForButton = () => {
    return !!(productID.length !== 0);
  };

  useEffect(() => {
    if (active.id !== data.id) {
      setProductID("");
    }
  }, [active]);

  const getProducts = async () => {
    setIsProductIdsLoading(true);
    const products = await getProductFromFamiliy(data.id);
    const filteredProductsData = filterByProductFamily(products.data.data, data.id);
    setProductIdsForFamily(getProductIds(filteredProductsData));
    setIsProductIdsLoading(false);
  };

  useEffect(() => {
    return () => {
      setProductID("");
      setImageUrl(null);
      setProductIdsForFamily([]);
    };
  }, []);

  return (
    <CardContainer active={active.id === data.id} onClick={activateCard} data-syklone="product-family-card-container">
      {isLoading ? (
        <ImgContainer>
          <WidgetLoader
            style={{
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 999999,
            }}
          />
        </ImgContainer>
      ) : (
        <ImgContainer>
          {active.id === data.id && <WidgetCustomChip label="Selected" color="success" />}
          {image ? <Img src={imageUrl} /> : <NoImageBox>No image available</NoImageBox>}
        </ImgContainer>
      )}

      <Box>
        <Title>
          <WidgetOverflowTip placement="top">{data.title}</WidgetOverflowTip>
        </Title>
      </Box>
      <Box>
        <Grid container direction="row" spacing="6">
          <Grid item xs={12}>
            <FormControl fullWidth size="small" data-syklone="product-family-pid-select">
              <InputLabel id="product-family-pid-label">Product ID</InputLabel>
              <Select
                labelId="product-family-pid-label"
                id="product-family-pid"
                value={productID}
                label="Product ID"
                onChange={handlePidChange}
                onOpen={productIdsForFamily.length === 0 ? getProducts : null}
              >
                {isProductIdsLoading ? (
                  <MenuItem value="">
                    <em>Loading</em>
                  </MenuItem>
                ) : productIdsForFamily.length === 0 ? (
                  <MenuItem value="">
                    <em>No product IDs available</em>
                  </MenuItem>
                ) : (
                  [
                    <MenuItem key="none" value="">
                      <em>None</em>
                    </MenuItem>,
                    ...productIdsForFamily.map((item) => (
                      <MenuItem key={`prod-${item.id}-${getRandomNumber()}`} value={item.id}>
                        {item.name}
                      </MenuItem>
                    )),
                  ]
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      {checkStatesForButton() && (
        <Box>
          <Button fullWidth variant="contained" onClick={() => onClickButton(productID, data.title, imageUrl)}>
            Product details
          </Button>
        </Box>
      )}
    </CardContainer>
  );
}

WidgetProductFamilyCard.propTypes = {
  data: PropTypes.object,
  dataProducts: PropTypes.array,
  active: PropTypes.object,
  setActive: PropTypes.func,
  onClickButton: PropTypes.func,
  image: PropTypes.any,
  getProductFamilyImage: PropTypes.func,
  filterByProductFamily: PropTypes.func,
  getProductFromFamiliy: PropTypes.func,
};

export default WidgetProductFamilyCard;
