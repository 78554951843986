import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, Grid, icons, Stack } from "syklone/ui/index.js";
import { useApiContext, useAuthContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { WidgetTitle } from "../../widgets/index.js";
import { DialogConfirmation } from "../../dialogs/index.js";
import { TableDataMapping } from "../../tables/index.js";

const generatePayload = (id, endpointArray) => {
  return {
    ["group_id"]: id,
    ["permission_id"]: endpointArray,
  };
};

function PageGlobalAdminSiteConfigurationGroupMapToPermissions({ data, isLoading, handleRefreshData, error }) {
  const { name, id } = data.adminGroup;
  const type = "permissions";
  const title = `Map groups ${name} to ${type}`;
  const tableData = data.adminPermissions;
  const activeIds = data.activePermissionsIds;
  const apiInstance = useApiContext();
  const { updateCurrentUserPermissions } = useAuthContext();

  const dialogConfirmationRef = React.useRef(null);
  const [allSelectedIds, setAllSelectedIds] = React.useState([]); // Tracks current UI selections

  const { showSnackbar } = useAlertSnackbar();
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;

  React.useEffect(() => {
    if (activeIds && activeIds.length) {
      setAllSelectedIds(activeIds);
    }
  }, [activeIds]);

  const handleDialogSignatureOpen = (value) => {
    dialogConfirmationRef.current?.setIsDialogOpen(value);
  };

  const setDialogConfirmationIsSubmitting = (value) => {
    dialogConfirmationRef.current?.setIsSubmitting(value);
  };

  const handleMapping = async () => {
    const addedIds = allSelectedIds.filter((addedId) => !activeIds.includes(addedId));
    const removedIds = activeIds.filter((removedId) => !allSelectedIds.includes(removedId));
    let noUpdate = false;
    let message = "";

    try {
      setDialogConfirmationIsSubmitting(true);
      // Handle added IDs
      if (addedIds.length === 0 && removedIds.length === 0) {
        noUpdate = true;
      }

      if (addedIds.length > 0) {
        const payloadMapIds = generatePayload(id, addedIds);
        const mapIdsResponse = await serviceAuth.postAdminMapGroupPermissions(payloadMapIds);
        message += mapIdsResponse?.data?.message ? `${mapIdsResponse.data.message} ` : "Successfully mapped. ";
      }

      // Handle removed IDs
      if (removedIds.length > 0) {
        const payloadUnmapIds = generatePayload(id, removedIds);
        const unmapIdsResponse = await serviceAuth.postAdminUnmapGroupPermissions(payloadUnmapIds);
        message += unmapIdsResponse?.data?.message ? unmapIdsResponse.data.message : "Successfully unmapped.";
      }
      if (addedIds.length > 0 || removedIds.length > 0) {
        await updateCurrentUserPermissions();
      }
      // If no IDs were added or removed
    } catch (e) {
      // Handle error from either operation
      message = typeof e?.detail === "string" ? e.detail : "An error occurred while updating.";
      handleDialogSignatureOpen(false);
      showSnackbar(message, "error");
      return; // Exit the function early on error
    } finally {
      setDialogConfirmationIsSubmitting(false);
    }

    // Show success message and refresh data if no error was caught
    if (!noUpdate) {
      showSnackbar(message, "success");
      handleRefreshData();
    } else {
      showSnackbar("No updates were made.", "warning");
    }

    handleDialogSignatureOpen(false);
  };

  return (
    <>
      <Grid item xs>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <WidgetTitle title={title} isBackButton={true} backRoute="/dashboard/admin/configuration/groups" />
              </Grid>
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Button
                    disabled={isLoading}
                    id="admin_save_map_changes"
                    variant="contained"
                    startIcon={<icons.mui.Save />}
                    onClick={() => handleDialogSignatureOpen(true)}
                    data-syklone="admin-save-map-gorup-to-permission-button"
                  >
                    Save Changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <Grid container>
              <TableDataMapping
                isLoading={isLoading}
                error={error}
                data={tableData}
                allSelectedIds={allSelectedIds}
                setAllSelectedIds={setAllSelectedIds}
                headerNameTitle={type.charAt(0).toUpperCase() + type.slice(1)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogConfirmation ref={dialogConfirmationRef} onConfirm={handleMapping} />
    </>
  );
}

PageGlobalAdminSiteConfigurationGroupMapToPermissions.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  handleRefreshData: PropTypes.func,
  error: PropTypes.object,
};

export default PageGlobalAdminSiteConfigurationGroupMapToPermissions;
