import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, Grid, icons, Stack } from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { WidgetTitle } from "../../widgets/index.js";
import { TableDataEndpoints } from "../../tables/index.js";
import { DialogEndpoints, DialogToggleActivation } from "../../dialogs/index.js";

function PageGlobalAdminSiteConfigurationEndpoints({ data, isLoading, handleRefreshData, error }) {
  const apiInstance = useApiContext();
  const dialogEndpointsRef = React.useRef(null);
  const dialogActivationRef = React.useRef(null);
  const dialogVisibilityRef = React.useRef(null);
  const { showSnackbar } = useAlertSnackbar();
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const [isUpdatingEndpoints, setIsUpdatingEndpoints] = React.useState(false);

  const setIsDialogOpenFn = (val) => {
    dialogEndpointsRef.current?.setIsDialogOpen(val);
  };

  const setDialogEditDataFn = (val) => {
    dialogEndpointsRef.current?.setDialogData(val);
  };

  const setDialogActivationDataFn = (val) => {
    dialogActivationRef.current?.setDialogData(val);
  };

  const setDialogVisiblityDataFn = (val) => {
    dialogVisibilityRef.current?.setDialogData(val);
  };

  const setIsDialogDeleteEndpointOpenFn = (val) => {
    dialogActivationRef.current?.setIsDialogOpen(val);
  };

  const setIsDialogVisibilityEndpointOpenFn = (val) => {
    dialogVisibilityRef.current?.setIsDialogOpen(val);
  };

  const setDialogEditResponseErrorMessage = (text) => {
    dialogEndpointsRef.current?.setResponseMessage({ type: "error", text });
  };

  const setDialogEditIsSubmitting = (value) => {
    dialogEndpointsRef.current?.setIsSubmitting(value);
  };

  const setDialogActivationIsSubmitting = (value) => {
    dialogActivationRef.current?.setIsSubmitting(value);
  };

  const setDialogVisibilityIsSubmitting = (value) => {
    dialogVisibilityRef.current?.setIsSubmitting(value);
  };

  const onClickUpdateEndpointsFn = async () => {
    try {
      setIsUpdatingEndpoints(true);
      const response = await serviceAuth.getAdminEndpointsUpdateAll();
      const responseMessage = response?.data?.message ? response.data.message : "Status was successfully updated!";
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Status was not successfully updated!";
      showSnackbar(responseMessage, "error");
    } finally {
      setIsUpdatingEndpoints(false);
    }
  };

  const handleEndpointStatusChange = async (dialogEditData) => {
    const id = dialogEditData?.id;
    if (id) {
      try {
        setDialogActivationIsSubmitting(true);
        const isActive = dialogEditData?.isActive;
        let response;
        if (isActive) {
          response = await serviceAuth.deleteAdminEndpointsByEndpointId(id);
        } else {
          response = await serviceAuth.postAdminEndpointsByEndpointId(id);
        }
        const responseMessage = response?.data?.message ? response.data.message : "Status was successfully updated!";
        showSnackbar(responseMessage, "success");
        handleRefreshData && handleRefreshData();
        setIsDialogDeleteEndpointOpenFn(false);
      } catch (e) {
        const responseMessage = typeof e?.detail === "string" ? e.detail : "Status was not successfully updated!";
        showSnackbar(responseMessage, "error");
        setIsDialogDeleteEndpointOpenFn(false);
      } finally {
        setDialogActivationIsSubmitting(false);
      }
    } else {
      showSnackbar("Status was not successfully updated!", "error");
    }
  };

  const handleEndpointVisibilityStatusChange = async (dialogEditData) => {
    const id = dialogEditData?.id;
    if (id) {
      try {
        setDialogVisibilityIsSubmitting(true);
        const isVisible = dialogEditData?.isVisible;
        let response;
        if (isVisible) {
          const obj = { visible: 0 };
          response = await serviceAuth.putAdminEndpointsByEndpointId(id, obj);
        } else {
          const obj = { visible: 1 };
          response = await serviceAuth.putAdminEndpointsByEndpointId(id, obj);
        }
        const responseMessage = response?.data?.message
          ? response.data.message
          : "Visibility was successfully updated!";
        showSnackbar(responseMessage, "success");
        handleRefreshData && handleRefreshData();
        setIsDialogVisibilityEndpointOpenFn(false);
      } catch (e) {
        const responseMessage = typeof e?.detail === "string" ? e.detail : "Visiblity was not updated!";
        showSnackbar(responseMessage, "error");
        setIsDialogVisibilityEndpointOpenFn(false);
      } finally {
        setDialogVisibilityIsSubmitting(false);
      }
    } else {
      showSnackbar("Visiblity was not successfully updated!", "error");
    }
  };

  const handleDialogEndpointEdit = async (id, payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.putAdminEndpointsByEndpointId(id, payload);
      const responseMessage = response?.data?.message ? response.data.message : "Endpoint successfully updated";
      setIsDialogOpenFn(false);
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Endpoint was not successfully updated!";
      setDialogEditResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  return (
    <>
      <Grid item xs>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <WidgetTitle title="Endpoints" isBackButton={true} backRoute="/dashboard/admin" />
              </Grid>
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Button
                    id="admin_update_endpoints"
                    variant="contained"
                    disabled={isUpdatingEndpoints || isLoading}
                    startIcon={<icons.mui.ListAlt />}
                    onClick={() => onClickUpdateEndpointsFn()}
                    data-syklone="admin-update-endpoints-button"
                  >
                    Update endpoints
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <Grid container>
              <TableDataEndpoints
                isLoading={isLoading}
                error={error}
                data={data}
                setters={{
                  setDialogEditDataFn: setDialogEditDataFn,
                  setDialogActivationDataFn: setDialogActivationDataFn,
                  setIsDialogDeleteEndpointOpenFn: setIsDialogDeleteEndpointOpenFn,
                  setIsDialogOpenFn: setIsDialogOpenFn,
                  setDialogVisiblityDataFn: setDialogVisiblityDataFn,
                  setIsDialogVisibilityEndpointOpenFn: setIsDialogVisibilityEndpointOpenFn,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogEndpoints ref={dialogEndpointsRef} onDialogEditEndpoints={handleDialogEndpointEdit} />
      <DialogToggleActivation
        ref={dialogActivationRef}
        toggleActionName="endpoint"
        onConfirm={handleEndpointStatusChange}
      />
      <DialogToggleActivation
        ref={dialogVisibilityRef}
        toggleActionName="visibility"
        onConfirm={handleEndpointVisibilityStatusChange}
        activateMessage={"activate"}
        deactivateMessage={"deactivate"}
      />
    </>
  );
}

PageGlobalAdminSiteConfigurationEndpoints.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  handleRefreshData: PropTypes.func,
  error: PropTypes.object,
};

export default PageGlobalAdminSiteConfigurationEndpoints;
