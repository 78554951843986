import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// imports: syklone
import { Grid, Typography, icons } from "syklone/ui/index.js";

function WidgetEcosystemFeatures({ content }) {
  return (
    <Grid item sx={styles.ecosystemBox} component={motion.div}>
      <Grid container direction="column" sx={{ width: 280 }}>
        <Grid>
          <Typography sx={{ fontWeight: 700, fontSize: 18 }}>{content.title}</Typography>
        </Grid>
        <Grid>
          <Typography sx={{ fontSize: 14, opacity: 0.6 }}>{content.description}</Typography>
        </Grid>
        <Grid sx={styles.readMore}>
          <Link to={content.url}>
            Learn more
            <icons.mui.ArrowForward sx={{ color: "#ffb500" }} fontSize="small" />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

WidgetEcosystemFeatures.propTypes = {
  content: PropTypes.object,
};

const styles = {
  readMore: {
    transform: "translateY(10px)",
    "& > a": {
      display: "inline-flex",
      alignItems: "center",
      gap: "3px",
      fontSize: 14,
      backgroundImage: "linear-gradient(to right, #feb400, #feb400 50%, #fff 50%)",
      backgroundSize: "200% 100%",
      backgroundPosition: "-100%",
      padding: "5px 0px",
      position: "relative",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      transition: "all 0.3s ease-in-out",
    },
    "& > a::before": {
      content: "''",
      background: "#feb400",
      display: "block",
      position: "absolute",
      bottom: "-3px",
      left: "0px",
      width: "0px",
      height: "2px",
      transition: "all 0.3s ease-in-out",
    },
    "& > a:hover": {
      backgroundPosition: 0,
    },
    "& > a:hover::before": {
      width: "100%",
    },
  },
};

export default WidgetEcosystemFeatures;
