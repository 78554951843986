import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, DataGrid, Grid, IconButton, icons, styled, TextField } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";
import { moment } from "syklone/libraries/index.js";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const createDate = (str) => {
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

const CustomGrid = styled(Grid)(({ theme }) => ({
  height: 675,
  width: "100%",
}));

const columns = [
  {
    field: "id",
    headerName: "ID",
    hide: true,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
  },
  {
    field: "date",
    headerName: "Completed on",
    minWidth: 140,
    flex: 1,
    disableColumnMenu: true,
    renderCell: (params) => {
      return createDate(params.row.date);
    },
  },
  /* {
    field: "completedByName",
    headerName: "Completed by",
    flex: 1,
    disableColumnMenu: true,
  },
  {
    field: "completedByEmail",
    headerName: "Email",
    flex: 1,
    disableColumnMenu: true,
  }, */
  {
    field: "oldVal",
    headerName: "Old values",
    flex: 1,
    disableColumnMenu: true,
    cellClassName: "MuiDataGrid-newValues",
    renderCell: (params) => {
      return (
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <ul style={{ listStyleType: "none", margin: 0, padding: "8px 0px" }}>{params.row.oldVal}</ul>
        </div>
      );
    },
  },
  {
    field: "newVal",
    headerName: "New values",
    flex: 1,
    disableColumnMenu: true,
    cellClassName: "MuiDataGrid-newValues",
    renderCell: (params) => {
      return (
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <ul style={{ listStyleType: "none", margin: 0, padding: "8px 0px" }}>{params.row.newVal}</ul>
        </div>
      );
    },
  },
];

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        position: "relative",
        right: 0,
        top: 0,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <TextField
        variant="outlined"
        size="small"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        disabled={props.disabled}
        data-syklone="table-filter"
        InputProps={{
          startAdornment: <icons.mui.Search fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <icons.mui.Cancel fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderLeft: "none",
              borderBottom: "1px solid #404040",
              borderTop: "none",
              borderRight: "none",
              borderRadius: 0,
            },
          },

          "& .MuiInputBase-root": {
            padding: "0px 7px 10px",
            "& > .MuiSvgIcon-root": {
              mr: 0.5,
            },
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

function DialogBuildHistory({ data, title, isOpen, setIsOpen, isLoading }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState([]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = filteredTableData.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field]?.toString());
      });
    });
    setTableData(filteredRows);
  };

  useEffect(() => {
    requestSearch("");
    setTableData(data);
    setFilteredTableData(data);
    setIsDialogOpen(isOpen);
    return () => {
      setTableData([]);
      setFilteredTableData([]);
    };
  }, [isOpen]);

  return (
    <DialogCommonBase
      title={`Build file history - ${title}`}
      isOpen={isDialogOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      fullWidth={true}
      maxWidth={"lg"}
      data-syklone="dialog-build-history"
    >
      <Grid container>
        <CustomGrid item data-syklone="build-history-table-wrapper">
          <DataGrid
            components={{ Toolbar: QuickSearchToolbar }}
            rows={tableData}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.id}
            loading={isLoading}
            getRowHeight={() => "auto"}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            sx={(theme) => ({
              fontSize: 13,
              "&.MuiDataGrid-root": {
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: theme.palette.mode === "dark" ? "#1F2527" : "#f8f8f8",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
                },
                "& .MuiDataGrid-newValues": {
                  display: "flex",
                  alignItems: "baseline",
                },
                "& .MuiDataGrid-columnHeader:focus": {
                  outline: "none",
                },
                border: "none",
              },
              ".MuiDataGrid-columnHeaders": {
                borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
              },
              ".MuiDataGrid-footerContainer": {
                borderTop: "none",
              },
            })}
          />
        </CustomGrid>
      </Grid>
    </DialogCommonBase>
  );
}

DialogBuildHistory.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DialogBuildHistory;
