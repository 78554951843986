import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  icons,
  Link,
  Pagination,
  styled,
  Typography,
} from "syklone/ui/index.js";

import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

import { usePermissionChecks } from "syklone/api/react/index.js";

// imports: local
import {
  WidgetProductFamilyCard,
  WidgetAutocompleteSearch,
  WidgetOverflowTip,
  WidgetPopoverMenu,
  WidgetTitle,
} from "../../widgets/index.js";

import { DialogAddFiles, DialogAddFamily } from "../../dialogs/index.js";

const CustomBox = styled(Box)(() => ({
  width: "100%",
  margin: "25px 30px 30px 30px",
}));

const ProductBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f8f8f8",
  borderRadius: "6px",
  marginTop: "22px",
}));

const ProductFamilyWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "16px 19px",
  borderRight: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
}));

const BoxFamiliyTitle = styled(Typography)(() => ({
  fontSize: "16px",
}));

const GlobalAssetsWrapper = styled(Box)(({ theme }) => ({
  paddingTop: "16px",
  width: "425px",
}));

const GlobalAssetsTitle = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
  paddingRight: "16px",
  marginLeft: "16px",
  paddingBottom: "16px",
}));

const GlobalAssetsImg = styled("img")(({ theme }) => ({
  width: "150px",
  borderRadius: "6px",
  border: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
}));

const GlobalAssetsFamilyTitle = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: "bold",
}));

const GlobalAssetsFilesTitle = styled(Grid)(({ theme }) => ({
  padding: "8px 16px",
  borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
  borderTop: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
}));

const GlobalAssetsFilesList = styled(Box)(() => ({
  height: "200px",
  overflowX: "hidden",
  overflowY: "auto",
  marginRight: "10px",
}));

const CustomPagination = styled(Pagination)(() => ({
  margin: "8px 0px",
  "& .MuiPagination-ul": {
    justifyContent: "center",
  },
}));

const AssetLink = styled(Link)(({ theme }) => ({
  display: "flex",
  maxWidth: "340px",
  gap: "10px",
  color: theme.palette.mode === "dark" ? "#FFF" : "#000",
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": { color: theme.palette.primary.main },
  "&:hover > .MuiSvgIcon-root": { opacity: 1 },
  "& > .MuiSvgIcon-root": { opacity: 0.6, transition: "0.1s" },
}));

const CustomAlert = styled(Alert)(() => ({
  fontSize: "14px",
  width: "100%",
}));

const MessageBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "80px",
  padding: "0 0 0 1rem",
}));

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

function PageProductFamilies({
  active,
  setActive,
  alert,
  setAlert,
  data,
  dataMenu,
  dialogState,
  dialogFn,
  getSearchResults,
  isLoadingStatus,
  onClickButton,
  onClickCreateProductFamily,
  onClickAddProductFamilyFiles,
  onClickDownloadProductFamilyFiles,
  getProductFamilyImage,
  filterByProductFamily,
  getProductFromFamiliy,
}) {
  const itemsPerPage = 12;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [id, setId] = useState("");

  const { checkElementVisibility } = usePermissionChecks();

  useEffect(() => {
    setNoOfPages(Math.ceil(data.length / itemsPerPage));
  }, [data]);

  // Dialog states
  const { isDialogAddFilesOpen, isDialogAddFamilyOpen, familyName, description, productFamilyImage, filesList } =
    dialogState;

  // Dialog functions
  const {
    setIsDialogAddFilesOpen,
    setIsDialogAddFamilyOpen,
    setFamilyName,
    setDescription,
    setProductFamilyImage,
    setFilesList,
  } = dialogFn;

  // Loading status
  const { isSearchLoading, isDataLoading, isDeleting, isAddingFiles, isDownloading } = isLoadingStatus;

  const handlePaginationChange = (_event, value) => {
    setPage(value);
  };

  const onClickAddFiles = () => {
    setIsDialogAddFilesOpen(true);
  };

  const onClickAddFamily = () => {
    setIsDialogAddFamilyOpen(true);
  };

  return (
    <>
      <DialogAddFiles
        alert={alert}
        setAlert={setAlert}
        id={id}
        isLoading={isAddingFiles}
        isOpen={isDialogAddFilesOpen}
        setIsOpen={setIsDialogAddFilesOpen}
        filesList={filesList}
        setFilesList={setFilesList}
        onSubmit={onClickAddProductFamilyFiles}
      />
      <DialogAddFamily
        alert={alert}
        setAlert={setAlert}
        isLoading={isDataLoading}
        isOpen={isDialogAddFamilyOpen}
        dialogFormState={{ familyName, description, productFamilyImage }}
        dialogFormFn={{ setFamilyName, setDescription, setProductFamilyImage }}
        setIsOpen={setIsDialogAddFamilyOpen}
        onSubmit={onClickCreateProductFamily}
      />
      <CustomBox>
        <Grid container justifyContent="space-between" sx={{ alignItems: "center" }}>
          <Grid item>
            <WidgetTitle title="Product briefcase" isBackButton={true} backRoute="/dashboard" />
          </Grid>
          <Grid item>
            <WidgetAutocompleteSearch getSearchResults={getSearchResults} isSearchLoading={isSearchLoading} />
          </Grid>
        </Grid>

        <ProductBox>
          {isDataLoading ? (
            <MessageBox>
              <CircularProgress />
            </MessageBox>
          ) : (
            <>
              <ProductFamilyWrapper>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid container justifyContent="space-between" sx={{ alignItems: "center" }}>
                      <Grid item>
                        <BoxFamiliyTitle>Product families</BoxFamiliyTitle>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          startIcon={<icons.mui.Add />}
                          data-syklone="add-product-family-dialog-button"
                          id="id-add-product-family-dialog-button"
                          onClick={() => onClickAddFamily()}
                          disabled={
                            isDataLoading ||
                            !checkElementVisibility(PermissionVisualElements.productFamilyAddProductFamilyDialogButton)
                          }
                        >
                          Add product family
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.length === 0 ? (
                    <MessageBox>
                      <CustomAlert severity="info">No product families available</CustomAlert>
                    </MessageBox>
                  ) : (
                    <>
                      <Grid item>
                        <Grid container spacing={3} data-syklone="product-family-list">
                          {data.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item) => (
                            <Grid item xs={12} md={6} lg={4} xl={2} key={item.id}>
                              <a onClick={() => setId(item.id)}>
                                <WidgetProductFamilyCard
                                  data={item}
                                  image={item.image}
                                  active={active}
                                  setActive={setActive}
                                  onClickButton={onClickButton}
                                  getProductFamilyImage={getProductFamilyImage}
                                  filterByProductFamily={filterByProductFamily}
                                  getProductFromFamiliy={getProductFromFamiliy}
                                />
                              </a>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <CustomPagination
                          color="primary"
                          count={noOfPages}
                          page={page}
                          onChange={handlePaginationChange}
                          defaultPage={1}
                          variant="outlined"
                          shape="rounded"
                          size="large"
                          data-syklone="product-families-pagination"
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </ProductFamilyWrapper>
              {data.length === 0 ? null : (
                <GlobalAssetsWrapper data-syklone="global-family-assets">
                  <Grid container direction="column" spacing={2}>
                    <GlobalAssetsTitle item>
                      <Grid container justifyContent="space-between" sx={{ alignItems: "center" }}>
                        <Grid item>
                          <BoxFamiliyTitle>Family assets</BoxFamiliyTitle>
                        </Grid>
                        <Grid item>
                          {!isObjectEmpty(active) ? (
                            <Button
                              variant="contained"
                              startIcon={<icons.mui.Add />}
                              data-syklone="add-product-files-button"
                              id="id-add-product-files-button"
                              onClick={() => onClickAddFiles()}
                              disabled={
                                !checkElementVisibility(PermissionVisualElements.productFamilyAddProductFilesButton)
                              }
                            >
                              Add files
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    </GlobalAssetsTitle>
                    <Grid item>
                      {!isObjectEmpty(active) ? (
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <Grid container spacing={2} wrap="nowrap">
                              <Grid item sx={{ marginLeft: "16px" }}>
                                {active.image !== null && (
                                  <GlobalAssetsImg src={active.image} data-syklone="global-family-assets-image" />
                                )}
                              </Grid>
                              <Grid item>
                                <Grid container direction="column" spacing={1}>
                                  <Grid item>
                                    <GlobalAssetsFamilyTitle data-syklone="global-family-assets-title">
                                      {active.title}
                                    </GlobalAssetsFamilyTitle>
                                  </Grid>
                                  <Grid item>
                                    <Grid container direction="column">
                                      <Grid item data-syklone="global-family-assets-models-number">
                                        <span style={{ opacity: 0.6 }}>Models:</span> {`3`}
                                      </Grid>
                                      <Grid item data-syklone="global-family-assets-sizes-number">
                                        <span style={{ opacity: 0.6 }}>Sizes:</span> {`3`}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item sx={{ margin: "0px 16px" }} data-syklone="global-family-assets-description">
                            {active.assets.description}
                          </Grid>

                          <Grid item sx={{ width: "100%" }} data-syklone="global-family-files-wrapper">
                            <Grid container direction="column" sx={{ marginBottom: "23px" }}>
                              <Grid item sx={{ marginBottom: "8px" }}>
                                <GlobalAssetsFilesTitle container justifyContent="space-between">
                                  <Grid item>Documents and files</Grid>
                                  <Grid item>
                                    <Grid container direction="row">
                                      {isDownloading ? (
                                        <Grid item>
                                          <Chip label="Downloading..." size="small" />
                                        </Grid>
                                      ) : null}
                                      <Grid item sx={{ opacity: 0.6 }} data-syklone="global-family-files-number">
                                        {active.assets.files.length}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </GlobalAssetsFilesTitle>
                              </Grid>
                              {!isAddingFiles > 0 ? (
                                <GlobalAssetsFilesList>
                                  <Grid container direction="column" data-syklone="global-family-files-list-wrapper">
                                    {active.assets.files.map((item, index) => (
                                      <Grid
                                        item
                                        key={`${item}-${index}`}
                                        sx={{ padding: "3px 3px 3px 16px" }}
                                        data-syklone="global-family-files-list-item"
                                      >
                                        <Grid container justifyContent="space-between" alignItems="center">
                                          <Grid item>
                                            <Box
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "5px",
                                                margin: "5px 0px",
                                              }}
                                            >
                                              <AssetLink
                                                onClick={() =>
                                                  onClickDownloadProductFamilyFiles({
                                                    id: active.id,
                                                    blob: item.name,
                                                  })
                                                }
                                                underline="none"
                                                target="_blank"
                                                rel="noopener"
                                                sx={isDeleting ? { opacity: 0.5 } : { opacity: 1 }}
                                              >
                                                <icons.mui.Description />{" "}
                                                <WidgetOverflowTip>{item.name}</WidgetOverflowTip>
                                              </AssetLink>
                                            </Box>
                                          </Grid>
                                          <Grid item>
                                            <WidgetPopoverMenu
                                              data={dataMenu}
                                              state={{ id: active.id, assets: item }}
                                              loading={isDeleting}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </GlobalAssetsFilesList>
                              ) : (
                                <Box sx={{ paddingLeft: "16px" }}>Uploading...</Box>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Box data-syklone="select-family-asset-alert">
                          <Alert sx={{ margin: "0px 16px" }} severity="info">
                            Select a Product family in order to see family assets
                          </Alert>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </GlobalAssetsWrapper>
              )}
            </>
          )}
        </ProductBox>
      </CustomBox>
    </>
  );
}

PageProductFamilies.propTypes = {
  active: PropTypes.object,
  setActive: PropTypes.func,
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  data: PropTypes.array,
  dataMenu: PropTypes.object,
  dialogState: PropTypes.object,
  dialogFn: PropTypes.object,
  getSearchResults: PropTypes.func,
  isLoadingStatus: PropTypes.object,
  onClickButton: PropTypes.func,
  onClickCreateProductFamily: PropTypes.func,
  onClickAddProductFamilyFiles: PropTypes.func,
  onClickDownloadProductFamilyFiles: PropTypes.func,
  onUploadProductFamilyImage: PropTypes.func,
  getProductFamilyImage: PropTypes.func,
  filterByProductFamily: PropTypes.func,
  getProductFromFamiliy: PropTypes.func,
};

export default PageProductFamilies;
