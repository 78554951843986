import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, DialogContent, DialogTitle, Grid, styled, Typography } from "syklone/ui/index.js";
import { WidgetLoader } from "syklone/components/widgets/widget_loader/index.js";

// imports: local
import CustomDialog from "./custom_dialog.jsx";

// -------- Private --------
const BoxWrapper = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    gap: "16px",
    alignItems: "flex-start",
    padding: "15px",
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.background.paper : theme.palette.grey[200],
    border: "1px solid transparent",
    borderRadius: "6px",
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      border: "1px dotted #373737",
    },
  };
});
const BoxImage = styled(Box)({
  display: "flex",
  borderRadius: "6px",
  padding: "5px",
  backgroundColor: "#000",
  width: "120px",
  height: "90px",
});
const Img = styled("img")({
  width: "120px",
  objectFit: "contain",
});

const NoImageBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
});
const DialogImg = styled("img")({
  width: "600px",
  objectFit: "contain",
});
const BoxPreText = styled(Box)({
  overflow: "hidden",
  maxWidth: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const PreText = styled(Typography)({
  textOverflow: "ellipsis",
  overflow: "hidden",
});

// -------- Public --------
const RecipeItemContainer = ({ snapshot }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <CustomDialog open={dialogOpen} onClose={handleClose} maxWidth="md" data-syklone="recipe-dialoog">
        <DialogTitle>Recipe component</DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <DialogImg alt={snapshot.description} src={snapshot.image} />
            </Grid>
            <Grid item>
              <Typography component="pre">{snapshot.description}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </CustomDialog>
      <Grid item key={snapshot} xs={12} sm={6}>
        <BoxWrapper onClick={handleClickOpen} data-syklone="recipe-item">
          {snapshot.isImageLoading ? (
            <BoxImage>
              <WidgetLoader
                style={{
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 999999,
                }}
              />
            </BoxImage>
          ) : (
            <>
              {snapshot.image ? (
                <BoxImage>
                  <Img alt={snapshot.description} src={snapshot.image} />
                </BoxImage>
              ) : (
                <BoxImage>
                  <NoImageBox>No image</NoImageBox>
                </BoxImage>
              )}
            </>
          )}
          <BoxPreText>
            <PreText component="pre" sx={{ opacity: "0.8" }}>
              {snapshot.description}
            </PreText>
          </BoxPreText>
        </BoxWrapper>
      </Grid>
    </>
  );
};

RecipeItemContainer.propTypes = {
  snapshot: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string,
    blobPath: PropTypes.string,
    isImageLoading: PropTypes.bool.isRequired,
  }),
};

export default RecipeItemContainer;
