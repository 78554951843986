import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import DimensionIndicators from "./dimension_indicators.jsx";
import ImageOverlays from "./images_overlay.jsx";
import PartImages from "./part_images.jsx";
import { Box, styled } from "syklone/ui";

const PlatformBox = styled(Box)({
  width: "100%",
  height: "100%",
  backgroundColor: "#6a6868",
  position: "absolute",
});

const PlatformDisplay = ({ width, height, selectedSize, gridItems, hoveredItem, driftValues }) => {
  const [maxWidth, setMaxWidth] = useState("100%");
  const containerRef = useRef(null);

  const updateMaxWidth = () => {
    if (containerRef.current) {
      setMaxWidth(`${containerRef.current.offsetHeight}px`);
    }
  };

  useEffect(() => {
    updateMaxWidth();
    window.addEventListener("resize", updateMaxWidth);

    return () => {
      window.removeEventListener("resize", updateMaxWidth);
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        width: maxWidth,
        height: "98%",
        position: "relative",
      }}
    >
      <PlatformBox id="platformplate">
        <PartImages
          driftValues={driftValues}
          gridItems={gridItems}
          selectedSize={selectedSize}
          hoveredItem={hoveredItem}
        />
        <ImageOverlays selectedSize={selectedSize} />
      </PlatformBox>
      <DimensionIndicators widthMM={width} heightMM={height} />
    </Box>
  );
};

PlatformDisplay.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  selectedSize: PropTypes.string.isRequired,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
    })
  ).isRequired,
  hoveredItem: PropTypes.number,
  driftValues: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.number.isRequired,
        relx: PropTypes.oneOfType([PropTypes.number, PropTypes.null]),
        rely: PropTypes.oneOfType([PropTypes.number, PropTypes.null]),
      })
    )
  ),
};

export default PlatformDisplay;
