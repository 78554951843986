import React from "react";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

// const capitalizeFirstLetterOnly = (input) => {
//   if (typeof input !== "string") {
//     return input;
//   }
//   return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
// };

const withSiteConfigurationUsers = (Component) => {
  const SiteConfigurationUsers = () => {
    const apiInstance = useApiContext();
    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const queryClient = useQueryClient();
    const {
      data: adminAccounts = [],
      isLoading: isLoadingAccounts,
      error: adminAccountsError,
    } = useQuery(["adminAccounts"], async () => {
      const response = await serviceAuth.getAdminAccounts();
      const data = response.data.data;
      const modifiedData = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          email: item.email,
          department: item.department,
          password: item.password,
          isActive: !!item["is_active"],
          isAdmin: !!item["is_admin"],
          accountTypeId: item["account_type"] ? `${item["account_type"]}` : "",
        };
      });
      return modifiedData;
    });

    // Temporarly Hardcoding the accountTypes as they will return
    /*
    const {
      data: accountTypes = [],
      isLoading: isAdminccountTypesLoading,
      error: adminAccountTypesError,
    } = useQuery(
      ["adminTypes"],
      async () => {
        const response = await serviceAuth.getAdminTypes();
        const data = response.data.data;
        const modifiedData = Object.entries(data).map(([key, value]) => ({
          value: key,
          label: capitalizeFirstLetterOnly(value),
        }));
        return modifiedData;
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );
    */

    const accountTypes = [{ value: "1", label: "User" }];

    const invalidateAdminUsers = () => {
      queryClient.invalidateQueries("adminAccounts");
    };

    const combinedLoading = isLoadingAccounts; //|| isAdminccountTypesLoading;
    const combinedError = adminAccountsError; //|| adminAccountTypesError;

    return (
      <Component
        data={adminAccounts}
        optionsData={{ accountTypes }}
        isLoading={combinedLoading}
        handleRefreshData={invalidateAdminUsers}
        error={combinedError}
      />
    );
  };
  SiteConfigurationUsers.displayName = "SiteConfigurationUsers";
  SiteConfigurationUsers.propTypes = {};
  return SiteConfigurationUsers;
};

export default withSiteConfigurationUsers;
