import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Typography, CircularProgress, styled, Box } from "syklone/ui/index.js";

const CenteredBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2.5),
}));

const WidgetContainer = ({ children, error, isTableDataEmpty, isLoading }) => {
  const errorMessage = typeof error === "string" && error ? error : "There was an error fetching data.";
  return (
    <>
      {isLoading ? (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      ) : error !== null && error !== undefined ? (
        <CenteredBox>
          <Typography>{errorMessage}</Typography>
        </CenteredBox>
      ) : isTableDataEmpty ? ( // Check if table data is empty
        <CenteredBox>
          <Typography>There is no data.</Typography>
        </CenteredBox>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default WidgetContainer;

WidgetContainer.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isTableDataEmpty: PropTypes.bool,
  marginTop: PropTypes.number,
  padding: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};
