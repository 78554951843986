// imports: syklone
import { CommandExchange } from "syklone/graphics/tools/core_commands/index.js";
import { utils } from "syklone/common/index.js";

// imports: local
import { BuildFilesFetcher } from "../../core/index.js";

export class CommandViewPlatform extends CommandExchange {
  constructor(tool, loader, data, apiInstance) {
    // imports: local: { buildFileId: string }
    super(apiInstance, tool, loader, data);
    this.setUndoable(false);
  }

  getClassName() {
    return "CommandViewPlatform";
  }

  async executeAsync() {
    let command = this;
    let widget = command.getWidget();
    const api = command.getApi();
    let data = command.getData();

    let sessionLoader = api.createSessionLoader();
    sessionLoader.setExporterOptions({ mode: "remote" });

    let widgetNotifications = widget.getWidgetNotifications();
    let service = api.sykloneApi.servicePlatformSessions;
    let loader = command.getLoader();
    let buildFileId = data.buildFileId;
    let buildFileType = data.buildFileType;
    let platformIndex = data.platformIndex;

    try {
      let startTime = new Date();

      loader(true, "Viewing platform 1/4");
      let fetcher = new BuildFilesFetcher(buildFileType, api);
      let platformId = await fetcher.getPlatformIdFromBuildFile(buildFileId, platformIndex);
      const id = platformId;
      const filename = `${id}`;

      loader(true, "Viewing platform 2/4");
      let response = await service.getBlob(id);
      let buffer = response.data;
      let text = new TextDecoder().decode(buffer);
      let state = JSON.parse(text);
      let widgetNotifications = widget.getWidgetNotifications();

      loader(true, "Viewing platform 3/4");
      await api.sykloneApi.serviceBlobCache.downloadComponents(state.widgetRender.scene.selectables);

      loader(true, "Importing platform 4/4");
      // is this correct?
      //widget.acceptVisitor(importer, state.widgetRender);
      sessionLoader.restoreFromDict(".widget", widget, state.widgetRender);

      let elapsed = new Date() - startTime;

      widget.handleBoundingBox();
      widget.getEventFactory().EventSceneGraphChanged(command);
      widgetNotifications.addNotification("VIEW_PLATFORM", "success", `Platform "${filename}" opened in ${elapsed}ms`);
      loader(false);

      // TODO: Hack to clear up
      widget.setInfoView("");
      widget.setInfoMode("");
      widget.setInfoContext("");
      widget.handleBoundingBox();
    } catch (error) {
      widget.handleBoundingBox();
      widget.getEventFactory().EventSceneGraphChanged(command);
      widgetNotifications.addNotification("VIEW_PLATFORM", "error", utils.stringifyError(error));
      loader(false);
    }
  }
}
