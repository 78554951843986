import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// imports: syklone
import { QueryClient, QueryClientProvider, ReactQueryDevtools } from "syklone/libraries/index.js";
import { ApiProvider, AuthProvider } from "syklone/api/react/index.js";
import { factories } from "syklone/api/index.js";
import { createTheme, CssBaseline, ThemeProvider, themes } from "syklone/ui/index.js";

// imports: local
import { getEnv as getRemoteEnv } from "./env.js";
import "./index.css";
import App from "./app.jsx";
import * as serviceWorker from "./service_worker.js";

const queryClient = new QueryClient();
const api = factories.createRemoteApi(getRemoteEnv(), "prdSvcAuthSign");
const root = ReactDOM.createRoot(document.getElementById("root"));
let currentTheme = createTheme(themes.themeDark);

root.render(
  <React.StrictMode>
    <ApiProvider api={api}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ThemeProvider theme={currentTheme}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </ApiProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
