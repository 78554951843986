import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  WidgetTopBarContainer,
  WidgetTopBarLogo,
  WidgetTopBarRightControlContainer,
  WidgetAvatar,
} from "syklone/components/widgets/index.js";
import { Button } from "syklone/ui/index.js";
import { useAuthContext, useAuthRedirect } from "syklone/api/react/index.js";

// imports: local
import * as img from "../../img/index.js";

function WidgetAuditTrailTopBar({ onTheme, navigate }) {
  const authState = useAuthContext();
  const { initiateAuthLogoutRedirect } = useAuthRedirect();
  let username = authState.user ? authState.user.name : undefined;
  // -------- Slots --------
  const onLogout = () => {
    initiateAuthLogoutRedirect();
  };

  const onProfile = () => {
    //implement later
  };

  // -------- Reactjs render --------
  return (
    <>
      <WidgetTopBarContainer
        style={{
          padding: "0px 20px",
          paddingResponsive: "0px 20px",
          justifyContent: "space-between",
        }}
        hasBorder={true}
      >
        <WidgetTopBarLogo img={img.LogoAuditTrail} alt="Audit trail" />
        <WidgetTopBarRightControlContainer>
          <Button onClick={() => navigate("/dashboard")} data-syklone="back-to-dashboard">
            Back to Dashboard
          </Button>
          <WidgetAvatar username={username} hasNotification={false} onProfile={onProfile} onLogout={onLogout} />
        </WidgetTopBarRightControlContainer>
      </WidgetTopBarContainer>
    </>
  );
}

WidgetAuditTrailTopBar.propTypes = {
  classes: PropTypes.object,
  navigate: PropTypes.func,
  location: PropTypes.object,
  onTheme: PropTypes.func,
};

export default WidgetAuditTrailTopBar;
