import React from "react";
import { useLocation } from "react-router-dom";

// imports: local
import WidgetPlatformViewerContainer from "./widget_platform_viewer_container.jsx";

function WidgetPlatformViewer() {
  const location = useLocation();
  const { buildFileId, buildFileType } = location.state;

  return <WidgetPlatformViewerContainer buildFileId={buildFileId} buildFileType={buildFileType} />;
}

export default WidgetPlatformViewer;
