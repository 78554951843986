import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Outlet, Link as RouterLink, matchPath } from "react-router-dom";

// imports: syklone
import { Grid, Box, icons, Tab, Tabs, Tooltip } from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { WidgetTitle } from "../index.js";
import { BuildFilesFetcher, BUILD_FILES_PROMOTION_TAB_VALUES } from "../../core/index.js";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`part-platform-tabpanel-${index}`}
      aria-labelledby={`part-platform-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3, pb: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function accessibilityProps(index) {
  return {
    id: `part-platform-tab-${index}`,
    "aria-controls": `part-platform-tabpanel-${index}`,
  };
}

const tabRoutes = [
  "/dashboard/part-platform/viewer/part-viewer",
  "/dashboard/part-platform/viewer/platform-viewer",
  "/dashboard/part-platform/viewer/slice-analyser",
];

function WidgetPagePartPlatformViewer({ location }) {
  const [buildFileId, setBuildFileId] = useState("");
  const [buildFileType, setBuildFileType] = useState("");
  const [tabValue, setTabValue] = useState("/dashboard/part-platform/viewer/part-viewer");
  const [isPlatform, setIsPlatform] = useState(false);

  const apiInstance = useApiContext();

  useEffect(() => {
    const { buildFileId, buildFileType } = location.state;
    setBuildFileId(buildFileId);
    setBuildFileType(buildFileType);
  }, []);

  useEffect(() => {
    tabRoutes.map((item) => {
      if (matchPath(`${item}/*`, location.pathname)) {
        setTabValue(item);
      }
    });
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      await checkIfPlatformsAreAvailable();
    };

    if (buildFileType) {
      fetchData();
    }
  }, [buildFileType]);

  const checkIfPlatformsAreAvailable = async () => {
    let fetcher = new BuildFilesFetcher(buildFileType, apiInstance);
    let platformId = await fetcher.getPlatformIdFromBuildFile(buildFileId);

    if (platformId) {
      setIsPlatform(true);
    }
  };

  const createTitle = () => {
    const title = ["Research and development", "Under review", "Released", "Cancelled", "Obsolete"];
    switch (buildFileType) {
      case BUILD_FILES_PROMOTION_TAB_VALUES[0]:
        return title[0];
      case BUILD_FILES_PROMOTION_TAB_VALUES[1]:
        return title[1];
      case BUILD_FILES_PROMOTION_TAB_VALUES[2]:
        return title[2];
      case BUILD_FILES_PROMOTION_TAB_VALUES[3]:
        return title[3];
      case BUILD_FILES_PROMOTION_TAB_VALUES[4]:
        return title[4];
      default:
        return "No title found";
    }
  };

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid sx={styles.wrapper} rowSpacing={2} container direction="column">
      <Grid sx={styles.titleRow} item>
        <WidgetTitle
          title={createTitle()}
          subTitle={buildFileId}
          subTitlePrefix="Build id"
          isBackButton={true}
          backRoute="/dashboard/part-platform"
        />
      </Grid>
      <Grid item>
        <Box sx={styles.subpageWrapper}>
          <Box sx={styles.tabWrapper}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              aria-label="part promotion tabs"
              sx={{
                ".MuiTabs-indicator": {
                  display: "none",
                },
                ".MuiTabs-flexContainer": {
                  gap: "5px",
                },
              }}
            >
              <Tab
                label="Part viewer"
                sx={styles.styledTabs}
                value={tabRoutes[0]}
                to={tabRoutes[0]}
                state={{ buildFileId: buildFileId, buildFileType: buildFileType }}
                component={RouterLink}
              />
              <Tab
                label={
                  <div>
                    Platform viewer
                    {!isPlatform && (
                      <Tooltip title="No data available" arrow>
                        <icons.mui.DoNotDisturbAlt
                          fontSize="small"
                          style={{ marginLeft: 5, verticalAlign: "middle" }}
                        />
                      </Tooltip>
                    )}
                  </div>
                }
                sx={styles.styledTabs}
                value={tabRoutes[1]}
                to={tabRoutes[1]}
                state={{ buildFileId: buildFileId, buildFileType: buildFileType }}
                component={RouterLink}
                disabled={!isPlatform}
                {...accessibilityProps(1)}
              />
              <Tab
                label="Slice Analyser"
                sx={styles.styledTabs}
                value={tabRoutes[2]}
                to={tabRoutes[2]}
                state={{ buildFileId: buildFileId, buildFileType: buildFileType }}
                component={RouterLink}
                {...accessibilityProps(2)}
              />
            </Tabs>
          </Box>
          <Outlet />
        </Box>
      </Grid>
    </Grid>
  );
}

WidgetPagePartPlatformViewer.propTypes = {
  location: PropTypes.object,
};

const styles = {
  wrapper: {
    padding: "25px",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
  },
  boxTitle: {
    fontSize: "22px!important",
    fontWeight: "500!important",
  },
  boxSubtitle: {
    fontSize: "12px!important",
    opacity: ".6",
    paddingTop: "5px",
  },
  subpageWrapper: {
    position: "relative",
    background: (theme) => (theme.palette.mode === "dark" ? "#09090D" : "#f8f8f8"),
    borderRadius: "6px",
  },
  tabWrapper: {
    paddingTop: "20px",
    paddingLeft: "20px",
    zIndex: "1",
  },
  styledTabs: {
    "&.Mui-selected": {
      backgroundColor: "#FFB500!important",
      color: "#000000!important",
    },
    borderRadius: "6px!important",
    backgroundColor: (theme) => (theme.palette.mode === "light" ? "#e1e1e1!important" : "#202022!important"),
    minHeight: "36px!important",
  },
  tabPanel: {
    "& > .MuiBox-root": {
      padding: "10px 0px 20px 0px",
    },
  },
};

export default WidgetPagePartPlatformViewer;
