import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Alert, Grid, Box, Button, icons, Pagination, styled } from "syklone/ui/index.js";

// imports: local
import { WidgetTitle, WidgetScheduleRow } from "../../widgets/index.js";
import { DialogStartBuildFile, DialogScheduleBuildFile } from "../../dialogs/index.js";

const CustomBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const CustomGrid = styled(Grid)({
  width: "100%",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

const CustomPagination = styled(Pagination)(() => ({
  margin: "8px 0px",
  "& .MuiPagination-ul": {
    justifyContent: "center",
  },
}));

const MessageBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "80px",
  padding: "0",
}));

const CustomAlert = styled(Alert)(() => ({
  fontSize: "14px",
  marginBottom: "10px",
}));

function PageSchedulingManual({
  alert,
  setAlert,
  data,
  isLoading,
  machineData,
  releasedBuildFiles,
  urBuildFiles,
  rdBuildFiles,
  onClickScheduleBuildFile,
  onClickMachine,
  putChangeBuildFilePosition,
  putUnscheduleBuildFile,
  getBfmPrintStatusByTaskId,
  dialogFn,
  dialogState,
}) {
  const { machines } = data;
  const itemsPerPage = 6;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  /* eslint-disable no-unused-vars */
  //const [updatedMachines, setUpdatedMachines] = useState([]);

  useEffect(() => {
    setNoOfPages(Math.ceil(machines.length / itemsPerPage));
  }, [machines]);

  const {
    setBuildStartDialogOpen,
    setBuildStartDialogData,
    setBuildFileScheduleDialogOpen,
    getLabelSemantics,
    postBfmPrint,
    getSnapshotBlobById,
    putRescheduleToAnotherMachine,
    postBfmPrintApproved,
    postBfmSwitchLockByMachineId,
    postBfmSwitchUnlockByMachineId,
  } = dialogFn;
  const { buildStartDialogOpen, buildStartDialogData, buildFileScheduleDialogOpen, labelSemantics, labelLoading } =
    dialogState;
  const {
    isLoadingMachines,
    isLoadingSchedulesBuildFiles,
    isLoadingReleasedBuildFiles,
    isChangePositionLoading,
    isLoadingAddBuildFiles,
    isRescheduleLoading,
    isPlayDialogLoading,
  } = isLoading;

  const handlePaginationChange = (_event, value) => {
    setPage(value);
  };

  /* eslint-disable */
  const onApprove = async (payload) => {
    await postBfmPrint(payload);
  };

  const onApproveApproved = async (payload) => {
    await postBfmPrintApproved(payload);
  };

  return (
    <>
      <DialogScheduleBuildFile
        alert={alert}
        setAlert={setAlert}
        isOpen={buildFileScheduleDialogOpen}
        setIsOpen={setBuildFileScheduleDialogOpen}
        machineData={machineData}
        releasedBuildFiles={releasedBuildFiles}
        urBuildFiles={urBuildFiles}
        rdBuildFiles={rdBuildFiles}
        onClickScheduleBuildFile={onClickScheduleBuildFile}
        getLabelSemantics={getLabelSemantics}
        labelSemantics={labelSemantics}
        labelLoading={labelLoading}
        getSnapshotBlobById={getSnapshotBlobById}
      />
      <DialogStartBuildFile
        onClose={setBuildStartDialogOpen}
        isOpen={buildStartDialogOpen}
        data={buildStartDialogData}
        onApprovePart={onApprove}
        onApproveApproved={onApproveApproved}
        postBfmSwitchLockByMachineId={postBfmSwitchLockByMachineId}
        postBfmSwitchUnlockByMachineId={postBfmSwitchUnlockByMachineId}
        isPlayDialogLoading={isPlayDialogLoading}
      />
      <Grid container rowSpacing={3} data-syklone="scheduling-wrapper">
        <CustomGrid item>
          <CustomBox>
            <WidgetTitle title="Scheduled build files" />
            <ButtonWrapper>
              <Button
                variant="contained"
                onClick={() => setBuildFileScheduleDialogOpen(true)}
                startIcon={<icons.mui.BuildCircle fontSize="small" />}
                data-syklone="schedule-button"
                disabled={machines.length === 0 || isLoadingAddBuildFiles ? true : false}
              >
                {isLoadingAddBuildFiles ? "Loading..." : "Schedule build file"}
              </Button>
            </ButtonWrapper>
          </CustomBox>
        </CustomGrid>
        {isLoadingMachines || isLoadingSchedulesBuildFiles || isLoadingReleasedBuildFiles ? (
          <MessageBox>
            <CustomAlert severity="info">Loading...</CustomAlert>
          </MessageBox>
        ) : machines.length === 0 ? (
          <MessageBox>
            <CustomAlert severity="info">No machines available</CustomAlert>
          </MessageBox>
        ) : (
          <>
            <CustomGrid item>
              <Grid container rowSpacing={2.5}>
                {machines.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item, index) => {
                  return (
                    <CustomGrid key={index} item data-syklone="scheduling-machine-row">
                      <Grid container rowSpacing={1}>
                        <WidgetScheduleRow
                          alert={alert}
                          setAlert={setAlert}
                          machine={item.machine}
                          active={false}
                          buildFiles={item.buildFiles}
                          dialogFn={{ setBuildStartDialogOpen, setBuildStartDialogData }}
                          onClickMachine={onClickMachine}
                          putChangeBuildFilePosition={putChangeBuildFilePosition}
                          putUnscheduleBuildFile={putUnscheduleBuildFile}
                          isChangePositionLoading={isChangePositionLoading}
                          putRescheduleToAnotherMachine={putRescheduleToAnotherMachine}
                          isRescheduleLoading={isRescheduleLoading}
                          postBfmSwitchUnlockByMachineId={postBfmSwitchUnlockByMachineId}
                        />
                      </Grid>
                    </CustomGrid>
                  );
                })}

                <Grid item xs={12}>
                  <CustomPagination
                    color="primary"
                    count={noOfPages}
                    page={page}
                    onChange={handlePaginationChange}
                    defaultPage={1}
                    variant="outlined"
                    shape="rounded"
                    size="large"
                    data-syklone="scheduling-pagination"
                  />
                </Grid>
              </Grid>
            </CustomGrid>
          </>
        )}
      </Grid>
    </>
  );
}

PageSchedulingManual.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  data: PropTypes.object,
  isLoading: PropTypes.object,
  machineData: PropTypes.array,
  releasedBuildFiles: PropTypes.array,
  urBuildFiles: PropTypes.array,
  rdBuildFiles: PropTypes.array,
  onClickScheduleBuildFile: PropTypes.func,
  onClickMachine: PropTypes.func,
  putChangeBuildFilePosition: PropTypes.func,
  putUnscheduleBuildFile: PropTypes.func,
  getBfmPrintStatusByTaskId: PropTypes.func,
  dialogFn: PropTypes.object,
  dialogState: PropTypes.object,
};

export default PageSchedulingManual;
