import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, Outlet } from "react-router-dom";

// imports: syklone
import { Box, Chip, Button, Grid, icons, Link, styled, Typography } from "syklone/ui/index.js";

// imports: local
import { DialogAddFiles } from "../../dialogs/index.js";
import { WidgetAutocompleteSearch, WidgetTitle, WidgetOverflowTip, WidgetPopoverMenu } from "../../widgets/index.js";

const CustomBox = styled(Box)(() => ({
  width: "100%",
  margin: "25px 30px 30px 30px",
}));

const ProductBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  backgroundColor: theme.palette.mode === "dark" ? "#000" : "#f8f8f8",
  borderRadius: "6px",
  marginTop: "22px",
}));

const ProductBoxWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: "16px 19px",
  borderRight: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
}));

const ProductAssetsWrapper = styled(Box)(({ theme }) => ({
  paddingTop: "16px",
  width: "425px",
}));

const ProductTitle = styled(Grid)(({ theme }) => ({
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const PageProductDetailsTitle = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
  paddingRight: "16px",
  marginLeft: "16px",
  paddingBottom: "16px",
}));

const PageProductDetailsFilesTitle = styled(Grid)(() => ({
  fontSize: "14px",
}));

const PageProductDetailsFilesList = styled(Box)(() => ({
  height: "200px",
  overflowX: "hidden",
  overflowY: "auto",
  marginRight: "10px",
}));

const BoxFamiliyTitle = styled(Typography)(() => ({
  fontSize: "16px",
}));

const PageProductDetailsRow = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#f8f8f8",
  borderRadius: "6px",
  padding: "13px",
  minWidth: "210px",
  border: "1px solid transparent",
}));

const PageProductDetailsRowWithTitle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#f8f8f8",
  borderRadius: "6px",
  minWidth: "210px",
  border: "1px solid transparent",
}));

const ProductImg = styled("img")(({ theme }) => ({
  width: "240px",
  borderRadius: "6px",
  border: `1px solid ${theme.palette.mode === "dark" ? "#212121" : "#dadada"}`,
}));

const PageProductDetailsLeft = styled(Box)(({ theme }) => ({
  "& ul": {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  "& ul > li": {
    marginBottom: "5px",
  },
  display: "flex",
  alignItems: "flex-start",
  gap: 14,
  padding: "8px 18px",
}));

const PageProductDetailsLeftValue = styled(Box)(({ theme }) => ({
  textAlign: "right",
  color: "#878787",
}));

const PageProductDetailsRowLeftValue = styled(Box)(({ theme }) => ({
  paddingLeft: 14,
  borderLeft: theme.palette.mode === "dark" ? "1px solid #404040" : "1px solid #E2E2E2",
}));

const AssetLink = styled(Link)(({ theme }) => ({
  display: "flex",
  maxWidth: "340px",
  gap: "10px",
  color: theme.palette.mode === "dark" ? "#FFF" : "#000",
  opacity: theme.palette.mode === "dark" ? 1 : 0.6,
  transition: "0.3s",
  cursor: "pointer",
  "&:hover": { color: theme.palette.primary.main },
  "&:hover > .MuiSvgIcon-root": { opacity: 1 },
  "& > .MuiSvgIcon-root": { opacity: 0.6, transition: "0.1s" },
}));

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

function PageProductDetails({
  id,
  alert,
  setAlert,
  data,
  dataMenu,
  dialogState,
  dialogFn,
  isLoading,
  getSearchResults,
  getAuditTrailHistory,
  onClickAddProductFile,
  onClickDownloadProductFiles,
}) {
  const location = useLocation();
  const [productData, setProductData] = useState({});

  // Loading states/fn
  const { isSearchLoading, isDownloading, isDeleting, isBuildFileLoading, isAddingFiles } = isLoading;

  // Dialog states
  const { filesList, isDialogAddFilesOpen } = dialogState;

  // Dialog functions
  const { setFilesList, setIsDialogAddFilesOpen } = dialogFn;

  const onClickAddFiles = () => {
    setIsDialogAddFilesOpen(true);
  };

  const productTitle = location?.state?.name === undefined ? "Product details" : location.state.name;

  useEffect(() => {
    if (!isObjectEmpty(data)) {
      setProductData({
        id: data.dataPageProductDetails.id,
        assets: data.dataPageProductDetails.assets,
        product: data.dataPageProductDetails.product,
        buidFiles: data.dataPageProductDetails.buidFiles,
        image: data.image,
      });
      return () => {
        setProductData({});
      };
    }
  }, [data]);

  return (
    <>
      <DialogAddFiles
        alert={alert}
        setAlert={setAlert}
        id={id}
        isLoading={isAddingFiles}
        isOpen={isDialogAddFilesOpen}
        setIsOpen={setIsDialogAddFilesOpen}
        filesList={filesList}
        setFilesList={setFilesList}
        onSubmit={onClickAddProductFile}
      />
      <CustomBox>
        <Grid container justifyContent="space-between" sx={{ alignItems: "center" }} wrap="nowrap">
          <ProductTitle item>
            <WidgetTitle
              title={productTitle}
              isBackButton={true}
              limitWidth={true}
              backRoute="/dashboard/product-families"
            />
          </ProductTitle>
          <Grid item>
            <WidgetAutocompleteSearch getSearchResults={getSearchResults} isSearchLoading={isSearchLoading} />
          </Grid>
        </Grid>
        <ProductBox>
          <ProductBoxWrapper>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <PageProductDetailsRow>
                  <Grid container>
                    <Grid item>
                      <ProductImg src={productData.image} />
                    </Grid>
                    <Grid item>
                      <PageProductDetailsLeft>
                        <PageProductDetailsLeftValue>
                          <ul data-syklone="product-details-keys-container">
                            <li>Family</li>
                            <li>Product ID</li>
                            <li>Model</li>
                            <li>Size</li>
                          </ul>
                        </PageProductDetailsLeftValue>
                        <PageProductDetailsRowLeftValue>
                          <ul data-syklone="product-details-values-container">
                            <li>{productTitle}</li>
                            <li>{productData.product?.productId}</li>
                            <li>{productData.product?.model}</li>
                            <li>{productData.product?.size}</li>
                          </ul>
                        </PageProductDetailsRowLeftValue>
                      </PageProductDetailsLeft>
                    </Grid>
                  </Grid>
                </PageProductDetailsRow>
              </Grid>

              <Grid item>
                <PageProductDetailsRowWithTitle>
                  <Outlet context={[data, getAuditTrailHistory, isBuildFileLoading]} />
                </PageProductDetailsRowWithTitle>
              </Grid>
            </Grid>
          </ProductBoxWrapper>
          <ProductAssetsWrapper data-syklone="product-assets">
            <Grid container direction="column" spacing={2}>
              <PageProductDetailsTitle item>
                <Grid container justifyContent="space-between" sx={{ alignItems: "center" }}>
                  <Grid item>
                    <BoxFamiliyTitle>Product assets</BoxFamiliyTitle>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      startIcon={<icons.mui.Add />}
                      data-syklone="add-product-files-button"
                      onClick={() => onClickAddFiles()}
                    >
                      Add files
                    </Button>
                  </Grid>
                </Grid>
              </PageProductDetailsTitle>
              <Grid item>
                <Grid container spacing={2}>
                  {productData.assets?.files.length > 0 && (
                    <Grid item sx={{ width: "100%" }} data-syklone="product-files-wrapper">
                      <Grid container direction="column" sx={{ marginBottom: "23px" }}>
                        <Grid item sx={{ marginBottom: "8px" }}>
                          <PageProductDetailsFilesTitle
                            container
                            justifyContent="space-between"
                            sx={{ padding: "0px 16px" }}
                          >
                            <Grid item sx={{ fontWeight: "bold" }}>
                              Documents and files
                            </Grid>

                            <Grid item>
                              <Grid container direction="row">
                                {isDownloading ? (
                                  <Grid item>
                                    <Chip label="Downloading..." size="small" />
                                  </Grid>
                                ) : null}
                                <Grid item sx={{ opacity: 0.6 }}>
                                  {productData.assets.files.length}
                                </Grid>
                              </Grid>
                            </Grid>
                          </PageProductDetailsFilesTitle>
                        </Grid>

                        <PageProductDetailsFilesList>
                          <Grid container direction="column" data-syklone="product-files-list-wrapper">
                            {productData.assets.files.map((item, index) => (
                              <Grid
                                item
                                key={`${item}-${index}`}
                                sx={{ padding: "3px 3px 3px 16px" }}
                                data-syklone="product-files-list-item"
                              >
                                <Grid container justifyContent="space-between" alignItems="center">
                                  <Grid item>
                                    <Box sx={{ display: "flex", alignItems: "center", gap: "5px", margin: "5px 0px" }}>
                                      <AssetLink
                                        underline="none"
                                        target="_blank"
                                        rel="noopener"
                                        sx={isDeleting ? { opacity: 0.5 } : { opacity: 1 }}
                                        onClick={() =>
                                          onClickDownloadProductFiles({
                                            id: id,
                                            name: item.name,
                                          })
                                        }
                                      >
                                        <icons.mui.Description /> <WidgetOverflowTip>{item.name}</WidgetOverflowTip>
                                      </AssetLink>
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <WidgetPopoverMenu
                                      data={dataMenu}
                                      state={{ name: item.name }}
                                      loading={isDeleting}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </PageProductDetailsFilesList>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ProductAssetsWrapper>
        </ProductBox>
      </CustomBox>
    </>
  );
}

PageProductDetails.propTypes = {
  id: PropTypes.string,
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  data: PropTypes.object,
  dataMenu: PropTypes.object,
  dialogState: PropTypes.object,
  dialogFn: PropTypes.object,
  getSearchResults: PropTypes.func,
  getAuditTrailHistory: PropTypes.func,
  isLoading: PropTypes.object,
  onClickAddProductFile: PropTypes.func,
  onClickDownloadProductFiles: PropTypes.func,
};

export default PageProductDetails;
