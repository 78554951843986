import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Stepper, Step, StepLabel, Divider, Button, Box, Typography } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

const withWizardStepsAbove = (DialogCommonBaseComponent) => {
  const DialogCommonWizard = ({
    activeStep,
    handleNext,
    handleBack,
    children,
    isNextDisabled,
    isBackDisabled,
    isError,
    isLoading,
    stepsTitle,
    nextStepTitle = "Next",
    ...props
  }) => {
    return (
      <DialogCommonBaseComponent
        dialogActionsSx={{ backgroundColor: "#343434" }}
        {...props}
        actions={
          <>
            <Button color="inherit" disabled={activeStep === 0 || isBackDisabled} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button variant="contained" size="small" disabled={isNextDisabled} onClick={handleNext}>
              {nextStepTitle}
            </Button>
          </>
        }
      >
        <Stepper sx={{ padding: "0px 0px 13px 0px" }} activeStep={activeStep} alternativeLabel>
          {stepsTitle.map((label, index) => {
            return (
              <Step
                key={label}
                data-syklone={`${props.dataTag}-step-number`}
                sx={{
                  "& .MuiStepConnector-root": {
                    top: "16px",
                  },
                }}
              >
                <StepLabel
                  optional={isError && <Typography variant="caption" color="error"></Typography>}
                  error={isError}
                  data-syklone={`${props.dataTag}-wizard-step`}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 34,
                    },
                    "& .MuiStepLabel-label": {
                      marginTop: "6px!important",
                    },
                  }}
                >
                  <span
                    data-syklone={`${props.dataTag}-wizard-step-label`}
                    data-step-number={index + 1}
                    data-step-active={index === activeStep}
                  >
                    {label}
                  </span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Divider />
        {children}
      </DialogCommonBaseComponent>
    );
  };
  DialogCommonWizard.displayName = "DialogCommonWizardStepsAbove";
  DialogCommonWizard.propTypes = {
    ...DialogCommonBase.propTypes,
    activeStep: PropTypes.number,
    handleNext: PropTypes.func,
    handleBack: PropTypes.func,
    nextStepTitle: PropTypes.string,
    stepsTitle: PropTypes.array,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isBackDisabled: PropTypes.bool,
    isNextDisabled: PropTypes.bool,
  };
  return DialogCommonWizard;
};

const DialogCommonWizardStepsAbove = withWizardStepsAbove(DialogCommonBase);

export default DialogCommonWizardStepsAbove;
