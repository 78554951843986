import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Grid, Box, icons, Link, styled } from "syklone/ui/index.js";

// imports: local
import { WidgetOverflowTip } from "../index.js";

const SearchProductsCategory = styled(Grid)(({ theme }) => ({
  padding: "8px 16px",
  borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#2E2E2E" : "#dadada"}`,
  borderTop: `1px solid ${theme.palette.mode === "dark" ? "#2E2E2E" : "#dadada"}`,
}));

const SearchProductsList = styled(Box)(() => ({
  maxHeight: "230px",
  overflowX: "hidden",
  overflowY: "auto",
  marginTop: "6px",
  marginRight: "8px",
  "&::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    minHeight: "24px",
    borderRadius: "8px",
    backgroundColor: "#585859",
  },
  "&::-webkit-scrollbar-corner": {
    backgroundColor: "#202022",
  },
}));

const SearchLink = styled(Link)(({ theme }) => ({
  display: "flex",
  maxWidth: "340px",
  alignItems: "center",
  gap: "10px",
  color: theme.palette.mode === "dark" ? "#FFF" : "#000",
  opacity: theme.palette.mode === "dark" ? 1 : 0.6,
  cursor: "pointer",
  transition: "0.3s",
  "&:hover": { color: theme.palette.primary.main },
  "&:hover > .MuiSvgIcon-root": { opacity: 1 },
  "& > .MuiSvgIcon-root": { opacity: 0.6, transition: "0.1s" },
}));

const NoIcon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "26px",
  height: "26px",
  borderRadius: "50%",
  backgroundColor: "#000",
  fontSize: 12,
  color: "#fff",
}));

function getFirstCharacter(str) {
  return str.charAt(0).toUpperCase();
}

function WidgetAutoCompleteSearchItem({ data }) {
  return (
    <Grid container direction="column" sx={{ paddingBottom: "8px" }}>
      <Grid item>
        <SearchProductsCategory container justifyContent="space-between" sx={{ padding: "0px 16px" }}>
          <Grid item>{data.name}</Grid>
          <Grid item sx={{ opacity: 0.6 }}>
            {data.results.length}
          </Grid>
        </SearchProductsCategory>
      </Grid>
      <SearchProductsList>
        <Grid container direction="column" data-syklone="search-category-list-wrapper">
          {data.results.map((item, index) => (
            <Grid
              item
              key={`${item}-${index}`}
              sx={{ padding: "3px 3px 3px 16px" }}
              data-syklone="search-category-list-item"
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px", margin: "5px 0px" }}>
                {data.type === "files" ? (
                  <SearchLink href={item.link} underline="none" target="_blank" rel="noopener">
                    <icons.mui.Description /> <WidgetOverflowTip>{item.name}</WidgetOverflowTip>
                  </SearchLink>
                ) : (
                  <SearchLink href={`link/to/fronted-page`} underline="none">
                    <NoIcon>{getFirstCharacter(data.name)}</NoIcon>
                    <Grid container direction="column" sx={{ width: "auto" }}>
                      <Grid item>
                        <WidgetOverflowTip>{item.name}</WidgetOverflowTip>
                      </Grid>
                      <Grid item sx={{ opacity: 0.6 }}>
                        {item.productFamily}
                      </Grid>
                    </Grid>
                  </SearchLink>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      </SearchProductsList>
    </Grid>
  );
}

WidgetAutoCompleteSearchItem.propTypes = {
  data: PropTypes.object,
};

export default WidgetAutoCompleteSearchItem;
