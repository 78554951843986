import React from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";

// imports: syklone
import { Box, SnackbarProvider } from "syklone/ui/index.js";

// imports: local
import { WidgetAuditTrailTopBar } from "../../widgets/index.js";

function PageAuditTrail({ navigate, location }) {
  // -------- Reactjs render --------
  return (
    <>
      <SnackbarProvider>
        <Box sx={styles.topBarContainer}>
          <WidgetAuditTrailTopBar location={location} navigate={navigate} />
        </Box>
        <Outlet />
      </SnackbarProvider>
    </>
  );
}

PageAuditTrail.propTypes = {
  navigate: PropTypes.func,
  location: PropTypes.object,
};

const styles = {
  topBarContainer: { position: "fixed", top: 0, left: 0, width: "100%", zIndex: 3 },
};

export default PageAuditTrail;
