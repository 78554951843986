import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DataGrid, Grid, Stack, styled } from "syklone/ui/index.js";

// imports: local
import WidgetCustomChip from "../widget_custom_chip/widget_custom_chip.jsx";

const GridWrapper = styled(Grid)({
  padding: "12px 7px",
});

function WidgetPodMetrics({ data }) {
  const [isLoading] = useState(false);

  // useMemo to recalculate when data changes
  const statusCounts = React.useMemo(() => {
    const statusCounts = { Pending: 0, Running: 0, Succeeded: 0, Failed: 0 };
    data.forEach((item) => {
      if (statusCounts[item.status] !== undefined) {
        statusCounts[item.status]++;
      }
    });
    return statusCounts;
  }, [data]);

  const expandedData = React.useMemo(() => {
    let expanded = [];
    data.forEach((row) => {
      row.containers.forEach((container, index) => {
        expanded.push({
          id: `${row.id}-${index}`,
          name: index === 0 ? row.name : "",
          status: index === 0 ? row.status : "",
          container: container.name,
          cpu: container.usage.cpu,
          memory: container.usage.memory,
        });
      });
    });
    return expanded;
  }, [data]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "container",
      headerName: "Container",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "cpu",
      headerName: "CPU",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "memory",
      headerName: "Memory",
      flex: 1,
      disableColumnMenu: true,
    },
  ];

  return (
    <GridWrapper container>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1}>
          <WidgetCustomChip title="Failed" type="error" val={statusCounts.Failed} />
          <WidgetCustomChip title="Pending" type="warning" val={statusCounts.Pending} />
          <WidgetCustomChip title="Succeeded" type="unlisted" val={statusCounts.Succeeded} />
          <WidgetCustomChip title="Running" type="success" val={statusCounts.Running} />
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ height: 375 }}>
        <DataGrid
          columnVisibilityModel={{
            id: false,
          }}
          rows={expandedData}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          loading={isLoading ? true : false}
          sx={(theme) => ({
            fontSize: 13,
            "&.MuiDataGrid-root": {
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            ".MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          })}
        />
      </Grid>
    </GridWrapper>
  );
}

WidgetPodMetrics.propTypes = {
  data: PropTypes.array,
};

export default WidgetPodMetrics;
