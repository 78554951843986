import React from "react";
import PropTypes from "prop-types";
import { styled } from "syklone/ui";

const fontSize = "16px";
const arrowPadding = "10px 5px";

const Indicator = styled("span")(({ isWidth }) => ({
  position: "absolute",
  fontSize: fontSize,
  transform: isWidth ? "translateX(-50%)" : "translateY(-50%) rotate(90deg)",
  bottom: isWidth ? "-25px" : "initial",
  left: isWidth ? "50%" : "initial",
  right: isWidth ? "initial" : "-70px",
  top: isWidth ? "initial" : "50%",
}));

const Arrow = styled("span")({
  fontSize: fontSize,
  padding: arrowPadding,
});

const DimensionIndicators = ({ widthMM, heightMM }) => (
  <div>
    <Indicator isWidth aria-label={`Width: ${widthMM} mm`} data-syklone="width-indicator">
      <Arrow>←</Arrow>
      {`${widthMM} mm`}
      <Arrow>→</Arrow>
    </Indicator>
    <Indicator aria-label={`Height: ${heightMM} mm`} data-syklone="height-indicator">
      <Arrow>←</Arrow>
      {`${heightMM} mm`}
      <Arrow>→</Arrow>
    </Indicator>
  </div>
);

DimensionIndicators.propTypes = {
  widthMM: PropTypes.number.isRequired,
  heightMM: PropTypes.number.isRequired,
};

export default DimensionIndicators;
