import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import OverlayImage from "./image_overlay.jsx";
import * as img from "../img/index.js";

const ImageOverlays = ({ selectedSize }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    let newImages;
    switch (selectedSize) {
      case "0":
        newImages = [
          { leftPos: -26, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 0, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 26, topPos: 97, backgroundImage: img.oddCircle, width: 10, height: 10 },
          { leftPos: -41, topPos: -79, backgroundImage: img.smallRound, width: 10, height: 10 },
          { leftPos: 41, topPos: -79, backgroundImage: img.smallFeint, width: 8, height: 10 },
        ];
        break;
      case "4":
        newImages = [
          { leftPos: -26, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 0, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 26, topPos: 97, backgroundImage: img.oddCircle, width: 10, height: 10 },
          { leftPos: -41, topPos: -79, backgroundImage: img.smallFeint, width: 10, height: 10 },
          { leftPos: 41, topPos: -79, backgroundImage: img.smallRound, width: 8, height: 10 },
        ];
        break;
      case "6":
        newImages = [
          { leftPos: -26, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 0, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 26, topPos: 97, backgroundImage: img.oddCircle, width: 10, height: 10 },
          { leftPos: -41, topPos: -63, backgroundImage: img.smallRound, width: 10, height: 10 },
          { leftPos: 39, topPos: -63, backgroundImage: img.smallFeint, width: 8, height: 10 },
        ];
        break;
      case "8":
        newImages = [
          { leftPos: -15, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 15, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 0, topPos: -104, backgroundImage: img.oddCircle, width: 10, height: 10 },
          { leftPos: -75, topPos: -41, backgroundImage: img.smallRound, width: 10, height: 10 },
          { leftPos: 75, topPos: -41, backgroundImage: img.smallFeint, width: 8, height: 10 },
        ];
        break;
      default:
        newImages = [
          { leftPos: -26, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 0, topPos: 97, backgroundImage: img.imageCircle, width: 10, height: 10 },
          { leftPos: 26, topPos: 97, backgroundImage: img.oddCircle, width: 10, height: 10 },
        ];
    }
    setImages(newImages);
  }, [selectedSize]);

  return (
    <>
      {images.map((image, index) => (
        <OverlayImage
          key={index}
          leftPos={image.leftPos}
          topPos={image.topPos}
          width={image.width}
          height={image.height}
          backgroundImage={image.backgroundImage}
        />
      ))}
    </>
  );
};

ImageOverlays.propTypes = {
  selectedSize: PropTypes.string.isRequired,
};

export default ImageOverlays;
