import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

// imports: syklone
import { WidgetCopyrightNotice } from "syklone/components/widgets/index.js";
import { Box } from "syklone/ui/index.js";

const transition = { duration: 0.5, ease: "easeOut", delay: 0.3 };

function WidgetFooter({ projectName, companyName }) {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={transition}
      sx={styles.mainFooter}
    >
      <Box sx={styles.copyright}>
        <WidgetCopyrightNotice projectName={projectName} companyName={companyName} />
      </Box>
    </Box>
  );
}

WidgetFooter.propTypes = {
  projectName: PropTypes.string,
  companyName: PropTypes.string,
};

const styles = {
  mainFooter: {
    minHeight: "50px",
  },
  copyright: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    opacity: 0.5,
    position: "relative",
    zIndex: 2,
  },
};

export default WidgetFooter;
