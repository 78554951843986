import React from "react";
import PropTypes from "prop-types";
import { styled, Box } from "syklone/ui";

const SizeContainer = styled(Box)({
  fontSize: "18px",
  paddingLeft: "10px",
  paddingTop: "10px",
});

const SizeSelect = styled("select")({
  fontSize: "18px",
});

const SizeSelector = ({ selectedSize, handleSizeChange }) => {
  return (
    <SizeContainer>
      <label htmlFor="sizeSelect">Size: </label>
      <SizeSelect id="sizeSelect" value={selectedSize} onChange={handleSizeChange}>
        <option value="0">0</option>
        <option value="4">4</option>
        <option value="6">6</option>
        <option value="8">8</option>
      </SizeSelect>
    </SizeContainer>
  );
};

SizeSelector.propTypes = {
  selectedSize: PropTypes.string.isRequired,
  handleSizeChange: PropTypes.func.isRequired,
};

export default SizeSelector;
