import React from "react";
import { Outlet } from "react-router-dom";

// imports: syklone
import { Box, styled } from "syklone/ui/index.js";

// -------- Styled components ---------
const PageWrapper = styled(Box)({
  padding: "25px",
});

function PageSchedulingMachines(props) {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
}

export default PageSchedulingMachines;
