// imports: syklone
import { metrics } from "syklone/graphics/lib/index.js";
import * as syklone from "syklone/version.js";

// imports: local
import * as appVersion from "../../version.js";

function getFontSize() {
  let el = document.querySelector("html");
  let style = window.getComputedStyle(el, null).getPropertyValue("font-size");
  return parseFloat(style);
}

export function createItems(items) {
  let lst = [
    ...items,
    { key: "DEP: THREE", value: metrics.getVersion() },
    { key: "DEP: SYKLONE", value: syklone.VERSION },
    { key: "VERSION_APP", value: appVersion.VERSION },
  ];

  try {
    lst.push({ key: "BROWSER: FONT-SIZE", value: getFontSize() });
  } catch (e) {
    // document may be undefined when using this code in a unit test
    lst.push({ key: "BROWSER: FONT-SIZE", value: -1 });
  }

  lst.sort((a, b) => {
    return a.key.localeCompare(b.key);
  });
  return lst;
}
