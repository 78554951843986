import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Grid, Box, IconButton, icons, styled, Tooltip, Typography } from "syklone/ui/index.js";

const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 20,
  cursor: "pointer",
  "&:hover > .build-file-circle": {
    border: `2px solid ${theme.palette.mode === "dark" ? "#FFFFFF" : "#2F2F2F"}`,
  },
  "&:hover > .build-file-row": {
    backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#E0E0E0",
  },
}));

const BoxRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
  alignItems: "center",
  padding: 8,
  width: "100%",
  transition: "0.2s linear",
}));

const HighlightedBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1E1E1E" : "#f8f8f8",
  color: theme.palette.text.primary,
  textAlign: "center",
  borderRadius: 3,
  minWidth: 52,
  padding: "5px 8px",
}));

const CustomTypography = styled(Typography)({
  whiteSpace: "nowrap",
});

const RowFragment = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 5,
  flexWrap: "nowrap",
});

const RowTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  opacity: 0.6,
  whiteSpace: "nowrap",
}));

function BuildFileItem(props) {
  const { data, index } = props;
  return (
    <Box>
      <BoxWrapper>
        <BoxRow className="build-file-row">
          <Box sx={{ marginRight: "16px" }}>
            <Grid container alignItems="center" spacing={2} sx={{ flexWrap: "nowrap" }}>
              <Grid item>
                <HighlightedBox>{index}</HighlightedBox>
              </Grid>
              <Grid item>
                <RowFragment>
                  <RowTitle>Name:</RowTitle>
                  <CustomTypography>{data.itemTitle}</CustomTypography>
                </RowFragment>
              </Grid>
            </Grid>
          </Box>
          <RowFragment>
            <Box>
              <Grid container alignItems="center" spacing={2} sx={{ flexWrap: "nowrap" }}>
                <Grid item>
                  <RowFragment>
                    <RowTitle>{data.itemSubTitlePrefix}</RowTitle>
                    <CustomTypography>{data.itemSubTitle}</CustomTypography>
                  </RowFragment>
                </Grid>
                <Grid item>
                  <RowFragment>
                    <RowTitle>Status:</RowTitle>
                    <CustomTypography>{data.status}</CustomTypography>
                  </RowFragment>
                </Grid>
                <Grid item>
                  {data.metadata?.jobId && (
                    <Tooltip
                      title={
                        <>
                          <div>Job ID: {data.metadata.jobId}</div>
                          {data.metadata.lotId || data.metadata.lotId !== "undefined" ? (
                            <div>Lot ID: {data.metadata.lotId}</div>
                          ) : null}
                          <div>Build file ID: {data.id}</div>
                        </>
                      }
                      arrow
                      placement="top"
                    >
                      <IconButton data-syklone="scheduling-build-file-info">
                        <icons.mui.Info fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Box>
          </RowFragment>
        </BoxRow>
      </BoxWrapper>
    </Box>
  );
}

BuildFileItem.propTypes = {
  data: PropTypes.object,
  isBuilding: PropTypes.bool,
  isFinished: PropTypes.bool,
  index: PropTypes.number,
  id: PropTypes.string,
  isDraggable: PropTypes.bool,
};

export default BuildFileItem;
