import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { moment } from "syklone/libraries/index.js";
import { Grid, icons, IconButton, styled, Tooltip, Typography } from "syklone/ui/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import { MachineActiveImage } from "./assets/img/index.js";

const WidgetScheduleRowPlayStatus = styled(Grid)({
  opacity: 0.6,
});

const WidgetScheduleRowTypography = styled(Typography)({
  fontSize: 13,
});

const createDate = () => {
  let date = new Date();
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

function WidgetScheduleRowPlay({ active, machine, buildFileData, dialogFn, isDisabled }) {
  const { setBuildStartDialogOpen, setBuildStartDialogData } = dialogFn;
  const { checkElementVisibility } = usePermissionChecks();

  const onClickStart = () => {
    setBuildStartDialogOpen(true);
    setBuildStartDialogData({ machine: machine, buildFileData: buildFileData });
  };

  if (!active) {
    return (
      <Grid container alignItems="center" direction="column" rowSpacing={0}>
        <Grid item>
          <Tooltip title="Start machine" placement="bottom" arrow>
            <span>
              <IconButton
                aria-label="start machine"
                onClick={() => onClickStart()}
                disabled={isDisabled || !checkElementVisibility(PermissionVisualElements.schedulingSchedulePlayButton)}
                color="primary"
                data-syklone="schedule-play-button"
                id="id-schedule-play-button"
              >
                <icons.mui.PlayCircle fontSize="large" />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container alignItems="center" direction="column" rowSpacing={0}>
        <WidgetScheduleRowPlayStatus item>
          <WidgetScheduleRowTypography>Status</WidgetScheduleRowTypography>
        </WidgetScheduleRowPlayStatus>
        <Grid item>
          <WidgetScheduleRowTypography>Active</WidgetScheduleRowTypography>
        </Grid>
        <Grid item>
          <img src={MachineActiveImage} alt="" />
        </Grid>
        <WidgetScheduleRowPlayStatus item>
          <WidgetScheduleRowTypography>Date started:</WidgetScheduleRowTypography>
        </WidgetScheduleRowPlayStatus>
        <Grid item>
          <WidgetScheduleRowTypography>{createDate()}</WidgetScheduleRowTypography>
        </Grid>
      </Grid>
    );
  }
}

WidgetScheduleRowPlay.propTypes = {
  active: PropTypes.bool,
  machine: PropTypes.object,
  buildFileData: PropTypes.any,
  dialogFn: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default WidgetScheduleRowPlay;
