import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Typography, Box } from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";

function WidgetSuiteVersion({ version, containerStyle, textStyle, preText }) {
  const apiContext = useApiContext();
  let displayVersion = "";
  if (apiContext?.suiteVersion) {
    displayVersion = apiContext.suiteVersion;
  } else if (version) {
    displayVersion = version;
  }
  return displayVersion ? (
    <Box sx={containerStyle}>
      <Typography sx={{ fontSize: "14px", ...textStyle }}>
        {preText}
        {displayVersion}
      </Typography>
    </Box>
  ) : null;
}

WidgetSuiteVersion.propTypes = {
  version: PropTypes.string,
  preText: PropTypes.string,
  containerStyle: PropTypes.object,
  textStyle: PropTypes.object,
};

export default WidgetSuiteVersion;
