export const buildFilesData = [
  {
    id: 1,
    name: "TibialCones_9536-B-400-Rev2_Platform_17730",
    details: {
      added: "2022-10-05T14:48:00.000Z",
      started: "2022-10-06T10:25:00.000Z",
      estimated: "02h 50min",
      finished: "2022-10-12T13:11:00.000Z",
      buildingTime: "2h 55min",
    },
  },
  {
    id: 2,
    name: "Patella_9552-M-320-10mm-A32_Platform_18021",
    details: {
      added: "2022-10-05T14:48:00.000Z",
      started: "2022-10-06T10:25:00.000Z",
      estimated: "02h 50min",
      finished: "2022-10-12T13:11:00.000Z",
      buildingTime: "2h 55min",
    },
  },
  {
    id: 3,
    name: "TridentII_702-04-42A-21-1kw-m_Platform_11407",
    details: {
      added: "2022-10-05T14:48:00.000Z",
      started: "2022-10-06T10:25:00.000Z",
      estimated: "02h 50min",
      finished: "2022-10-12T13:11:00.000Z",
      buildingTime: "2h 55min",
    },
  },
];
