import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { Box, icons, Tooltip, styled } from "syklone/ui/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";

const BoxWrapper = styled(Box)(({ theme, disabled }) => ({
  transition: "0.2s linear",
  borderRadius: "50%",
  width: "45px",
  height: "45px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: disabled ? "default" : "pointer",
  opacity: disabled ? 0.5 : 1,
  pointerEvents: disabled ? "none" : "auto",
  "&:hover": {
    background: disabled ? undefined : theme.palette.background.surface,
  },
}));

function WidgetAdminButton({ link }) {
  const navigate = useNavigate();
  const navigateToAdmin = () => {
    navigate(link);
  };

  const { checkElementVisibility } = usePermissionChecks();
  const disabled = !checkElementVisibility(PermissionVisualElements.adminButton);

  return (
    <Tooltip title="Admin" placement="right" arrow>
      <BoxWrapper onClick={navigateToAdmin} id="id-admin-button" data-syklone="admin-button" disabled={disabled}>
        <icons.mui.SettingsOutlined style={{ color: "#949494" }} />
      </BoxWrapper>
    </Tooltip>
  );
}

WidgetAdminButton.propTypes = {
  link: PropTypes.string,
};

export default WidgetAdminButton;
