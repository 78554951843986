import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";

const WidgetWizardActiveStepContainer = ({ children, activeStep }) => {
  return React.Children.map(children, (child, index) => {
    // If you want to hide step instead of rendering add hideIfInactive property on child component
    const { hideIfInactive } = child.props ?? { hideIfInactive: false };
    if (hideIfInactive) {
      return <Box hidden={activeStep !== index}>{child}</Box>;
    }
    if (activeStep === index) {
      return child;
    } else {
      return null;
    }
  });
};

WidgetWizardActiveStepContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  activeStep: PropTypes.number,
};

export default WidgetWizardActiveStepContainer;
