import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";

function WidgetAuditTrailTableHeader({ items }) {
  // -------- Reactjs render --------
  return (
    <Box sx={styles.tableHeaderWrapper}>
      {items.map((item, index) => (
        <Box sx={styles.tableHeaderItem} key={index}>
          {item}
        </Box>
      ))}
    </Box>
  );
}

WidgetAuditTrailTableHeader.propTypes = {
  items: PropTypes.array,
};

const styles = {
  tableHeaderWrapper: {
    display: "flex",
    gap: "10px",
    padding: "10px 12px 10px 15px",
    color: (theme) => (theme.palette.mode === "dark" ? "#BBBBBB" : "#555555"),
    position: "sticky",
    top: "55px",
    background: (theme) => (theme.palette.mode === "dark" ? "#111111" : "#fff"),
    zIndex: 2,
    fontWeight: 700,
    fontSize: 15,
  },
  tableHeaderItem: {
    minWidth: "200px",
  },
};

export default WidgetAuditTrailTableHeader;
