import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  arrayMove,
  closestCenter,
  DndContext,
  horizontalListSortingStrategy,
  KeyboardSensor,
  PointerSensor,
  restrictToHorizontalAxis,
  SortableContext,
  sortableKeyboardCoordinates,
  useSensor,
  useSensors,
} from "syklone/libraries/index.js";
import { Box, Chip, icons, IconButton, styled } from "syklone/ui/index.js";

// imports: local
import { WidgetScheduleRowItem, WidgetScheduleRowPlay } from "../index.js";
import { BuildFileSeparator, BuildFileSeparatorLastChild } from "./assets/img/index.js";

const BoxContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#f8f8f8",
  borderRadius: "3px",
  display: "flex",
  width: "100%",
  alignItems: "center",
  paddingLeft: 0,
  paddingRight: ".5rem",
  paddingTop: ".5rem",
  paddingBottom: ".5rem",
}));

const BoxMachine = styled(Box)({
  minWidth: "230px",
});

const BoxPlayButton = styled(Box)({
  minWidth: "135px",
  marginRight: 15,
  textAlign: "center",
});

const BoxBuildFilesWrapper = styled(Box)({
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
});

const BoxBuildFiles = styled(Box)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "row-reverse",
  margin: "0px 5px",
  overflowX: "scroll",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const BoxWidgetScheduleRowItemWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  "&:before": {
    content: "''",
    width: 21,
    height: 9,
    backgroundImage: `url(${BuildFileSeparator})`,
  },
  "&:first-of-type": {
    "&:after": {
      content: "''",
      width: 15,
      height: 9,
      backgroundImage: `url(${BuildFileSeparatorLastChild})`,
    },
  },
  "&:last-of-type": {
    "&:before": {
      content: "''",
      display: "none",
      backgroundImage: "none",
    },
  },
});

const sideScroll = (element, speed, distance, step) => {
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};

function WidgetScheduleRow({
  alert,
  setAlert,
  machine,
  active,
  buildFiles,
  dialogFn,
  onClickMachine,
  putChangeBuildFilePosition,
  putUnscheduleBuildFile,
  isChangePositionLoading,
  putRescheduleToAnotherMachine,
  isRescheduleLoading,
  postBfmSwitchUnlockByMachineId,
}) {
  const overflowWrapper = useRef(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(buildFiles);
  }, [buildFiles]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setItems(async (items) => {
        const oldIndex = items.findIndex((item) => item.metadata.jobId === active.id);
        const newIndex = items.findIndex((item) => item.metadata.jobId === over.id);
        const activeBf = items.filter((item) => item.metadata.jobId === active.id)[0];

        console.log(`Moved from position ${oldIndex + 1} to ${newIndex + 1}`);
        await putChangeBuildFilePosition(activeBf.id, active.id, machine.id, newIndex);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  return (
    <BoxContainer>
      <BoxBuildFilesWrapper>
        {items.length > 0 ? (
          <IconButton
            aria-label="scroll left"
            onClick={() => {
              sideScroll(overflowWrapper.current, 10, 200, -10);
            }}
            data-syklone="scheduling-arrow-left"
          >
            <icons.mui.ChevronLeft />
          </IconButton>
        ) : null}

        <BoxBuildFiles
          ref={overflowWrapper}
          sx={Array.isArray(items) && items.length === 0 ? { justifyContent: "center" } : {}}
        >
          {Array.isArray(items) && items.length > 0 ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToHorizontalAxis]}
            >
              <SortableContext
                items={items.map((item) => item.metadata?.jobId)}
                strategy={horizontalListSortingStrategy}
              >
                {items.map((item) => (
                  <BoxWidgetScheduleRowItemWrapper
                    key={`${item.id}_${item.metadata.jobId}`}
                    data-syklone="scheduling-build-file-wrapper"
                  >
                    <WidgetScheduleRowItem
                      alert={alert}
                      setAlert={setAlert}
                      data={{ ...item, lock: Array.isArray(items) && items.some((item) => item.metadata.lock) }}
                      id={item.id}
                      key={`${item.id}_${item.metadata.jobId}`}
                      isDraggable={true}
                      putUnscheduleBuildFile={putUnscheduleBuildFile}
                      machineValidity={item.machineValidity}
                      putRescheduleToAnotherMachine={putRescheduleToAnotherMachine}
                      isRescheduleLoading={isRescheduleLoading}
                    />
                  </BoxWidgetScheduleRowItemWrapper>
                ))}
              </SortableContext>
            </DndContext>
          ) : (
            <Chip
              label={isChangePositionLoading?.machineId === machine.id ? "Loading..." : "No scheduled build files"}
              data-syklone="scheduling-empty"
            />
          )}
        </BoxBuildFiles>

        {items.length > 0 ? (
          <IconButton
            aria-label="scroll right"
            onClick={() => {
              sideScroll(overflowWrapper.current, 10, 200, 10);
            }}
            data-syklone="scheduling-arrow-right"
          >
            <icons.mui.ChevronRight />
          </IconButton>
        ) : null}
      </BoxBuildFilesWrapper>
      <BoxPlayButton>
        <WidgetScheduleRowPlay
          active={active}
          dialogFn={dialogFn}
          buildFileData={items}
          machine={machine}
          isDisabled={!Array.isArray(items) || items.length === 0 || items.some((item) => item.metadata.lock)}
        />
      </BoxPlayButton>
      <BoxMachine>
        <WidgetScheduleRowItem
          data={{ ...machine, lock: Array.isArray(items) && items.some((item) => item.metadata.lock) }}
          isDraggable={false}
          width={230}
          onClickItem={onClickMachine}
          unlock={postBfmSwitchUnlockByMachineId}
        />
      </BoxMachine>
    </BoxContainer>
  );
}

WidgetScheduleRow.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  machine: PropTypes.object,
  active: PropTypes.bool,
  buildFiles: PropTypes.array,
  dialogFn: PropTypes.object,
  onClickMachine: PropTypes.func,
  putChangeBuildFilePosition: PropTypes.func,
  putUnscheduleBuildFile: PropTypes.func,
  isChangePositionLoading: PropTypes.any,
  putRescheduleToAnotherMachine: PropTypes.func,
  isRescheduleLoading: PropTypes.bool,
  postBfmSwitchLockByMachineId: PropTypes.func,
  postBfmSwitchUnlockByMachineId: PropTypes.func,
};

export default WidgetScheduleRow;
