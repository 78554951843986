import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Grid, useTheme } from "syklone/ui/index.js";
import { WidgetChartsAdmin, WidgetAdminPanel } from "syklone/components/widgets/index.js";

// imports: local
import { WidgetPodMetrics, WidgetNodeMetrics, WidgetContainer } from "../../widgets/index.js";

/*
const AlertButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  backgroundColor: theme.palette.error.dark,
  "&:hover": {
    backgroundColor: theme.palette.error.main,
  },
}));
*/

// const VERSIONS_HEIGHT = 245;

function PageGlobalAdminDashboard({ users, versions, dataPodMetrics, dataNodes }) {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  // const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (!theme?.charts?.main) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [theme]);

  return (
    <Grid item xs sx={{ minWidth: "987px" }}>
      <Grid container spacing={3}>
        {/* This will  be added later
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <WidgetTitle title="Global admin dashboard" isBackButton={true} backRoute="/dashboard/part-platform" />
            </Grid>
            <Grid item>
              <AlertButton
                variant="contained"
                startIcon={<icons.mui.CircleNotifications />}
                data-syklone="system-alerts-button"
                onClick={() => setDrawerOpen(true)}
              >
                System alerts
              </AlertButton>
              <WidgetSystemAlerts isOpen={drawerOpen} setIsOpen={setDrawerOpen} data={data.dataSystemAlerts} />
            </Grid>
          </Grid>
        </Grid>
        */}
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <WidgetAdminPanel
                    data={{
                      title: "Users",
                      height: 245,
                    }}
                  >
                    <WidgetContainer isLoading={isLoading || users.isLoading} error={users.error?.details}>
                      <WidgetChartsAdmin data={users.data} chartType="pie" areaHeight={180} />
                    </WidgetContainer>
                  </WidgetAdminPanel>
                </Grid>
                {/* 
                  <Grid item xs={12} sm={6} lg={6} data-syklone="versions-wrapper">
                    <WidgetAdminPanel
                      data={{
                        title: "Versions",
                        height: VERSIONS_HEIGHT,
                      }}
                    >
                      <WidgetVersions data={versions.data} areaHeight={VERSIONS_HEIGHT} />
                    </WidgetAdminPanel>
                  </Grid>
                  */}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item sm={12} data-syklone="resource-health-wrapper">
                  <WidgetAdminPanel
                    data={{
                      title: "Pods",
                      height: 470,
                    }}
                  >
                    <WidgetPodMetrics data={dataPodMetrics.data} />
                  </WidgetAdminPanel>
                </Grid>
                <Grid item sm={12} data-syklone="service-status-wrapper">
                  <WidgetAdminPanel
                    data={{
                      title: "Nodes",
                      height: 470,
                    }}
                  >
                    <WidgetNodeMetrics data={dataNodes.data} />
                  </WidgetAdminPanel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

PageGlobalAdminDashboard.propTypes = {
  users: PropTypes.object,
  versions: PropTypes.object,
  dataPodMetrics: PropTypes.object,
  dataNodes: PropTypes.object,
};

export default PageGlobalAdminDashboard;
