import React, { useCallback } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Document, diff, moment, Page, Text, View, StyleSheet } from "syklone/libraries/index.js";

// imports: local
import { WidgetAuditTrailPagePdfText, WidgetAuditTrailPagePdfTitle } from "../index.js";

const styles = StyleSheet.create({
  firstPage: {
    display: "flex",
    height: "100%",
    width: "100%",
    position: "relative",
    justifyContent: "center",
  },
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingTop: 50,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  footerText: {
    position: "absolute",
    fontSize: 10,
    left: 30,
    bottom: 30,
    color: "grey",
  },
  selectionCriteraWrapper: {
    padding: "20px 0px",
  },
  selectionCriteraTitle: {
    width: "100%",
    padding: "5px 0px",
    marginBottom: "5px",
    borderBottom: "1px solid #E2E2E2",
  },
  selectionCriteraRecord: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    padding: "3px 0px",
  },
  selectionCriteraRecordName: {
    opacity: "0.6",
    width: "20%",
  },
  createdDateAndUser: {
    padding: "15px 0px",
  },
  tableItemWrapper: {
    marginBottom: "10px",
    backgroundColor: "#eeeeee",
    borderRadius: "3px",
  },
  tableHeaderViewContainer: {
    position: "absolute",
    top: 30,
    left: 40,
  },
  tableHeaderWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    color: "#555555",
    borderBottom: "1px solid #e1e1e1",
    padding: "5px 0px",
    marginBottom: "10px",
  },
  tableHeaderItem: {
    width: "140px",
  },
  resultsWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 5,
    paddingTop: 3,
    paddingBottom: 3,
  },
  smallCaption: {
    color: "#606060",
    fontSize: 9,
  },
  recordTitleWrapper: {
    position: "absolute",
    top: 13,
    right: 16,
    alignItems: "center",
  },
  recordTitleText: {
    fontSize: 8,
    textTransform: "uppercase",
    color: "#606060",
  },
  recordLabel: {
    fontSize: 10,
    color: "#606060",
  },
  erSignList: {
    "& > li": {
      marginBottom: 5,
    },
  },
  bottomSpacing: {
    marginBottom: 3,
  },
  divDataDetailsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  divDataDetailsItemContainer: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    padding: "12px 17px 9px 13px",
  },
  divDataDetailsErSignatureContainer: {
    display: "flex",
    width: "50%",
    padding: "12px 17px 9px 13px",
    borderLeft: "1px solid #dadada",
    position: "relative",
  },
  rowBottom: {
    display: "flex",
    backgroundColor: "#E2E2E2",
    borderBottomLeftRadius: "3px",
    borderBottomRightRadius: "3px",
    marginTop: "3px",
    flexDirection: "row",
  },
  modifiedRecordRowLeft: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    alignItems: "flex-start",
    padding: "5px 13px",
  },
  modifiedRecordRowRight: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    alignItems: "flex-start",
    padding: "5px 13px",
    borderLeft: "1px solid #c6c6c6",
  },
  modifiedRecordList: {
    listStyle: "none",
    margin: "0px",
    padding: "0px",
  },
  modifiedRecordListItem: {
    width: "280px",
    padding: "2px 5px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s",
    fontSize: 13,
    display: "flex",
    gap: "3px",
  },
  modifiedRecordNew: {
    color: "#44B700",
    borderRadius: "6px",
    padding: "3px 6px",
    backgroundColor: "#c6c6c6",
    textAlign: "center",
    fontSize: 10,
  },
  modifiedRecordOld: {
    color: "#DC3131",
    borderRadius: "6px",
    padding: "3px 6px",
    textAlign: "center",
    backgroundColor: "#c6c6c6",
    fontSize: 10,
  },
  modifiedTitle: {
    color: "#555555",
    fontSize: "12px",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  modifiedValue: {
    color: "#555555",
    display: "inline-block",
    minWidth: "100px",
    fontSize: 10,
  },
});

const valueFormater = (value) => {
  const notAvailable = "N/A";

  if (value === "" || value === undefined) {
    return notAvailable;
  }

  if (typeof value === "boolean") {
    return value.toString();
  }

  if (Array.isArray(value) && value.length > 0 && typeof value[0] === "string" && value[0].length === 0) {
    return notAvailable;
  }

  if (typeof value === "object" && value !== null && !(value instanceof Date) && Object.keys(value).length === 0) {
    return notAvailable;
  }

  try {
    const stringValue = JSON.stringify(value);
    if (stringValue) {
      return stringValue
        .replace(/]|[[]|{|}|"|"/g, "")
        .split(",")
        .join(", ");
    }
    return notAvailable;
  } catch (e) {
    return notAvailable;
  }
};

const createDate = (str) => {
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

function convertKebabToSentinceCase(txt) {
  const result = txt.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

function splitLongWords(text, maxCharsPerLine = 24) {
  const words = text.split(" ");

  return words
    .map((word) => {
      if (word.length > maxCharsPerLine) {
        let result = "";
        for (let i = 0; i < word.length; i += maxCharsPerLine) {
          result += word.slice(i, i + maxCharsPerLine) + (i + maxCharsPerLine < word.length ? "\n" : "");
        }
        return result;
      } else {
        return word;
      }
    })
    .join(" ");
}

const TABLE_HEADER_ITEMS = ["Action", "Type", "Electronic record", "Location", "Completed by", "Completed on"];

function WidgetAuditTrailPagePdf(props) {
  const pageNumbers = useCallback(({ pageNumber, totalPages }) => {
    return `Page ${pageNumber} of ${totalPages}`;
  }, []);

  return (
    <Document>
      <Page size="LETTER" orientation="landscape" style={styles.page} wrap>
        <View style={styles.firstPage}>
          <WidgetAuditTrailPagePdfTitle>Audit Trail Report</WidgetAuditTrailPagePdfTitle>

          {/* Selection criteria */}
          {props.pageDetails !== true && (
            <div style={styles.selectionCriteraWrapper}>
              <div style={styles.selectionCriteraTitle}>
                <WidgetAuditTrailPagePdfText>Filter Criteria</WidgetAuditTrailPagePdfText>
              </div>
              {Object.entries(props.selectionCritera).map((item, index) => {
                if (item[1].child) {
                  let name = item[0];
                  if (item[1][`${name}`].length === 0) {
                    return null;
                  }
                  return (
                    <div key={index} style={styles.selectionCriteraRecord}>
                      <div style={styles.selectionCriteraRecordName}>
                        <WidgetAuditTrailPagePdfText>{convertKebabToSentinceCase(item[0])}</WidgetAuditTrailPagePdfText>
                      </div>
                      <div style={{ width: "80%" }}>
                        <WidgetAuditTrailPagePdfText>
                          {item[1][`${name}`].join().split(",").join(", ")}
                        </WidgetAuditTrailPagePdfText>
                      </div>
                    </div>
                  );
                }
                return (
                  <div key={index} style={styles.selectionCriteraRecord}>
                    <div style={styles.selectionCriteraRecordName}>
                      <WidgetAuditTrailPagePdfText>{convertKebabToSentinceCase(item[0])}</WidgetAuditTrailPagePdfText>
                    </div>
                    <div style={{ width: "80%" }}>
                      {item[0] === "startDate" || item[0] === "endDate" ? (
                        <WidgetAuditTrailPagePdfText>{createDate(item[1]).split(",")[0]}</WidgetAuditTrailPagePdfText>
                      ) : (
                        <WidgetAuditTrailPagePdfText>
                          {typeof item[1] === "string" ? item[1] : item[1].join().split(",").join(", ")}
                        </WidgetAuditTrailPagePdfText>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          {/* Generated date */}
          <div style={styles.createdDateAndUser}>
            <div style={styles.selectionCriteraRecordName}>
              <WidgetAuditTrailPagePdfText>Generated on</WidgetAuditTrailPagePdfText>
            </div>
            <WidgetAuditTrailPagePdfText>{props.date}</WidgetAuditTrailPagePdfText>
          </div>

          {/* Generated by */}
          <div style={styles.createdDateAndUser}>
            <div style={styles.selectionCriteraRecordName}>
              <WidgetAuditTrailPagePdfText>Generated by</WidgetAuditTrailPagePdfText>
            </div>
            <WidgetAuditTrailPagePdfText>{props.username}</WidgetAuditTrailPagePdfText>
          </div>
        </View>

        {/* Audit trail data */}
        <View>
          {props.data.map((item, index) => {
            let arr = [];
            if (item && typeof item === "object") {
              const hasOldValues =
                item.oldValues && typeof item.oldValues === "object" && Object.keys(item.oldValues).length !== 0;
              const hasNewValues = item.newValues && typeof item.newValues === "object";

              if (hasOldValues && hasNewValues) {
                arr = Object.keys(diff(item.oldValues, item.newValues));
              } else if (hasNewValues) {
                arr = Object.keys(item.newValues);
              }
            }

            return (
              <View key={index} wrap={false}>
                <div style={styles.tableItemWrapper}>
                  {props.pageDetails !== true ? (
                    <div style={styles.resultsWrapper}>
                      <div style={styles.tableHeaderItem}>
                        <WidgetAuditTrailPagePdfText>{item.action}</WidgetAuditTrailPagePdfText>
                      </div>
                      <div style={styles.tableHeaderItem}>
                        <WidgetAuditTrailPagePdfText>
                          {splitLongWords(item.electronicRecordType.name)}
                        </WidgetAuditTrailPagePdfText>
                      </div>
                      <div style={styles.tableHeaderItem}>
                        {item.electronicSignature ? (
                          <>
                            <WidgetAuditTrailPagePdfText>
                              {splitLongWords(item.completedBy.name)}
                            </WidgetAuditTrailPagePdfText>
                            <Text style={styles.smallCaption}>{item.completedBy.email}</Text>
                            <Text style={styles.smallCaption}>{createDate(item.completedBy.date)}</Text>
                          </>
                        ) : (
                          <WidgetAuditTrailPagePdfText>N/A</WidgetAuditTrailPagePdfText>
                        )}
                      </div>
                      <div style={styles.tableHeaderItem}>
                        <WidgetAuditTrailPagePdfText>{createDate(item.completedOn)}</WidgetAuditTrailPagePdfText>
                      </div>
                    </div>
                  ) : (
                    <div style={styles.divDataDetailsContainer}>
                      <div style={styles.divDataDetailsItemContainer}>
                        <div style={{ opacity: 0.6, width: "30%" }}>
                          {TABLE_HEADER_ITEMS.map((key, idx) => (
                            <div key={idx} style={styles.bottomSpacing}>
                              <WidgetAuditTrailPagePdfText>{key}:</WidgetAuditTrailPagePdfText>
                            </div>
                          ))}
                        </div>
                        <div style={{ width: "70%" }}>
                          <div style={styles.bottomSpacing}>
                            <WidgetAuditTrailPagePdfText>{item.action}</WidgetAuditTrailPagePdfText>
                          </div>
                          <div style={styles.bottomSpacing}>
                            <WidgetAuditTrailPagePdfText>{item.electronicRecordType.name}</WidgetAuditTrailPagePdfText>
                          </div>
                          <div style={styles.bottomSpacing}>
                            <WidgetAuditTrailPagePdfText>{item.electronicRecord}</WidgetAuditTrailPagePdfText>
                          </div>
                          <div style={styles.bottomSpacing}>
                            <WidgetAuditTrailPagePdfText>{item.location}</WidgetAuditTrailPagePdfText>
                          </div>
                          {item.completedBy && (
                            <div style={styles.bottomSpacing}>
                              <WidgetAuditTrailPagePdfText>
                                {!item?.completedBy?.email
                                  ? `${item?.completedBy?.name || ""}`
                                  : `${item?.completedBy?.name || ""} / ${item?.completedBy?.email || ""} / ${
                                      createDate(item?.completedBy?.date) || ""
                                    }`}
                              </WidgetAuditTrailPagePdfText>
                            </div>
                          )}
                          <div style={styles.bottomSpacing}>
                            <WidgetAuditTrailPagePdfText>{createDate(item.completedOn)}</WidgetAuditTrailPagePdfText>
                          </div>
                        </div>
                      </div>
                      <div style={styles.divDataDetailsErSignatureContainer}>
                        {item.electronicSignature ? (
                          <>
                            <div style={styles.recordTitleWrapper}>
                              <Text style={styles.recordTitleText}>Electronic Signature</Text>
                            </div>
                            <div style={styles.erSignList}>
                              <div style={styles.bottomSpacing}>
                                <div>
                                  <Text style={styles.recordLabel}>Name:</Text>
                                </div>
                                <div>
                                  <WidgetAuditTrailPagePdfText>{`${item.name} / ${item.email}`}</WidgetAuditTrailPagePdfText>
                                </div>
                              </div>
                              {item.reason ? (
                                <div style={styles.bottomSpacing}>
                                  <div>
                                    <Text style={styles.recordLabel}>Reason:</Text>
                                  </div>
                                  <div>
                                    <WidgetAuditTrailPagePdfText>{item.reason}</WidgetAuditTrailPagePdfText>
                                  </div>
                                </div>
                              ) : null}
                              <div style={styles.bottomSpacing}>
                                <div>
                                  <Text style={styles.recordLabel}>Date:</Text>
                                </div>
                                <div>
                                  <WidgetAuditTrailPagePdfText>{createDate(item.date)}</WidgetAuditTrailPagePdfText>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div style={styles.rowBottom}>
                    <div style={styles.modifiedRecordRowLeft}>
                      <div style={styles.modifiedRecordOld}>
                        <Text>Old value</Text>
                      </div>
                      <div>
                        <ul style={styles.modifiedRecordList}>
                          {item &&
                            typeof item === "object" &&
                            item.oldValues &&
                            typeof item.oldValues === "object" &&
                            Object.keys(item.oldValues).length !== 0 &&
                            arr.slice(0, 7).map((i, index) => (
                              <li style={styles.modifiedRecordListItem} key={`${i}-${index}`}>
                                <Text style={styles.modifiedValue}>{i}: </Text>
                                <WidgetAuditTrailPagePdfText>
                                  {valueFormater(item.oldValues[i])}
                                </WidgetAuditTrailPagePdfText>
                              </li>
                            ))}
                          {item &&
                            typeof item === "object" &&
                            item.oldValues &&
                            typeof item.oldValues === "object" &&
                            Object.keys(item.oldValues).length !== 0 &&
                            arr.slice(0, 7).map((item, index) => (
                              <li style={styles.modifiedRecordListItem} key={`${item}-${index}`}>
                                <WidgetAuditTrailPagePdfText style={styles.modifiedValue}>
                                  {item}:
                                </WidgetAuditTrailPagePdfText>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div style={styles.modifiedRecordRowRight}>
                      <div style={styles.modifiedRecordNew}>
                        <Text>New value</Text>
                      </div>
                      <div>
                        <ul style={styles.modifiedRecordList}>
                          {arr.slice(0, 7).map((i, index) => (
                            <li style={styles.modifiedRecordListItem} key={`${i}-${index}`}>
                              <Text style={styles.modifiedValue}>{i}:</Text>
                              <WidgetAuditTrailPagePdfText>
                                {valueFormater(item.newValues[i])}
                              </WidgetAuditTrailPagePdfText>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </View>
            );
          })}
        </View>

        {/* Table header */}
        {props.pageDetails !== true && (
          <View
            style={styles.tableHeaderViewContainer}
            fixed
            render={({ pageNumber }) => {
              return (
                pageNumber !== 1 && (
                  <View style={styles.tableHeaderWrapper}>
                    {TABLE_HEADER_ITEMS.map((item, index) => (
                      <div style={styles.tableHeaderItem} key={index}>
                        <Text style={{ fontSize: 10, opacity: 0.6 }}>{item}</Text>
                      </div>
                    ))}
                  </View>
                )
              );
            }}
          />
        )}

        {/* Page numbers */}
        <Text style={styles.footerText} fixed>
          Audit Trail Report
        </Text>
        {/* Page numbers */}
        <Text style={styles.pageNumber} render={pageNumbers} fixed />
      </Page>
    </Document>
  );
}

WidgetAuditTrailPagePdf.propTypes = {
  data: PropTypes.array,
  selectionCritera: PropTypes.object,
  username: PropTypes.string,
  date: PropTypes.string,
  pageDetails: PropTypes.bool,
  type: PropTypes.any,
};

export default WidgetAuditTrailPagePdf;
