import React from "react";
import PropTypes from "prop-types";

import { useNavigate } from "react-router-dom";

// imports: syklone
import { Box, Typography, IconButton, icons, styled } from "syklone/ui/index.js";

// imports: local
import { WidgetOverflowTip } from "../index.js";

const WidgetTitleWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "5px",
});

const TitleBoxWrapper = styled(Box)({
  display: "block",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const TitleTypography = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: "500",
});

const SubtitleTypography = styled(Typography)({
  fontSize: "0.75rem",
  opacity: ".7",
  paddingTop: "5px",
});

function WidgetTitle({ title, subTitle, subTitlePrefix, isBackButton, backRoute = "", limitWidth = false }) {
  const navigate = useNavigate();

  const buttonBackElement = (
    <IconButton onClick={() => navigate(backRoute.length === 0 ? -1 : backRoute)}>
      <icons.mui.ArrowBack />
    </IconButton>
  );

  const subTitlePrefixElement = <span>{`${subTitlePrefix}: `}</span>;

  const subTitleElement = (
    <SubtitleTypography>
      {subTitlePrefix && subTitlePrefixElement}
      {subTitle}
    </SubtitleTypography>
  );

  return (
    <WidgetTitleWrapper>
      <Box>{isBackButton && buttonBackElement}</Box>
      <TitleBoxWrapper>
        <TitleTypography variant="h1" component="h2">
          {limitWidth ? <WidgetOverflowTip placement="top">{title}</WidgetOverflowTip> : title}
        </TitleTypography>
        {subTitle && subTitleElement}
      </TitleBoxWrapper>
    </WidgetTitleWrapper>
  );
}

WidgetTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  subTitlePrefix: PropTypes.string,
  isBackButton: PropTypes.bool,
  backRoute: PropTypes.string,
  limitWidth: PropTypes.bool,
};

export default WidgetTitle;
