import React from "react";
import { Outlet } from "react-router-dom";

// imports: syklone
import { Box, Grid, icons, styled } from "syklone/ui/index.js";
import { WidgetAdminSidebar, WidgetAdminSidebarItem } from "syklone/components/widgets/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

const CustomBox = styled(Box)(() => ({
  width: "100%",
  margin: "25px 30px 30px 30px",
}));

function PageGlobalAdmin() {
  const { checkElementVisibility } = usePermissionChecks();
  const adminUserSectionButtonVisibility = checkElementVisibility(PermissionVisualElements.adminUserSectionButton);
  const adminGroupsSectionButtonVisibility = checkElementVisibility(PermissionVisualElements.adminGroupSectionButton);
  const adminPermissionsSectionButtonVisibility = checkElementVisibility(
    PermissionVisualElements.adminPermissionSectionButton
  );
  const adminEndpointsSectionButtonVisibility = checkElementVisibility(
    PermissionVisualElements.adminEndpointSectionButton
  );

  return (
    <CustomBox>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item sx={{ width: "100%" }}>
          <Grid container spacing={3}>
            <WidgetAdminSidebar>
              <WidgetAdminSidebarItem
                title="Dashboard"
                icon={<icons.mui.GridViewOutlined fontSize="small" />}
                link="/dashboard/admin"
              />
              <WidgetAdminSidebarItem title="Site configuration" icon={<icons.mui.SettingsOutlined fontSize="small" />}>
                <WidgetAdminSidebarItem
                  disabled={!adminUserSectionButtonVisibility}
                  icon={<icons.mui.Person fontSize="small" />}
                  title="Users"
                  link="/dashboard/admin/configuration/users"
                />
                <WidgetAdminSidebarItem
                  disabled={!adminGroupsSectionButtonVisibility}
                  icon={<icons.mui.Groups fontSize="small" />}
                  title="Groups"
                  link="/dashboard/admin/configuration/groups"
                />
                {adminPermissionsSectionButtonVisibility && (
                  <WidgetAdminSidebarItem
                    icon={<icons.mui.Lock fontSize="small" />}
                    title="Permissions"
                    link="/dashboard/admin/configuration/permissions"
                  />
                )}
                {adminEndpointsSectionButtonVisibility && (
                  <WidgetAdminSidebarItem
                    icon={<icons.mui.ListAlt fontSize="small" />}
                    title="Endpoints"
                    link="/dashboard/admin/configuration/endpoints"
                  />
                )}
              </WidgetAdminSidebarItem>
            </WidgetAdminSidebar>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </CustomBox>
  );
}

export default PageGlobalAdmin;
