import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { useNavigate } from "react-router-dom";
import { DataGrid } from "syklone/ui/index.js";
import { WidgetPopoverMenu, WidgetContainer } from "../../widgets/index.js";
import {
  styles,
  useSearchableData,
  QuickSearchToolbar,
  TableCustomGrid,
  TableDataIsActiveCell,
} from "../components/index.js";

function TableDataPermissions({ data, setters, isLoading, error }) {
  const navigate = useNavigate();
  const { searchText, handleSearchChange, clearSearch, filteredData } = useSearchableData(data, ["name"]);

  const columns = React.useMemo(() => {
    const actionMenu = (params) => {
      const isPermissionActive = params.row.isActive;
      return {
        menuItems: [
          {
            name: "Edit",
            action: () => {
              setters.setDialogEditDataFn(params.row);
              setters.setIsDialogEditPermissionOpenFn(true);
            },
          },
          {
            name: "Map endpoints",
            action: () => {
              const id = params.row.id;
              navigate(`/dashboard/admin/configuration/permissions/${id}/endpoints`);
            },
            disabled: !isPermissionActive,
          },
          {
            name: `${isPermissionActive ? "Deactivate" : "Activate"} permission`,
            action: () => {
              setters.setDialogActivationDataFn(params.row);
              setters.setIsDialogDeletePermissionOpenFn(true);
            },
            type: isPermissionActive ? "error" : "success",
          },
        ],
      };
    };

    return [
      {
        field: "id",
        headerName: "ID",
        hide: true,
      },
      {
        field: "name",
        headerName: "Permission name",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "description",
        headerName: "Description",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "route",
        headerName: "Route",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "htmlId",
        headerName: "HTML ID",
        minwidth: 150,
        disableColumnMenu: true,
      },
      {
        field: "isActive",
        headerName: "Status",
        minwidth: 90,
        disableColumnMenu: true,
        renderCell: (params) => <TableDataIsActiveCell isActive={params.row.isActive} />,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        width: 70,
        disableColumnMenu: true,
        renderCell: (params) => <WidgetPopoverMenu data={actionMenu(params)} />,
      },
    ];
  }, [setters]);

  return (
    <TableCustomGrid item sx={{ marginTop: "30px" }}>
      <WidgetContainer isLoading={isLoading} error={error}>
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          rows={filteredData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => handleSearchChange(event.target.value),
              clearSearch: () => clearSearch(),
            },
          }}
          sx={styles.gridStyle}
        />
      </WidgetContainer>
    </TableCustomGrid>
  );
}

TableDataPermissions.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  setters: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

export default TableDataPermissions;
