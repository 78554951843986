import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, MobileStepper } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

const withWizard = (DialogCommonBaseComponent) => {
  const DialogCommonWizard = ({
    maxSteps,
    activeStep,
    handleNext,
    handleBack,
    children,
    actions,
    nextStepTitle = "Next",
    isNextDisabled,
    ...props
  }) => {
    return (
      <DialogCommonBaseComponent
        dialogActionsSx={{ backgroundColor: "#343434" }}
        {...props}
        actions={
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{ width: "100%", backgroundColor: "inherit", paddingLeft: 0, paddingRight: 0 }}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={isNextDisabled}>
                {nextStepTitle}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                Back
              </Button>
            }
          />
        }
      >
        {children}
      </DialogCommonBaseComponent>
    );
  };
  DialogCommonWizard.displayName = "DialogCommonWizard";
  DialogCommonWizard.propTypes = {
    ...DialogCommonBase.propTypes,
    maxSteps: PropTypes.number,
    activeStep: PropTypes.number,
    handleNext: PropTypes.func,
    handleBack: PropTypes.func,
    nextStepTitle: PropTypes.string,
  };
  return DialogCommonWizard;
};

const DialogCommonWizard = withWizard(DialogCommonBase);

export default DialogCommonWizard;
