import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Typography, useFormik } from "syklone/ui/index.js";

// imports: local
import { DialogCommonConfirmation, DialogResponseMessage } from "../index.js";
import { WidgetElectronicSignature } from "../../widgets/index.js";

const DialogElectronicSignature = React.forwardRef(
  ({ onSubmitSuccess, title = null, subtitle, onDialogClose, onConfirmTitle }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [responseMessage, setResponseMessage] = React.useState(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const widgetElectronicSignatureRef = React.useRef(null);
    const dialogDataRef = React.useRef(null);
    const formik = useFormik({
      initialValues: {
        username: "",
        userSigned: false,
        userComment: "",
      },
      onSubmit: (values) => {
        widgetElectronicSignatureRef.current?.setSignatureError(false);
        const { username, userSigned, userComment } = values;
        if (!username || !userSigned) {
          widgetElectronicSignatureRef.current?.setSignatureError(true);
          return; // Exit the onSubmit function early
        }

        const payloadSignature = { ["user_signed_email"]: username, ["user_signed"]: userSigned, comment: userComment };
        const payloadData = dialogDataRef.current;
        onSubmitSuccess && onSubmitSuccess(payloadSignature, payloadData);
      },
    });

    React.useImperativeHandle(ref, () => ({
      setResponseMessage: (errorMessage) => {
        setResponseMessage(errorMessage);
      },
      setIsDialogOpen: (value) => {
        setIsOpen(value);
      },
      setIsSubmitting: (value) => {
        setIsSubmitting(value);
      },
      setDialogData: (data) => {
        dialogDataRef.current = data;
      },
    }));

    React.useEffect(() => {
      if (!isOpen) {
        widgetElectronicSignatureRef.current?.setSignatureError(false);
        formik.resetForm();
        setResponseMessage(false);
        dialogDataRef.current = null;
      } else {
        widgetElectronicSignatureRef.current?.resetSignature();
      }
    }, [isOpen]);

    const { values, setFieldValue, handleChange } = formik;

    const handleCloseDialog = () => {
      setIsOpen(false);
      onDialogClose && onDialogClose();
    };

    return (
      <DialogCommonConfirmation
        contentHeight={50}
        isConfirmDisabled={isSubmitting}
        title={title ? title : "Electronic signature"}
        onConfirm={() => formik.submitForm()}
        onConfirmTitle={onConfirmTitle}
        onCancel={handleCloseDialog}
        onClose={handleCloseDialog}
        isOpen={isOpen}
        showClose
        disableBackdropClick
      >
        <form>
          {subtitle && <Typography sx={{ marginBottom: "1em" }}>{subtitle}</Typography>}
          <DialogResponseMessage message={responseMessage} widgetName="dialog-electronic-signature" />
          <WidgetElectronicSignature
            ref={widgetElectronicSignatureRef}
            values={values}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
          />
        </form>
      </DialogCommonConfirmation>
    );
  }
);

DialogElectronicSignature.displayName = "DialogElectronicSignature";

DialogElectronicSignature.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onDialogClose: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  dialogText: PropTypes.string,
  onConfirmTitle: PropTypes.string,
};

export default DialogElectronicSignature;
