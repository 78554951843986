import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Text, StyleSheet } from "syklone/libraries/index.js";

const styles = StyleSheet.create({
  title: {
    fontSize: 22,
    fontWeight: "bold",
  },
});

function WidgetAuditTrailPagePdfTitle(props) {
  return <Text style={styles.title}>{props.children}</Text>;
}

WidgetAuditTrailPagePdfTitle.propTypes = {
  children: PropTypes.node,
};

export default WidgetAuditTrailPagePdfTitle;
