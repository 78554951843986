import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Button } from "syklone/ui/index.js";

const WidgetShowMoreTextToggle = ({ text, sx, maxLength = 100, buttonSx }) => {
  const [showFullText, setShowFullText] = React.useState(false);

  const toggleShowFull = () => setShowFullText(!showFullText);

  const isTextLong = text.length > maxLength;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box component={"span"} sx={sx}>
        {showFullText || !isTextLong ? text : `${text.substring(0, maxLength)}...`}
      </Box>
      {isTextLong && (
        <Button
          onClick={toggleShowFull}
          variant="text"
          sx={{
            width: "96px",
            marginTop: "0.5em",
            ...buttonSx,
          }}
        >
          {showFullText ? "Show Less" : "Show More"}
        </Button>
      )}
    </Box>
  );
};

WidgetShowMoreTextToggle.propTypes = {
  text: PropTypes.string.isRequired,
  sx: PropTypes.object,
  buttonSx: PropTypes.object,
  maxLength: PropTypes.number,
};

export default WidgetShowMoreTextToggle;
