import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { DataGrid } from "syklone/ui/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

import { WidgetPopoverMenu, WidgetContainer } from "../../widgets/index.js";
import {
  styles,
  useSearchableData,
  QuickSearchToolbar,
  TableCustomGrid,
  TableDataIsActiveCell,
  TableDataColoredTextCell,
} from "../components/index.js";

function TableDataUsers({ data, selected, setters, error, isLoading, optionsData }) {
  const navigate = useNavigate();
  const accountTypes = optionsData.accountTypes;
  const { searchText, handleSearchChange, clearSearch, filteredData } = useSearchableData(data, ["name", "department"]);
  const { checkElementVisibility } = usePermissionChecks();

  const hasheMapAccountTypes = React.useMemo(() => {
    const result = {};
    accountTypes.forEach((item) => {
      result[item.value] = item.label;
    });
    return result;
  }, [accountTypes]);

  const columns = React.useMemo(() => {
    const actionMenu = (params) => {
      const isUserActive = params.row.isActive;
      return {
        menuItems: [
          {
            name: "Edit",
            action: () => {
              setters.setDialogEditDataFn(params.row);
              setters.setIsDialogEditUserOpenFn(true);
            },
            disabled: !checkElementVisibility(PermissionVisualElements.adminUsersEditUser),
          },
          {
            name: "Map group",
            action: () => {
              const id = params.row.id;
              navigate(`/dashboard/admin/configuration/users/${id}/groups`);
            },
            disabled: !isUserActive || !checkElementVisibility(PermissionVisualElements.adminUsersMapUsersToGroup),
          },
          {
            name: `${isUserActive ? "Deactivate" : "Activate"} user`,
            action: () => {
              setters.setDialogActivationDataFn(params.row);
              setters.setIsDialogDeleteUserOpenFn(true);
            },
            type: isUserActive ? "error" : "success",
            disabled: isUserActive
              ? !checkElementVisibility(PermissionVisualElements.adminUsersDeactivateUser)
              : !checkElementVisibility(PermissionVisualElements.adminUsersActivateUser),
          },
        ],
      };
    };

    return [
      {
        field: "id",
        headerName: "ID",
        hide: true,
      },
      {
        field: "name",
        headerName: "User name",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "email",
        headerName: "Email",
        flex: 1,
        disableColumnMenu: true,
      },
      {
        field: "accountTypeId",
        flex: 1,
        headerName: "Account type",
        disableColumnMenu: true,
        renderCell: (params) =>
          hasheMapAccountTypes[params.row.accountTypeId] ? hasheMapAccountTypes[params.row.accountTypeId] : "",
      },
      {
        field: "department",
        flex: 1,
        headerName: "Department",
        disableColumnMenu: true,
      },
      {
        field: "isAdmin",
        headerName: "Admin",
        disableColumnMenu: true,
        renderCell: (params) => <TableDataColoredTextCell condition={params.row.isAdmin} />,
      },
      {
        field: "isActive",
        headerName: "Status",
        disableColumnMenu: true,
        renderCell: (params) => <TableDataIsActiveCell isActive={params.row.isActive} />,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => <WidgetPopoverMenu data={actionMenu(params)} />,
      },
    ];
  }, [setters, hasheMapAccountTypes]);

  const filteredRows = React.useMemo(() => {
    if (!selected.accountType) {
      return filteredData;
    }
    return filteredData.filter((item) => {
      return item.accountTypeId.toString() === selected.accountType;
    });
  }, [filteredData, selected]);

  return (
    <TableCustomGrid item sx={{ marginTop: "60px" }}>
      <WidgetContainer isLoading={isLoading} error={error}>
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          rows={filteredRows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          loading={isLoading}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => handleSearchChange(event.target.value),
              clearSearch: () => clearSearch(),
            },
          }}
          sx={styles.gridStyle}
        />
      </WidgetContainer>
    </TableCustomGrid>
  );
}

TableDataUsers.propTypes = {
  data: PropTypes.array,
  optionsData: PropTypes.shape({
    accountTypes: PropTypes.array.isRequired,
  }).isRequired,
  isFiltered: PropTypes.bool,
  selected: PropTypes.object,
  setters: PropTypes.object,
  error: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default TableDataUsers;
