import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Alert, Box, Button, icons, IconButton, Stack, TextField } from "syklone/ui/index.js";

function WidgetEditCustomFields({
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  touched,
  errors,
  isCheckboxStateChanged,
}) {
  const addCustomField = () => {
    const fields = [...values.customFields, { name: "", val: "" }];
    setFieldValue("customFields", fields);
  };

  const removeCustomField = (index) => {
    const fields = [...values.customFields];
    fields.splice(index, 1);
    setFieldValue("customFields", fields);
  };

  return (
    <Box>
      {values.customFields.length === 0 && (
        <Alert severity="info" sx={{ mb: 1 }}>
          No metadata defined.
        </Alert>
      )}
      {values.customFields.map((element, index) => (
        <Stack key={index} sx={{ alignItems: "baseline", mb: 1 }} direction="row" spacing={1}>
          <TextField
            label="Name"
            name={`customFields[${index}].name`}
            value={element.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.customFields?.[index]?.name && Boolean(errors.customFields?.[index]?.name)}
            helperText={touched.customFields?.[index]?.name && errors.customFields?.[index]?.name}
            disabled={isCheckboxStateChanged}
          />
          <TextField
            label="Value"
            name={`customFields[${index}].val`}
            value={element.val}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.customFields?.[index]?.val && Boolean(errors.customFields?.[index]?.val)}
            helperText={touched.customFields?.[index]?.val && errors.customFields?.[index]?.val}
            disabled={isCheckboxStateChanged}
          />
          <IconButton onClick={() => removeCustomField(index)} disabled={isCheckboxStateChanged}>
            <icons.mui.Close />
          </IconButton>
        </Stack>
      ))}
      <Box sx={{ marginBottom: "20px" }}>
        <Button size="small" variant="outlined" onClick={addCustomField} disabled={isCheckboxStateChanged}>
          Add
        </Button>
      </Box>
    </Box>
  );
}

WidgetEditCustomFields.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  isCheckboxStateChanged: PropTypes.bool,
};

export default WidgetEditCustomFields;
