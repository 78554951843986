// imports: syklone
import { Dialog, styled } from "syklone/ui/index.js";

const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    backgroundColor: "#252525",
    backgroundImage: "none",
  },
});

export default CustomDialog;
