import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Outlet } from "react-router-dom";

// imports: syklone
import { Box, Grid, themes, SnackbarProvider } from "syklone/ui/index.js";
import { WidgetExitButton } from "syklone/components/widgets/index.js";
import { withApiContext } from "syklone/api/react/index.js";
import { withBeforeUnload } from "syklone/components/hoc/index.js";

// imports: local
import { WidgetAdminButton, WidgetSuiteVersion } from "../../widgets/index.js";
import { DialogAbout } from "../../dialogs/index.js";
import * as img from "../../resources/index.js";

class _PageGfDashboard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    navigate: PropTypes.func,
    location: PropTypes.object,
    context: PropTypes.func,
    match: PropTypes.func,
    api: PropTypes.object,
    authRedirect: PropTypes.object,
  };

  static APP_SIZING = {
    SIDEBAR_GF_WIDTH_COLLAPSED: "82px",
    SIDEBAR_GF_WIDTH_EXPANDED: "200px",
  };

  _mounted = false;

  // -------- Public: Reactjs --------
  constructor(props) {
    super(props);
    this._setMounted(false);
    this.state = {
      theme: {},
      isExpanded: false,
      promotionNotifcationsData: [],
      sideBarOpen: false,
      isProfileOpen: false,
    };
    this.api = props.api;
    this.refWidgetSidebarDesktop = React.createRef();
    this.refWidgetThemeSwitcher = React.createRef();
    this.refDialogAbout = React.createRef();
    this.refAvatarNotifications = React.createRef();
  }

  componentDidMount = () => {
    this._setMounted(true);
    this.onTheme(this.api.sessionSettings.data().isThemeLight);
  };

  componentWillUnmount = () => {
    this._setMounted(false);
  };

  // -------- Private: Getters/Setters --------
  _getTheme = (isThemeLight) => {
    return isThemeLight ? themes.themeLight : themes.themeDark;
  };

  _getDialogAbout = () => {
    return this.refDialogAbout.current;
  };

  _getUsername = (auth) => {
    return auth.user ? auth.user.name : undefined;
  };

  _getProfileState = () => {
    return this.state.isProfileOpen;
  };

  _setProfileState = (value) => {
    this.setState({
      isProfileOpen: value,
    });
  };

  _isMounted = () => {
    return this._mounted;
  };

  _setMounted = (value) => {
    this._mounted = value;
  };

  // -------- Private: Slots --------
  _onAbout = () => {
    let dialogAbout = this._getDialogAbout();
    dialogAbout.show();
  };

  _onClickMenu = () => {
    // Implement later
  };

  // -------- Public: Slots --------
  onTheme = (isThemeLight) => {
    let data = this.api.sessionSettings.data();
    data.isThemeLight = isThemeLight;
    this.api.sessionSettings.save(data);
    this.setState({
      theme: this._getTheme(isThemeLight),
    });
  };

  onHandleToggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  onLogout = async (authRedirect) => {
    await this.props.authRedirect.initiateAuthLogoutRedirect();
  };

  onClickExitButton = (authRedirect) => {
    this.onLogout(authRedirect);
  };

  onProfile = () => {
    this._setProfileState(true);
  };

  // -------- Private: Component creation --------
  _createDialogAbout = () => {
    return <DialogAbout ref={this.refDialogAbout} />;
  };

  /* _createSideBarItems = () => {
    return [
      {
        title: "Home",
        icon: <img src={img.IconGfPagePartPlatform} width="18px" />,
        method: this._onClickMenu,
      },
      {
        title: "Products",
        icon: <img src={img.IconGfProducts} width="18px" />,
        method: this._onClickMenu,
      },
      {
        title: "Scheduling",
        icon: <img src={img.IconGfSchedule} width="18px" />,
        method: this._onClickMenu,
      },
      {
        title: "Machines",
        icon: <img src={img.IconGfMachines} width="18px" />,
        method: this._onClickMenu,
      },
      {
        title: "Audit trail",
        icon: <img src={img.IconGfAuditTrail} width="18px" />,
        method: this._onClickMenu,
      },
      {
        title: "About",
        icon: <icons.mui.InfoOutlined />,
        method: this._onAbout,
      },
    ];
  }; */

  _createMainLayout = () => {
    const dividerVerticalRight = this.api.sessionSettings.data().isThemeLight
      ? styles.dividerLightVerticalRight
      : styles.dividerDarkVerticalRight;
    const sideBarWidth = this.state.isExpanded
      ? _PageGfDashboard.APP_SIZING.SIDEBAR_GF_WIDTH_EXPANDED
      : _PageGfDashboard.APP_SIZING.SIDEBAR_GF_WIDTH_COLLAPSED;

    const containerStyle = { ...styles.colProps, ...styles.leftCol, ...dividerVerticalRight, width: sideBarWidth };
    return (
      <Box sx={styles.root}>
        <Grid container spacing={0}>
          <Grid item sx={containerStyle}>
            <Grid sx={styles.logo} container spacing={0}>
              <Link to="dashboard/part-platform">
                <img src={img.LogoGlobalFrontend} alt="Global Frontend" />
              </Link>
              <WidgetSuiteVersion containerStyle={{ marginTop: "5px" }} />
            </Grid>

            <Grid sx={styles.menu} container spacing={0}>
              <Box sx={styles.widgetSidebarDesktop}></Box>
            </Grid>
            <Grid sx={styles.exit} container spacing={2} direction="column">
              <Grid item>
                <WidgetAdminButton link="/dashboard/admin" />
              </Grid>
              <Grid item>
                <WidgetExitButton
                  onClickExitButton={() => this.onClickExitButton()}
                  isThemeLight={this.api.sessionSettings.data().isThemeLight}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ ...styles.colProps, ...styles.rightCol }} xs>
            <Grid container sx={styles.mainContent}>
              <Outlet context={[this._onAbout]} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  // -------- Public: Reactjs render --------
  render() {
    return (
      <SnackbarProvider>
        {this._createDialogAbout()}
        {this._createMainLayout()}
      </SnackbarProvider>
    );
  }
}

const styles = {
  root: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    alignItems: "stretch",
    overflow: "hidden",
    "& *::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
    },
    "& *::-webkit-scrollbar-thumb": {
      minHeight: "24px",
      borderRadius: "8px",
      backgroundColor: "#585859",
    },
    "& *::-webkit-scrollbar-corner": {
      backgroundColor: "#202022",
    },
  },
  colProps: {
    height: "100%",
  },
  leftCol: {
    display: "flex",
    flexDirection: "column!important",
    width: "82px",
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
  },
  rightCol: {
    overflowX: "hidden",
    overflowY: "auto",
  },
  logo: {
    position: "absolute",
    top: "20px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  menu: {
    flexGrow: 1,
    alignItems: "center",
    minHeight: "400px",
  },
  exit: {
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(0px, -20px)",
  },
  dividerDarkVerticalRight: {
    borderRight: "1px solid #2F2F2F",
  },
  dividerLightVerticalRight: {
    borderRight: "1px solid #E8E8E8",
  },
  mainContent: {
    display: "flex",
    flexGrow: 1,
    alignItems: "stretch",
    "@media (min-width: 1280px)": {
      flexDirection: "row",
    },
  },
  areaBgDark: {
    backgroundColor: "#000000",
  },
  areaBgLight: {
    backgroundColor: "#ffffff",
    boxShadow: "rgba(0, 0, 0, 0.06) 0px 3px 5px",
  },
  expandButton: {
    position: "absolute",
    width: "23px",
    height: "23px",
    left: "50%",
    transform: "translateX(-50%)",
    top: "90px",
    "&:hover": {
      background: "#929292",
    },
  },
  expandButtonDark: {
    background: "#1C1C1C",
    boxShadow: "-4px 6px 9px -5px rgba(0,0,0,1)",
  },
  expandButtonLight: {
    background: "#fff",
    boxShadow: "-4px 6px 9px -5px rgba(0,0,0,0.3)",
  },
};

const PageGfDashboard = withBeforeUnload(withApiContext(_PageGfDashboard));

export default PageGfDashboard;
