import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DataGrid } from "syklone/ui/index.js";

function DataTable({ dataCommitted }) {
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "component",
      headerName: "Component",
      width: 160,
      editable: false,
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      editable: false,
    },
    {
      field: "origin",
      headerName: "Origin",
      width: 100,
      editable: false,
    },
    {
      field: "filename",
      headerName: "Filename",
      minWidth: 350,
      editable: false,
      flex: 1,
    },
    {
      field: "areas",
      headerName: "Areas",
      minWidth: 285,
      editable: false,
      flex: 1,
    },
  ];
  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        data-syklone="commit-info-table"
        rows={dataCommitted}
        columns={columns}
        pageSize={100}
        disableSelectionOnClick
        hideFooter
        autoHeight
        sx={(theme) => ({
          fontSize: 13,
          "&.MuiDataGrid-root": {
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            border: "none",
          },
          ".MuiDataGrid-columnHeaders": {
            borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
          },
          ".MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
        })}
      />
    </div>
  );
}

DataTable.propTypes = {
  dataCommitted: PropTypes.array,
};

export default DataTable;
