import React from "react";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

function getImagePathById(index) {
  const imagePaths = ["mjf.png", "ebm.png", "lrm.png"];
  const cyclicIndex = index % imagePaths.length;
  return imagePaths[cyclicIndex];
}

const withMachineListData = (Component) => {
  const MachineListData = () => {
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const serviceBuildCreator = apiInstance.sykloneApi.serviceBuildCreator;
    const {
      data: adminMachines = [],
      isLoading: isLoadingMachines,
      error: adminMachineError,
    } = useQuery(["adminMachines"], async () => {
      const response = await serviceAuth.getAdminEquipment();
      const data = response.data.data;
      const modifiedData = data.map((item) => {
        return {
          avatar: item.image || getImagePathById(item.id),
          id: item.id,
          name: item.name,
          site: item.site,
          domain: item["domain_name"],
          description: item.description,
          endpoint: item.endpoint,
          status: item["is_qualified_for_production"],
          isActive: !!item["is_qualified_for_production"],
          meta: item["meta"],
          drift: item["drift"],
          machineDefinition: item["machine_definition_id"],
        };
      });
      return modifiedData;
    });

    const {
      data: locations = [],
      isLoading: isLocationsLoading,
      error: locationsError,
    } = useQuery(
      ["machineLocations"],
      async () => {
        const response = await serviceBuildCreator.getLocations();
        const data = response;
        const modifiedData = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        return modifiedData;
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const {
      data: machineDefinitions = [],
      isLoading: isMachineDefinitionsLoading,
      error: machineDefinitionsError,
    } = useQuery(
      ["machineDefinitions"],
      async () => {
        const response = await serviceBuildCreator.getMachineDefinitions();
        const data = response.data.data["machine-definitions"];

        const modifiedData = data.map((item) => ({
          value: item?._id?.$oid ?? "",
          label: item.definitionName,
        }));
        return modifiedData;
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const invalidateAdminMachines = () => {
      queryClient.invalidateQueries("adminMachines");
    };

    const combinedLoading = isLoadingMachines || isLocationsLoading || isMachineDefinitionsLoading;
    const combinedError = adminMachineError || locationsError || machineDefinitionsError;

    return (
      <Component
        data={adminMachines}
        isLoading={combinedLoading}
        optionsData={{ locations, machineDefinitions }}
        handleRefreshData={invalidateAdminMachines}
        error={combinedError}
      />
    );
  };
  MachineListData.displayName = "MachineListData";
  MachineListData.propTypes = {};
  return MachineListData;
};

export default withMachineListData;
