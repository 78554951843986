import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

// imports: syklone
import { Grid, styled, Tabs, Tab, Typography } from "syklone/ui/index.js";

// imports: local
import { SeparatorLight, SeparatorDark } from "./assets/img/index.js";

const ScheduleNavContainer = styled(Grid)(({ theme }) => ({
  alignItems: "center",
  borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
}));

const NavTitleWrapper = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "0px 20px",
  margin: "0px 0px 0px 10px",
  "&:after": {
    content: "''",
    display: "flex",
    marginLeft: "20px",
    width: 16,
    height: 51,
    backgroundImage: theme.palette.mode === "dark" ? `url(${SeparatorDark})` : `url(${SeparatorLight})`,
  },
}));

const NavTitleTypography = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "0.75rem",
  opacity: 0.7,
  fontWeight: 500,
}));

function WidgetScheduleNavigation({ data, location, match }) {
  const [page, setPage] = useState("/dashboard/scheduling/manual");

  function updateMainTabIfRouteMatch() {
    data.menuItems.map((item) => {
      if (match(`${item.navTo}/*`, location.pathname)) {
        setPage(item.navTo);
      }
    });
  }

  useEffect(() => {
    updateMainTabIfRouteMatch();
  }, []);

  const handleTabChange = (event, newValue) => {
    setPage(newValue);
  };

  return (
    <>
      <ScheduleNavContainer container sx={{ alignItems: "center" }}>
        <NavTitleWrapper item>
          <NavTitleTypography>{data.title}</NavTitleTypography>
        </NavTitleWrapper>
        <Grid item>
          <Tabs
            value={page}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {data.menuItems.map((item, index) => (
              <Tab
                key={item.name}
                value={item.navTo}
                label={<span>{item.name}</span>}
                to={item.navTo}
                disabled={index > 0 ? true : false}
                component={RouterLink}
              />
            ))}
          </Tabs>
        </Grid>
      </ScheduleNavContainer>
    </>
  );
}

WidgetScheduleNavigation.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.func,
};

export default WidgetScheduleNavigation;
