import React from "react";
import { useParams } from "react-router-dom";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

const withSiteConfigurationUsersMapToGroup = (Component) => {
  const SiteConfigurationMapUsersToGroup = () => {
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = React.useState(null);
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();

    React.useEffect(() => {
      if (!id) {
        setErrorMessage("ID is required but was not provided");
      } else {
        setErrorMessage(null);
      }
    }, [id]);

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const {
      data: adminGroups = [],
      isLoading: isLoadingGroups,
      error: adminGroupsError,
    } = useQuery(["adminGroups"], async () => {
      const response = await serviceAuth.getAdminGroups();
      const data = response.data.data;
      const modifiedData = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description ? item.description : "",
        };
      });
      return modifiedData;
    });

    const {
      data: adminActiveGroup = [],
      isLoading: isAdminActiveGroupLoading,
      error: adminActiveGroupError,
    } = useQuery(
      ["adminActiveGroup", id],
      async () => {
        try {
          const response = await serviceAuth.getAdminAccountsGroupByAccountId(id);
          const data = response.data.data;
          return [data.id];
        } catch (e) {
          return [];
        }
      },
      {
        enabled: !!id,
        refetchOnWindowFocus: false,
      }
    );

    const {
      data: adminUser = {
        id,
        name: "",
      },
      isLoading: isAdminUserLoading,
      error: adminUserError,
    } = useQuery(
      ["adminUser", id],
      async () => {
        const response = await serviceAuth.getAdminAccountsByAccountId(id);
        const data = response.data.data;
        const modifiedData = {
          id: data.id,
          name: data.name,
        };
        return modifiedData;
      },
      {
        enabled: !!id,
      }
    );

    const invalidateAdminUser = () => {
      queryClient.invalidateQueries("adminGroups");

      // this is because if the data didnt change to trigger useEffectAgain
      queryClient.setQueryData(["adminActiveGroup", id], null);
      queryClient.invalidateQueries(["adminActiveGroup", id]);

      queryClient.invalidateQueries(["adminUser", id]);
    };

    const combinedError = errorMessage || adminGroupsError || adminActiveGroupError || adminUserError;
    const combinedLoading = isLoadingGroups || isAdminActiveGroupLoading || isAdminUserLoading;

    const combinedData = {
      adminUser,
      adminGroups,
      adminActiveGroup,
    };

    return (
      <Component
        data={combinedData}
        isLoading={combinedLoading}
        handleRefreshData={invalidateAdminUser}
        error={combinedError}
      />
    );
  };
  SiteConfigurationMapUsersToGroup.displayName = "SiteConfigurationUsersMapToGroup";
  SiteConfigurationMapUsersToGroup.propTypes = {};
  return SiteConfigurationMapUsersToGroup;
};

export default withSiteConfigurationUsersMapToGroup;
