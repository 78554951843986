import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, FormControl, Stack, TextField, useFormik, FormControlLabel, Checkbox, Yup } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

// imports: local
import { DialogResponseMessage } from "../index.js";

const validationSchema = Yup.object({
  name: Yup.string().required("Permission name is required"),
  description: Yup.string().required("Description is required"),
});

const DialogPermissions = React.forwardRef(({ onDialogEditPermission, onDialogCreatePermission }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const dialogDataRef = React.useRef(null);
  const id = dialogDataRef.current?.id;

  /* eslint-disable */
  const onSubmit = async (values) => {
    const payload = {
      name: values.name,
      ["is_active"]: values.isActive ? 1 : 0,
      ["content_type_id"]: 5,
      description: values.description,
      html_id: values.htmlId,
      route: values.route,
    };
    if (isEditMode) {
      onDialogEditPermission(id, payload);
    } else {
      //console.log(payload)
      onDialogCreatePermission(payload);
    }
  };
  const initialValues = { name: "", isActive: false, description: "", htmlId: "", route: "" };

  React.useImperativeHandle(ref, () => ({
    setResponseMessage: (errorMessage) => {
      setResponseMessage(errorMessage);
    },
    setDialogData: (data) => {
      setIsEditMode(data ? true : false);
      dialogDataRef.current = data;
    },
    setIsDialogOpen: (value) => {
      setIsOpen(value);
    },
    setIsSubmitting: (value) => {
      setIsSubmitting(value);
    },
  }));

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  React.useEffect(() => {
    if (isOpen && isEditMode) {
      const { name, isActive, description, route, htmlId } = dialogDataRef.current ?? initialValues;
      formik.setValues({
        name: name || "",
        isActive: isActive || false,
        description: description || "",
        route: route || "",
        htmlId: htmlId || "",
      });
    } else {
      setResponseMessage(null);
      setIsSubmitting(false);
      formik.resetForm();
    }
  }, [isOpen, isEditMode]);

  return (
    <DialogCommonBase
      title={isEditMode ? "Edit permission" : "Create permission"}
      isOpen={isOpen}
      dataSyklone="admin-permission-dialog"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2}>
          <DialogResponseMessage message={responseMessage} widgetName="dialog-permissions" />
          <FormControl
            sx={{
              "& > :not(style)": { mt: 1, width: "450px" },
            }}
            noValidate
            autoComplete="off"
            data-syklone="dialog-permission-name"
          >
            <TextField
              required
              id="dialog-permission-name"
              label="Permission name"
              name="name"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.name }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>
          <FormControl
            sx={{
              "& > :not(style)": { mt: 1, width: "450px" },
            }}
            noValidate
            autoComplete="off"
            data-syklone="dialog-permission-description"
          >
            <TextField
              required
              id="dialog-user-permission-description"
              label="Description"
              name="description"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.description }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </FormControl>

          <FormControl
            sx={{
              "& > :not(style)": { mt: 1, width: "450px" },
            }}
            noValidate
            autoComplete="off"
            data-syklone="dialog-permission-route"
          >
            <TextField
              id="dialog-permission-route"
              label="Route"
              name="route"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.route }}
              value={formik.values.route}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.route && Boolean(formik.errors.route)}
              helperText={formik.touched.route && formik.errors.route}
            />
          </FormControl>

          <FormControl
            sx={{
              "& > :not(style)": { mt: 1, width: "450px" },
            }}
            noValidate
            autoComplete="off"
            data-syklone="dialog-permission-htmlId"
          >
            <TextField
              id="dialog-permission-htmlId"
              label="HTML ID"
              name="htmlId"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.htmlId }}
              value={formik.values.htmlId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.htmlId && Boolean(formik.errors.htmlId)}
              helperText={formik.touched.htmlId && formik.errors.htmlId}
            />
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.isActive}
                onChange={formik.handleChange}
                name="isActive"
                data-syklone="permission-is-active"
              />
            }
            label="Active"
          />
          <Button
            variant="contained"
            size="large"
            type="submit"
            data-syklone="submit-permission-button"
            disabled={isSubmitting}
          >
            {isEditMode ? "Edit permission" : "Create permission"}
          </Button>
        </Stack>
      </form>
    </DialogCommonBase>
  );
});

DialogPermissions.displayName = "DialogPermissions";

DialogPermissions.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onDialogEditPermission: PropTypes.func,
  onDialogCreatePermission: PropTypes.func,
  dialogData: PropTypes.object,
  message: PropTypes.object,
};

export default DialogPermissions;
