import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, FormControl, Stack, TextField, useFormik, Yup } from "syklone/ui";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

// import: local
import { DialogResponseMessage } from "../index.js";

const validationSchema = Yup.object({
  description: Yup.string().required("Description is required"),
});

const DialogEndpoints = React.forwardRef(({ onDialogEditEndpoints }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const dialogDataRef = React.useRef(null);
  const id = dialogDataRef.current?.id;

  const onSubmit = async (values) => {
    const payload = {
      name: values.name,
      ["is_active"]: values.isActive ? 1 : 0,
      ["content_type_id"]: 5,
      description: values.description,
    };
    onDialogEditEndpoints(id, payload);
  };
  const initialValues = { description: "" };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  React.useImperativeHandle(ref, () => ({
    setResponseMessage: (errorMessage) => {
      setResponseMessage(errorMessage);
    },
    setDialogData: (data) => {
      dialogDataRef.current = data;
    },
    setIsDialogOpen: (value) => {
      setIsOpen(value);
    },
    setIsSubmitting: (value) => {
      setIsSubmitting(value);
    },
  }));

  React.useEffect(() => {
    if (isOpen) {
      formik.setValues({ description: dialogDataRef.current?.description });
    } else {
      formik.resetForm();
      setResponseMessage(null);
      setIsSubmitting(false);
    }
  }, [isOpen]);

  return (
    <DialogCommonBase
      title={"Edit endpoint"}
      isOpen={isOpen}
      dataSyklone="admin-endpoint-dialog"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2}>
          <DialogResponseMessage message={responseMessage} widgetName="dialog-endpoint" />
          <FormControl
            sx={{
              "& > :not(style)": { mt: 1, width: "450px" },
            }}
            noValidate
            autoComplete="off"
            data-syklone="dialog-endpoint-description"
          >
            <TextField
              id="dialog-user-endpoint-description"
              label="Description"
              name="description"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.description }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </FormControl>
          <Button
            variant="contained"
            size="large"
            type="submit"
            data-syklone="submit-endpoint-button"
            disabled={isSubmitting}
          >
            Edit endpoint
          </Button>
        </Stack>
      </form>
    </DialogCommonBase>
  );
});

DialogEndpoints.displayName = "DialogEndpoints";

DialogEndpoints.propTypes = {
  onDialogEditEndpoints: PropTypes.func,
};

export default DialogEndpoints;
