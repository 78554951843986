import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DataGrid } from "syklone/ui/index.js";
import { WidgetPopoverMenu, WidgetContainer } from "../../widgets/index.js";
import {
  useSearchableData,
  QuickSearchToolbar,
  TableDataIsActiveCell,
  TableDataCounter,
  TableCustomGrid,
  styles,
} from "../components/index.js";

function TableDataEndpoints({ data, setters, isLoading, error }) {
  const { searchText, handleSearchChange, clearSearch, filteredData } = useSearchableData(data, ["patternName"]);
  const columns = React.useMemo(() => {
    const actionMenu = (params) => {
      const isEndpointActive = params.row.isActive;
      const isEndpointVisible = params.row.isVisible;
      return {
        menuItems: [
          {
            name: "Edit Description",
            action: () => {
              setters.setDialogEditDataFn(params.row);
              setters.setIsDialogOpenFn(true);
            },
          },
          {
            name: `Make endpoint ${isEndpointVisible ? "invisible" : "visible"}`,
            action: () => {
              setters.setDialogVisiblityDataFn(params.row);
              setters.setIsDialogVisibilityEndpointOpenFn(true);
            },
          },
          {
            name: `${isEndpointActive ? "Deactivate" : "Activate"} endpoint`,
            action: () => {
              setters.setDialogActivationDataFn(params.row);
              setters.setIsDialogDeleteEndpointOpenFn(true);
            },
            type: isEndpointActive ? "error" : "success",
          },
        ],
      };
    };

    return [
      { field: "id", headerName: "ID", hide: true },
      { field: "patternName", headerName: "Pattern", flex: 1, minWidth: 200, disableColumnMenu: true },
      { field: "description", headerName: "Description", flex: 1, minWidth: 200, disableColumnMenu: true },
      {
        field: "isMapped",
        headerName: "Mapped",
        width: 90,
        disableColumnMenu: true,
        renderCell: (params) => <TableDataCounter counter={params.row.counter} />,
      },
      {
        field: "isVisible",
        headerName: "Visible",
        width: 90,
        disableColumnMenu: true,
        renderCell: (params) => <TableDataIsActiveCell isActive={params.row.isVisible} type={"visiblity"} />,
      },
      {
        field: "isActive",
        headerName: "Status",
        width: 90,
        disableColumnMenu: true,
        renderCell: (params) => <TableDataIsActiveCell isActive={params.row.isActive} type={"active"} />,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        width: 70,
        disableColumnMenu: true,
        renderCell: (params) => <WidgetPopoverMenu data={actionMenu(params)} />,
      },
    ];
  }, [setters]);

  return (
    <TableCustomGrid item sx={{ marginTop: "30px" }}>
      <WidgetContainer isLoading={isLoading} error={error}>
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          rows={filteredData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => handleSearchChange(event.target.value),
              clearSearch: () => clearSearch(),
            },
          }}
          sx={styles.gridStyle}
        />
      </WidgetContainer>
    </TableCustomGrid>
  );
}

TableDataEndpoints.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  setters: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

export default TableDataEndpoints;
