import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DataGrid, Grid, styled } from "syklone/ui/index.js";

const GridWrapper = styled(Grid)({
  padding: "12px 7px",
});

function WidgetNodeMetrics({ data }) {
  const [isLoading] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      disableColumnMenu: true,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      disableColumnMenu: true,
    },
    {
      field: "region",
      headerName: "Region",
      disableColumnMenu: true,
    },
    {
      field: "cluster",
      headerName: "Cluster",
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "version",
      headerName: "Version",
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
    },
    {
      field: "allocatedCpu",
      headerName: "Allocated CPU",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "allocatedMemory",
      headerName: "Allocated Memory",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "capacityCpu",
      headerName: "Capacity CPU",
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "capacityMemory",
      headerName: "Capacity Memory",
      width: 150,
      disableColumnMenu: true,
    },
  ];

  return (
    <GridWrapper container>
      <Grid item xs={12} sx={{ height: 375 }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          loading={isLoading ? true : false}
          columnVisibilityModel={{
            id: false,
          }}
          sx={(theme) => ({
            fontSize: 13,
            "&.MuiDataGrid-root": {
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            ".MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          })}
        />
      </Grid>
    </GridWrapper>
  );
}

WidgetNodeMetrics.propTypes = {
  data: PropTypes.array,
};

export default WidgetNodeMetrics;
