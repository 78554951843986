import PropTypes from "prop-types";
import React from "react";

// imports: syklone
import { WidgetCard } from "syklone/components/widgets/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { arrayBufferBase64, addbase64Flag } from "syklone/common/utils.js";

// imports: local
import { BUILD_FILES_PROMOTION_TAB_VALUES } from "../../core/index.js";

const R_AND_D = "R&D";
const UNDER_REVIEW = "Under Review";
const RELEASED = "Released";
const CANCELLED = "Cancelled";
const OBSOLETE = "Obsolete";

const WidgetRecentBuildFileCard = ({ recentBuildFile, history, navigate }) => {
  const apiInstance = useApiContext();
  const [image, setImage] = React.useState("");
  const [isImageLoading, setIsImageLoading] = React.useState(false);

  const getSnapshotByBlobName = React.useCallback(async (blobName) => {
    recentBuildFile.id;
    const responseSnapshot = await apiInstance.sykloneApi.serviceSnapshots.getBlob(blobName);
    const imageStr = addbase64Flag(arrayBufferBase64(responseSnapshot.data));
    return imageStr;
  }, []);

  const getFirstPart = React.useCallback(async () => {
    if (!recentBuildFile?.partSession?.id) {
      return;
    }
    try {
      setIsImageLoading(true);
      const id = recentBuildFile.partSession.id;
      const responsePart = await apiInstance.sykloneApi.servicePartSessions.getById(id);
      const responsePartResult = responsePart.result;
      if (responsePartResult && responsePartResult.snapshots.snapshots.length) {
        const snapshot = responsePartResult.snapshots.snapshots[0];
        const blobName = snapshot.blobPath.slice(0, -4);
        const img = await getSnapshotByBlobName(blobName);

        setImage(img);
      }
    } catch (e) {
      console.log("error", e);
      setImage(null);
    } finally {
      setIsImageLoading(false);
    }
  }, [recentBuildFile]);

  React.useEffect(() => {
    getFirstPart();
  }, [getFirstPart]);

  if (!recentBuildFile) {
    return null;
  }

  let status;
  switch (recentBuildFile.status) {
    case R_AND_D:
      status = BUILD_FILES_PROMOTION_TAB_VALUES[0];
      break;
    case UNDER_REVIEW:
      status = BUILD_FILES_PROMOTION_TAB_VALUES[1];
      break;
    case RELEASED:
      status = BUILD_FILES_PROMOTION_TAB_VALUES[2];
      break;
    case CANCELLED:
      status = BUILD_FILES_PROMOTION_TAB_VALUES[3];
      break;
    case OBSOLETE:
      status = BUILD_FILES_PROMOTION_TAB_VALUES[4];
      break;
    default:
      status = "";
      break;
  }

  const onClickCard = (rid, widget, route) => {
    if (status) {
      navigate("dashboard/part-platform/viewer/part-viewer", {
        state: {
          buildFileId: recentBuildFile.id,
          buildFileType: status,
        },
      });
    } else {
      alert("Part has no status");
    }
  };

  return (
    <WidgetCard
      key={recentBuildFile.id}
      id={Math.floor(Math.random() * 100)}
      history={history}
      status={{
        message: recentBuildFile.status,
        type: recentBuildFile.status === "Cancelled" || recentBuildFile.status === "Obsolete" ? "danger" : "success",
      }}
      isImageLoading={isImageLoading}
      title={recentBuildFile.name}
      subtitle={recentBuildFile.productId ?? ""}
      image={image}
      imgStyle={{ width: "100%", position: "relative" }}
      widget="viewer/part-viewer"
      route="dashboard/part-platform"
      navigate={navigate}
      onClickCard={onClickCard}
      isThemeLight={apiInstance.sessionSettings.data().isThemeLight}
      data-syklone="recent-build-file-card"
    />
  );
};

WidgetRecentBuildFileCard.propTypes = {
  history: PropTypes.object,
  navigate: PropTypes.func,
  recentBuildFile: PropTypes.object,
};

export default WidgetRecentBuildFileCard;
