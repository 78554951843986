import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Button, icons, Typography, Drawer, Pagination, useMediaQuery } from "syklone/ui/index.js";
import { useAuthContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import {
  WidgetAuditTrailPagePdf,
  WidgetFilter,
  WidgetAuditTrailTableHeader,
  WidgetAuditTrailTableItem,
  WidgetButtonGeneratePdf,
  WidgetContainer,
} from "../../widgets/index.js";

const TABLE_HEADER_ITEMS = ["Action", "Type", "Electronic record", "Location", "Completed by", "Completed on"];

function PageAuditTrailAtDefault({
  auditTrailData,
  auditTrailCount,
  isLoadingAuditTrailData,
  auditTrailError,
  filterSchema,
  filterSchemaError,
  currentPage,
  totalPages,
  goToPage,
  queryParams,
  setQueryParams,
  isAuditTrailFiltered,
  resetData,
  fetchPdfData,
}) {
  const authInstance = useAuthContext();
  const { showSnackbar } = useAlertSnackbar();
  const username = authInstance.user ? authInstance.user.name : undefined;
  const matches = useMediaQuery("(min-width: 1700px)");

  // -------- Reactjs state management --------
  const [sideBarOpen, setSideBarOpen] = React.useState(false);

  // -------- Slots --------
  const onChange = (_event, value) => {
    goToPage(value);
  };

  const onShowSidebar = (open) => {
    setSideBarOpen(open);
  };

  const handleFilterClick = (params) => {
    setQueryParams(params);
    goToPage(1);
  };

  // -------- Reactjs render --------
  const containerStyle = {
    ...styles.colLeft,
    ...(matches ? { display: "flex", flexDirection: "column", justifyContent: "space-between" } : { display: "none" }),
  };
  return (
    <>
      <Drawer anchor="left" open={sideBarOpen} onClose={() => onShowSidebar(false)}>
        <Box
          sx={{
            width: 330,
            height: "99%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "20px 17px 0px 17px",
          }}
        >
          <WidgetFilter
            filterSchema={filterSchema}
            filterErrors={filterSchemaError}
            handleFilterClick={handleFilterClick}
            handleResetClick={resetData}
          />
        </Box>
      </Drawer>
      <Box sx={styles.container}>
        <Box sx={containerStyle}>
          <WidgetFilter
            filterSchema={filterSchema}
            filterErrors={filterSchemaError}
            handleFilterClick={handleFilterClick}
            handleResetClick={resetData}
          />
        </Box>
        <Box
          sx={{
            ...styles.colRight,
            marginLeft: matches ? "330px" : 0,
          }}
        >
          <Box sx={styles.pageToolbarWrapper}>
            <Box sx={styles.pageTitle}>
              {filterSchema && filterSchema.length ? (
                <Button
                  sx={matches ? { display: "none" } : { display: "flex" }}
                  size="large"
                  variant="contained"
                  onClick={() => onShowSidebar(true)}
                  startIcon={<icons.mui.Menu />}
                  data-syklone="audit-trail-filter-options"
                >
                  Filters
                </Button>
              ) : null}
              <Typography component="h1" variant="h4" sx={{ fontWeight: 800, fontSize: 24 }}>
                Audit trail
              </Typography>
            </Box>
            <Box>
              {isAuditTrailFiltered && auditTrailData.length !== 0 && (
                <WidgetButtonGeneratePdf
                  onError={() => {
                    showSnackbar("Error generating pdf.", "error");
                  }}
                  componentType={WidgetAuditTrailPagePdf}
                  fetchPdfSourceData={fetchPdfData}
                  componentProps={{
                    selectionCritera: queryParams,
                    username: username,
                  }}
                />
              )}
            </Box>
          </Box>

          <Box sx={styles.tableHeader}>
            {auditTrailData && auditTrailData.length !== 0 && (
              <Box data-syklone="audit-trail-list-header">
                <WidgetAuditTrailTableHeader items={TABLE_HEADER_ITEMS} />
              </Box>
            )}
          </Box>
          <WidgetContainer
            isLoading={isLoadingAuditTrailData}
            error={auditTrailError}
            isTableDataEmpty={!Array.isArray(auditTrailData) || auditTrailData.length === 0}
          >
            <Box data-syklone="audit-trail-list" sx={styles.tableListWrapper}>
              {auditTrailData &&
                auditTrailData.map((item, index) => {
                  return (
                    <Box data-syklone="audit-trail-list-item" key={index}>
                      <WidgetAuditTrailTableItem key={index} item={item} />
                    </Box>
                  );
                })}
            </Box>
          </WidgetContainer>
          <Box data-syklone="pagination" sx={styles.pagination}>
            <Pagination
              color="primary"
              count={totalPages}
              page={currentPage}
              onChange={onChange}
              defaultPage={1}
              variant="outlined"
              shape="rounded"
              size="large"
              hideNextButton={auditTrailData.length === 0 ? true : false}
              hidePrevButton={auditTrailData.length === 0 ? true : false}
              sx={{
                "& .MuiPaginationItem-ellipsis": {
                  border: "0px",
                },
              }}
            />
            <Box component="span" sx={styles.ammountRecords}>
              Number of records: {auditTrailCount}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    width: "100%",
  },
  colLeft: {
    width: 330,
    height: "calc(100vh - 55px)",
    position: "fixed",
    top: "55px",
    padding: "20px",
    backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#252525" : "#f0f0f0"),
  },
  colRight: {
    flexGrow: 1,
    padding: "25px",
    marginTop: "55px",
  },
  pageToolbarWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: (theme) => (theme.palette.mode === "dark" ? "1px solid #404040" : "1px solid #E2E2E2"),
    paddingBottom: "17px",
    marginBottom: "10px",
  },
  tableListWrapper: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  },
  tableHeader: {
    position: "sticky",
    top: "55px",
    zIndex: 999,
  },
  pageTitle: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  pagination: {
    marginTop: "20px",
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
    "& .MuiPaginationItem-outlinedPrimary.Mui-selected": {
      backgroundColor: "rgba(255, 181, 0, 0.2)",
      border: "1px solid rgba(255, 181, 0, 0.5)",
      color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
    },
    "& .MuiPaginationItem-outlined": {
      border: (theme) => (theme.palette.mode === "dark" ? "1px solid #464646" : "1px solid #DADADA"),
      color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
      transition: "all 0.3s",
    },
    "& .MuiPaginationItem-outlined:not(.MuiPaginationItem-outlinedPrimary.Mui-selected):hover": {
      border: (theme) => (theme.palette.mode === "dark" ? "1px solid #ABABAB" : "1px solid #BCBCBC"),
    },
    "& .MuiPaginationItem-ellipsis": {
      border: 0,
    },
  },
  ammountRecords: {
    display: "flex",
    justifyContent: "center",
    fontSize: 13,
    opacity: 0.5,
    paddingTop: "15px",
  },
  pdfButton: {
    pointerEvents: "none",
  },
};

PageAuditTrailAtDefault.propTypes = {
  auditTrailData: PropTypes.array,
  auditTrailCount: PropTypes.number,
  isLoadingAuditTrailData: PropTypes.bool,
  auditTrailError: PropTypes.object,
  filterSchema: PropTypes.array,
  isLoadingFilterSchema: PropTypes.bool,
  filterSchemaError: PropTypes.object,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  goToPage: PropTypes.func,
  setQueryParams: PropTypes.func,
  isAuditTrailFiltered: PropTypes.bool,
  resetData: PropTypes.func,
  queryParams: PropTypes.object,
  fetchPdfData: PropTypes.func,
};

export default PageAuditTrailAtDefault;
