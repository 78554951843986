import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Divider,
  icons,
  FormControl,
  InputLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Tooltip,
  Typography,
  FormHelperText,
  FormControlLabel,
} from "syklone/ui/index.js";

function WidgetEditMachines({
  values,
  setFieldValue,
  handleChange,
  touched,
  errors,
  equipment,
  buildFileData,
  isDeleting,
  isCheckboxStateChanged,
  setIsCheckboxStateChanged,
  switchChecked,
  setSwitchChecked,
  onHoldState,
}) {
  const filterByName = (name) => {
    return buildFileData?.machineValidity?.find((item) => item.name === name);
  };

  //-------- Tooltip description --------
  const tooltipDesc = (item) => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {item.name ? (
          <Box>
            <span style={{ opacity: 0.7 }}>Name:</span> {item.name}
          </Box>
        ) : null}
        {item.description ? (
          <Box>
            <span style={{ opacity: 0.7 }}>Description:</span> {item.description}
          </Box>
        ) : null}
        {item.domainName ? (
          <Box>
            <span style={{ opacity: 0.7 }}>Domain name:</span> {item.domainName}
          </Box>
        ) : null}
        {item.endpoint ? (
          <Box>
            <span style={{ opacity: 0.7 }}>Endpoint:</span> {item.endpoint}
          </Box>
        ) : null}
        {item.location ? (
          <Box>
            <span style={{ opacity: 0.7 }}>Location:</span> {item.location}
          </Box>
        ) : null}
        {item.isQualifiedForProduction ? (
          <Box>
            <span style={{ opacity: 0.7 }}>Is qualified for production:</span>{" "}
            {item.isQualifiedForProduction === 1 ? "Yes" : "No"}
          </Box>
        ) : null}
      </Box>
    );
  };

  // Checkbox handle if machine validity is coming from the response payload
  const filterResult = filterByName(values.equipmentAccount);

  // Switch
  const handleSwitchChange = (event) => {
    setSwitchChecked(event.target.checked);
  };

  React.useEffect(() => {
    if (filterResult) {
      const updatedMachineOnHold = {
        ...values.machineOnHold,
        [values.equipmentAccount]: filterResult.onHold,
      };
      setFieldValue("machineOnHold", updatedMachineOnHold);
    }
  }, [filterResult, values.equipmentAccount, setFieldValue]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
        <Typography sx={{ fontWeight: "bold" }}>Machine validity</Typography>
      </Box>
      {onHoldState.length > 0 ? (
        <Alert variant="filled" severity="warning" sx={{ mb: 1.5 }}>
          Machines can not be added to build file on hold.
        </Alert>
      ) : (
        <Alert variant="filled" severity="info" sx={{ mb: 1.5 }}>
          Machines can only be assigned to build file one at a time.
        </Alert>
      )}
      <Box sx={{ mb: 1 }}>
        <FormControl
          sx={{ mb: 2, width: "100%" }}
          error={touched.equipmentAccounts && Boolean(errors.equipmentAccounts)}
        >
          <InputLabel id="machine-validity" required>
            Select machine
          </InputLabel>
          <Select
            labelId="machine-validity"
            id="machine-validity-select"
            name="equipmentAccount"
            value={values.equipmentAccount || ""}
            onChange={handleChange}
            input={<OutlinedInput label="Select machine" />}
            disabled={isDeleting || isCheckboxStateChanged || switchChecked || onHoldState?.length > 0}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {equipment && equipment.length ? (
              equipment.map((item) => (
                <MenuItem key={item.name} value={item.name}>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <ListItemText primary={item.name} /* secondary={item.location} */ />
                    {filterByName(item.name) ? <Chip label="Added" color="primary" size="small" /> : null}
                    <Tooltip title={tooltipDesc(item)}>
                      <ListItemIcon sx={{ justifyContent: "center" }}>
                        <icons.mui.Info fontSize="small" />
                      </ListItemIcon>
                    </Tooltip>
                  </Box>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>
                <em>No machine available</em>
              </MenuItem>
            )}
          </Select>
          {touched.equipmentAccount && errors.equipmentAccount && (
            <FormHelperText error={true}>{errors.equipmentAccount}</FormHelperText>
          )}

          {values.equipmentAccount && (
            <>
              <List
                sx={{
                  background: "#0a0a0d",
                  borderRadius: "6px",
                  mt: 0.5,
                }}
                data-syklone="machine-validity-onhold"
              >
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>
                        <b>Selected machine</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        <b>On Hold</b>
                      </Typography>
                    </Grid>
                    {filterByName(values.equipmentAccount) ? (
                      <Grid item xs={3}>
                        <Typography>
                          <b>Remove</b>
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </ListItem>
                {values.equipmentAccount && (
                  <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={6}>
                        {(() => {
                          const equipmentItem = equipment.find((item) => item.name === values.equipmentAccount);
                          return equipmentItem ? (
                            <ListItemText
                              data-syklone="machine-validity-onhold-machine-name"
                              primary={equipmentItem.name}
                            />
                          ) : null;
                        })()}
                      </Grid>
                      <Grid item xs={3}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              data-syklone="machine-validity-onhold-checkbox"
                              disabled={switchChecked}
                              checked={values.machineOnHold[values.equipmentAccount] || false}
                              onChange={(event) => {
                                setIsCheckboxStateChanged(!isCheckboxStateChanged);

                                const updatedMachineOnHold = {
                                  ...values.machineOnHold,
                                  [values.equipmentAccount]: event.target.checked,
                                };

                                setFieldValue("machineOnHold", updatedMachineOnHold);
                              }}
                            />
                          }
                        />
                      </Grid>
                      {filterByName(values.equipmentAccount) ? (
                        <Grid item xs={3}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={switchChecked}
                                disabled={isCheckboxStateChanged}
                                onChange={(event) => {
                                  handleSwitchChange(event);
                                  const updatedMachineRemoved = {
                                    ...values.machineRemove,
                                    [values.equipmentAccount]: event.target.checked,
                                  };

                                  setFieldValue("machineRemove", updatedMachineRemoved);
                                }}
                              />
                            }
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </ListItem>
                )}
                {isCheckboxStateChanged && (
                  <>
                    <Divider sx={{ mt: 0.5 }} />
                    <Typography variant="caption" display="block" gutterBottom sx={{ pl: 2, pt: 1 }}>
                      Save the dialog form to change status of <b>{"On Hold"}</b>
                    </Typography>
                  </>
                )}
                {switchChecked && (
                  <>
                    <Divider sx={{ mt: 0.5 }} />
                    <Typography variant="caption" display="block" gutterBottom sx={{ pl: 2, pt: 1 }}>
                      Save the dialog form to <b>remove</b> the machine validity
                    </Typography>
                  </>
                )}
                {!switchChecked && !isCheckboxStateChanged && !filterByName(values.equipmentAccount) && (
                  <>
                    <Divider sx={{ mt: 0.5 }} />
                    <Typography variant="caption" display="block" gutterBottom sx={{ pl: 2, pt: 1 }}>
                      Save the dialog to <b>add</b> machine validity.
                    </Typography>
                  </>
                )}
              </List>
            </>
          )}
        </FormControl>
      </Box>
    </>
  );
}

WidgetEditMachines.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  equipment: PropTypes.array,
  buildFileData: PropTypes.object,
  isDeleting: PropTypes.bool,
  isCheckboxStateChanged: PropTypes.bool,
  setIsCheckboxStateChanged: PropTypes.func,
  switchChecked: PropTypes.bool,
  setSwitchChecked: PropTypes.func,
  onHoldState: PropTypes.array,
};

export default WidgetEditMachines;
