import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { DataGrid } from "syklone/ui/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";

import { WidgetPopoverMenu, WidgetContainer } from "../../widgets/index.js";
import {
  useSearchableData,
  QuickSearchToolbar,
  styles,
  TableCustomGrid,
  TableDataIsActiveCell,
} from "../components/index.js";
function TableDataGroups({ data, setters, isLoading, error }) {
  const navigate = useNavigate();
  const { searchText, handleSearchChange, clearSearch, filteredData } = useSearchableData(data, ["name"]);
  const { checkElementVisibility } = usePermissionChecks();

  const columns = React.useMemo(() => {
    const actionMenu = (params) => {
      const isGroupActive = params.row.isActive;
      return {
        menuItems: [
          {
            name: "Edit",
            action: () => {
              setters.setDialogEditDataFn(params.row);
              setters.setIsDialogEditGroupOpenFn(true);
            },
            disabled: !checkElementVisibility(PermissionVisualElements.adminGroupsEditGroup),
          },
          {
            name: "Map permissions",
            action: () => {
              const id = params.row.id;
              navigate(`/dashboard/admin/configuration/groups/${id}/permissions`);
            },
            disabled:
              !isGroupActive || !checkElementVisibility(PermissionVisualElements.adminGroupsMapGroupToPermissions),
          },
          {
            name: `${isGroupActive ? "Deactivate" : "Activate"} group`,
            action: () => {
              setters.setDialogActivationDataFn(params.row);
              setters.setIsDialogDeleteGroupOpenFn(true);
            },
            type: isGroupActive ? "error" : "success",
            disabled: isGroupActive
              ? !checkElementVisibility(PermissionVisualElements.adminGroupsDeactivateGroup)
              : !checkElementVisibility(PermissionVisualElements.adminGroupsActivateGroup),
          },
        ],
      };
    };

    return [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "name",
        flex: 1,
        headerName: "Group name",
        disableColumnMenu: true,
      },
      {
        field: "description",
        flex: 1,
        headerName: "Description",
        disableColumnMenu: true,
      },
      {
        field: "isActive",
        headerName: "Status",
        disableColumnMenu: true,
        renderCell: (params) => <TableDataIsActiveCell isActive={params.row.isActive} />,
      },
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => <WidgetPopoverMenu id="groups" data={actionMenu(params)} />,
      },
    ];
  }, [setters]);

  return (
    <TableCustomGrid item sx={{ marginTop: "30px" }}>
      <WidgetContainer isLoading={isLoading} error={error}>
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          rows={filteredData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          getRowId={(row) => row.id}
          loading={isLoading}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => handleSearchChange(event.target.value),
              clearSearch: () => clearSearch(),
            },
          }}
          sx={styles.gridStyle}
        />
      </WidgetContainer>
    </TableCustomGrid>
  );
}

TableDataGroups.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  setters: PropTypes.object,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

export default TableDataGroups;
