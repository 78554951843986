import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Accordion, AccordionSummary, AccordionDetails, Box, icons, Typography } from "syklone/ui/index.js";
import { moment } from "syklone/libraries/index.js";

const NOT_AVAILABLE = "N/A";

const formatValue = (value) => {
  if (
    value === "" ||
    value === undefined ||
    (typeof value === "object" && value !== null && Object.keys(value).length === 0) ||
    (Array.isArray(value) && value.length === 0) ||
    (Array.isArray(value) && value.length > 0 && typeof value[0] === "string" && value[0].length === 0)
  ) {
    return NOT_AVAILABLE;
  }

  if (typeof value === "object" && !Array.isArray(value)) {
    const formattedElements = Object.entries(value).map(([key, val]) => (
      <span
        key={key}
        style={{ marginRight: "8px", paddingRight: "8px", borderRight: "1px solid rgba(255, 255, 255, 0.2)" }}
      >
        <span style={{ opacity: "0.7" }}>{key}</span>: {val.toString()}
      </span>
    ));

    return <>{formattedElements}</>;
  }

  return value.toString();
};

const getSubItemDetails = (record) => {
  return formatValue(record);
};

const createDate = (str) => {
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

function WidgetBfList({ data, type }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionItem = (key, value, index) => {
    let expandable;

    if (typeof value === "object") {
      expandable = true;
    } else {
      expandable = false;
    }

    const detailsItem = (obj) => {
      return Object.entries(obj)
        .map(([itemKey, itemValue], itemIndex) => {
          if (typeof value === "string") {
            return (
              <Box sx={styles.itemValuesWrapper} key={itemIndex}>
                <Box sx={styles.itemValuesKey}>{itemKey}:</Box>
                <Box sx={styles.itemValuesValue}>{itemValue}</Box>
              </Box>
            );
          } else {
            return (
              <Box sx={styles.itemValuesWrapper} key={itemIndex}>
                <Box sx={styles.itemValuesKey}>{itemKey}:</Box>
                {getSubItemDetails(itemValue)}
              </Box>
            );
          }
        })
        .reverse();
    };

    return (
      <Accordion
        disableGutters
        square
        key={index}
        expanded={expanded === `panel${index}`}
        onChange={expandable ? handleChange(`panel${index}`) : null}
        elevation={0}
        sx={(theme) => ({
          backgroundColor: "transparent",
          borderBottom: `1px solid ${theme.palette.divider}`,
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
        })}
      >
        <AccordionSummary
          expandIcon={expandable ? <icons.mui.ExpandMore /> : null}
          aria-controls={`panel${index}bfm-content`}
          id={`panel${index}bfm-header`}
          sx={{
            userSelect: "auto",
          }}
        >
          <Typography sx={{ color: "text.secondary", width: "250px", flexShrink: 0 }}>{key}</Typography>
          <Typography>
            {expandable ? (
              <Box component="span" sx={styles.expandLink}>
                Details
              </Box>
            ) : (
              <span>{value}</span>
            )}
          </Typography>
        </AccordionSummary>
        {expandable ? (
          <AccordionDetails sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
            {detailsItem(value)}
          </AccordionDetails>
        ) : (
          <></>
        )}
      </Accordion>
    );
  };

  const createBfmUi = (data) => {
    return [...data].map((item, index) => {
      if (!item) {
        return null;
      }

      return (
        <Box sx={{ padding: "0px 20px 20px 20px" }} key={index}>
          <Box>{accordionItem("Build file ID", item.id, `id_${index}`)}</Box>
          <Box>{accordionItem("Name", item.name, `name_${index}`)}</Box>
          {item.productId && <Box>{accordionItem("Product id", item.productId, `id_${index}`)}</Box>}
          {item.approver && <Box>{accordionItem("Approver", item.approver, `approver_${index}`)}</Box>}
          <Box>{accordionItem("Created by", item.lifecycle[0].user, `user_${index}`)}</Box>
          <Box>{accordionItem("Status", item.status, `status_${index}`)}</Box>
          <Box>{accordionItem("Technology", item.technology, `technology_${index}`)}</Box>
          <Box>{accordionItem("Build file created at", createDate(item.createdAt), `createdAt_${index}`)}</Box>
          <Box>{accordionItem("Build file updated at", createDate(item.updatedAt), `updatedAt_${index}`)}</Box>
          {item.metadata && <Box>{accordionItem("Metadata", item.metadata, `metadata_${index}`)}</Box>}
          {item.machineValidity.length !== 0 && (
            <Box>{accordionItem("Machine validity", item.machineValidity, `machineValidity_${index}`)}</Box>
          )}
        </Box>
      );
    });
  };

  return (
    <>
      {type === "part" && createBfmUi(data)}
      {type === "platform" && createBfmUi(data)}
    </>
  );
}

WidgetBfList.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
};

const styles = {
  itemValuesWrapper: {
    display: "flex",
  },
  itemValuesKey: {
    minWidth: "140px",
    opacity: ".5",
  },
  itemValuesValue: {
    "&:last-child": {
      borderRight: "0px",
    },
    borderRight: (theme) => `1px solid ${theme.palette.mode === "dark" ? "#2F2F2F" : "#E8E8E8"}`,
    paddingRight: "8px",
    marginRight: "8px",
  },
  expandLink: {
    color: "#ffb500",
  },
  chip: {
    fontSize: 12,
  },
};

export default WidgetBfList;
