import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Alert, Box, Button, FormControl, TextField, Stack, styled } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

const CustomAlert = styled(Alert)(() => ({
  fontSize: "14px",
  marginBottom: "10px",
}));

const ImageWrapper = styled(Box)(() => ({
  border: "1px dotted rgba(255, 255, 255, 0.4)",
  borderRadius: "4px",
  paddingLeft: "4px",
}));

const CustomInput = styled("input")(() => ({
  display: "none",
}));

function DialogAddFamily({ alert, setAlert, dialogFormState, dialogFormFn, isOpen, setIsOpen, isLoading, onSubmit }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Dialog states
  const { familyName, description, productFamilyImage } = dialogFormState;

  // Dialog form functions
  const { setFamilyName, setDescription, setProductFamilyImage } = dialogFormFn;

  const inputRef = useRef(null);

  const handleFamilyName = (event) => {
    setFamilyName(event.target.value);
  };

  const handleDescription = (event) => {
    setDescription(event.target.value);
  };

  const resetState = () => {
    setFamilyName("");
    setDescription("");
    setProductFamilyImage(null);
  };

  useEffect(() => {
    setIsDialogOpen(isOpen);
  }, [isOpen]);

  const onSubmitButton = async (familyName, description) => {
    await onSubmit({ name: familyName, description: description });
  };

  const onImageInputChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setProductFamilyImage(e.target.files[0]);
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <DialogCommonBase
      title="Add product family"
      isOpen={isDialogOpen}
      onClose={() => {
        setIsOpen(false);
        setAlert({ message: "", severity: "", component: "" });
        resetState();
      }}
      data-syklone="dialog-add-product-family"
    >
      {alert.severity === "error" && alert.component === "DialogAddFamily" ? (
        <CustomAlert severity={alert.severity || "success"} sx={{ width: "100%" }}>
          {alert.message}
        </CustomAlert>
      ) : null}
      <Stack direction="column" spacing={2}>
        <FormControl
          sx={{
            "& > :not(style)": { mt: 1, width: "450px" },
          }}
          noValidate
          autoComplete="off"
          data-syklone="family-name"
        >
          <TextField
            id="dialog-family-name"
            label="Product family name"
            name="name"
            variant="outlined"
            InputLabelProps={{ shrink: !!familyName }}
            value={familyName}
            onChange={handleFamilyName}
            disabled={isLoading}
          />
        </FormControl>

        <FormControl noValidate autoComplete="off" data-syklone="family-name">
          <TextField
            id="dialog-family-description"
            label="Product family description"
            name="description"
            variant="outlined"
            InputLabelProps={{ shrink: !!description }}
            value={description}
            multiline
            rows={3}
            onChange={handleDescription}
            disabled={isLoading}
          />
        </FormControl>

        <FormControl noValidate autoComplete="off" data-syklone="family-image">
          <ImageWrapper>
            <CustomInput
              ref={inputRef}
              accept="image/*"
              type="file"
              id="input-image-upload"
              multiple={false}
              onChange={onImageInputChange}
            />
            <label id="label-image-upload" htmlFor="input-image-upload">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button onClick={onButtonClick} variant="text" data-syklone="add-images-button">
                  Select image
                </Button>
                <Box sx={{ fontSize: "12px" }}>{productFamilyImage?.name !== undefined && productFamilyImage.name}</Box>
              </Stack>
            </label>
          </ImageWrapper>
        </FormControl>

        <Button
          variant="contained"
          size="large"
          onClick={() => onSubmitButton(familyName, description)}
          data-syklone="add-product-family-button"
          disabled={familyName.length === 0 || description.length === 0 || isLoading ? true : false}
        >
          {isLoading ? "Loading..." : "Add product family"}
        </Button>
      </Stack>
    </DialogCommonBase>
  );
}

DialogAddFamily.propTypes = {
  alert: PropTypes.object,
  setAlert: PropTypes.func,
  dialogFormState: PropTypes.object,
  dialogFormFn: PropTypes.object,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  setIsOpen: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DialogAddFamily;
