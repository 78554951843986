import React from "react";
import { useParams } from "react-router-dom";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

const withSiteConfigurationPermissionMapToEndpoints = (Component) => {
  const SiteConfigurationMapPermissionToEndpoints = () => {
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = React.useState(null);
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();

    React.useEffect(() => {
      if (!id) {
        setErrorMessage("ID is required but was not provided");
      } else {
        setErrorMessage(null);
      }
    }, [id]);

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const {
      data: adminEndpoints = [],
      isLoading: isLoadingEndpoints,
      error: adminEndpointsError,
    } = useQuery(
      ["adminEndpoints"],
      async () => {
        const response = await serviceAuth.getAdminEndpoints();

        const data = response.data.data;

        const modifiedData = data
          .filter((item) => item.is_active === 1)
          .map((item) => {
            return {
              id: item.id,
              name: item["pattern_name"],
              description: item.description ? item.description : "",
            };
          });
        return modifiedData;
      },
      {
        refetchOnWindowFocus: false,
      }
    );

    const {
      data: adminActiveEndpoints = [],
      isLoading: isAdminActiveEndpointsLoading,
      error: adminActiveEndpointsPermissionError,
    } = useQuery(
      ["adminActiveEndpoints", id],
      async () => {
        const response = await serviceAuth.getAdminPermissionEndpointsByPermissionId(id);

        const data = response.data.data;
        const modifiedData = data
          .filter((item) => item["is_active"] === 1)
          .map((item) => {
            return item.id;
          });
        return modifiedData;
      },
      {
        enabled: !!id,
        refetchOnWindowFocus: false,
      }
    );

    const {
      data: adminPermission = {
        id,
        name: "",
      },
      isLoading: isAdminPermissionLoading,
      error: adminPermissionError,
    } = useQuery(
      ["adminPermission", id],
      async () => {
        const response = await serviceAuth.getAdminPermissionsByPermissionId(id);

        const data = response.data.data;
        return {
          id: data.id,
          name: data.name,
        };
      },
      {
        enabled: !!id,
        refetchOnWindowFocus: false,
      }
    );

    const invalidateAdminEndpoints = () => {
      queryClient.invalidateQueries("adminEndpoints");

      // this is because if the data didnt change to trigger useEffectAgain
      queryClient.setQueryData(["adminActiveEndpoints", id], []);
      queryClient.invalidateQueries(["adminActiveEndpoints", id]);

      queryClient.invalidateQueries(["adminPermission", id]);
    };

    const combinedError =
      errorMessage || adminEndpointsError || adminPermissionError || adminActiveEndpointsPermissionError;
    const combinedLoading = isAdminPermissionLoading || isLoadingEndpoints || isAdminActiveEndpointsLoading;

    const combinedData = {
      adminPermission,
      adminEndpoints,
      activeEndpointIds: adminActiveEndpoints,
    };

    return (
      <Component
        data={combinedData}
        isLoading={combinedLoading}
        handleRefreshData={invalidateAdminEndpoints}
        error={combinedError}
      />
    );
  };
  SiteConfigurationMapPermissionToEndpoints.displayName = "SiteConfigurationMapPermissionToEndpoints";
  SiteConfigurationMapPermissionToEndpoints.propTypes = {};
  return SiteConfigurationMapPermissionToEndpoints;
};

export default withSiteConfigurationPermissionMapToEndpoints;
