import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Grid, Box, Button, icons, styled } from "syklone/ui/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";
import { useQueryClient } from "syklone/libraries/index.js";

// imports: local
import { WidgetTitle, WidgetContainer, WidgetMachineItem } from "../../widgets/index.js";
import { DialogToggleActivation, DialogMachine } from "../../dialogs/index.js";
import { QuickSearchToolbar, useSearchableData } from "../../tables/components/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

const CustomGrid = styled(Grid)({
  width: "100%",
});

const CustomGridList = styled(Grid)({
  background: "#000",
  padding: "20px",
  borderRadius: "6px",
  marginTop: "20px",
  width: "100%",
});

const CustomBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const ButtonWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
});

function PageMachineList({ data, isLoading, handleRefreshData, error, optionsData }) {
  const apiInstance = useApiContext();
  const { showSnackbar } = useAlertSnackbar();
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const { searchText, handleSearchChange, clearSearch, filteredData } = useSearchableData(data, ["name"]);
  const dialogMachineRef = React.useRef(null);
  const dialogActivationRef = React.useRef(null);
  const { checkElementVisibility } = usePermissionChecks();
  const queryClient = useQueryClient();

  const onClickAddMachine = () => {
    setIsDialogOpenFn(true);
    setDialogEditDataFn(null);
  };

  const setIsDialogOpenFn = (val) => {
    dialogMachineRef.current?.setIsDialogOpen(val);
  };

  const setDialogEditMachineDataFn = (val) => {
    dialogActivationRef.current?.setDialogData(val);
  };

  const setDialogEditDataFn = (val) => {
    dialogMachineRef.current?.setDialogData(val);
  };

  const handleDialogSignatureOpen = (value) => {
    dialogActivationRef.current?.setIsDialogOpen(value);
  };

  const setDialogEditIsSubmitting = (value) => {
    dialogActivationRef.current?.setIsSubmitting(value);
  };

  const setDialogResponseErrorMessage = (text) => {
    dialogMachineRef.current?.setResponseMessage({ type: "error", text });
  };

  const handleMachineStatusChange = async (dialogEditData) => {
    const id = dialogEditData?.id;
    if (id) {
      try {
        setDialogEditIsSubmitting(true);
        const isActive = dialogEditData?.isActive;
        let response;
        if (isActive) {
          response = await serviceAuth.postAdminEquipmentDectivateByEquipmentAccountId(id);
        } else {
          response = await serviceAuth.postAdminEquipmentActivateByEquipmentAccountId(id);
        }
        const responseMessage = response?.message ? response.message : "Status was successfully updated!";
        showSnackbar(responseMessage, "success");
        handleDialogSignatureOpen(false);
        handleRefreshData && handleRefreshData();
      } catch (e) {
        const message = typeof e?.detail === "string" ? e.detail : "Status was not successfully updated!";
        showSnackbar(message, "error");
        handleDialogSignatureOpen(false);
      } finally {
        setDialogEditIsSubmitting(false);
      }
    } else {
      showSnackbar("Status was not successfully updated!", "error");
      handleDialogSignatureOpen(false);
    }
  };

  const handleDialogMachineEdit = async (id, payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.putAdminEquipmentByEquipmentAccountId(id, payload);
      const responseMessage = response?.data?.message ? response.data.message : "Machine successfully updated";
      queryClient.invalidateQueries("equipment");
      setIsDialogOpenFn(false);
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Machine was not successfully updated!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  const handleDialogEquipmentCreate = async (payload) => {
    try {
      const response = await serviceAuth.postAdminEquipment(payload);
      queryClient.invalidateQueries("equipment");
      setIsDialogOpenFn(false);
      const responseMessage = response.message ? response.message : "Machine successfully created";
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = e?.detail ? e.detail : "Machine was not successfully updated!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  return (
    <>
      <Grid container rowSpacing={3}>
        <CustomGrid item>
          <CustomBox>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Box sx={{ marginRight: "20px" }}>
                <WidgetTitle title="Machines" subTitle="List of available machines" isBackButton={true} />
              </Box>
              <QuickSearchToolbar
                value={searchText}
                onChange={(event) => handleSearchChange(event.target.value)}
                clearSearch={clearSearch}
                sx={{ display: "flex" }}
              />
            </Box>
            <ButtonWrapper>
              <Button
                id="add-machine-button"
                variant="contained"
                disabled={isLoading || !checkElementVisibility(PermissionVisualElements.machinesCreateMachine)}
                onClick={onClickAddMachine}
                startIcon={<icons.mui.PrecisionManufacturing fontSize="small" />}
              >
                Add machine
              </Button>
            </ButtonWrapper>
          </CustomBox>
        </CustomGrid>

        <CustomGridList item>
          <WidgetContainer
            isLoading={isLoading}
            error={error}
            isTableDataEmpty={!Array.isArray(filteredData) || filteredData.length === 0}
          >
            <Grid container rowSpacing={1}>
              {filteredData.map((item) => (
                <Grid xs={12} item key={item.id}>
                  <WidgetMachineItem
                    data={item}
                    setters={{
                      setDialogEditDataFn: setDialogEditDataFn,
                      setIsDialogEditEquipmentOpenFn: setIsDialogOpenFn,
                      setIsDialogDeleteEquipmentOpenFn: handleDialogSignatureOpen,
                      setDialogEditMachineDataFn: setDialogEditMachineDataFn,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </WidgetContainer>
        </CustomGridList>
      </Grid>

      <DialogMachine
        ref={dialogMachineRef}
        optionsData={optionsData}
        onDialogEdit={handleDialogMachineEdit}
        onDialogCreate={handleDialogEquipmentCreate}
      />
      <DialogToggleActivation
        ref={dialogActivationRef}
        toggleActionName="machine"
        onConfirm={handleMachineStatusChange}
      />
    </>
  );
}

PageMachineList.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  handleRefreshData: PropTypes.func,
  error: PropTypes.object,
  optionsData: PropTypes.shape({
    locations: PropTypes.array.isRequired,
    machineDefinitions: PropTypes.array.isRequired,
  }).isRequired,
};

export default PageMachineList;
