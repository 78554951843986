import React, { useEffect } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";

// imports: syklone
import { Box, Typography, keyframes } from "syklone/ui/index.js";

// imports: local
import * as img from "../../img/index.js";
import { WidgetAuthButtons, WidgetSuiteVersion } from "../../widgets/index.js";

const transitionGlobe = { duration: 3, ease: "easeOut" };
const transition = { duration: 1, ease: "anticipate" };
const underlineAnimation = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

function PageGfLandingDefault() {
  const [pages, active, setActive] = useOutletContext();

  useEffect(() => {
    setActive(pages[0].id);
  });

  return (
    <>
      <Box sx={styles.imgBox}>
        <motion.img
          key="sphere123"
          style={styles.sphere}
          initial={{
            scale: 0.9,
            opacity: 0,
            rotate: -5,
            top: "50%",
            left: "50%",
          }}
          animate={{ scale: 1, opacity: 1, rotate: 0 }}
          exit="exit"
          transition={transitionGlobe}
          src={img.Sphere}
          alt="Sphere"
        />
      </Box>
      <Box sx={styles.mainContentWrapper}>
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, ...transition }}
        >
          <Typography sx={{ fontSize: 18 }}>Powered by Amagine ®</Typography>
        </Box>
        <Box
          component={motion.div}
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={transition}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={(theme) => ({
              fontSize: 59,
              fontWeight: 800,
              [theme.breakpoints.down("md")]: { fontSize: "2.9rem" },
            })}
          >
            <Box component="span" sx={styles.mainTitleUnderline}>
              Syklone
            </Box>{" "}
            Additive Manufacturing EcoSystem
          </Typography>
        </Box>
        <Box sx={styles.mainCtrl}>
          <motion.ul
            style={styles.ctrlList}
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, ...transition }}
          >
            {pages.map((item) => {
              if (item.visible) {
                return (
                  <Box
                    component="li"
                    sx={{
                      ...styles.ctrlListItem,
                      ...styles.readMore,
                      ...(active === item.id && styles.active),
                    }}
                    key={item.id}
                  >
                    <Link to={item.to} onClick={() => setActive(item.id)} id={item.id}>
                      {item.title}
                    </Link>
                  </Box>
                );
              }
            })}
          </motion.ul>
        </Box>
        <motion.div
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7, ...transition }}
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "230px" }}>
            <WidgetAuthButtons />
          </Box>
          <WidgetSuiteVersion containerStyle={{ marginTop: "15px" }} preText="Version: " />
          {/*  <WidgetButtons onClickLogin={onClickLogin} onClickRegister={onClickRegister} align="center" />  */}
        </motion.div>
      </Box>
    </>
  );
}

const styles = {
  mainContentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxWidth: 960,
    transform: "translateY(30px)",
    zIndex: 2,
  },
  mainCtrl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ctrlList: {
    display: "flex",
    listStyle: "none",
    gap: "50px",
    paddingLeft: 0,
  },
  ctrlListItem: {
    display: "flex",
  },
  mainTitleUnderline: {
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: -2,
      height: 2,
      width: "0px",
      backgroundColor: "#ffb500",
      animation: `${underlineAnimation} 300ms forwards ease-in-out`,
      animationDelay: "500ms",
    },
  },
  "@keyframes underline": {
    "0%": {
      width: 0,
    },
    "100%": {
      width: "100%",
    },
  },
  readMore: {
    "& > a": {
      fontSize: 18,
      backgroundImage: "linear-gradient(to right, #feb400, #feb400 50%, #fff 50%)",
      backgroundSize: "200% 100%",
      backgroundPosition: "-100%",
      display: "inline-block",
      padding: "5px 0px",
      position: "relative",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      transition: "all 0.3s ease-in-out",
      opacity: 0.4,
    },
    "& > a::before": {
      content: "''",
      background: "#feb400",
      display: "block",
      position: "absolute",
      bottom: "-1px",
      left: "0px",
      width: "0px",
      height: "2px",
      transition: "all 0.3s ease-in-out",
    },
    "& > a:hover": {
      backgroundPosition: 0,
      opacity: 1,
    },
    "& > a:hover::before": {
      width: "100%",
    },
  },
  imgBox: {
    position: "absolute",
    zIndex: 1,
    overflow: "hidden",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  sphere: {
    maxWidth: "863px",
    maxHeight: "810px",
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
};

export default PageGfLandingDefault;
