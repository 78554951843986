import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, icons, styled, Typography } from "syklone/ui/index.js";
import { moment } from "syklone/libraries/index.js";

// imports: local
import { WidgetOverflowTip } from "../../../widgets/index.js";

const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: 10,
  gap: 10,
  "&:hover": {
    backgroundColor: theme.palette.background.default,
    borderRadius: 3,
  },
}));

const BoxIcon = styled(({ type, ...rest }) => <Box {...rest} />)(({ theme, type }) => {
  let color = "";
  switch (type.toLowerCase()) {
    case "success":
      color = theme.palette.success.main;
      break;
    case "warning":
      color = theme.palette.warning.dark;
      break;
    case "error":
      color = theme.palette.error.main;
      break;
    case "info":
      color = theme.palette.grey[800];
      break;
    default:
      return "No type provided or does not exist.";
  }
  return {
    position: "relative",
    width: 54,
    height: 54,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: color,
  };
});

const BoxNotificationText = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 8,
});

const BoxSeparator = styled(Box)(({ theme }) => ({
  width: 1,
  height: 13,
  backgroundColor: theme.palette.mode === "dark" ? "#434343" : "#EEEEEE",
}));

const BoxNotif = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  width: 16,
  height: 16,
  borderRadius: "50%",
  backgroundColor: "#0F6FB5",
  border: theme.palette.mode === "dark" ? "4px solid #252525" : "4px solid #FFF",
}));

const createDate = (str) => {
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

const createIcon = (type) => {
  switch (type.toLowerCase()) {
    case "success":
      return <icons.mui.CheckCircle sx={{ color: "#FFF" }} />;
    case "warning":
      return <icons.mui.Error sx={{ color: "#FFF" }} />;
    case "error":
      return <icons.mui.Cancel sx={{ color: "#FFF" }} />;
    case "info":
      return <icons.mui.Info sx={{ color: "#FFF" }} />;
    default:
      return "No type provided or does not exist.";
  }
};

function WidgetSystemAlertsItem({ data }) {
  return (
    <BoxWrapper onClick={() => console.log("Read full message")} data-syklone="notifications-item">
      <BoxIcon type={data.type}>
        {createIcon(data.type)}
        {data.status && <BoxNotif></BoxNotif>}
      </BoxIcon>
      <Box>
        <Box>
          <Typography sx={{ width: 345, fontWeight: data.status ? "bold" : "normal" }}>
            <WidgetOverflowTip>{data.message}</WidgetOverflowTip>
          </Typography>
        </Box>
        <BoxNotificationText>
          <Box>{createDate(data.date)}</Box>
          <BoxSeparator></BoxSeparator>
          <Box>
            <Typography sx={{ width: 220 }}>
              <WidgetOverflowTip>{data.service}</WidgetOverflowTip>
            </Typography>
          </Box>
        </BoxNotificationText>
      </Box>
    </BoxWrapper>
  );
}

WidgetSystemAlertsItem.propTypes = {
  data: PropTypes.object,
};

export default WidgetSystemAlertsItem;
