import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Alert, Box, Stack, Button } from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";

function PageAdmin() {
  // -------- Reactjs state management --------
  const [message, setMessage] = useState("");
  const apiInstance = useApiContext();

  const resetBuildFiles = async () => {
    try {
      let response = await apiInstance.prdComplianceModule.ApiBuildFiles.deleteReset();
      setMessage(response.data.message);
    } catch (error) {
      alert(`${error}`);
    }
  };

  const generateBuildFiles = async () => {
    try {
      let response = await apiInstance.prdComplianceModule.ApiBuildFiles.postGenerate();
      setMessage(response.data.message);
    } catch (error) {
      alert(`${error}`);
    }
  };

  // -------- Reactjs render --------
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        {message !== "" && (
          <Box sx={{ margin: "20px 0px" }}>
            <Alert variant="filled" severity="success">
              {message || "Files generated"}
            </Alert>
          </Box>
        )}
        <Stack spacing={2} direction="row">
          <Button onClick={resetBuildFiles} variant="contained" color="error">
            Reset build files
          </Button>
          <Button onClick={generateBuildFiles} variant="contained" color="success">
            Generate build files
          </Button>
        </Stack>
      </Box>
    </>
  );
}

PageAdmin.propTypes = {
  classes: PropTypes.object,
  navigate: PropTypes.func,
  location: PropTypes.object,
};

export default PageAdmin;
