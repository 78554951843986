import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { arrayBufferBase64, addbase64Flag } from "syklone/common/utils.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import RecipeItemContainer from "./recipe_item_container.jsx";

const useSnapshotFetching = (blobPath) => {
  const apiInstance = useApiContext();
  const [image, setImage] = useState(null);
  const [isImageLoading, setIsImageLoading] = useState(false);

  useEffect(() => {
    const getSnapshot = async () => {
      setIsImageLoading(true);
      try {
        const responseSnapshot = await apiInstance.sykloneApi.serviceSnapshots.getBlob(blobPath.slice(0, -4));
        const imageStr = addbase64Flag(arrayBufferBase64(responseSnapshot.data));
        setImage(imageStr);
      } catch (error) {
        console.error("Error fetching snapshot:", error);
        setImage(null);
      }
      setIsImageLoading(false);
    };
    getSnapshot();
  }, [apiInstance, blobPath]);

  return { image, isImageLoading };
};

const RecipeItem = ({ snapshot }) => {
  const { image, isImageLoading } = useSnapshotFetching(snapshot.blobPath);

  const enhancedSnapshot = {
    ...snapshot,
    image,
    isImageLoading,
  };

  return <RecipeItemContainer snapshot={enhancedSnapshot} />;
};

RecipeItem.propTypes = {
  snapshot: PropTypes.object.isRequired,
};

export default RecipeItem;
