// TODO: Move this file to syklone/js
import { BUILD_FILES_PROMOTION_TAB_VALUES } from "./constants.js";

export class BuildFilesFetcher {
  constructor(type, apiInstance) {
    this.setType(type);
    this._apiInstance = apiInstance;
  }

  getType() {
    return this._type;
  }

  setType(type) {
    if (!BUILD_FILES_PROMOTION_TAB_VALUES.includes(type)) {
      throw Error(`Unrecognized build file fetcher '${type}'`);
    }

    this._type = type;
  }

  async getIdBuildFile(buildFileId) {
    let type = this.getType();
    switch (type) {
      case BUILD_FILES_PROMOTION_TAB_VALUES[0]:
        return await this._apiInstance.sykloneApi.serviceBfm.getResearchAndDevelopmentByBuildFileId(buildFileId);
      case BUILD_FILES_PROMOTION_TAB_VALUES[1]:
        return await this._apiInstance.sykloneApi.serviceBfm.getUnderReviewByBuildFileId(buildFileId);
      case BUILD_FILES_PROMOTION_TAB_VALUES[2]:
        return await this._apiInstance.sykloneApi.serviceBfm.getReleasedByBuildFileId(buildFileId);
      case BUILD_FILES_PROMOTION_TAB_VALUES[3]:
        return await this._apiInstance.sykloneApi.serviceBfm.getUnderReviewByBuildFileId(buildFileId);
      case BUILD_FILES_PROMOTION_TAB_VALUES[4]:
        return await this._apiInstance.sykloneApi.serviceBfm.getReleasedByBuildFileId(buildFileId);
      default:
        return null;
    }
  }

  async getDataFromBuildFile(buildFileId) {
    let response = await this.getIdBuildFile(buildFileId);
    return response;
  }

  async getPartIdsFromBuildFile(buildFileId) {
    let response = await this.getIdBuildFile(buildFileId);
    return response.partSession.id;
  }

  async getPlatformIdFromBuildFile(buildFileId, index = 0) {
    const response = await this.getIdBuildFile(buildFileId);
    return response?.platforms?.[index]?.platformSession?.id || null;
  }

  async getPlatformList(buildFileId) {
    const response = await this.getIdBuildFile(buildFileId);
    return response?.platforms || null;
  }

  async getPartsFromBuildFile(buildFileId) {
    let response = await this.getIdBuildFile(buildFileId);
    let parts = [];
    for (let partId of response.data.parts) {
      let blobPart = await this._apiInstance.serviceParts.getBlob(partId);
      let state = JSON.parse(new TextDecoder().decode(blobPart.data));
      parts.push(state);
    }

    return parts;
  }

  async getPlatformFromBuildFile(buildFileId) {
    let response = await this.getIdBuildFile(buildFileId);
    const platformId = response.data.platform;
    let blobPlatform = await this._apiInstance.servicePlatforms.getBlob(platformId);
    return JSON.parse(new TextDecoder().decode(blobPlatform.data));
  }
}
