import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Button } from "syklone/ui/index.js";

function WidgetButtons({ align, onClickLogin, onClickRegister }) {
  return (
    <Box sx={{ ...styles.buttonBox, justifyContent: align }}>
      <Button
        size="large"
        variant="contained"
        onClick={onClickLogin}
        sx={{
          backgroundColor: "#fff",
          color: "#000",
          "&:hover": {
            backgroundColor: "#FFB500",
          },
        }}
      >
        Sign in
      </Button>

      <Button
        size="large"
        variant="outlined"
        onClick={onClickRegister}
        sx={{
          color: "#fff",
          border: "1px solid rgba(255, 255, 255, .5)",
          "&:hover": {
            backgroundColor: "transparent",
            border: "1px solid rgba(255, 255, 255, 1)",
          },
        }}
      >
        Register
      </Button>
    </Box>
  );
}

WidgetButtons.propTypes = {
  align: PropTypes.string,
  onClickLogin: PropTypes.func,
  onClickRegister: PropTypes.func,
};

const styles = {
  buttonBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
};

export default WidgetButtons;
