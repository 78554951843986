import React from "react";
import { Particles } from "react-tsparticles";
import { loadFull } from "tsparticles";

const WidgetSpace = () => {
  const particlesInit = React.useCallback((main) => {
    loadFull(main);
  }, []);

  return (
    <Particles
      id="syklone-particles"
      init={particlesInit}
      options={{
        particles: {
          number: {
            value: 30,
            density: {
              enable: true,
              valueArea: 100,
            },
          },
          color: {
            value: "#ffffff",
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacityMin: 0,
              sync: false,
            },
          },
          size: {
            value: 2,
            random: true,
            anim: {
              enable: true,
              speed: 4,
              sizeMin: 0.3,
              sync: false,
            },
          },
          lineLinked: {
            enable: true,
            distance: 150,
            color: "#696983",
            opacity: 0.1,
            width: 1,
          },
          move: {
            enable: true,
            speed: 0.17,
            direction: "none",
            random: true,
            straight: false,
            outMode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 600,
            },
          },
        },
        interactivity: {
          detectOn: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "bubble",
            },
            onclick: {
              enable: false,
              mode: "repulse",
            },
            resize: false,
          },
          modes: {
            grab: {
              distance: 400,
              lineLinked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 250,
              size: 0,
              duration: 2,
              opacity: 0,
              speed: 3,
            },
            repulse: {
              distance: 400,
              duration: 0.4,
            },
            push: {
              particlesNb: 4,
            },
            remove: {
              particlesNb: 2,
            },
          },
        },
        retinaDetect: true,
      }}
    />
  );
};

export default WidgetSpace;
