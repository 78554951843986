import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// imports: syklone
import { Box, CircularProgress, icons, Typography, Container } from "syklone/ui/index.js";
import { useAuthContext } from "syklone/api/react/index.js";
import { moment } from "syklone/libraries/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import {
  WidgetAuditTrailModifiedRecords,
  WidgetAuditTrailPagePdf,
  WidgetButtonGeneratePdf,
} from "../../widgets/index.js";
import { DialogNotification } from "../../dialogs/index.js";

const TABLE_HEADER_ITEMS = [
  "Action",
  "Type",
  "Electronic record",
  "Location",
  "Completed by",
  "\u200B",
  "Completed on",
];

const createDate = (str) => {
  if (!str) {
    return "No date";
  }
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

function PageAuditTrailAtDetails({ auditTrailData, isLoadingAuditTrailData, auditTrailError, activeAuditTrailId }) {
  const authInstance = useAuthContext();
  const { showSnackbar } = useAlertSnackbar();
  const username = authInstance.user.name;
  return (
    <>
      <Container
        sx={{
          position: "relative",
          top: "55px",
          width: 1200,
          maxWidth: "1200px!important",
        }}
        fixed
      >
        <Box sx={styles.topToolBar}>
          <Box sx={styles.backToPreviousPage}>
            <Box component={Link} sx={styles.backLink} to="/audit" data-syklone="back-to-results">
              <icons.mui.ArrowBack />
              Back to results
            </Box>
          </Box>
          <Box>
            {auditTrailData && auditTrailData.length !== 0 && !auditTrailError && (
              <WidgetButtonGeneratePdf
                onError={() => {
                  showSnackbar("Error generating pdf", "error");
                }}
                componentType={WidgetAuditTrailPagePdf}
                componentProps={{
                  data: auditTrailData,
                  selectionCritera: {},
                  username: username,
                  pageDetails: true,
                }}
              />
            )}
          </Box>
        </Box>
        {auditTrailError && <DialogNotification alert={auditTrailError} />}
        {isLoadingAuditTrailData && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        <Box sx={styles.auditNodeDetails}>
          {auditTrailData.map((item, index) => {
            const itemId = (item.id || "").toString();
            console.log(item);
            return (
              <Box
                key={item.id || index}
                data-id={item.id}
                sx={styles.auditNodeDetail}
                data-syklone="audit-trail-details-row"
              >
                <Box sx={styles.auditNodeDetailsLeft}>
                  <Box sx={styles.auditNodeVersion}>
                    <Typography
                      sx={{
                        ...styles.auditNodeVersion,
                        fontSize: 10,
                        fontWeight: 700,
                      }}
                    >
                      Version
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      ...styles.auditNodeVersionNumber,
                      ...(activeAuditTrailId === itemId && styles.active),
                    }}
                    data-syklone={activeAuditTrailId === itemId ? "version active" : "version"}
                  >
                    {auditTrailData && auditTrailData.length !== 0 ? auditTrailData.length - index : 0}
                  </Box>
                </Box>
                <Box sx={styles.auditNodeDetailsRight}>
                  <Box data-syklone="audit-trail-list-item-top" sx={styles.auditNodeDetailsRow}>
                    <Box sx={styles.auditNodeDetailsRowLeft}>
                      <Box sx={styles.auditNodeDetailsRowLeftKey}>
                        <ul data-syklone="audit-trail-keys-container">
                          {TABLE_HEADER_ITEMS.map((key, idx) => (
                            <li key={idx}>{key}</li>
                          ))}
                        </ul>
                      </Box>
                      <Box sx={styles.auditNodeDetailsRowLeftValue}>
                        <ul data-syklone="audit-trail-values-container">
                          <li>{item.action}</li>
                          <li>{item.electronicRecordType.name}</li>
                          <li>{item.electronicRecord}</li>
                          <li>{item.location}</li>
                          <li>{item?.completedBy?.name}</li>
                          <li>{item?.completedBy?.email}</li>
                          <li>{createDate(item.completedOn)}</li>
                        </ul>
                      </Box>
                    </Box>
                    <Box data-syklone="audit-trail-electronic-signature" sx={styles.auditNodeDetailsRowRight}>
                      {item.electronicSignature && (
                        <>
                          <Box sx={styles.recordTitle}>
                            <icons.mui.Assignment fontSize="small" sx={{ color: "#44B700" }} />
                            Electronic Signature
                          </Box>
                          <ul>
                            <li>
                              <Box sx={styles.recordLabel}>Name</Box>
                              <Box>{`${item.electronicSignature?.name} / ${item.electronicSignature?.email}`}</Box>
                            </li>
                            {item.electronicSignature?.reason && (
                              <li>
                                <Box sx={styles.recordLabel}>Reason</Box>
                                <Box>{item.electronicSignature.reason}</Box>
                              </li>
                            )}
                            <li>
                              <Box sx={styles.recordLabel}>Date</Box>
                              <Box>{createDate(item.electronicSignature?.date)}</Box>
                            </li>
                          </ul>
                        </>
                      )}
                    </Box>
                  </Box>
                  <WidgetAuditTrailModifiedRecords listItemWidth={135} item={item} />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Container>
    </>
  );
}

PageAuditTrailAtDetails.propTypes = {
  auditTrailData: PropTypes.array,
  isLoadingAuditTrailData: PropTypes.bool,
  auditTrailError: PropTypes.object,
  activeAuditTrailId: PropTypes.string,
};

const styles = {
  topToolBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 0px",
  },
  auditNodeDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  auditNodeDetail: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  auditNodeDetailsLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
  },
  auditNodeVersion: {
    textTransform: "uppercase",
    color: (theme) => (theme.palette.mode === "dark" ? "#BBBBBB" : "#555555"),
  },
  auditNodeVersionNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    fontWeight: 800,
    padding: "15px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    border: "1px solid #FFB500",
  },
  active: {
    background: "#FFB500",
    color: "#000",
  },
  auditNodeDetailsRow: {
    display: "flex",
    borderBottom: (theme) => (theme.palette.mode === "dark" ? "1px solid #404040" : "1px solid #E2E2E2"),
  },
  auditNodeDetailsRowLeft: {
    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    "& ul > li": {
      marginBottom: "5px",
    },
    display: "flex",
    alignItems: "flex-start",
    gap: "14px",
    padding: "18px",
    width: "50%",
    borderRight: (theme) => (theme.palette.mode === "dark" ? "1px solid #404040" : "1px solid #E2E2E2"),
  },
  auditNodeDetailsRowLeftValue: {
    paddingLeft: "14px",
    borderLeft: (theme) => (theme.palette.mode === "dark" ? "1px solid #404040" : "1px solid #E2E2E2"),
  },
  auditNodeDetailsRowLeftKey: {
    textAlign: "right",
    color: "#878787",
  },
  auditNodeDetailsRight: {
    backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#252525" : "#f0f0f0"),
    borderRadius: "6px",
    width: "100%",
  },
  auditNodeDetailsRowRight: {
    "& ul": {
      listStyle: "none",
      padding: 0,
      margin: 0,
    },
    "& ul > li": {
      marginBottom: "5px",
    },
    padding: "18px",
    width: "50%",
    position: "relative",
  },
  recordTitle: {
    display: "flex",
    position: "absolute",
    top: 13,
    right: 13,
    gap: "3px",
    alignItems: "center",
    fontSize: 12,
    textTransform: "uppercase",
    color: "#878787",
  },
  recordLabel: {
    fontSize: 12,
    fontWeight: 800,
    color: "#878787",
  },
  noRecord: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "13px 0px",
    background: (theme) => (theme.palette.mode === "dark" ? "#313131" : "#E2E2E2"),
    borderRadius: "3px",
    color: "#878787",
    gap: 3,
  },
  backLink: {
    "&:hover": {
      color: "#FFB500",
    },
    display: "flex",
    alignItems: "center",
    gap: "5px",
    textDecoration: "none",
    color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "#000"),
    transition: "all 0.3s",
  },
  pdfButton: {
    width: "150px",
    borderRadius: "3px",
    padding: "8px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    textAlign: "center",
    color: (theme) => (theme.palette.mode === "dark" ? "#fff" : "rgb(0 0 0 / 87%)"),
    fontWeight: 500,
    textTransform: "uppercase",
    fontSize: "0.8rem",
    textDecoration: "none",
    backgroundColor: "#ffb500",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: (theme) => (theme.palette.mode === "dark" ? "#000000" : "#b27e00"),
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
};

export default PageAuditTrailAtDetails;
