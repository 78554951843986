import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { moment } from "syklone/libraries/index.js";
import { Box, Button } from "syklone/ui/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { WidgetAuditTrailModifiedRecords } from "../index.js";

const formatDateforATItem = (date) => {
  console.log(date);
  let d = new Date(date);
  return moment(d).format("DD-MMM-yyyy, HH:mm");
};

function WidgetAuditTrailTableItem({ item }) {
  let navigate = useNavigate();
  const { showSnackbar } = useAlertSnackbar();

  const handleClickMore = () => {
    const { objectId, electronicRecordType, id } = item;
    const contentTypeId = electronicRecordType?.id;
    if (!objectId || !contentTypeId || !id) {
      showSnackbar(
        "There is a problem accessing the audit details. Please try again later or contact support.",
        "error"
      );
      return;
    }
    navigate(`/audit/${contentTypeId}/${objectId}/${id}`);
  };
  console.log(item);
  // -------- Reactjs render --------
  return (
    <>
      <Box sx={styles.tableItemWrapper}>
        <Box sx={styles.rowTop} data-syklone="audit-trail-list-item-top">
          <Box sx={styles.tableItemsBody}>
            <Box sx={styles.tableItem} data-syklone="action">
              {item.action}
            </Box>
            <Box sx={styles.tableItem} data-syklone="type">
              {item.electronicRecordType.name}
            </Box>
            <Box sx={styles.tableItem} data-syklone="electronic-record">
              {item.electronicRecord}
            </Box>
            <Box sx={styles.tableItem} data-syklone="location">
              {item.location}
            </Box>
            <Box sx={styles.tableItem} data-syklone="completed-by">
              {/* 
              <Box>{item.completedBy?.name}</Box>
              <Box sx={styles.smallText}>{item.completedBy?.email}</Box>
              */}
              {item.completedBy ? (
                <>
                  <Box>{item.completedBy.name}</Box>
                  <Box sx={styles.smallText}>{item.completedBy.email}</Box>
                  <Box sx={styles.smallText}>{formatDateforATItem(item.completedBy.date)}</Box>
                </>
              ) : (
                <Box>N/A</Box>
              )}
            </Box>
            <Box sx={styles.tableItem} data-syklone="completed-on">
              {formatDateforATItem(item.completedOn)}
            </Box>
          </Box>
          <Box sx={styles.button}>
            <Button onClick={handleClickMore} variant="contained" data-syklone="audit-trail-more">
              More
            </Button>
          </Box>
        </Box>
        <WidgetAuditTrailModifiedRecords listItemWidth={150} item={item} />
      </Box>
    </>
  );
}

WidgetAuditTrailTableItem.propTypes = {
  item: PropTypes.object,
};

const styles = {
  tableItemWrapper: {
    "&:hover": {
      border: "1px dotted #FFB500",
    },
    display: "flex",
    flexDirection: "column",
    background: (theme) => (theme.palette.mode === "dark" ? "#252525" : "#eeeeee"),
    borderRadius: "6px",
    border: "1px solid transparent",
    transition: "all 0.3s",
  },
  rowTop: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 12px 10px 15px",
    borderBottom: (theme) => (theme.palette.mode === "dark" ? "1px solid #404040" : "1px solid #E2E2E2"),
  },
  rowBottom: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    paddingLeft: "17px",
    paddingRight: "8px",
  },
  tableItemsBody: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontWeight: "bold",
  },
  tableItem: {
    width: 200,
    overflowWrap: "break-word",
    wordWrap: "break-word",
    hyphens: "auto",
  },
  button: {
    display: "flex",
    alignItems: "baseline",
  },
  smallText: {
    fontSize: 12,
    opacity: 0.5,
  },
};

export default WidgetAuditTrailTableItem;
