import React from "react";
import PropTypes from "prop-types";

// import: local
import { DialogCommonConfirmation } from "../index.js";

const DialogCancelBuildFile = React.forwardRef(({ message, title, fn }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const dialogDataRef = React.useRef(null);

  React.useImperativeHandle(ref, () => ({
    setIsDialogOpen: (value) => {
      setIsOpen(value);
    },
  }));

  const closeDialog = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (!isOpen) {
      dialogDataRef.current = null;
    }
  }, [isOpen]);

  return (
    <DialogCommonConfirmation
      contentHeight={50}
      title={title}
      onConfirm={fn}
      onCancel={closeDialog}
      onClose={closeDialog}
      isOpen={isOpen}
    >
      {message}
    </DialogCommonConfirmation>
  );
});

DialogCancelBuildFile.displayName = "DialogCancelBuildFile";

DialogCancelBuildFile.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string,
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  fn: PropTypes.func,
};

export default DialogCancelBuildFile;
