import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";

const DialogResponseMessage = ({ message, widgetName }) => {
  if (!message || !message.text) {
    return null;
  }
  return (
    <Box sx={{ mb: 2, color: message.type === "error" ? "#f44336" : "" }} data-syklone={`${widgetName}-message`}>
      {typeof message?.text === "string" ? message.text : "There was an error, please try again!"}
    </Box>
  );
};

export default DialogResponseMessage;

DialogResponseMessage.propTypes = {
  message: PropTypes.any,
  widgetName: PropTypes.string,
};
