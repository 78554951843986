import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Grid,
  Button,
  Box,
  DialogTitle,
  DialogContent,
  icons,
  IconButton,
  styled,
  Tab,
  Tabs,
} from "syklone/ui/index.js";
import { arrayBufferBase64, addbase64Flag } from "syklone/common/utils.js";
import { useApiContext } from "syklone/api/react/index.js";
import { WidgetLoader } from "syklone/components/widgets/widget_loader/index.js";

// imports: local
import { WidgetMessageViewer } from "../index.js";
import { BuildFilesFetcher } from "../../core/index.js";
import PartCreatorViewerWithApi from "./part_creator_viewer_with_api.jsx";
import CustomDialog from "./custom_dialog.jsx";
import RecipeItem from "./recipe_item.jsx";
import DataTable from "./data_table.jsx";

const ViewerBox = styled(Box)({
  display: "flex",
  position: "relative",
  height: "650px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  borderRadius: "6px",
});

const ViewerBoxImg = styled("img")({
  maxWidth: "100%",
  maxHeight: "650px",
});

const ViewerBoxButton = styled(Button)({
  position: "absolute",
  left: "16px",
  bottom: "16px",
  color: "#000",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`part-viewer-tabpanel-${index}`}
      aria-labelledby={`part-viewer-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 3, pb: 0, pl: 3, pr: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function accessibilityProps(index) {
  return {
    id: `part-viewer-tab-${index}`,
    "aria-controls": `part-viewer-tabpanel-${index}`,
  };
}

function WidgetPartViewerContainer({ buildFileId, buildFileType }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const apiInstance = useApiContext();

  const [value, setValue] = useState(0);
  const [dataCommitted, setDataCommited] = useState([]);
  const [snapshotsPart, setSnapshotsPart] = useState([]);
  const [mainImage, setMainImage] = useState({ image: null, description: "" });

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const getSnapshotsByPartId = React.useCallback(async (id) => {
    const parts = await apiInstance.sykloneApi.servicePartSessions.getById(id);
    return parts.result.snapshots.snapshots;
  }, []);

  useEffect(() => {
    setSnapshotsPart([]);
    setDataCommited([]);
    const loadData = async () => {
      try {
        setIsError(false);
        setIsLoading(true);
        let fetcher = new BuildFilesFetcher(buildFileType, apiInstance);
        let partId = await fetcher.getPartIdsFromBuildFile(buildFileId);

        let response = await apiInstance.sykloneApi.servicePartSessions.getBlob(partId);
        let buffer = response.data;
        let text = new TextDecoder().decode(buffer);
        let state = JSON.parse(text);
        let selectables = state.widgetRender.scene.selectables;
        const modifiedData = selectables.map((item, index) => ({
          id: index + 1,
          component: item.name,
          type: item.type,
          origin: item.creationParams.origin,
          filename: item.creationParams.filename,
          areas: `${item.materials[0].name} - ${item.materials[0].buildParams.family} - ${item.materials[0].buildParams.paramSet}`,
        }));
        setDataCommited(modifiedData);

        let snapshotsPart = await getSnapshotsByPartId(partId);
        setSnapshotsPart(snapshotsPart);
        const blobName = snapshotsPart[0].blobPath.slice(0, -4);
        const responseSnapshot = await apiInstance.sykloneApi.serviceSnapshots.getBlob(blobName);
        const imageStr = addbase64Flag(arrayBufferBase64(responseSnapshot.data));
        setMainImage({ image: imageStr, description: snapshotsPart[0].description });
      } catch (error) {
        setIsError(true);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, []);

  return (
    <Grid container direction="column">
      <CustomDialog
        open={dialogOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        data-syklone="3d-part-viewer-dialog"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          3D Part viewer
          <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
            <icons.mui.Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PartCreatorViewerWithApi
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 500,
            }}
            buildFileId={buildFileId}
            buildFileType={buildFileType}
          />
        </DialogContent>
      </CustomDialog>
      <Grid sx={{ padding: "0px 20px 15px 20px" }} item>
        {isError ? (
          <WidgetMessageViewer centered>Error fetching data</WidgetMessageViewer>
        ) : (
          <ViewerBox>
            {isLoading ? (
              <WidgetLoader
                style={{
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 999999,
                }}
              />
            ) : (
              <>
                <ViewerBoxImg alt={mainImage.description} src={mainImage.image} />
                <ViewerBoxButton
                  startIcon={<icons.mui.ViewInAr />}
                  variant="contained"
                  onClick={handleClickOpen}
                  size="large"
                  data-syklone="3d-part-viewer-button"
                >
                  Open 3D viewer
                </ViewerBoxButton>
              </>
            )}
          </ViewerBox>
        )}
      </Grid>
      <Grid item>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="3d Viewer Part Tabs" centered>
              <Tab label="Commit info" {...accessibilityProps(0)} />
              <Tab label="Recipe" {...accessibilityProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            {isError ? (
              <WidgetMessageViewer>Error fetching data</WidgetMessageViewer>
            ) : isLoading ? (
              <WidgetMessageViewer>Loading....</WidgetMessageViewer>
            ) : (
              <DataTable dataCommitted={dataCommitted} />
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid container sx={{ width: "100%" }} spacing={2}>
              {snapshotsPart.map((snapshot, index) => {
                return <RecipeItem key={`${snapshot.blobPath}-${index}`} snapshot={snapshot} />;
              })}
            </Grid>
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
}

WidgetPartViewerContainer.propTypes = {
  buildFileId: PropTypes.string,
  buildFileType: PropTypes.string,
};

export default WidgetPartViewerContainer;
