import React from "react";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

const withSiteConfigurationEndpoints = (Component) => {
  const SiteConfigurationEndpoints = () => {
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const {
      data: adminEndpoints = [],
      isLoading: isLoadingEndpoints,
      error: adminEndpointsError,
    } = useQuery(["adminEndpoints"], async () => {
      const response = await serviceAuth.getAdminEndpoints();
      const data = response.data.data;
      const modifiedData = data.map((item) => {
        return {
          id: item.id,
          patternName: item["pattern_name"],
          isActive: !!item["is_active"],
          description: item.description ? item.description : "",
          isVisible: !!item["visible"],
          counter: item.counter,
        };
      });
      return modifiedData;
    });

    const invalidateAdminEndpoints = () => {
      queryClient.invalidateQueries("adminEndpoints");
    };

    return (
      <Component
        data={adminEndpoints}
        isLoading={isLoadingEndpoints}
        handleRefreshData={invalidateAdminEndpoints}
        error={adminEndpointsError}
      />
    );
  };
  SiteConfigurationEndpoints.displayName = "SiteConfigurationEndpoints";
  SiteConfigurationEndpoints.propTypes = {};
  return SiteConfigurationEndpoints;
};

export default withSiteConfigurationEndpoints;
