import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, Grid, icons, Stack } from "syklone/ui/index.js";
import { useApiContext, usePermissionChecks, useAuthContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { WidgetTitle } from "../../widgets/index.js";
import { DialogToggleActivation, DialogGroups } from "../../dialogs/index.js";
import { TableDataGroups } from "../../tables/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

function PageGlobalAdminSiteConfigurationGroups({ data, isLoading, handleRefreshData, error }) {
  const apiInstance = useApiContext();
  const { updateCurrentUserPermissions } = useAuthContext();
  const { showSnackbar } = useAlertSnackbar();
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const dialogActivationRef = React.useRef(null);
  const dialogGroupRef = React.useRef(null);
  const { checkElementVisibility } = usePermissionChecks();

  const setIsDialogOpenFn = (val) => {
    dialogGroupRef.current?.setIsDialogOpen(val);
  };

  const setDialogEditDataFn = (val) => {
    dialogGroupRef.current?.setDialogData(val);
  };

  const setDialogActivationDataFn = (val) => {
    dialogActivationRef.current?.setDialogData(val);
  };

  const setIsDialogDeleteGroupOpenFn = (val) => {
    dialogActivationRef.current?.setIsDialogOpen(val);
  };

  const onClickCreateGroupFn = () => {
    setIsDialogOpenFn(true);
    setDialogEditDataFn(null);
  };

  const setDialogResponseErrorMessage = (text) => {
    dialogGroupRef.current?.setResponseMessage({ type: "error", text });
  };

  const setDialogEditIsSubmitting = (value) => {
    dialogGroupRef.current?.setIsSubmitting(value);
  };

  const setDialogActivationIsSubmitting = (value) => {
    dialogActivationRef.current?.setIsSubmitting(value);
  };

  const handleGroupStatusChange = async (dialogEditData) => {
    const id = dialogEditData?.id;
    if (id) {
      try {
        setDialogActivationIsSubmitting(true);
        const isActive = dialogEditData?.isActive;
        let response;
        if (isActive) {
          response = await serviceAuth.deleteAdminGroupsByGroupId(id);
        } else {
          response = await serviceAuth.postAdminGroupsByGroupId(id);
        }
        updateCurrentUserPermissions();
        const responseMessage = response?.message ? response.message : "Status was successfully updated!";
        showSnackbar(responseMessage, "success");
        handleRefreshData && handleRefreshData();
        setIsDialogDeleteGroupOpenFn(false);
      } catch (e) {
        const responseMessage = typeof e?.detail === "string" ? e.detail : "Status was not successfully updated!";
        showSnackbar(responseMessage, "error");
        setIsDialogDeleteGroupOpenFn(false);
      } finally {
        setDialogActivationIsSubmitting(false);
      }
    } else {
      showSnackbar("Status was not successfully updated!", "error");
    }
  };

  const handleDialogGroupEdit = async (id, payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.putAdminGroupsByGroupId(id, payload);
      const responseMessage = response?.data?.message ? response.data.message : "Group successfully updated";
      setIsDialogOpenFn(false);
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Group was not successfully updated!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  const handleDialogGroupCreate = async (payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.postAdminGroups(payload);
      setIsDialogOpenFn(false);
      const responseMessage = response.message ? response.message : "Group successfully created";
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Group was not successfully created!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  return (
    <>
      <Grid item xs>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <WidgetTitle title="Groups" isBackButton={true} backRoute="/dashboard/admin" />
              </Grid>
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Button
                    id="admin_create_group"
                    variant="contained"
                    disabled={isLoading || !checkElementVisibility(PermissionVisualElements.adminGroupsCreateGroup)}
                    startIcon={<icons.mui.GroupAdd />}
                    onClick={() => onClickCreateGroupFn()}
                    data-syklone="admin-create-group-button"
                  >
                    Create group
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <Grid container>
              <TableDataGroups
                isLoading={isLoading}
                data={data}
                error={error}
                setters={{
                  setDialogEditDataFn: setDialogEditDataFn,
                  setIsDialogEditGroupOpenFn: setIsDialogOpenFn,
                  setDialogActivationDataFn: setDialogActivationDataFn,
                  setIsDialogDeleteGroupOpenFn: setIsDialogDeleteGroupOpenFn,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogGroups
        ref={dialogGroupRef}
        onDialogEditGroup={handleDialogGroupEdit}
        onDialogCreateGroup={handleDialogGroupCreate}
      />
      <DialogToggleActivation
        ref={dialogActivationRef}
        toggleActionName="group"
        onConfirm={handleGroupStatusChange}
      />
    </>
  );
}

PageGlobalAdminSiteConfigurationGroups.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  handleRefreshData: PropTypes.func,
  error: PropTypes.object,
};

export default PageGlobalAdminSiteConfigurationGroups;
