import PropTypes from "prop-types";
import React from "react";
import { Outlet } from "react-router-dom";

// imports: syklone
import { Box, styled } from "syklone/ui/index.js";

// -------- Styled components ---------
const PageWrapper = styled(Box)({
  padding: "25px",
  width: "100%",
});

function Machines(props) {
  return (
    <PageWrapper>
      <Outlet />
    </PageWrapper>
  );
}

Machines.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  navigate: PropTypes.func,
};

export default Machines;
