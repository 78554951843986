import React from "react";
import { useOutletContext } from "react-router-dom";

const withOutletContext = (Component) => {
  const OutletContext = (props) => {
    const contextValue = useOutletContext();
    return <Component {...props} contextValue={contextValue} />;
  };

  OutletContext.displayName = "OutletContext";

  return OutletContext;
};

export default withOutletContext;
