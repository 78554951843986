import React from "react";
import { useParams } from "react-router-dom";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

const withSiteConfigurationGroupMapToPermissions = (Component) => {
  const SiteConfigurationMapGroupToPermissions = () => {
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = React.useState(null);
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();

    React.useEffect(() => {
      if (!id) {
        setErrorMessage("ID is required but was not provided");
      } else {
        setErrorMessage(null);
      }
    }, [id]);

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const {
      data: adminPermissions = [],
      isLoading: isLoadingPermissions,
      error: adminPermissionsError,
    } = useQuery(["adminPermissions"], async () => {
      const response = await serviceAuth.getAdminPermissionsAll();
      const data = response.data.data;
      const modifiedData = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description ? item.description : "",
        };
      });
      return modifiedData;
    });

    const {
      data: adminActivePermissions = [],
      isLoading: isAdminActivePermissionsLoading,
      error: adminActivePermissionsError,
    } = useQuery(
      ["adminActivePermissions", id],
      async () => {
        const response = await serviceAuth.getAdminGroupPermissionsByGroupId(id);

        const data = response.data.data;
        const modifiedData = data
          .filter((item) => item["is_active"] === 1)
          .map((item) => {
            return item.id;
          });
        return modifiedData;
      },

      {
        enabled: !!id,
        refetchOnWindowFocus: false,
      }
    );

    const {
      data: adminGroup = {
        id,
        name: "",
      },
      isLoading: isAdminGroupLoading,
      error: adminGroupError,
    } = useQuery(
      ["adminGroup", id],
      async () => {
        const response = await serviceAuth.getAdminGroupsByGroupId(id);
        const data = response.data.data;
        const modifiedData = {
          id: data.id,
          name: data.name,
        };

        return modifiedData;
      },
      {
        enabled: !!id,
      }
    );

    const invalidateAdminGroups = () => {
      queryClient.invalidateQueries("adminPermissions");

      // this is because if the data didnt change to trigger useEffectAgain
      queryClient.setQueryData(["adminActiveGroups", id], []);
      queryClient.invalidateQueries(["adminActiveGroups", id]);

      queryClient.invalidateQueries(["adminGroup", id]);
    };

    const combinedError = errorMessage || adminPermissionsError || adminGroupError || adminActivePermissionsError;
    const combinedLoading = isAdminGroupLoading || isLoadingPermissions || isAdminActivePermissionsLoading;

    const combinedData = {
      adminGroup,
      adminPermissions,
      activePermissionsIds: adminActivePermissions,
    };

    return (
      <Component
        data={combinedData}
        isLoading={combinedLoading}
        handleRefreshData={invalidateAdminGroups}
        error={combinedError}
      />
    );
  };
  SiteConfigurationMapGroupToPermissions.displayName = "SiteConfigurationMapGroupToPermissions";
  SiteConfigurationMapGroupToPermissions.propTypes = {};
  return SiteConfigurationMapGroupToPermissions;
};

export default withSiteConfigurationGroupMapToPermissions;
