import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Alert } from "syklone/ui/index.js";

const DialogNotification = ({ alert, style }) => {
  if (!alert || !alert.message) {
    return null;
  }
  return (
    <Alert severity={alert.severity || "success"} sx={{ width: "100%", marginBottom: "0.5rem", ...style }}>
      {alert?.message}
    </Alert>
  );
};

DialogNotification.propTypes = {
  alert: PropTypes.object,
  style: PropTypes.object,
};

export default DialogNotification;
