import React from "react";
import { Button, styled, Box } from "syklone/ui";
import PropTypes from "prop-types";

const SaveButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
  width: "100%",
  padding: "10px 0",
});

const SaveDriftButton = ({ onClickSave, disabled }) => (
  <SaveButtonContainer>
    <Button
      data-syklone="dave-drift"
      size="large"
      disabled={disabled}
      variant="outlined"
      onClick={onClickSave}
      sx={{ width: "50px", backgroundColor: "black" }}
    >
      Save
    </Button>
  </SaveButtonContainer>
);

SaveDriftButton.propTypes = {
  onClickSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SaveDriftButton;
