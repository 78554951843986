import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  icons,
  IconButton,
  styled,
  TextField,
} from "syklone/ui/index.js";
import { arrayBufferBase64, addbase64Flag } from "syklone/common/utils.js";
import { WidgetLoader } from "syklone/components/widgets/widget_loader/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { BuildFilesFetcher } from "../../core/index.js";
import { WidgetBfList, WidgetMessageViewer } from "../index.js";
import PlatformCreatorViewerWithApi from "./platform_creator_viewer_with_api.jsx";

const CustomDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    backgroundColor: "#252525",
    backgroundImage: "none",
  },
});

const ViewerBox = styled(Box)({
  display: "flex",
  position: "relative",
  height: "650px",
  justifyContent: "center",
  alignItems: "center",
  background: "#000",
  borderRadius: "6px",
});

const ViewerBoxImg = styled("img")({
  height: "650px",
});

const ViewerBoxButton = styled(Button)({
  position: "absolute",
  left: "1rem",
  bottom: "1rem",
  color: "#000",
  "&:hover": {
    color: "#fff",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
});

const CustomAutocomplete = styled(Autocomplete)({
  position: "absolute",
  bottom: "1rem",
  left: "13rem",
});

function WidgetPlatformViewerContainer({ buildFileId, buildFileType }) {
  const apiInstance = useApiContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mainImage, setMainImage] = useState({ image: null, description: "" });
  const [data, setData] = useState([]);
  const [platformList, setPlatformList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const getBuildFileData = async () => {
    try {
      let fetcher = new BuildFilesFetcher(buildFileType, apiInstance);
      let bfData = await fetcher.getDataFromBuildFile(buildFileId);
      setData([bfData]);
    } catch (error) {
      console.log(error.response);
    }
  };

  const getSnapshotsByPlatformId = React.useCallback(async (id) => {
    const platforms = await apiInstance.sykloneApi.servicePlatformSessions.getById(id);
    return platforms.result.snapshots.snapshots;
  }, []);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const fetchBuildFileData = async (index = 0) => {
    setIsLoading(true);
    let fetcher = new BuildFilesFetcher(buildFileType, apiInstance);
    let platformId = await fetcher.getPlatformIdFromBuildFile(buildFileId, index);
    let snapshotPlatform = await getSnapshotsByPlatformId(platformId);
    let platforms = await fetcher.getPlatformList(buildFileId);
    if (platforms.length > 1) {
      setPlatformList(platforms);
    }
    const blobName = snapshotPlatform[0].blobPath.slice(0, -4);
    const responseSnapshot = await apiInstance.sykloneApi.serviceSnapshots.getBlob(blobName);
    const imageStr = addbase64Flag(arrayBufferBase64(responseSnapshot.data));
    setMainImage({ image: imageStr, description: snapshotPlatform[index].description });
    setIsLoading(false);
  };

  const sessionIds = platformList.map((item, index) => ({
    id: item.platformSession?.id,
    index: index,
    label: `${item.platformSession?.id}`,
  }));

  const handleAutocompleteChange = (_event, newValue) => {
    setSelectedOption(newValue);
    if (newValue) {
      fetchBuildFileData(newValue.index);
    } else {
      console.log("No selection");
    }
  };

  const isOptionEqualToValue = (option, value) => option.id === value.id;

  useEffect(() => {
    const fetchData = async () => {
      getBuildFileData();
      try {
        setIsLoading(true);
        setIsError(false);
        fetchBuildFileData();
      } catch (error) {
        console.log(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Grid container direction="column">
      <CustomDialog
        open={dialogOpen}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        data-syklone="3d-platform-viewer-dialog"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          3D Platform viewer
          <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
            <icons.mui.Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PlatformCreatorViewerWithApi
            style={styles.platformViewerWrapper}
            buildFileId={buildFileId}
            buildFileType={buildFileType}
            platformIndex={selectedOption?.index || 0}
          />
        </DialogContent>
      </CustomDialog>

      <Grid sx={{ padding: "0px 20px 15px 20px" }} item>
        {isError ? (
          <WidgetMessageViewer centered>Error fetching data</WidgetMessageViewer>
        ) : (
          <ViewerBox>
            {isLoading ? (
              <WidgetLoader
                style={{
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 999999,
                }}
              />
            ) : (
              <>
                {platformList.length > 1 && (
                  <>
                    <CustomAutocomplete
                      disablePortal
                      disableClearable
                      size="small"
                      options={sessionIds}
                      value={selectedOption || sessionIds[0]}
                      onChange={handleAutocompleteChange}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Select platform" />}
                      style={{ width: 250 }}
                      isOptionEqualToValue={isOptionEqualToValue}
                    />
                  </>
                )}

                <ViewerBoxImg alt={mainImage.description} src={mainImage.image} />
                <ViewerBoxButton
                  startIcon={<icons.mui.ViewInAr />}
                  variant="contained"
                  onClick={handleClickOpen}
                  size="large"
                  data-syklone="3d-platform-viewer-button"
                >
                  Open 3D viewer
                </ViewerBoxButton>
              </>
            )}
          </ViewerBox>
        )}
      </Grid>
      <Grid item>
        <Grid container direction="row">
          <Grid item sx={styles.platformViewerRightCol}>
            <WidgetBfList data={data} type="platform" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
WidgetPlatformViewerContainer.propTypes = {
  buildFileId: PropTypes.string,
  buildFileType: PropTypes.string,
};

const styles = {
  platformViewerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 500,
  },
  platformViewerRightCol: {
    flex: "1",
  },
};

export default WidgetPlatformViewerContainer;
