import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";
import { diff } from "syklone/libraries/index.js";

// imports: local
import { WidgetShowMoreTextToggle } from "../index.js";

const valueFormater = (value) => {
  const notAvailable = "N/A";

  if (value === "" || value === undefined) {
    return notAvailable;
  }

  if (typeof value === "boolean") {
    return value.toString();
  }

  if (typeof value === "object" && value !== null) {
    if (Array.isArray(value)) {
      if (value.length === 0 || (value.length > 0 && typeof value[0] === "string" && value[0].length === 0)) {
        return notAvailable;
      }
    } else {
      if (Object.keys(value).length === 0) {
        return notAvailable;
      }
    }
  } else if (typeof value !== "string") {
    return String(value);
  }

  try {
    return JSON.stringify(value)
      .replace(/]|[[]|{|}|"|"/g, "")
      .split(",")
      .join(", ");
  } catch (e) {
    return notAvailable;
  }
};

const VALUE_TEXT_LENGTH = 85;
function WidgetAuditTrailModifiedRecords({ item }) {
  const newVal = item?.newValues;
  const oldVal = item?.oldValues;

  let isEmpty = (obj) => Object.keys(obj).length === 0;

  let arr =
    isEmpty(oldVal) && isEmpty(newVal)
      ? []
      : Object.keys(oldVal).length !== 0
      ? Object.keys(diff(oldVal, newVal))
      : Object.keys(newVal);

  // -------- Reactjs render --------
  return (
    <Box
      data-syklone="audit-trail-list-item-bottom"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          background: (theme) => (theme.palette.mode === "dark" ? "#313131" : "#E2E2E2"),
          borderRadius: "3px",
          display: "flex",
          width: "100%",
        }}
      >
        <Box
          data-syklone="old-values"
          sx={{
            ...styles.modifiedRecordRow,
            borderRight: (theme) => (theme.palette.mode === "dark" ? "1px solid #404040" : "1px solid #c6c6c6"),
          }}
        >
          <Box
            sx={{
              color: "#DC3131",
              borderRadius: "6px",
              padding: "0px 5px",
              background: (theme) => (theme.palette.mode === "dark" ? "#111" : "#c6c6c6"),
            }}
          >
            Old value
          </Box>
          <Box>
            <Box component="ul" sx={styles.modifiedRecordList}>
              {Object.keys(oldVal).length !== 0 &&
                arr.slice(0, 7).map((item, index) => (
                  <Box component="li" sx={styles.modifiedRecordListItem} key={index}>
                    <Box component="span" sx={styles.modifiedValue}>
                      {item}:{" "}
                    </Box>{" "}
                    <WidgetShowMoreTextToggle
                      maxLength={VALUE_TEXT_LENGTH}
                      text={valueFormater(oldVal[item])}
                      sx={styles.modifiedValueFormater}
                    />
                  </Box>
                ))}
              {Object.keys(oldVal).length === 0 &&
                arr.slice(0, 7).map((item, index) => (
                  <Box component="li" sx={styles.modifiedRecordListItem} key={index}>
                    <Box component="span" sx={styles.modifiedValue}>
                      {item}:
                    </Box>{" "}
                    N/A
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
        <Box data-syklone="new-values" sx={styles.modifiedRecordRow}>
          <Box
            sx={{
              color: "#44B700",
              borderRadius: "6px",
              padding: "0px 5px",
              background: (theme) => (theme.palette.mode === "dark" ? "#111" : "#c6c6c6"),
            }}
          >
            New value
          </Box>
          <Box>
            <Box component="ul" sx={styles.modifiedRecordList}>
              {arr.slice(0, 7).map((item, index) => (
                <Box component="li" sx={styles.modifiedRecordListItem} key={index}>
                  <Box component="span" sx={styles.modifiedValue}>
                    {item}:
                  </Box>{" "}
                  <WidgetShowMoreTextToggle
                    maxLength={VALUE_TEXT_LENGTH}
                    text={valueFormater(newVal[item])}
                    sx={styles.modifiedValueFormater}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

WidgetAuditTrailModifiedRecords.propTypes = {
  item: PropTypes.object,
};

const styles = {
  modifiedRecordRow: {
    display: "flex",
    width: "50%",
    alignItems: "flex-start",
    padding: "5px 13px",
  },
  modifiedRecordList: {
    listStyle: "none",
    margin: "0px",
    padding: "0px",
  },
  modifiedRecordListItem: {
    "& span:hover": {
      opacity: "0.6",
      cursor: "pointer",
    },
    padding: "0px 12px",
    whiteSpace: "break-spaces",
    overflow: "hidden",
    textOverflow: "ellipsis",
    transition: "all 0.3s",
    fontSize: 13,
    display: "flex",
    gap: "3px",
  },
  modifiedValue: {
    color: (theme) => (theme.palette.mode === "dark" ? "#BBBBBB" : "#555555"),
    display: "inline-block",
    minWidth: "105px",
  },
  modifiedValueFormater: {
    display: "inline-block",
    width: "310px",
  },
};

export default WidgetAuditTrailModifiedRecords;
