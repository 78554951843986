import React from "react";
import { useLocation } from "react-router-dom";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { WidgetSliceAnalyser as WidgetSliceAnalyserContainer } from "syklone/graphics/components/index.js";

const WidgetSliceAnalyser = () => {
  const location = useLocation();
  const { buildFileId, buildFileType } = location.state;
  const apiInstance = useApiContext();

  return (
    <div style={{ width: "100%", height: "800px", marginTop: "1rem", marginBottom: "68px", backgroundColor: "red" }}>
      <WidgetSliceAnalyserContainer buildFileData={{ id: buildFileId, type: buildFileType }} api={apiInstance} />
    </div>
  );
};

export default WidgetSliceAnalyser;
