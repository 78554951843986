import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, IconButton, icons, Popover, styled, Typography } from "syklone/ui/index.js";

function convertToLowercaseAndDashes(inputString) {
  let lowercaseString = inputString.toLowerCase();
  let finalString = lowercaseString.replace(/ /g, "-");

  return finalString;
}

const CustomPopover = styled(Popover)(({ theme }) => ({
  "& > .MuiPaper-root": {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.primary.dark : "#fff",
  },
}));

const PopoverMenu = styled("ul")({
  listStyle: "none",
  margin: 0,
  padding: 0,
  minWidth: "120px",
});

const PopoverMenuLink = styled("li")(({ theme, type }) => {
  let color;
  switch (type) {
    case "success":
      color = theme.palette.success.main;
      break;
    case "error":
      color = theme.palette.error.main;
      break;
    case "warning":
      color = theme.palette.warning.main;
      break;
    case "info":
      color = theme.palette.info.main;
      break;
    case "default":
    default:
      color = theme.palette.primary.contrastText;
  }

  return {
    color: color,
    cursor: "pointer",
    padding: "6px 14px",
    borderBottom: theme.palette.mode === "dark" ? "1px solid #2E2E2E" : "1px solid #e1e1e1",
    "&:last-child": {
      borderBottom: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.background.light,
    },
  };
});

function WidgetPopoverMenu(props) {
  const { menuName } = props.data;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClickButonOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const propsId = props.id === undefined ? "action-menu" : props.id;
  const id = open ? propsId : undefined;

  let buttonType;
  if (Boolean(menuName) === false) {
    buttonType = (
      <IconButton onClick={onClickButonOpen} disabled={props.loading || props.disabled}>
        <icons.mui.MoreVert />
      </IconButton>
    );
  } else {
    buttonType = (
      <Button
        aria-describedby={id}
        variant="text"
        onClick={onClickButonOpen}
        endIcon={<icons.mui.KeyboardArrowDown fontSize="small" />}
        disabled={props.loading}
      >
        {menuName}
      </Button>
    );
  }
  return (
    <>
      {buttonType}
      <CustomPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <PopoverMenu data-syklone="widget-popover-menu-wrapper">
          {props.data.menuItems.map((item) => {
            if (item.isHidden) {
              return null;
            }
            return (
              <PopoverMenuLink
                id={`${id}-${convertToLowercaseAndDashes(item.name)}`}
                type={item.type}
                onClick={(event) => {
                  if (item.disabled) {
                    return;
                  } else {
                    item.action(event, props.state);
                    onClosePopover();
                  }
                }}
                key={item.name}
              >
                <Typography sx={{ opacity: item.disabled ? "0.6" : "1" }}>{item.name}</Typography>
              </PopoverMenuLink>
            );
          })}
        </PopoverMenu>
      </CustomPopover>
    </>
  );
}

WidgetPopoverMenu.propTypes = {
  data: PropTypes.object,
  state: PropTypes.any,
  type: PropTypes.string,
  isHidden: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default WidgetPopoverMenu;
