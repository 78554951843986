import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { moment } from "syklone/libraries/index.js";
import { Box, Typography } from "syklone/ui/index.js";

// imports: local

const dataTag = "widget-avatar-promotion-item";

const createDate = (str) => {
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

const WidgetAvatarPromotionItem = ({ onItemClick, data }) => {
  return (
    <>
      <Box sx={styles.promotionItemWrapper} onClick={() => onItemClick(data)}>
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: 14 }} data-syklone={`${dataTag}-title`}>
            {data.approvalStatus}
          </Typography>
        </Box>
        <Box component="ul" sx={{ listStyleType: "none", paddingLeft: 0 }} data-syklone={`${dataTag}-list`}>
          <Box component="li">
            <Typography sx={{ fontSize: 12 }}>
              <Box component="span" sx={styles.rowLabel}>
                Request date:{" "}
              </Box>
              <span data-syklone={`${dataTag}-request-date`}>{createDate(data.requestDate)}</span>
            </Typography>
          </Box>
          <Box component="li">
            <Typography sx={{ fontSize: 12 }}>
              <Box component="span" sx={styles.rowLabel}>
                Requestor:{" "}
              </Box>
              <span data-syklone={`${dataTag}-email`}>{data.requestor}</span>
            </Typography>
          </Box>
          <Box component="li">
            <Typography sx={{ fontSize: 12 }}>
              <Box component="span" sx={styles.rowLabel}>
                Name:{" "}
              </Box>
              <span data-syklone={`${dataTag}-name`}>{data.name}</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

WidgetAvatarPromotionItem.propTypes = {
  data: PropTypes.object,
  onItemClick: PropTypes.func,
};

const styles = {
  promotionItemWrapper: {
    borderBottom: (theme) => `1px solid ${theme.palette.mode === "dark" ? "#464646" : "#E8E8E8"}`,
    padding: "8px 15px",
    cursor: "pointer",
    "&:hover": {
      background: (theme) => (theme.palette.mode === "dark" ? "#111" : "#f8f8f8"),
    },
  },
  rowLabel: {
    opacity: 0.6,
  },
};

export default WidgetAvatarPromotionItem;
