import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "syklone/ui";
import * as img from "../img/index.js";

const PartImages = ({ selectedSize, gridItems, hoveredItem, driftValues }) => {
  const [partSize, setPartSize] = useState(31.5);
  const imageAspectRatio = 403 / 264;
  const [overlayHeight, setOverlayHeight] = useState((31.5 / imageAspectRatio).toFixed(2));

  useEffect(() => {
    const newSize =
      selectedSize === "0"
        ? 23
        : selectedSize === "4"
        ? 27
        : selectedSize === "6"
        ? 31.5
        : selectedSize === "8"
        ? 35.7
        : 31.5;
    setPartSize(newSize);
    const newOverlayHeight = (newSize / imageAspectRatio).toFixed(2);
    setOverlayHeight(newOverlayHeight);
  }, [selectedSize]);

  const getDriftValues = (itemNumber) => {
    if (driftValues && driftValues[selectedSize]) {
      const itemDrift = driftValues[selectedSize].find((drift) => drift.number === itemNumber);
      if (itemDrift) {
        return { relx: itemDrift.relx || 0, rely: itemDrift.rely || 0 };
      }
    }
    return { relx: 0, rely: 0 };
  };

  return (
    <Box
      id="overlaycontainer"
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      {gridItems.map((item, index) => {
        const { relx, rely } = getDriftValues(item.number);
        return (
          <Box
            key={`img-${item.number}`}
            sx={{
              ...styles.overlay,
              left: `${((item.left + relx + 125) / 250) * 100}%`,
              top: `${(1 - (item.top + rely + 125) / 250) * 100}%`,
              width: `${partSize}%`,
              height: `${overlayHeight}%`,
              backgroundImage: `url(${hoveredItem === item.number ? img.baseplateImageGreen : img.baseplateImage})`,
            }}
          >
            <span className="numberLabel">{item.number}</span>
          </Box>
        );
      })}
    </Box>
  );
};

PartImages.propTypes = {
  selectedSize: PropTypes.string.isRequired,
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number.isRequired,
      left: PropTypes.number.isRequired,
      top: PropTypes.number.isRequired,
    })
  ).isRequired,
  hoveredItem: PropTypes.number,
  driftValues: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.number.isRequired,
        relx: PropTypes.oneOfType([PropTypes.number, PropTypes.null]),
        rely: PropTypes.oneOfType([PropTypes.number, PropTypes.null]),
      })
    )
  ),
};

const styles = {
  overlay: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "white",
    "& .numberLabel": {
      fontSize: "2rem",
      color: "black",
      fontWeight: "bold",
    },
  },
};

export default PartImages;
