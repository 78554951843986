import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DataGrid } from "syklone/ui/index.js";
import { WidgetContainer } from "../../widgets/index.js";
import { styles, useSearchableData, QuickSearchToolbar, TableCustomGrid } from "../components/index.js";

function TableDataMapping({
  data,
  setAllSelectedIds,
  allSelectedIds,
  isLoading,
  error,
  headerNameTitle = "name",
  singleSelect = false,
}) {
  const { searchText, handleSearchChange, clearSearch, filteredData } = useSearchableData(data, ["name"]);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const columns = React.useMemo(() => {
    return [
      { field: "id", headerName: "ID", hide: true },
      {
        field: "name",
        flex: 1,
        headerName: headerNameTitle,
        disableColumnMenu: true,
      },
      {
        field: "description",
        flex: 1,
        headerName: "Description",
        disableColumnMenu: true,
      },
    ];
  }, [headerNameTitle]);

  const onhandleSelectionChange = (newSelection) => {
    if (singleSelect) {
      const isSelectedIdVisible = filteredData.some((row) => row.id === newSelection);
      if (isSelectedIdVisible) {
        setAllSelectedIds([newSelection]);
      } else {
        setAllSelectedIds([]);
      }
    } else {
      setAllSelectedIds((prevSelectedIds) => {
        const currentlyDisplayedIds = newSelection;
        const filteredOutIds = prevSelectedIds.filter((id) => !filteredData.some((row) => row.id === id));
        return [...new Set([...currentlyDisplayedIds, ...filteredOutIds])];
      });
    }
  };

  const handleRowClick = (params) => {
    if (singleSelect) {
      onhandleSelectionChange(params.id);
    }
  };

  React.useEffect(() => {
    if (singleSelect) {
      const visibleSelection = allSelectedIds.filter((id) => filteredData.some((row) => row.id === id));
      setSelectionModel(visibleSelection.length > 0 ? [visibleSelection[0]] : []);
    } else {
      setSelectionModel(allSelectedIds.filter((id) => filteredData.some((row) => row.id === id)));
    }
  }, [filteredData, allSelectedIds, singleSelect]);

  return (
    <TableCustomGrid item sx={{ marginTop: "30px" }}>
      <WidgetContainer isLoading={isLoading} error={error}>
        <DataGrid
          components={{ Toolbar: QuickSearchToolbar }}
          rows={filteredData}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          getRowId={(row) => row.id}
          loading={isLoading}
          checkboxSelection={!singleSelect}
          onRowSelectionModelChange={!singleSelect ? (params) => onhandleSelectionChange(params) : undefined}
          onRowClick={singleSelect ? handleRowClick : undefined}
          rowSelectionModel={selectionModel}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (event) => handleSearchChange(event.target.value),
              clearSearch: () => clearSearch(),
            },
          }}
          sx={singleSelect ? styles.gridStyleSingleSelect : styles.gridStyle}
        />
      </WidgetContainer>
    </TableCustomGrid>
  );
}

TableDataMapping.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
  allSelectedIds: PropTypes.array,
  setAllSelectedIds: PropTypes.func,
  headerNameTitle: PropTypes.string,
  singleSelect: PropTypes.bool,
};

export default TableDataMapping;
