import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Chip, styled } from "syklone/ui/core/index.js";

const BoxWrapper = styled(({ height, ...rest }) => <Box {...rest} />)(({ height }) => {
  return {
    display: "flex",
    flexDirection: "column",
    height: height,
    overflow: "auto",
    gap: "11px",
    padding: "0px 8px 8px 8px",
    margin: "8px 0px",
  };
});

const BoxItem = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function Versions({ areaHeight, data }) {
  let height = areaHeight - 62;
  return (
    <BoxWrapper height={height}>
      {data.map((item) => (
        <BoxItem key={item.name} data-syklone={item.name}>
          <Box>{item.name}</Box>
          <Chip label={item.version} size="small" />
        </BoxItem>
      ))}
    </BoxWrapper>
  );
}

Versions.propTypes = {
  areaHeight: PropTypes.number,
  data: PropTypes.array,
};

export default Versions;
