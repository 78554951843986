export const gridStyle = (theme) => ({
  fontSize: 13,
  "&.MuiDataGrid-root": {
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    border: "none",
  },
  ".MuiDataGrid-columnHeaders": {
    borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
  },
  ".MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
});

export const gridStyleSingleSelect = (theme) => ({
  fontSize: 13,
  "&.MuiDataGrid-root": {
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    border: "none",
  },
  ".MuiDataGrid-columnHeaders": {
    borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
  },
  ".MuiDataGrid-footerContainer": {
    borderTop: "none",
  },

  "& .Mui-selected": {
    "&.MuiDataGrid-row": {
      outline: "2px solid orange",
      "& .MuiDataGrid-cell": {
        borderWidth: 0,
        outline: "none !important",
      },
    },
  },
  "& .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
});
