import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText,
  Typography,
  Input,
  ListItemText,
  Box,
} from "syklone/ui/index.js";

const WidgetEntityAssignment = ({
  singleEntityName,
  singleEntityValue,
  multipleEntityFieldName,
  multipleEntityLabel,
  multipleEntityOptions,
  multipleEntityValue, // Passed from Formik's values
  handleMultipleEntityChange, // Passed from Formik
  multipleEntityError, // Passed from Formik's errors
  multipleEntityTouched, // Passed from Formik's touched
  singleEntityFieldName,
}) => {
  return (
    <Box>
      {/* Single Entity Display */}
      <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
        {singleEntityName}: {singleEntityValue}
      </Typography>
      <Input type="hidden" name={singleEntityFieldName} value={singleEntityValue} />

      {/* Multiple Entities Select */}
      <FormControl required sx={{ mb: 1.5 }} fullWidth>
        <InputLabel id={`${multipleEntityFieldName}-label`}>{multipleEntityLabel}</InputLabel>
        <Select
          labelId={`${multipleEntityFieldName}-label`}
          id={multipleEntityFieldName}
          multiple
          value={multipleEntityValue}
          onChange={handleMultipleEntityChange}
          renderValue={(selected) =>
            selected.map((val) => multipleEntityOptions.find((o) => o.id === val)?.name || val).join(", ")
          }
          error={multipleEntityTouched && Boolean(multipleEntityError)}
          MenuProps={{ PaperProps: { style: { maxHeight: 224 } } }}
        >
          {multipleEntityOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={multipleEntityValue.includes(option.id)} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
        {multipleEntityTouched && multipleEntityError && <FormHelperText>{multipleEntityError}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

WidgetEntityAssignment.propTypes = {
  singleEntityName: PropTypes.string.isRequired,
  singleEntityValue: PropTypes.string.isRequired,
  multipleEntityFieldName: PropTypes.string.isRequired,
  multipleEntityLabel: PropTypes.string.isRequired,
  multipleEntityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  multipleEntityValue: PropTypes.array.isRequired,
  handleMultipleEntityChange: PropTypes.func.isRequired,
  multipleEntityError: PropTypes.string,
  multipleEntityTouched: PropTypes.bool.isRequired,
  singleEntityFieldName: PropTypes.string,
};

export default WidgetEntityAssignment;
