import React from "react";
import { useQuery } from "syklone/libraries/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { useParams } from "react-router-dom";
import { helperAuditTrail } from "../../helpers/index.js";

const withAuditTrailData = (Component) => {
  const AuditTrailData = () => {
    const [errorMessage, setErrorMessage] = React.useState(null);
    const apiInstance = useApiContext();
    const serviceAuditTrail = apiInstance.sykloneApi.serviceAuditTrail;

    const { contentTypeId, objectId, auditTrailId } = useParams();

    React.useEffect(() => {
      if (!contentTypeId && !objectId && !auditTrailId) {
        setErrorMessage("ID is required but was not provided");
      } else {
        setErrorMessage(null);
      }
    }, [contentTypeId, objectId, auditTrailId]);

    const {
      data: auditTrail = [],
      isLoading: isLoadingAuditTrail,
      error: errorAuditTrail,
    } = useQuery([["singleAuditTrail", contentTypeId, objectId]], async () => {
      const queryParams = {
        contentTypeId,
        objectId,
      };
      const response = await serviceAuditTrail.getHistory(queryParams);
      const data = response.data.data;
      const modifiedResponse = helperAuditTrail.formatAuditTrailData(data);

      return modifiedResponse;
    });

    const formattedError = errorAuditTrail
      ? {
          message:
            typeof errorAuditTrail === "string"
              ? errorAuditTrail
              : errorAuditTrail.message || "There was an error getting the audit trail",
          severity: "error",
        }
      : null;

    const combinedError = errorMessage || formattedError;
    return (
      <Component
        activeAuditTrailId={auditTrailId}
        auditTrailData={auditTrail}
        isLoadingAuditTrailData={isLoadingAuditTrail}
        auditTrailError={combinedError}
      />
    );
  };

  AuditTrailData.displayName = "AuditTrailData";
  return AuditTrailData;
};

export default withAuditTrailData;
