import React from "react";
import { useLocation } from "react-router-dom";

// imports: syklone
import { Button } from "syklone/ui/index.js";

const PageElectronicSignatureResponse = () => {
  const [countdown, setCountdown] = React.useState(5);
  const { search } = useLocation();
  const params = React.useMemo(() => new URLSearchParams(search), [search]);
  let status = params.get("status") || "success";
  const isSuccess = status === "success";
  const message = isSuccess
    ? "You have successfully authenticated. Please close this page and continue with your workflow."
    : "There was an error during the authentication process. Please close this page and try again.";
  const isBrowser = typeof window !== "undefined";

  const handleCloseWindow = () => {
    if (isBrowser) {
      window.close();
    }
  };

  React.useEffect(() => {
    if (countdown === 0) {
      handleCloseWindow();
      return;
    }
    let timerId;
    // Set up the countdown
    if (isBrowser) {
      timerId = window.setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }

    // Clean up the timer
    return () => isBrowser && window.clearTimeout(timerId);
  }, [countdown]);

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <p>{message}</p>
      <p>If this page does not close automatically in {countdown} seconds, please close this tab/window manually.</p>
      <Button
        type="submit"
        size="large"
        variant="contained"
        color="primary"
        onClick={handleCloseWindow}
        style={{ marginTop: "10px" }}
      >
        Close Page
      </Button>
    </div>
  );
};

export default PageElectronicSignatureResponse;
