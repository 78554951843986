import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { icons, Popper, IconButton, Paper, styled, TextField, Typography } from "syklone/ui/index.js";
import { Grid } from "syklone/ui/core/index.js";

// imports: local
import { WidgetAutocompleteSearchItem } from "../index.js";

const SearchProductsTitle = styled(Grid)(({ theme }) => ({
  padding: "13px 16px",
  opacity: 0.6,
}));

const WidgetAutocompleteSearch = ({ data, getSearchResults, isSearchLoading }) => {
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length >= 3) {
      setAnchorEl(event.currentTarget);
      let results = await getSearchResults();
      setSearchResults(results);
    } else {
      setAnchorEl(null);
    }
  };

  const onClickInputCloseIcon = () => {
    setSearchResults([]);
    setInputValue("");
  };

  const handleClosePopper = () => {
    setAnchorEl(null);
  };

  const popperOpen = () => {
    return Boolean(anchorEl) && inputValue.length >= 3;
  };

  const openDialogIfInputNotEmpty = (event) => {
    setAnchorEl(event.currentTarget);
    if (inputValue !== "") {
      popperOpen();
    }
  };

  return (
    <>
      <TextField
        label="Search (enter at least 3 characters)"
        value={inputValue}
        onChange={handleInputChange}
        onClick={openDialogIfInputNotEmpty}
        size="small"
        sx={{ width: "450px" }}
        InputProps={{
          endAdornment:
            inputValue.length !== 0 ? (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: inputValue.length !== 0 ? "visible" : "hidden" }}
                sx={inputValue.length !== 0 && { position: "relative", zIndex: 9999999 }}
                onClick={onClickInputCloseIcon}
              >
                <icons.mui.Cancel fontSize="small" />
              </IconButton>
            ) : (
              <icons.mui.Search fontSize="small" />
            ),
        }}
      />

      <Popper open={popperOpen()} anchorEl={anchorEl} placement="bottom-start" sx={{ width: "450px" }}>
        <Paper>
          {isSearchLoading ? (
            <Typography sx={{ p: 2 }}>Loading...</Typography>
          ) : searchResults.length > 0 ? (
            <Grid container direction="column">
              <SearchProductsTitle item>Search results</SearchProductsTitle>
              <Grid item>
                {searchResults.map((item, index) => (
                  <WidgetAutocompleteSearchItem data={item} key={`${item}-${index}`} />
                ))}
              </Grid>
            </Grid>
          ) : (
            <Typography sx={{ p: 2 }}>{`No results for keyword "${inputValue}"`}</Typography>
          )}
        </Paper>
      </Popper>

      {popperOpen() && (
        <div onClick={handleClosePopper} style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0 }}></div>
      )}
    </>
  );
};

WidgetAutocompleteSearch.propTypes = {
  data: PropTypes.object,
  getSearchResults: PropTypes.func,
  isSearchLoading: PropTypes.bool,
};

export default WidgetAutocompleteSearch;
