import React from "react";
import PropTypes from "prop-types";
import { loadFull } from "tsparticles";
import { Particles } from "react-tsparticles/esm/index.js";

// imports: syklone
import { Box, Typography, createTheme, CssBaseline, ThemeProvider, themes } from "syklone/ui/index.js";
import { useAuthContext } from "syklone/api/react/index.js";
import { FormLogin } from "syklone/components/forms/index.js";

// imports: local
import * as img from "./img/index.js";

const Space = () => {
  const particlesInit = React.useCallback((main) => {
    loadFull(main);
  }, []);

  return (
    <Particles
      id="syklone-particles"
      init={particlesInit}
      options={{
        particles: {
          number: {
            value: 30,
            density: {
              enable: true,
              valueArea: 100,
            },
          },
          color: {
            value: "#ffffff",
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacityMin: 0,
              sync: false,
            },
          },
          size: {
            value: 2,
            random: true,
            anim: {
              enable: true,
              speed: 4,
              sizeMin: 0.3,
              sync: false,
            },
          },
          lineLinked: {
            enable: true,
            distance: 150,
            color: "#696983",
            opacity: 0.1,
            width: 1,
          },
          move: {
            enable: true,
            speed: 0.17,
            direction: "none",
            random: true,
            straight: false,
            outMode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 600,
            },
          },
        },
        interactivity: {
          detectOn: "canvas",
          events: {
            onhover: {
              enable: false,
              mode: "bubble",
            },
            onclick: {
              enable: false,
              mode: "repulse",
            },
            resize: false,
          },
          modes: {
            grab: {
              distance: 400,
              lineLinked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 250,
              size: 0,
              duration: 2,
              opacity: 0,
              speed: 3,
            },
            repulse: {
              distance: 400,
              duration: 0.4,
            },
            push: {
              particlesNb: 4,
            },
            remove: {
              particlesNb: 2,
            },
          },
        },
        retinaDetect: true,
      }}
    />
  );
};
function PageGfLogin({ navigate }) {
  const { login, loadProvider } = useAuthContext();

  const onLogin = async (email, password, cb) => {
    const isProviderLoaded = await loadProvider("auditTrail");
    if (isProviderLoaded) {
      const isLoginSuccess = await login({ email, password, cb });
      if (isLoginSuccess) {
        navigate("/dashboard/part-platform");
      }
    } else {
      cb({ errors: { message: "Provider is not Loaded" } });
    }
  };

  function onSubmitRegistration() {
    //Implement registration logic
  }

  let currentTheme = createTheme(themes.themeDark);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Box sx={styles.container}>
        <Space />
        <Box sx={styles.loginWrapper}>
          <Box sx={styles.leftWrapper}>
            <Box sx={styles.leftWrapperLogo}>
              <img src={img.SykloneLoginLogo} alt="Syklone logo" />
            </Box>
            <Box>
              <Typography sx={{ fontSize: 16, opacity: 0.5 }}>
                Welcome to the Syklone Additive Manufacturing EcoSystem
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.rightWrapper}>
            <Box sx={styles.containerDark} maxWidth="xs">
              <FormLogin onLogin={onLogin} onSubmitRegistration={onSubmitRegistration} isThemeLight={false} />
            </Box>
          </Box>
        </Box>
        <Box sx={styles.imgWrapper}>
          <Box component="img" sx={styles.img} src={img.Sphere} alt="" />
          <Box sx={styles.copyright}>
            <Typography>
              © 2022 The Stryker Company. All rights reserved. Syklone is trademark of the Stryker company.
            </Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

PageGfLogin.propTypes = {
  navigate: PropTypes.func,
};

const styles = {
  container: {
    display: "flex",
    background: "#111111",
    zIndex: "1",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  loginWrapper: {
    display: "flex",
    maxWidth: "1040px",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "5",
    background: "#000",
    borderRadius: "6px",
    "@media (max-width: 1140px)": {
      flexDirection: "column",
    },
  },
  leftWrapper: {
    padding: "90px",
    textAlign: "center",
    maxWidth: "520px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    "@media (max-width: 1140px)": {
      padding: "30px 90px 20px 90px",
      gap: "10px",
    },
  },
  rightWrapper: {
    position: "relative",
    width: "520px",
    zIndex: "1",
    background: "#242424",
    padding: "10px 0px 40px 0px",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    "@media (max-width: 1140px)": {
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "6px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "6px",
    },
  },
  imgWrapper: {
    position: "absolute",
    bottom: 0,
    zIndex: 1,
  },
  img: {
    width: "100%",
    maxWidth: "1040px",
  },
  containerDark: {
    background: "#242424",
  },
  copyright: {
    position: "absolute",
    display: "flex",
    width: "100%",
    bottom: "40px",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
  },
};

export default PageGfLogin;
