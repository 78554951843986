import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { useQueryClient } from "syklone/libraries/index.js";
import { Box, Typography, MenuItem } from "syklone/ui/index.js";
import { WidgetScrollBar } from "syklone/components/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { WidgetAvatarPromotionItem, WidgetPendingApprovalDialog } from "../index.js";

const types = {
  1: "New user registration",
  2: "R&D → Under review",
  3: "Under review → Released",
};

function WidgetAvatarNotifications({ promotionNotifcationData, closeMenu }) {
  const [promotionDialogOpen, setPromotionDialogOpen] = React.useState(false);
  const [productFamilies, setPageProductFamilies] = React.useState([]);
  const [isApproving, setIsApproving] = React.useState(false);

  const [savedData, setSavedData] = React.useState(null);

  const widgetPendingApprovalDialogRef = React.useRef();
  //TODO: how each type supposed to act
  // const hasApprovalType = data.type > 2;

  const queryClient = useQueryClient();
  const apiInstance = useApiContext();

  const serviceAuditTrail = apiInstance.sykloneApi.serviceAuditTrail;
  const serviceBfm = apiInstance.sykloneApi.serviceBfm;
  const serviceProducts = apiInstance.sykloneApi;

  function familyDataAdapter(id, title) {
    return {
      id: id,
      title: title,
    };
  }

  /* eslint-disable */
  const createProduct = async (id, payload) => {
    const { productId, productFamilyId } = payload;

    const data = {
      product_id: productId,
      family: productFamilyId,
      model: "702",
      size: "06",
      build_file_id: [id],
      assets: [""],
    };

    try {
      await serviceProducts.bcPostProduct(data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getLastUpdatedBuildFile = async () => {
    try {
      let response = await serviceBfm.getReleased();
      let lastItem = response.data.data.pop();
      let id = lastItem.id;
      return id;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const getPageProductFamilies = async () => {
    try {
      let response = await serviceProducts.bcGetAllProductFamilies();
      if (response.data) {
        setPageProductFamilies(response.data.data.map((item) => familyDataAdapter(item._id, item.name)));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onApprove = async (payload) => {
    // password
    const { productId, username, userComment, userSigned, type, id } = payload;
    const hasApprovalType = type > 2;
    const payloadData = {
      ["electronic_signature"]: {
        ["user_signed_email"]: username,
        ["user_signed"]: userSigned,
        comment: userComment,
      },
      ...(hasApprovalType && {
        data: {
          productId: productId,
        },
      }),
    };
    setIsApproving(true);
    try {
      let response = await serviceAuditTrail.postApproveById(id, payloadData);
      if (hasApprovalType) {
        let bfid = await getLastUpdatedBuildFile();
        await createProduct(bfid, payload);
      }

      const message = response?.data.success ? response.data.success : "Build successfully submitted";
      widgetPendingApprovalDialogRef.current.setStepMessage(message, "success");
      widgetPendingApprovalDialogRef.current.nextStep();
    } catch (error) {
      const message =
        error && typeof error.detail === "string" ? error.detail : "There was an error submitting the build";
      widgetPendingApprovalDialogRef.current.setStepMessage(message, "error");
    } finally {
      setIsApproving(false);
    }
  };

  const modifiedData = React.useMemo(() => {
    const data = promotionNotifcationData ?? [];
    return data.map((item) => ({
      ...item,
      approvalStatus: types[item.type],
    }));
  }, [promotionNotifcationData]);

  const onPromotionDialogOpen = async (item) => {
    closeMenu();
    setSavedData(item);
    await getPageProductFamilies();
    setPromotionDialogOpen(true);
  };

  const onPromotionDialogClose = () => {
    setPromotionDialogOpen(false);
    setSavedData(null);
    queryClient.invalidateQueries("dataUnderReview");
    queryClient.invalidateQueries("dataReleased");
  };

  return (
    <MenuItem sx={styles.notificationWrapper} disableRipple={true}>
      {modifiedData.length > 0 && (
        <Box sx={styles.notificationBody}>
          <Typography sx={{ fontSize: 16, marginBottom: "0.3em", marginTop: "0.2em" }}>Notifications</Typography>
          <WidgetScrollBar autoHeightMax={300} style={{ marginLeft: "-1.2em", marginRight: "-1.9em" }}>
            <Box
              component="ul"
              sx={{ listStyleType: "none", paddingLeft: 0 }}
              data-syklone="promotion-notifications-list"
            >
              {modifiedData.map((item, index) => {
                return (
                  <Box component="li" key={index}>
                    <WidgetAvatarPromotionItem key={index} data={item} onItemClick={onPromotionDialogOpen} />
                  </Box>
                );
              })}
            </Box>
          </WidgetScrollBar>
        </Box>
      )}
      <WidgetPendingApprovalDialog
        ref={widgetPendingApprovalDialogRef}
        onClose={onPromotionDialogClose}
        isOpen={promotionDialogOpen}
        data={savedData}
        isSubmitting={isApproving}
        onApprove={onApprove}
        productFamilies={productFamilies}
      />
    </MenuItem>
  );
}

WidgetAvatarNotifications.propTypes = {
  promotionNotifcationData: PropTypes.array,
  closeMenu: PropTypes.func,
};

const styles = {
  notificationWrapper: {
    maxWidth: 300,
    "&:first-of-type": {
      marginTop: "-8px",
    },
    "&:hover": {
      backgroundColor: "initial!important",
      cursor: "initial!important",
    },
  },
  notificationBody: {
    borderBottom: (theme) => `2px solid ${theme.palette.mode === "dark" ? "#262626" : "#E8E8E8"}`,
  },
};

export default WidgetAvatarNotifications;
