import React, { useState } from "react";
import PropTypes from "prop-types";

// imports: local
import WidgetMachineCard from "./widget_machine_card";
import { Alert, Box, Radio, RadioGroup, FormControlLabel, FormControl, styled } from "syklone/ui";

const CustomFormControl = styled(FormControl)({
  paddingLeft: "1rem",
  paddingBottom: "0.5rem",
  paddingTop: "0.5rem",
  position: "sticky",
  top: "0px",
  background: "#121212",
  width: "100%",
  opacity: 1,
  zIndex: 999,
});

function WidgetMachineCards({ data, onClick }) {
  const [filter, setFilter] = useState("all");

  const filteredData = data?.filter((item) => {
    switch (filter) {
      case "printing":
        return item.status.type === 1;
      case "available":
        return item.status.type === 2;
      case "idle":
        return item.status.type === 3;
      default:
        return true;
    }
  });
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  return (
    <Box>
      <>
        {!data ? (
          <Alert severity="error">No machines defined.</Alert>
        ) : (
          <>
            <CustomFormControl component="fieldset">
              <RadioGroup row aria-label="filter" name="filter1" value={filter} onChange={handleFilterChange}>
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel value="printing" control={<Radio />} label="Printing" />
                <FormControlLabel value="available" control={<Radio />} label="Available" />
                <FormControlLabel value="idle" control={<Radio />} label="Idle" />
              </RadioGroup>
            </CustomFormControl>
            <Box sx={{ display: "flex", gap: "8px", flexDirection: "column" }}>
              {filteredData?.map((item) => (
                <WidgetMachineCard key={item.machineName} data={item} onClick={onClick} />
              ))}
            </Box>
          </>
        )}
      </>
    </Box>
  );
}

WidgetMachineCards.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
};

export default WidgetMachineCards;
