import React from "react";
import PropTypes from "prop-types";
import { Outlet, useNavigate } from "react-router-dom";

// imports: syklone
import { Box } from "syklone/ui/index.js";

// imports: local
import { WidgetHeader, WidgetFooter, WidgetSpace } from "../../widgets/index.js";
import { useLocalStorage } from "../../hooks/index.js";

function PageGfLanding() {
  let navigate = useNavigate();

  // TODO: [] Disabled temporarily
  /*
  const { validateAuthentication } = useApiContext();

  React.useEffect(() => {
    const checkIfAuthed = async () => {
      const isAuthenticated = await validateAuthentication();
      if (isAuthenticated) {
        navigate("/dashboard/part-platform");
      }
    };
    checkIfAuthed();
  }, [validateAuthentication, navigate]);
  */

  const pages = [
    {
      id: "gf-default",
      title: "Home",
      to: "/",
      visible: false,
    },
    {
      id: "gf-ecosystem",
      title: "System information",
      to: "/system-information",
      visible: true,
    },
    {
      id: "gf-about",
      title: "Access",
      to: "/access",
      visible: true,
    },
  ];
  const [active, setActive] = useLocalStorage("");

  const onClickLogin = () => {
    navigate("/login");
  };

  const onClickRegister = () => {
    // TODO: [] To be implemented
  };

  return (
    <>
      <WidgetSpace />
      <Box sx={styles.mainWrapper}>
        <WidgetHeader pages={pages} active={active} setActive={setActive} />
        <Box sx={styles.mainContent}>
          <Outlet context={[pages, active, setActive, onClickLogin, onClickRegister]} />
        </Box>
        <WidgetFooter projectName={"Syklone"} companyName={"Stryker"} />
      </Box>
    </>
  );
}
PageGfLanding.propTypes = {
  classes: PropTypes.object,
};

const styles = {
  mainContent: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    zIndex: 2,
    position: "relative",
  },
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    overflow: "hidden",
  },
};

export default PageGfLanding;
