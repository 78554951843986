import React from "react";
import PropTypes from "prop-types";

// import: local
import { DialogCommonConfirmation } from "../index.js";

const DialogToggleActivation = React.forwardRef(
  ({ onConfirm, toggleActionName, activateMessage = "activate", deactivateMessage = "deactivate" }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [isActive, setIsActive] = React.useState(false);
    const dialogDataRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
      setDialogData: (data) => {
        dialogDataRef.current = data;
        setIsActive(dialogDataRef.current?.isActive);
      },
      setIsDialogOpen: (value) => {
        setIsOpen(value);
      },
      setIsSubmitting: (value) => {
        setIsSubmitting(value);
      },
    }));

    const closeDialog = () => {
      setIsOpen(false);
    };

    const handleDialogConfirm = () => {
      onConfirm(dialogDataRef.current);
    };

    React.useEffect(() => {
      if (!isOpen) {
        setIsActive(false);
        dialogDataRef.current = null;
      }
    }, [isOpen]);

    const dialogText = `Do you want to ${isActive ? deactivateMessage : activateMessage} this ${
      toggleActionName ? toggleActionName : "action"
    }?`;

    return (
      <DialogCommonConfirmation
        contentHeight={50}
        isConfirmDisabled={isSubmitting}
        title="Action"
        onConfirm={handleDialogConfirm}
        onCancel={closeDialog}
        onClose={closeDialog}
        isOpen={isOpen}
      >
        {dialogText}
      </DialogCommonConfirmation>
    );
  }
);

DialogToggleActivation.displayName = "DialogToggleActivation";

DialogToggleActivation.propTypes = {
  onConfirm: PropTypes.func,
  onDialogClose: PropTypes.func,
  toggleActionName: PropTypes.string,
  activateMessage: PropTypes.string,
  deactivateMessage: PropTypes.string,
};

export default DialogToggleActivation;
