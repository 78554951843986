import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Autocomplete,
  AdapterMoment,
  Checkbox,
  LocalizationProvider,
  DesktopDatePicker,
  Box,
  Button,
  icons,
  Typography,
  Select,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
} from "syklone/ui/index.js";
import { moment } from "syklone/libraries/index.js";

// imports: local
import { DialogNotification } from "../../dialogs/index.js";

const optionsPanelHeight = 220;

const filteredDataDefaultValue = {};

function WidgetFilter({ filterSchema, handleFilterClick, handleResetClick, filterErrors }) {
  // -------- Reactjs state management --------
  const [formValid, setFormValid] = React.useState(true);
  const [datePickerValue, setDatePickerValue] = React.useState(filteredDataDefaultValue);
  const [filteredData, setFilteredData] = React.useState(filteredDataDefaultValue);
  const [isChanged, setIsChanged] = React.useState(false);

  // -------- Helpers --------
  const isObject = (x) => typeof x === "object";
  const isItemFound = (x, y) =>
    !(x && y && isObject(x) && isObject(y)) ? x === y : Object.keys(x).some((key) => isItemFound(x[key], y[key]));

  const hasDataChanged = () => {
    const keysA = Object.keys(filteredData);
    const keysB = Object.keys(filteredDataDefaultValue);

    // If no keys have been populated in either, no changes have been made
    if (keysA.length === 0 && keysB.length === 0) return false;

    // If the number of keys is different, there's a change
    if (keysA.length !== keysB.length) return true;

    // Check deeper equality
    return !isItemFound(filteredData, filteredDataDefaultValue);
  };

  // Update the isChanged state based on changes in filteredData or datePickerValue
  React.useEffect(() => {
    setIsChanged(hasDataChanged());
  }, [filteredData, datePickerValue]);

  // -------- Setters --------
  const setParent = (state, name, val) => {
    const filtered = Object.entries(state).filter(([_key, value]) => value.child !== name);
    const temp = Object.fromEntries(filtered);
    setFilteredData({ ...temp, [name]: val });
  };

  // -------- Handle MUI element type  --------
  const handleMuiElementType = (data, index, isChild) => {
    switch (data.type) {
      case "dropdown":
        return elementSelect(data, index, isChild);
      case "multiselectDropdown":
        return elementAutoComplete(data, index, isChild, true);
      case "datetimePicker":
        return elementDatePicker(data.key, data.label);
      default:
        return null;
    }
  };

  // -------- Check if selected element is child --------
  const checkIfElementIsChild = (i) => {
    if (i.children) {
      return i.children.map((item, index) => {
        let tmpFilteredData = {};
        let obj = {};

        if (filteredData[item.condition.key] === undefined || typeof filteredData[item.condition.key] !== "object") {
          tmpFilteredData = filteredData;
        } else {
          tmpFilteredData = filteredData[item.condition.key];
        }
        Object.assign(obj, { [item.condition.key]: item.condition.result });

        if (isItemFound(obj, tmpFilteredData)) {
          return (
            <Box sx={styles.childFilter} key={index}>
              {handleMuiElementType(item, index, true)}
            </Box>
          );
        }
      });
    }
  };

  // -------- MUI: Select --------
  const elementSelect = (data, index, isChild) => {
    const checkValues = (item, key) => {
      return isObject(item) ? item[key] : item;
    };
    const options = data.children || [];
    return (
      <Box sx={{ mb: 2.4 }}>
        <FormControl fullWidth data-syklone={`audit-trail-filter-${data.label}`}>
          <InputLabel id={`${data.label}-${index}`}>{data.label}</InputLabel>
          <Select
            labelId={`${data.label}-${index}`}
            id={`${data.label}-${index}`}
            value={checkValues(filteredData[data.key], data.key) ?? ""}
            name={data.key}
            label={data.label}
            onChange={(e) => onSelectChange(e, data, isChild)}
            MenuProps={{ PaperProps: { sx: { maxHeight: optionsPanelHeight } } }}
          >
            {options.map((item, i) => {
              return (
                <MenuItem key={`${item.key}-${i}`} value={item.key}>
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  // -------- MUI: Autocomplete list --------
  const elementAutoComplete = (data, index, isChild, multiple) => {
    const checkValues = (item, key) => {
      return isObject(item) && isChild ? item[key] : item;
    };
    return (
      <Box>
        <FormControl
          fullWidth
          data-syklone={
            data.condition
              ? `audit-trail-filter-${data.condition?.result}-${data.label}`
              : `audit-trail-filter-${data.label}`
          }
        >
          <Autocomplete
            multiple={multiple}
            ListboxProps={{ style: { maxHeight: optionsPanelHeight } }}
            limitTags={2}
            id={`${data.key}-${index}`}
            value={checkValues(filteredData[data.key], data.key) ?? []}
            options={data.options}
            onChange={(event, value) => onChangeAutoComplete(value, data, isChild)}
            getOptionLabel={(option) => {
              return option || "";
            }}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<icons.mui.CheckBoxOutlineBlank />}
                  checkedIcon={<icons.mui.CheckBox />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => <TextField {...params} label={data.label} />}
          />
        </FormControl>
      </Box>
    );
  };

  // -------- MUI: Datepicker --------
  const elementDatePicker = (key, label) => {
    return (
      <Box sx={{ mb: 0 }}>
        <FormControl fullWidth data-syklone={`audit-trail-filter-${label}`}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              label={label}
              disableFuture
              disableMaskedInput
              inputFormat="DD-MMM-yyyy"
              value={datePickerValue[key] ? datePickerValue[key] : null}
              onChange={(value) => onChangeDatePicker(key, value)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>
    );
  };

  // -------- Create URL for filtering --------
  /* eslint-disable no-unused-vars */
  const createQueryParameters = () => {
    let query = {};
    Object.entries(filteredData).map(([key, value]) => {
      if (typeof value === "object" && !Array.isArray(value)) {
        const { child, ...rest } = { ...value };
        const key = Object.keys(rest)[0];
        query[key] = value[key];
      } else {
        query[key] = value;
      }
    });
    return query;
  };

  // -------- MUI Elements events --------
  const onSelectChange = (event, data, isChild) => {
    const { name, value } = event.target;
    if (isChild) {
      setFilteredData((prevState) => ({
        ...prevState,
        [name]: {
          child: data.condition.key,
          [name]: value,
        },
      }));
    } else {
      setParent(filteredData, name, value);
    }
  };

  const onChangeAutoComplete = (value, data, isChild) => {
    if (isChild) {
      setFilteredData((prevState) => ({
        ...prevState,
        [data.key]: {
          child: data.condition.key,
          [data.key]: value,
        },
      }));
    } else {
      setFilteredData((prevState) => ({ ...prevState, [data.key]: value }));
    }
  };

  const onChangeDatePicker = (dateName, dateValue) => {
    setDatePickerValue((prevState) => ({
      ...prevState,
      [dateName]: dateValue,
    }));

    dateValue?.toISOString() === null ? setFormValid(false) : setFormValid(true);

    if (dateValue !== null) {
      if (dateName === "endDate") {
        setFilteredData((prevState) => ({
          ...prevState,
          [dateName]: `${createNextDay(dateValue)}`,
        }));
      } else {
        setFilteredData((prevState) => ({
          ...prevState,
          [dateName]: `${dateValue.toISOString()}`,
        }));
      }
    } else {
      delete filteredData[dateName];
    }
  };

  const createNextDay = (day) => {
    let newDate = moment(day);
    newDate.add({ hours: 23, minutes: 59 });
    return newDate.toISOString();
  };

  const onClickFilter = () => {
    const queryParams = createQueryParameters();
    if (queryParams !== "") {
      handleFilterClick(queryParams);
    }
  };

  const onClickReset = () => {
    setFormValid(true);
    setDatePickerValue(filteredDataDefaultValue);
    setFilteredData(filteredDataDefaultValue);
    handleResetClick(filteredDataDefaultValue);
  };

  return (
    <Box
      sx={{
        height: "99%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box>
          <Typography sx={{ fontSize: 15, fontWeight: 700, mb: 2 }}>Filter Audit trail</Typography>
        </Box>
        <DialogNotification alert={filterErrors} />
        <Box
          sx={{
            "& .MuiTextField-root": { mb: 1 },
          }}
        >
          {filterSchema.map((item, index) => (
            <Box key={index}>
              {handleMuiElementType(item, index, false)}
              <Box sx={styles.childFilterWrapper}>{checkIfElementIsChild(item)}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Stack direction="row" spacing={2} data-syklone="audit-trail-filter-wrapper">
        <Button
          data-syklone="audit-trail-reset"
          fullWidth
          size="large"
          variant="outlined"
          onClick={onClickReset}
          sx={{ width: "100%" }}
        >
          Reset
        </Button>
        <Button
          data-syklone="audit-trail-filter"
          disabled={!formValid || !isChanged}
          fullWidth
          size="large"
          variant="contained"
          onClick={onClickFilter}
        >
          Filter
        </Button>
      </Stack>
    </Box>
  );
}

WidgetFilter.propTypes = {
  filterSchema: PropTypes.array,
  handleFilterClick: PropTypes.func,
  handleResetClick: PropTypes.func,
  filterErrors: PropTypes.object,
};

const styles = {
  childFilterWrapper: {
    borderLeft: "2px solid #ffb500",
    marginTop: "-5px",
    marginBottom: "11px",
  },
  childFilter: {
    padding: "5px 0px 2px 0px",
    "& .MuiBox-root": {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: 0,
      "& .MuiFormControl-root": {
        width: "97%",
        marginLeft: "3%",
      },
    },
  },
  buttonGroup: {
    display: "flex",
    gap: 10,
    mt: 1,
  },
};

export default WidgetFilter;
