import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, Grid, icons, Stack } from "syklone/ui/index.js";
import { useApiContext, useAuthContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { WidgetTitle } from "../../widgets/index.js";
import { TableDataPermissions } from "../../tables/index.js";
import { DialogToggleActivation, DialogPermissions } from "../../dialogs/index.js";

function PageGlobalAdminSiteConfigurationPermissions({ data, isLoading, handleRefreshData, error }) {
  const apiInstance = useApiContext();
  const { updateCurrentUserPermissions } = useAuthContext();
  const { showSnackbar } = useAlertSnackbar();
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const dialogActivationRef = React.useRef(null);
  const dialogPermissionRef = React.useRef(null);

  const setIsDialogOpenFn = (val) => {
    dialogPermissionRef.current?.setIsDialogOpen(val);
  };

  const setDialogEditDataFn = (val) => {
    dialogPermissionRef.current?.setDialogData(val);
  };

  const setDialogActivationDataFn = (val) => {
    dialogActivationRef.current?.setDialogData(val);
  };

  const setIsDialogDeleteEndpointOpenFn = (val) => {
    dialogActivationRef.current?.setIsDialogOpen(val);
  };

  const onClickCreatePermissionFn = () => {
    setIsDialogOpenFn(true);
    setDialogEditDataFn(null);
  };

  const setDialogResponseErrorMessage = (text) => {
    dialogPermissionRef.current?.setResponseMessage({ type: "error", text });
  };

  const setDialogEditIsSubmitting = (value) => {
    dialogPermissionRef.current?.setIsSubmitting(value);
  };

  const setDialogActivationIsSubmitting = (value) => {
    dialogActivationRef.current?.setIsSubmitting(value);
  };

  const handlePermissionStatusChange = async (dialogEditData) => {
    const id = dialogEditData?.id;
    if (id) {
      try {
        setDialogActivationIsSubmitting(true);
        const isActive = dialogEditData?.isActive;
        let response;
        if (isActive) {
          response = await serviceAuth.deleteAdminPermissionsByPermissionId(id);
        } else {
          response = await serviceAuth.postAdminPermissionsByPermissionId(id);
        }
        await updateCurrentUserPermissions();
        const responseMessage = response?.data?.message ? response.data.message : "Status was successfully updated!";
        showSnackbar(responseMessage, "success");
        handleRefreshData && handleRefreshData();
        setIsDialogDeleteEndpointOpenFn(false);
      } catch (e) {
        const responseMessage = typeof e?.detail === "string" ? e.detail : "Status was not successfully updated!";
        showSnackbar(responseMessage, "error");
        setIsDialogDeleteEndpointOpenFn(false);
      } finally {
        setDialogActivationIsSubmitting(false);
      }
    } else {
      showSnackbar("Status was not successfully updated!", "error");
    }
  };

  const handleDialogPermissionEdit = async (id, payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.putAdminPermissionsByPermissionId(id, payload);
      await updateCurrentUserPermissions();
      const responseMessage = response?.data?.message ? response.data.message : "Permission successfully updated";
      setIsDialogOpenFn(false);
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Permission was not successfully updated!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  const handleDialogPermissionCreate = async (payload) => {
    try {
      setDialogEditIsSubmitting(true);
      const response = await serviceAuth.postAdminPermissions(payload);
      await updateCurrentUserPermissions();
      setIsDialogOpenFn(false);
      const responseMessage = response.message ? response.message : "Permission successfully created";
      showSnackbar(responseMessage, "success");
      handleRefreshData && handleRefreshData();
    } catch (e) {
      const responseMessage = typeof e?.detail === "string" ? e.detail : "Permission was not successfully updated!";
      setDialogResponseErrorMessage(responseMessage);
    } finally {
      setDialogEditIsSubmitting(false);
    }
  };

  return (
    <>
      <Grid item xs>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <WidgetTitle title="Permissions" isBackButton={true} backRoute="/dashboard/admin" />
              </Grid>
              <Grid item>
                <Stack spacing={1} direction="row">
                  <Button
                    id="admin_create_permission"
                    variant="contained"
                    disabled={isLoading}
                    startIcon={<icons.mui.Lock />}
                    onClick={() => onClickCreatePermissionFn()}
                    data-syklone="admin-create-permission-button"
                  >
                    Create permission
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "16px" }}>
            <Grid container>
              <TableDataPermissions
                isLoading={isLoading}
                error={error}
                data={data}
                setters={{
                  setDialogEditDataFn: setDialogEditDataFn,
                  setIsDialogEditPermissionOpenFn: setIsDialogOpenFn,
                  setDialogActivationDataFn: setDialogActivationDataFn,
                  setIsDialogDeletePermissionOpenFn: setIsDialogDeleteEndpointOpenFn,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogPermissions
        ref={dialogPermissionRef}
        onDialogEditPermission={handleDialogPermissionEdit}
        onDialogCreatePermission={handleDialogPermissionCreate}
      />
      <DialogToggleActivation
        ref={dialogActivationRef}
        toggleActionName="permission"
        onConfirm={handlePermissionStatusChange}
      />
    </>
  );
}

PageGlobalAdminSiteConfigurationPermissions.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  handleRefreshData: PropTypes.func,
  error: PropTypes.object,
};

export default PageGlobalAdminSiteConfigurationPermissions;
