import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Grid, Typography } from "syklone/ui/index.js";

function WidgetAccessFeature({ content, index }) {
  return (
    <Grid item>
      <Box sx={styles.accessListItem}>
        <Box sx={styles.accessListPoint}>{index + 1}</Box>
        <Box>
          <Typography
            sx={{
              background: "rgba(17, 17, 17, .3)",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {content.title}
          </Typography>
          <Typography
            sx={{
              background: "rgba(17, 17, 17, .3)",
              fontSize: 14,
              opacity: 0.6,
            }}
          >
            {content.description}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

WidgetAccessFeature.propTypes = {
  content: PropTypes.object,
  index: PropTypes.number,
};

const styles = {
  accessListItem: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  accessListPoint: {
    border: "1px solid #fff",
    borderRadius: "50%",
    width: "42px",
    height: "42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default WidgetAccessFeature;
