import React from "react";
import PropTypes from "prop-types";

// import: local
import { DialogCommonConfirmation } from "../index.js";

const DialogConfirmation = React.forwardRef(
  ({ onConfirm, dialogText = "Are you sure you want to make changes?", dialogTitle = "Action" }, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const dialogDataRef = React.useRef(null);

    React.useImperativeHandle(ref, () => ({
      setDialogData: (data) => {
        dialogDataRef.current = data;
      },
      setIsDialogOpen: (value) => {
        setIsOpen(value);
      },
      setIsSubmitting: (value) => {
        setIsSubmitting(value);
      },
    }));

    const closeDialog = () => {
      setIsOpen(false);
    };

    const handleDialogConfirm = () => {
      onConfirm(dialogDataRef.current);
    };

    React.useEffect(() => {
      if (!isOpen) {
        dialogDataRef.current = null;
      }
    }, [isOpen]);

    return (
      <DialogCommonConfirmation
        contentHeight={50}
        isConfirmDisabled={isSubmitting}
        title={dialogTitle}
        onConfirm={handleDialogConfirm}
        onCancel={closeDialog}
        onClose={closeDialog}
        isOpen={isOpen}
      >
        {dialogText}
      </DialogCommonConfirmation>
    );
  }
);

DialogConfirmation.displayName = "DialogConfirmation";

DialogConfirmation.propTypes = {
  onConfirm: PropTypes.func,
  dialogText: PropTypes.string,
  dialogTitle: PropTypes.string,
};

export default DialogConfirmation;
