import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { WidgetRenderDebug } from "syklone/graphics/components/index.js";
import { createRestrictedToolPlatformCreatorViewer } from "syklone/graphics/tools/factories.js";
import { withApiContext } from "syklone/api/react/index.js";

// imports: local
import { CommandViewPlatform } from "./command_view_platform.js";

class PlatformCreatorViewer extends WidgetRenderDebug {
  static propTypes = {
    classes: PropTypes.object,
    state: PropTypes.object,
    buildFileId: PropTypes.string,
    buildFileType: PropTypes.string,
    api: PropTypes.object,
    platformIndex: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.api = props.api;
    this.refWidgetRender = React.createRef();
  }

  async componentDidMount() {
    const profileManager = this.api.profileManager;
    let profile = profileManager.getCurrentProfile();

    const buildFileId = this.props.buildFileId;
    const buildFileType = this.props.buildFileType;
    const platformIndex = this.props.platformIndex;

    super.componentDidMount();
    let widgetRender = this.refWidgetRender.current;
    this.tool = createRestrictedToolPlatformCreatorViewer(this.api, widgetRender, profile);

    function loader(loading, text) {
      widgetRender.setLoading(loading, text);
    }

    this.api.commandManager.executeAsync(
      new CommandViewPlatform(
        this.tool,
        loader,
        {
          buildFileId: buildFileId,
          buildFileType: buildFileType,
          platformIndex: platformIndex,
        },
        this.api
      )
    );
  }
}

const PlatformCreatorViewerWithApi = withApiContext(PlatformCreatorViewer);

export default PlatformCreatorViewerWithApi;
