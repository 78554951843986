import React, { useState, useEffect } from "react";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useMutation, useQueryClient } from "syklone/libraries/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";
import { useParams } from "react-router-dom";

function getImagePathById(index) {
  const imagePaths = ["mjf.png", "ebm.png", "lrm.png"];
  const cyclicIndex = index % imagePaths.length;
  return imagePaths[cyclicIndex];
}

const INITIAL_MACHINE_DATA = {
  avatar: "",
  id: null,
  name: "",
  location: "",
  domain: "",
  description: "",
  endpoint: "",
  status: 0,
  isActive: false,
  meta: "",
};

const withMachineData = (Component) => {
  const MachineData = () => {
    const [scheduledBuildFilesData, setScheduledBuildFilesData] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const { id } = useParams();
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();
    const { showSnackbar } = useAlertSnackbar();

    //Dialog states
    const [buildStartDialogOpen, setBuildStartDialogOpen] = useState(false);
    const [isPlayDialogLoading, setIsPlayDialogLoading] = useState(false);

    React.useEffect(() => {
      if (!id) {
        setErrorMessage("ID is required but was not provided");
      } else {
        setErrorMessage(null);
      }
    }, [id]);

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const sykloneApi = apiInstance.sykloneApi;
    const serviceBuildCreator = apiInstance.sykloneApi.serviceBuildCreator;
    const {
      data: adminMachineById = INITIAL_MACHINE_DATA,
      isLoading: isLoadingMachineById,
      error: adminMachineError,
    } = useQuery(
      ["adminMachineById", id],
      async () => {
        const response = await serviceAuth.getAdminEquipmentByEquipmentAccountId(id);
        const item = response.data.data;
        const modifiedData = {
          avatar: item.image || getImagePathById(item.id),
          id: item.id,
          name: item.name,
          site: item.site,
          domain: item["domain_name"],
          description: item.description,
          endpoint: item.endpoint,
          status: item["is_qualified_for_production"],
          isActive: !!item["is_qualified_for_production"],
          meta: item["meta"],
          drift: item["drift"] ? (typeof item["drift"] === "string" ? JSON.parse(item["drift"]) : item["drift"]) : {},
          machineDefinition: item["machine_definition_id"],
        };
        return modifiedData;
      },
      {
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchInterval: 10000,
      }
    );

    const {
      data: locations = [],
      isLoading: isLocationsLoading,
      error: locationsError,
    } = useQuery(
      ["machineLocations"],
      async () => {
        const response = await serviceBuildCreator.getLocations();
        const data = response;
        const modifiedData = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        return modifiedData;
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const {
      data: machineDefinitions = [],
      isLoading: isMachineDefinitionsLoading,
      error: machineDefinitionsError,
    } = useQuery(
      ["machineDefinitions"],
      async () => {
        const response = await serviceBuildCreator.getMachineDefinitions();
        const machineDefinitionData = response.data.data["machine-definitions"];
        const modifiedData = machineDefinitionData?.map((item) => ({
          value: item?._id?.$oid ?? "",
          label: item.definitionName,
        }));
        return modifiedData;
      },
      {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
      }
    );

    const invalidateAdminEquipment = () => {
      // this is because if the data didnt change to trigger useEffectAgain
      queryClient.setQueryData(["adminMachineById", id], INITIAL_MACHINE_DATA);
      queryClient.invalidateQueries(["adminMachineById", id]);
    };

    const {
      isLoading: isLoadingSchedulesBuildFilesMachine,
      isSuccess: isSuccessBuildFilesMachine,
      data: scheduledBuildFilesMachine,
    } = useQuery({
      queryKey: ["scheduledBuildFilesMachine"],
      queryFn: () => sykloneApi.bcGetScheduledBuildfiles(),
      onError: () => {
        showSnackbar("Error getting scheduled build files.", "error");
      },
      refetchInterval: 5000,
    });

    const {
      isLoading: isLoadingReleasedBuildFilesMachine,
      isSuccess: isSuccessReleasedMachine,
      data: releasedBuildFilesMachine,
    } = useQuery({
      queryKey: ["releasedBuildFilesMachine"],
      queryFn: () => sykloneApi.bcGetBfmReleased(),
      onError: () => {
        showSnackbar("Error getting released build files.", "error");
      },
    });

    const {
      isLoading: isLoadingRdBuildFilesMachine,
      isSuccess: isSuccessRdBuildFilesMachine,
      data: rdBuildFilesMachine,
    } = useQuery({
      queryKey: ["rdBuildFilesMachine"],
      queryFn: () => sykloneApi.getBfmValidRndBuildFilesByMachineId(),
      onError: () => {
        showSnackbar("Error getting Research & development build files.", "error");
      },
    });

    const {
      isLoading: isLoadingUrBuildFilesMachine,
      isSuccess: isSuccessUrBuildFilesMachine,
      data: urBuildFilesMachine,
    } = useQuery({
      queryKey: ["urBuildFilesMachine"],
      queryFn: () => sykloneApi.getBfmValidUnderReviewBuildFilesByMachineId(),
      onError: () => {
        showSnackbar("Error getting under review build files.", "error");
      },
    });

    const postBfmPrintApproved = async (machineId) => {
      if (machineId === null || machineId === undefined) {
        return;
      }

      try {
        setIsPlayDialogLoading(true);
        let resposne = await sykloneApi.postBfmPrintApproved(machineId);
        if (resposne) {
          queryClient.invalidateQueries({ queryKey: ["scheduledBuildFiles"] });
          showSnackbar("Event for running machine excuted successfully.", "success");
          setBuildStartDialogOpen(false);
          setIsPlayDialogLoading(false);
        }
        return resposne;
      } catch (error) {
        console.log("Error running printing taks: ", error);
        showSnackbar("Error running printing taks.", "error");
        setIsPlayDialogLoading(false);
      }
    };

    const { mutate: postBfmSwitchLockByMachineId } = useMutation(
      async (data) => {
        const { machineId } = data;
        let response = await sykloneApi.postBfmSwitchLockByMachineId(machineId);
        return response.data;
      },
      {
        onSuccess: (data) => {
          const message = data?.message ? data?.message : "Machine locked.";
          showSnackbar(message, "success");
        },
        onError: (error) => {
          showSnackbar("Error locking the machine.", "error");
        },
      }
    );

    const { mutate: postBfmSwitchUnlockByMachineId } = useMutation(
      async (data) => {
        const { machineId } = data;
        let response = await sykloneApi.postBfmSwitchUnlockByMachineId(machineId);
        return response.data;
      },
      {
        onSuccess: (data) => {
          const message = data?.message ? data?.message : "Machine locked.";
          showSnackbar(message, "success");
        },
        onError: (error) => {
          showSnackbar("Error locking the machine.", "error");
        },
      }
    );

    const buildFileData = scheduledBuildFilesData?.buildFileData;
    const isMachineLocked = Array.isArray(buildFileData) && buildFileData.some((item) => item.metadata.lock);

    useEffect(() => {
      if (
        adminMachineById &&
        isSuccessReleasedMachine &&
        isSuccessBuildFilesMachine &&
        isSuccessRdBuildFilesMachine &&
        isSuccessUrBuildFilesMachine
      ) {
        const machine = adminMachineById;
        const machineScheduledBuildFiles =
          scheduledBuildFilesMachine.data.data
            .flatMap((scheduled) => scheduled.machine)
            .find((schMachine) => schMachine.machineId === String(machine.id))?.items || [];

        let allBfTypes = [
          ...rdBuildFilesMachine.data.data,
          ...urBuildFilesMachine.data.data,
          ...releasedBuildFilesMachine.data.data,
        ];

        const buildFileData = machineScheduledBuildFiles
          .map((scheduledBuildFile) => {
            const filteredBuildFile = allBfTypes.find((buildFile) => buildFile.id === scheduledBuildFile.buildFileId);
            if (filteredBuildFile) {
              const updatedMachineValidity = filteredBuildFile.machineValidity.map((validity) => {
                return {
                  ...validity,
                  machineId: machine.id,
                  lotId: scheduledBuildFile.lotId,
                };
              });

              return {
                id: filteredBuildFile.id,
                typeName: "Build file",
                itemTitle: filteredBuildFile.name,
                itemSubTitlePrefix: "Created by",
                itemSubTitle: filteredBuildFile.lifecycle[0].user,
                status: filteredBuildFile.status,
                metadata: {
                  jobId: scheduledBuildFile.jobId,
                  machineId: machine.id,
                  lotId: scheduledBuildFile.lotId,
                  lock: scheduledBuildFile.lock,
                },
                machineValidity: updatedMachineValidity,
              };
            }
            return null;
          })
          .filter((buildFile) => buildFile !== null);

        setScheduledBuildFilesData({
          machine: {
            id: machine.id,
            typeName: "Machine",
            itemTitle: machine.name,
            itemSubTitlePrefix: "Domain",
            itemSubTitle: machine.domain_name,
            status: machine.status,
          },
          buildFileData,
        });
      }
    }, [
      adminMachineById,
      isSuccessReleasedMachine,
      scheduledBuildFilesMachine,
      isSuccessUrBuildFilesMachine,
      isSuccessRdBuildFilesMachine,
    ]);

    const combinedLoading =
      isLoadingMachineById ||
      isLocationsLoading ||
      isMachineDefinitionsLoading ||
      isLoadingSchedulesBuildFilesMachine ||
      isLoadingReleasedBuildFilesMachine ||
      isLoadingRdBuildFilesMachine ||
      isPlayDialogLoading ||
      isLoadingUrBuildFilesMachine;

    const combinedError = adminMachineError || locationsError || machineDefinitionsError || errorMessage;

    return (
      <Component
        data={adminMachineById}
        scheduledBuildFilesData={scheduledBuildFilesData}
        isLoading={combinedLoading}
        optionsData={{ locations, machineDefinitions }}
        handleRefreshData={invalidateAdminEquipment}
        postBfmPrintApproved={postBfmPrintApproved}
        postBfmSwitchLockByMachineId={postBfmSwitchLockByMachineId}
        postBfmSwitchUnlockByMachineId={postBfmSwitchUnlockByMachineId}
        isMachineLocked={isMachineLocked}
        dialogFn={{
          setBuildStartDialogOpen,
        }}
        dialogState={{
          buildStartDialogOpen,
        }}
        error={combinedError}
      />
    );
  };
  MachineData.displayName = "MachineData";
  MachineData.propTypes = {};
  return MachineData;
};

export default withMachineData;
