import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Tooltip, Typography } from "syklone/ui/index.js";

function WidgetOverflowTip({ children, placement = "bottom" }) {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);
  return (
    <Tooltip
      title={<Typography sx={{ fontSize: 13 }}>{children}</Typography>}
      disableHoverListener={!isOverflowed}
      placement={placement}
      arrow
    >
      <span
        ref={textElementRef}
        style={{
          display: "block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
}

WidgetOverflowTip.propTypes = {
  children: PropTypes.string,
  placement: PropTypes.string,
};

export default WidgetOverflowTip;
