import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { WidgetRenderDebug } from "syklone/graphics/components/index.js";
import { createRestrictedToolPartCreatorViewer } from "syklone/graphics/tools/factories.js";
import { withApiContext } from "syklone/api/react/index.js";

// imports: local
import { CommandViewPart } from "./command_view_part.js";

class PartCreatorViewer extends WidgetRenderDebug {
  static propTypes = {
    classes: PropTypes.object,
    state: PropTypes.object,
    buildFileId: PropTypes.string,
    buildFileType: PropTypes.string,
    api: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.api = props.api;
    this.refWidgetRender = React.createRef();
  }

  async componentDidMount() {
    const profileManager = this.api.profileManager;
    let profile = profileManager.getCurrentProfile();

    const buildFileId = this.props.buildFileId;
    const buildFileType = this.props.buildFileType;

    super.componentDidMount();
    let widgetRender = this.refWidgetRender.current;
    this.tool = createRestrictedToolPartCreatorViewer(this.api, widgetRender, profile);

    function loader(loading, text) {
      widgetRender.setLoading(loading, text);
    }

    this.api.commandManager.executeAsync(
      new CommandViewPart(
        this.tool,
        loader,
        {
          buildFileId: buildFileId,
          buildFileType: buildFileType,
        },
        this.api
      )
    );
  }
}

const PartCreatorViewerWithApi = withApiContext(PartCreatorViewer);

export default PartCreatorViewerWithApi;
