import React, { Component } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DialogAbout as DialogAboutCommon } from "syklone/components/dialogs/index.js";
import { withApiContext } from "syklone/api/react/index.js";

// imports: local
import { about } from "../../core/index.js";

class _DialogAbout extends Component {
  static defaultProps = {
    open: false,
  };

  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    api: PropTypes.object,
  };

  // -------- Public: Reactjs --------
  constructor(props) {
    super(props);
    this._api = React.createRef();
    this.state = {
      versions: {},
      ...props,
    };
    this.contextApi = props.api;
  }

  async componentDidMount() {
    try {
      const sykloneApi = this.contextApi.sykloneApi;
      const versions = await sykloneApi.metrics();
      this.setState({ versions: versions });
    } catch (error) {
      alert(`${error}`);
    }
  }

  // -------- Public: Getters/Setters --------
  get api() {
    return this._api.current;
  }

  get items() {
    return [
      ...this.contextApi.profileManager.items(),
      ...this.contextApi.buildSettings.items(),
      { key: "DEP: REACT", value: React.version },
      { key: "VERSION_SUITE", value: this.contextApi?.suiteVersion ? this.contextApi.suiteVersion : -1 },
    ];
  }

  // -------- Public: Slots --------
  show = () => {
    this.api.setState(
      {
        items: about.createItems(this.items),
      },
      () => {
        this.api.show();
      }
    );
  };

  // -------- Public: Reactjs render --------
  render() {
    return (
      <DialogAboutCommon
        open={this.props.open}
        items={about.createItems(this.items)}
        ref={this._api}
        versions={this.state.versions}
      />
    );
  }
}

const DialogAbout = withApiContext(_DialogAbout);

export default DialogAbout;
