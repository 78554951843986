import React from "react";

export function useBlobDownloader() {
  return React.useCallback((blob, filename = "download.pdf", onError = (error) => {}) => {
    try {
      if (window === "undefined" || typeof document === "undefined") {
        return;
      }
      // Attempt to create a URL for the Blob
      const url = URL.createObjectURL(blob);

      // Attempt to create a temporary <a> element and set attributes for download
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;

      // Append to the document and trigger the download
      document.body.appendChild(a);
      a.click();

      // Clean up by removing the element and revoking the Blob URL
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      onError(error); // Call the provided onError callback with the error
    }
  }, []);
}
