export const getGridItems = (size) => {
  switch (size) {
    case "0":
      return [
        { number: 1, left: -80.62, top: -97 },
        { number: 2, left: 0.0, top: -97 },
        { number: 3, left: 80.62, top: -97 },
        { number: 4, left: -80.62, top: -50 },
        { number: 5, left: 0.0, top: -50 },
        { number: 6, left: 80.62, top: -50 },
        { number: 7, left: -80.62, top: 0 },
        { number: 8, left: 0.0, top: 0 },
        { number: 9, left: 80.62, top: 0 },
        { number: 10, left: -80.62, top: 50 },
        { number: 11, left: 0.0, top: 50 },
        { number: 12, left: 80.62, top: 50 },
        { number: 13, left: -80.62, top: 97.17 },
        { number: 14, left: 80.62, top: 97.17 },
      ];
    case "4":
      return [
        { number: 1, left: -80.62, top: -97 },
        { number: 2, left: 0.0, top: -97 },
        { number: 3, left: 80.62, top: -97 },
        { number: 4, left: -80.62, top: -50 },
        { number: 5, left: 0.0, top: -50 },
        { number: 6, left: 80.62, top: -50 },
        { number: 7, left: -80.62, top: 0 },
        { number: 8, left: 0.0, top: 0 },
        { number: 9, left: 80.62, top: 0 },
        { number: 10, left: -80.62, top: 50 },
        { number: 11, left: 0.0, top: 50 },
        { number: 12, left: 80.62, top: 50 },
        { number: 13, left: -80.62, top: 97.17 },
        { number: 14, left: 80.62, top: 97.17 },
      ];
    case "6":
      return [
        { number: 1, left: -80.62, top: -87.96 },
        { number: 2, left: 0.0, top: -87.96 },
        { number: 3, left: 80.62, top: -87.96 },
        { number: 4, left: -80.62, top: -28.58 },
        { number: 5, left: 0.0, top: -28.58 },
        { number: 6, left: 80.62, top: -28.58 },
        { number: 7, left: -80.62, top: 30.79 },
        { number: 8, left: 0.0, top: 30.79 },
        { number: 9, left: 80.62, top: 30.79 },
        { number: 10, left: -80.62, top: 90.17 },
        { number: 11, left: 80.62, top: 90.17 },
      ];
    case "8":
      return [
        { number: 1, left: -75.5, top: -86.96 },
        { number: 2, left: 75.5, top: -86.96 },
        { number: 3, left: 0.0, top: -43 },
        { number: 4, left: -75.5, top: 0.96 },
        { number: 5, left: 75.5, top: 0.96 },
        { number: 6, left: 0.0, top: 43 },
        { number: 7, left: -75.5, top: 86.96 },
        { number: 8, left: 75.5, top: 86.96 },
      ];
    default:
      return [];
  }
};
