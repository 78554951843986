import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, styled } from "syklone/ui/index.js";

const ChipWrapper = styled(({ type, ...rest }) => <Box {...rest} />)(({ theme, type, value }) => {
  let color = "";
  switch (type.toLowerCase()) {
    case "success":
      color = theme.palette.success.main;
      break;
    case "warning":
      color = theme.palette.warning.dark;
      break;
    case "error":
      color = theme.palette.error.main;
      break;
    case "unlisted":
      color = theme.palette.grey[800];
      break;
    default:
      return "No type provided or does not exist.";
  }
  return {
    display: "flex",
    gap: "10px",
    padding: value ? "6px 7px 6px 14px" : "6px 7px 6px 6px",
    backgroundColor: color,
    color: "#fff",
    borderRadius: "20px",
    fontWeight: 500,
    alignItems: "center",
    width: value ? "auto" : "100%",
    justifyContent: value ? "space-between" : "center",
  };
});

const ChipTitle = styled(Box)({
  fontSize: "12px",
  fontWeight: 600,
  textTransform: "uppercase",
});

const ChipValue = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "2px 8px",
  fontSize: "12px",
  borderRadius: "15px",
  background: "#fff",
  color: "#000",
}));

function WidgetCustomChip({ type, title, val }) {
  return (
    <ChipWrapper type={type}>
      <ChipTitle aria-label="testTitle">{title}</ChipTitle>
      {val && <ChipValue>{val}</ChipValue>}
    </ChipWrapper>
  );
}

WidgetCustomChip.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  val: PropTypes.number,
};

export default WidgetCustomChip;
