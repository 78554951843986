import React from "react";
import { Route, Routes, Navigate, useNavigate, useLocation, matchPath } from "react-router-dom";

// imports: syklone
import { WidgetNotFound } from "syklone/components/widgets/index.js";
import {
  AuthLoginRedirect,
  AuthGuard,
  AuthErrorPage,
  AuthLogoutRedirect,
  AuthProtectedRoute,
} from "syklone/api/react/index.js";
import { PermissionApplicationRoutes } from "syklone/api/modules/permission_settings/data/index.js";
import { useQueryClient } from "syklone/libraries/index.js";

// imports: local
import { DashboardWithSidebar, DashboardWithoutSidebar } from "./components/layouts/index.js";
import {
  PageAdmin,
  PageAuditTrail,
  PageAuditTrailAtDefault,
  PageAuditTrailAtDetails,
  PageElectronicSignatureResponse,
  PageGfDashboard,
  PageGfLanding,
  PageGfLandingAccess,
  PageGfLandingDefault,
  PageGfLandingEcosystem,
  PageGlobalAdmin,
  PageGlobalAdminDashboard,
  PageGlobalAdminSiteConfigurationEndpoints,
  PageGlobalAdminSiteConfigurationGroupMapToPermissions,
  PageGlobalAdminSiteConfigurationGroups,
  PageGlobalAdminSiteConfigurationPermissionMapToEndpoints,
  PageGlobalAdminSiteConfigurationPermissions,
  PageGlobalAdminSiteConfigurationUserMapToGroups,
  PageGlobalAdminSiteConfigurationUsers,
  PageMachine,
  PageMachineList,
  PageMachines,
  PagePartPlatform,
  PageProductDetails,
  PageProductFamilies,
  PageScheduling,
  PageSchedulingAuto,
  PageSchedulingDistributed,
  PageSchedulingManual,
  PageSchedulingPriority,
  PageTableDataBuildFiles,
} from "./components/pages/index.js";
import {
  withMachineData,
  withMachineDataListData,
  withPageProductDetails,
  withPageProductFamilies,
  withPageSchedulingManual,
  withSiteConfigurationEndpoints,
  withSiteConfigurationGroupMapToPermissions,
  withSiteConfigurationGroups,
  withSiteConfigurationPermissionMapToEndpoints,
  withSiteConfigurationPermissions,
  withSiteConfigurationUsersMapToGroup,
  withSiteConfigurationUsers,
  withUserAdminStatistics,
  withAuditTrailListData,
  withAuditTrailDetailsData,
} from "./components/hoc/index.js";

import {
  WidgetPartPlatformViewer,
  WidgetPartViewer,
  WidgetPlatformViewer,
  WidgetSliceAnalyser,
} from "./components/widgets/index.js";

// Admin dashboard HOC
const UserAdminStatistics = withUserAdminStatistics(PageGlobalAdminDashboard);
const SiteConfigurationGroups = withSiteConfigurationGroups(PageGlobalAdminSiteConfigurationGroups);
const SiteConfigurationPermissions = withSiteConfigurationPermissions(PageGlobalAdminSiteConfigurationPermissions);
const SiteConfigurationUsers = withSiteConfigurationUsers(PageGlobalAdminSiteConfigurationUsers);
const SiteConfigurationEndpoints = withSiteConfigurationEndpoints(PageGlobalAdminSiteConfigurationEndpoints);

// Admin Mapping
const SiteConfigurationPermissionMapToEndpoints = withSiteConfigurationPermissionMapToEndpoints(
  PageGlobalAdminSiteConfigurationPermissionMapToEndpoints
);
const SiteConfigurationGroupMapToPermissions = withSiteConfigurationGroupMapToPermissions(
  PageGlobalAdminSiteConfigurationGroupMapToPermissions
);
const SiteConfigurationUsersMapToGroups = withSiteConfigurationUsersMapToGroup(
  PageGlobalAdminSiteConfigurationUserMapToGroups
);

// Machines HOC
const PageMachineListHoc = withMachineDataListData(PageMachineList);
const PageMachineHoc = withMachineData(PageMachine);

// Product briefcase HOC
const ProductBriefcaseHOC = withPageProductFamilies(PageProductFamilies);
const PageProductDetailsHOC = withPageProductDetails(PageProductDetails);

// Scheduling HOC
const SchedulingPageManual = withPageSchedulingManual(PageSchedulingManual);

// AuditTrail Hoc
const AuditTrailListPage = withAuditTrailListData(PageAuditTrailAtDefault);
const AuditTrailDetailsPage = withAuditTrailDetailsData(PageAuditTrailAtDetails);

function App() {
  const navigate = useNavigate();
  let location = useLocation();
  const queryClient = useQueryClient();

  return (
    <Routes>
      <Route element={<PageGfLanding />}>
        <Route path="/" element={<PageGfLandingDefault />} />
        <Route path="system-information" element={<PageGfLandingEcosystem />} />
        <Route path="access" element={<PageGfLandingAccess />} />
      </Route>
      <Route
        exact
        path="/api/oauth/dashboard"
        element={<AuthLoginRedirect loginRoute="/" successRoute="/dashboard" />}
      />
      <Route exact path="/api/oauth/logout" element={<AuthLogoutRedirect loginRoute="/" />} />
      <Route exact path="/api/oauth/error" element={<AuthErrorPage loginRoute="/" />} />

      <Route exact path="/api/oauth/electronic-signature/redirect" element={<PageElectronicSignatureResponse />} />

      <Route path="*" element={<WidgetNotFound />} />

      {/* Private routes */}
      <Route element={<AuthGuard loginRoute="/" />}>
        <Route path="/dashboard" element={<Navigate to="/dashboard/part-platform" />} />
        <Route element={<PageGfDashboard navigate={navigate} location={location} match={matchPath} />}>
          <Route
            element={
              <DashboardWithSidebar
                navigate={navigate}
                location={location}
                match={matchPath}
                queryClient={queryClient}
              />
            }
          >
            {/* Part/Platforms */}
            <Route
              exact
              path="/dashboard/part-platform"
              element={<PagePartPlatform navigate={navigate} location={location} />}
            />

            <Route element={<WidgetPartPlatformViewer navigate={navigate} location={location} match={matchPath} />}>
              <Route path="/dashboard/part-platform/viewer/part-viewer" element={<WidgetPartViewer />} />
              <Route path="/dashboard/part-platform/viewer/platform-viewer" element={<WidgetPlatformViewer />} />
              <Route path="/dashboard/part-platform/viewer/slice-analyser" element={<WidgetSliceAnalyser />} />
            </Route>
          </Route>

          {/* Global Admin Dashboard */}
          <Route element={<DashboardWithoutSidebar navigate={navigate} location={location} match={matchPath} />}>
            <Route element={<PageGlobalAdmin navigate={navigate} location={location} />}>
              <Route
                path="/dashboard/admin"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendAdminDashboard]}>
                    <UserAdminStatistics />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/dashboard/admin/configuration/users"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendUsers]}>
                    <SiteConfigurationUsers />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/dashboard/admin/configuration/users/:id/groups"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendAdminUsersGroups]}>
                    <SiteConfigurationUsersMapToGroups />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/dashboard/admin/configuration/groups"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendGroups]}>
                    <SiteConfigurationGroups />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/dashboard/admin/configuration/groups/:id/permissions"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendAdminGroupsPermissions]}>
                    <SiteConfigurationGroupMapToPermissions />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/dashboard/admin/configuration/permissions"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendGroups]}>
                    <SiteConfigurationPermissions />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/dashboard/admin/configuration/permissions/:id/endpoints"
                element={<SiteConfigurationPermissionMapToEndpoints />}
              />
              <Route
                path="/dashboard/admin/configuration/endpoints"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendEndpoints]}>
                    <SiteConfigurationEndpoints />
                  </AuthProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* Products / Product families */}
          <Route element={<DashboardWithoutSidebar navigate={navigate} location={location} match={matchPath} />}>
            <Route
              exact
              path="/dashboard/product-families"
              element={
                <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendProductFamilies]}>
                  <ProductBriefcaseHOC />
                </AuthProtectedRoute>
              }
            />
            <Route
              element={
                <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendProductFamilies]}>
                  <PageProductDetailsHOC />
                </AuthProtectedRoute>
              }
            >
              <Route
                exact
                path="/dashboard/product-families/:id"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendProductFamilies]}>
                    <PageTableDataBuildFiles />
                  </AuthProtectedRoute>
                }
              />
            </Route>
          </Route>

          {/* Scheduling */}
          <Route element={<DashboardWithoutSidebar navigate={navigate} location={location} match={matchPath} />}>
            <Route path="/dashboard/scheduling" element={<Navigate to="/dashboard/scheduling/manual" />} />
            <Route element={<PageScheduling navigate={navigate} location={location} match={matchPath} />}>
              <Route
                path="/dashboard/scheduling/manual"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendSchedulingManual]}>
                    <SchedulingPageManual />
                  </AuthProtectedRoute>
                }
              />
              <Route path="/dashboard/scheduling/auto" element={<PageSchedulingAuto />} />
              <Route path="/dashboard/scheduling/priority" element={<PageSchedulingPriority />} />
              <Route path="/dashboard/scheduling/distributed" element={<PageSchedulingDistributed />} />
            </Route>
          </Route>

          {/* Machines */}
          <Route element={<DashboardWithoutSidebar navigate={navigate} location={location} match={matchPath} />}>
            <Route element={<PageMachines navigate={navigate} location={location} match={matchPath} />}>
              <Route
                exact
                path="/dashboard/machines"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendMachines]}>
                    <PageMachineListHoc />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/dashboard/machines/:id"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageGlobalFrontendMachines]}>
                    <PageMachineHoc />
                  </AuthProtectedRoute>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* Audit Trail */}
        <Route element={<PageAuditTrail navigate={navigate} location={location} />}>
          <Route path="audit" element={<AuditTrailListPage />} />
          <Route path="audit/:contentTypeId/:objectId/:auditTrailId" element={<AuditTrailDetailsPage />} />
        </Route>
        <Route exact path="/admin-test" element={<PageAdmin navigate={navigate} location={location} />} />
      </Route>
    </Routes>
  );
}

export default App;
