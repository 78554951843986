import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { Box, Grid, Typography, styled } from "syklone/ui/index.js";
import { usePermissionChecks } from "syklone/api/react/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import { WidgetOverflowTip, WidgetPopoverMenu } from "../../widgets/index.js";
import { TableDataColoredTextCell } from "../../tables/components/index.js";

const BoxContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#f8f8f8",
  justifyContent: "space-between",
  borderRadius: "3px",
  display: "flex",
  width: "100%",
  alignItems: "center",
  padding: "12px 20px",
  border: "1px solid transparent",
  transition: "0.2s linear",
  "&:hover": {
    border: "1px dotted #373737",
  },
}));

const ImgContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1E1E1E" : "#f8f8f8",
  borderRadius: "50%",
  width: 84,
  height: 84,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  "& > p": {
    fontSize: 12,
  },
}));

const Tagline = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  opacity: 0.6,
}));

function WidgetMachineItem({ data, setters }) {
  const navigate = useNavigate();
  const { checkElementVisibility } = usePermissionChecks();

  const isEquipmentActive = data.isActive;
  const menuData = {
    menuItems: [
      {
        name: "Details",
        action: () => navigate(`/dashboard/machines/${data.id}`),
      },
      {
        name: "Edit",
        action: () => {
          setters.setDialogEditDataFn(data);
          setters.setIsDialogEditEquipmentOpenFn(true);
        },
        disabled: !checkElementVisibility(PermissionVisualElements.machinesEditMachine),
      },
      // Conditionally add "Drift update" if setIsDialogueDriftOpenFn is available
      ...(setters.setIsDialogueDriftOpenFn
        ? [
            {
              name: "Drift update",
              action: () => {
                setters.setDialogEditDataFn(data);
                setters.setIsDialogueDriftOpenFn(true);
              },
            },
          ]
        : []),
      {
        name: `${isEquipmentActive ? "Deactivate" : "Activate"} machine`,
        action: () => {
          setters.setDialogEditMachineDataFn(data);
          setters.setIsDialogDeleteEquipmentOpenFn(true);
        },
        type: isEquipmentActive ? "error" : "success",
        disabled: isEquipmentActive
          ? !checkElementVisibility(PermissionVisualElements.machinesDeactivateMachine)
          : !checkElementVisibility(PermissionVisualElements.machinesActivateMachine),
      },
    ],
  };

  return (
    <BoxContainer>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Grid container columnSpacing={4} alignItems="center" sx={{ width: "100%", flexWrap: "nowrap" }}>
          <Grid item>
            <ImgContainer>
              {data.avatar ? <img src={`/img/${data.avatar}`} /> : <Typography>No image found</Typography>}
            </ImgContainer>
          </Grid>
          <Grid item sx={{ flexGrow: 0, flexBasis: "14%", maxWidth: "14%" }}>
            <Tagline>Machine name</Tagline>
            <Typography>
              <WidgetOverflowTip>{data.name}</WidgetOverflowTip>
            </Typography>
          </Grid>
          <Grid item sx={{ flexGrow: 0, flexBasis: "14%", maxWidth: "14%" }}>
            <Tagline>Location</Tagline>
            <Typography>
              <WidgetOverflowTip>{data.location}</WidgetOverflowTip>
            </Typography>
          </Grid>
          <Grid item sx={{ flexGrow: 0, flexBasis: "14%", maxWidth: "14%" }}>
            <Tagline>Domain</Tagline>
            <Typography>
              <WidgetOverflowTip>{data.domain}</WidgetOverflowTip>
            </Typography>
          </Grid>
          <Grid item sx={{ flexGrow: 0, flexBasis: "14%", maxWidth: "14%" }}>
            <Tagline>Description</Tagline>
            <Typography>
              <WidgetOverflowTip>{data.description}</WidgetOverflowTip>
            </Typography>
          </Grid>
          <Grid item sx={{ flexGrow: 0, flexBasis: "14%", maxWidth: "14%" }}>
            <Tagline>Endpoint</Tagline>
            <Typography>
              <WidgetOverflowTip>{data.endpoint}</WidgetOverflowTip>
            </Typography>
          </Grid>
          <Grid item sx={{ flexGrow: 0, flexBasis: "14%", maxWidth: "14%", minWidth: "210px" }}>
            <Tagline>Status</Tagline>
            <TableDataColoredTextCell
              condition={data.isActive}
              trueText="Qualified for production"
              falseText="Not Qualified for production"
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <WidgetPopoverMenu data={menuData} id={"machines"} />
      </Box>
    </BoxContainer>
  );
}

WidgetMachineItem.propTypes = {
  data: PropTypes.object,
  setters: PropTypes.object,
};

export default WidgetMachineItem;
