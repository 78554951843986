import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { fileDownload, useQuery, useMutation, useQueryClient } from "syklone/libraries/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

// imports: local
import { dataSearchResults } from "../../data/index.js";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function familyDataAdapter(id, title, assets, description, image) {
  return {
    id: id,
    title: title,
    assets: {
      description: description,
      files: assets.map((item) => {
        return {
          name: item.name,
          link: item.blobPath,
        };
      }),
    },
    productIDs: [],
    image: image,
  };
}

const withPageProductFamilies = (Component) => {
  const PageProductFamilies = () => {
    const apiInstance = useApiContext();
    const sykloneApi = apiInstance.sykloneApi;
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { showSnackbar } = useAlertSnackbar();

    // imports: local state
    const [data, setData] = useState([]);
    const [productFamilies, setPageProductFamilies] = useState([]);

    // Dialogs state
    const [isDialogAddFilesOpen, setIsDialogAddFilesOpen] = useState(false);
    const [isDialogAddFamilyOpen, setIsDialogAddFamilyOpen] = useState(false);
    const [familyName, setFamilyName] = useState("");
    const [description, setDescription] = useState("");
    const [productFamilyImage, setProductFamilyImage] = useState(null);
    const [filesList, setFilesList] = useState(null);

    // Alert state
    const [alert, setAlert] = useState({ message: "", severity: "", snackBar: false, component: "" });

    // Loading state
    const [isSearchLoading, setSearchIsLoading] = useState(false);

    // Active product family
    const [active, setActive] = useState({});

    // Assets menu
    const dataMenu = {
      menuItems: [
        {
          name: "Download",
          action: (_event, state) =>
            onClickDownloadProductFamilyFiles.mutate({ id: state.id, blob: state.assets.name }),
        },
        {
          name: "Delete file",
          action: (_event, state) => onClickDeleteProductFamilyFiles.mutate({ id: state.id, blob: state.assets.name }),
          type: "error",
        },
      ],
    };

    const updateActiveState = (array) => {
      let files = array.map((item) => {
        return {
          link: item.blobPath,
          name: item.name,
        };
      });

      setActive((prevState) => ({
        ...prevState,
        assets: {
          ...prevState.assets,
          files: files,
        },
      }));
    };

    const getSearchResults = async () => {
      setSearchIsLoading(true);
      await timeout(500);
      setSearchIsLoading(false);
      return dataSearchResults;
    };

    function filterByProductFamily(data, family) {
      return data.filter((item) => item.family === family);
    }

    const {
      isLoading,
      isSuccess,
      data: getPageProductFamilies,
    } = useQuery({
      queryKey: ["productFamilies"],
      queryFn: () => sykloneApi.bcGetAllProductFamilies(),
      enabled: true,
      onError: () => {
        showSnackbar("Error establishing connection with the service.", "error");
      },
    });

    useEffect(() => {
      if (isSuccess) {
        setPageProductFamilies(
          getPageProductFamilies.data.data.map((item) =>
            familyDataAdapter(item._id, item.name, item.assets, item.description, item.image)
          )
        );
        setData(productFamilies);
      }
    }, [getPageProductFamilies]);

    useEffect(() => {
      setData(productFamilies);
    }, [productFamilies]);

    const onClickButton = (id, name, image) => {
      navigate(`${id}`, { state: { id: id, name: name, image: image } });
    };

    const onClickCreateProductFamily = useMutation(
      async (data) => {
        return await sykloneApi.bcPostProductFamilies(data);
      },
      {
        onSuccess: (data) => {
          if (productFamilyImage !== null) {
            onUploadProductFamilyImage.mutate({ id: data.data.data._id, image: productFamilyImage });
          } else {
            queryClient.invalidateQueries({ queryKey: ["productFamilies"] });
          }
          showSnackbar("Product family created successfully.", "success");
          setIsDialogAddFamilyOpen(false);
          setFamilyName("");
          setDescription("");
        },
        onError: (error) => {
          //TODO: Alert message
          setAlert({
            message: error.response.data.detail,
            severity: "error",
            snackBar: false,
            component: "DialogAddFamily",
          });
        },
      }
    );
    const onUploadProductFamilyImage = useMutation(
      async ({ id, image }) => {
        const formData = new FormData();
        formData.append("files", image);
        await sykloneApi.bcPostProductFamilyImage(id, formData);
      },
      {
        onSuccess: () => {
          setProductFamilyImage(null);
          queryClient.invalidateQueries({ queryKey: ["productFamilies"] });
        },
        onError: () => {
          setProductFamilyImage(null);
          showSnackbar("Image upload error.", "error");
        },
      }
    );

    const onClickAddProductFamilyFiles = useMutation(
      async (id) => {
        const formData = new FormData();
        for (let element of filesList) {
          formData.append("files", element);
        }
        let response = await sykloneApi.bcPostProductFamilyFiles(id, formData);
        return response.data;
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({ queryKey: ["productFamilies"] });
          updateActiveState(data.data.assets);
          showSnackbar("File/files uploaded successfully.", "success");
          setIsDialogAddFilesOpen(false);
          setFilesList(null);
        },
        onError: (error) => {
          setFilesList(null);
          //TODO: Alert Message
          setAlert({
            message: error.response.data.detail,
            severity: "error",
            snackBar: false,
            component: "DialogAddFiles",
          });
        },
      }
    );

    /* eslint-disable */
    const onClickDeleteProductFamilyFiles = useMutation(
      async ({ id, blob }) => {
        const queryParams = {
          blob_name: blob,
        };
        let response = await sykloneApi.bcDeleteProductFamilyAssetsById(id, queryParams);
        return {
          data: response.data,
          blob: blob,
        };
      },
      {
        onSuccess: ({ data, blob }) => {
          queryClient.invalidateQueries({ queryKey: ["productFamilies"] });
          updateActiveState(data.data.assets);
          showSnackbar(`File "${blob}" deleted successfully.`, "success");
          setFilesList(null);
        },
        onError: (error) => {
          setFilesList(null);
          const message = error?.response?.data?.message
            ? error.response.data.message
            : "There was an error deleting the asset";
          showSnackbar(message, "error");
        },
      }
    );

    const onClickDownloadProductFamilyFiles = useMutation(
      async ({ id, blob }) => {
        const queryParams = {
          blob_name: blob,
        };
        let response = await sykloneApi.bcGetProductFamilyAssetsById(id, queryParams, {
          responseType: "blob",
        });
        return {
          data: response.data,
          blob: blob,
        };
      },
      {
        onSuccess: ({ data, blob }) => {
          fileDownload(data, blob);
        },
        onError: (error) => {
          showSnackbar("File download error.", "error");
        },
      }
    );

    const getProductFamilyImage = async (id, image) => {
      const queryParams = {
        blob_name: image,
      };
      try {
        let response = await sykloneApi.bcGetProductFamilyImageById(id, queryParams, {
          responseType: "blob",
        });
        return response.data;
      } catch (error) {
        showSnackbar("Error getting the image.", "error");
      }
    };

    const getProductFromFamiliy = async (id) => {
      try {
        return await sykloneApi.bcGetProductFromFamily(id);
      } catch (error) {
        showSnackbar("Error getting products.", "error");
      }
    };

    const isDataLoading = isLoading || onClickCreateProductFamily.isLoading || onUploadProductFamilyImage.isLoading;
    const isDeleting = onClickDeleteProductFamilyFiles.isLoading;
    const isAddingFiles = onClickAddProductFamilyFiles.isLoading;
    const isDownloading = onClickDownloadProductFamilyFiles.isLoading;

    return (
      <Component
        active={active}
        setActive={setActive}
        alert={alert}
        setAlert={setAlert}
        dialogState={{
          isDialogAddFilesOpen,
          isDialogAddFamilyOpen,
          familyName,
          description,
          productFamilyImage,
          filesList,
        }}
        dialogFn={{
          setIsDialogAddFilesOpen,
          setIsDialogAddFamilyOpen,
          setFamilyName,
          setDescription,
          setProductFamilyImage,
          setFilesList,
        }}
        data={data}
        dataMenu={dataMenu}
        getSearchResults={getSearchResults}
        isLoadingStatus={{ isSearchLoading, isDataLoading, isDeleting, isAddingFiles, isDownloading }}
        onClickButton={onClickButton}
        onClickCreateProductFamily={onClickCreateProductFamily.mutate}
        onClickAddProductFamilyFiles={onClickAddProductFamilyFiles.mutate}
        onClickDownloadProductFamilyFiles={onClickDownloadProductFamilyFiles.mutate}
        getProductFamilyImage={getProductFamilyImage}
        filterByProductFamily={filterByProductFamily}
        getProductFromFamiliy={getProductFromFamiliy}
      />
    );
  };

  PageProductFamilies.displayName = "PageProductFamilies";
  PageProductFamilies.propTypes = {};

  return PageProductFamilies;
};

export default withPageProductFamilies;
