import React, { useState, useEffect, useRef } from "react";
import { PropTypes } from "prop-types";

// imports: syklone
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  Switch,
  Typography,
  useFormik,
  Yup,
} from "syklone/ui/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";
import { useQueryClient } from "syklone/libraries/index.js";

// imports: local
import { BUILD_FILES_PROMOTION_TAB_VALUES } from "../../core/index.js";
import { DialogCommonConfirmation, DialogCommonWizardStepsAbove, DialogNotification } from "../../dialogs/index.js";
import { WidgetWizardActiveStepContainer, WidgetElectronicSignature } from "../index.js";

const sitesData = [
  { site: "Anngrove", type: "local", onHold: false },
  { site: "Global", type: "global", onHold: false },
];

const Header = ({ buildFileName, buildFileId, buildFileStatus }) => {
  const headerStatus = { RESEARCH_AND_DEVELOPMENT: "R&D", UNDER_REVIEW: "Under review", RELEASED: "Released" }[
    buildFileStatus
  ];
  return (
    <Grid container flexDirection="column" alignItems="flex-start">
      <Grid item mb={0.5}>
        <Typography sx={{ fontSize: 16 }}>Set on hold</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ opacity: ".7", fontSize: 12 }}>{`Name: ${buildFileName}`}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ opacity: ".7", fontSize: 12 }}>{`Id: ${buildFileId}`}</Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ opacity: ".7", fontSize: 12 }}>{`Status: ${headerStatus}`}</Typography>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  buildFileName: PropTypes.string,
  buildFileId: PropTypes.string,
  buildFileStatus: PropTypes.string,
};

const generateBfmTypeKey = (bfmType, scope) => {
  const formattedScope = scope.charAt(0).toUpperCase() + scope.slice(1).toLowerCase();
  const lowerCaseType = bfmType.toLowerCase();
  const parts = lowerCaseType.split("_");
  const pascalCaseParts = parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1));
  return `buildFiles${pascalCaseParts.join("")}${formattedScope}SiteList`;
};

const FormOnHold = ({
  data,
  values,
  handleChange,
  touched,
  errors,
  bfmType,
  onHoldState,
  initialOnHoldSet,
  setInitialOnHoldSet,
  setFieldValue,
}) => {
  const { checkElementVisibility } = usePermissionChecks();

  useEffect(() => {
    if (values.site && !initialOnHoldSet) {
      setFieldValue("onHold", onHoldState.includes(values.site));
      setInitialOnHoldSet(true);
    }
  }, [values.site, onHoldState, setFieldValue, initialOnHoldSet]);

  return (
    <Grid container flexDirection="column" alignItems="flex-start" spacing={1}>
      <Grid item mt={1}>
        <FormControl sx={{ width: "396px" }} error={touched.site && Boolean(errors.site)}>
          <InputLabel id="site_selection" htmlFor="site_selection" required>
            Select site
          </InputLabel>
          <Select
            native
            label="Select site"
            labelId="site_selection"
            id="site_selection"
            name="site"
            value={values.site}
            onChange={(event) => {
              handleChange(event);
              setInitialOnHoldSet(false);
            }}
          >
            <option aria-label="None" value="" />
            {checkElementVisibility(PermissionVisualElements[generateBfmTypeKey(bfmType, "global")]) &&
              (data.some((entry) => entry.type === "global") ? (
                <optgroup label="Global" id={`${bfmType.toLowerCase()}-global-site-list`}>
                  {data
                    .filter((entry) => entry.type === "global")
                    .map((entry) => (
                      <option key={entry.site} value={entry.site}>
                        {entry.site}
                      </option>
                    ))}
                </optgroup>
              ) : (
                <optgroup label="Global">
                  <option disabled style={{ fontStyle: "italic" }}>
                    No Global Sites
                  </option>
                </optgroup>
              ))}
            {checkElementVisibility(PermissionVisualElements[generateBfmTypeKey(bfmType, "local")]) &&
              (data.some((entry) => entry.type === "local") ? (
                <optgroup label="Local" id={`${bfmType.toLowerCase()}-local-site-list`}>
                  {data
                    .filter((entry) => entry.type === "local")
                    .map((entry) => (
                      <option key={entry.site} value={entry.site}>
                        {entry.site}
                      </option>
                    ))}
                </optgroup>
              ) : (
                <optgroup label="Local">
                  <option disabled style={{ fontStyle: "italic" }}>
                    No Local Sites
                  </option>
                </optgroup>
              ))}
          </Select>
          {touched.site && errors.site && <FormHelperText error={true}>{errors.site}</FormHelperText>}
        </FormControl>
      </Grid>
      {values.site ? (
        <Grid item sx={{ paddingLeft: "0px!important" }}>
          <FormControlLabel
            sx={{
              marginLeft: 0,
              marginRight: 0,
              marginTop: 1,
              "& > .MuiFormControlLabel-label": {
                fontWeight: "bold",
              },
            }}
            control={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Switch
                  id="id-onhold-switch"
                  checked={values.onHold}
                  onChange={handleChange}
                  name="onHold"
                  color="primary"
                />
                <span>{values.onHold ? "On" : "Off"}</span>
              </div>
            }
            label="On hold"
            labelPlacement="top"
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

FormOnHold.propTypes = {
  data: PropTypes.array,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  bfmType: PropTypes.string,
  onHoldState: PropTypes.array,
  initialOnHoldSet: PropTypes.bool,
  setInitialOnHoldSet: PropTypes.func,
  setFieldValue: PropTypes.func,
};

const validationFirstStepSchema = Yup.object().shape({
  site: Yup.string()
    .oneOf(sitesData.map((entry) => entry.site))
    .required("Site selection is required."),
  onHold: Yup.boolean(),
});

const authenticationStepSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  userSigned: Yup.boolean().required("Required").oneOf([true], "You must digitally sign the promotion"),
});

const combinedTwoStepSchema = validationFirstStepSchema.shape({
  ...authenticationStepSchema.fields,
});

const initialValuesSingle = { site: "", onHold: false, type: "" };

const initialValuesSteps = {
  ...initialValuesSingle,
  username: "",
  userSigned: false,
  userComment: "",
};

const stepsTitle = ["Details", "Authenticate"];

function WidgetBfOnHoldDialog({
  isDialogOpen,
  setIsDialogOpen,
  buildFileStatus,
  buildFileName,
  buildFileId,
  onHoldState,
}) {
  const apiInstance = useApiContext();
  const { showSnackbar } = useAlertSnackbar();
  const serviceBfm = apiInstance.sykloneApi.serviceBfm;

  const widgetElectronicSignatureRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialOnHoldSet, setInitialOnHoldSet] = useState(false);

  const isLastStep = stepsTitle.length === activeStep;
  const hasSteps = buildFileStatus !== BUILD_FILES_PROMOTION_TAB_VALUES[0];

  const queryClient = useQueryClient();

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const previousStep = () => {
    widgetElectronicSignatureRef.current?.resetSignature();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClose = () => {
    if (hasSteps) {
      setActiveStep(0);
    }
    formik.resetForm();
    widgetElectronicSignatureRef.current?.resetSignature();
    setIsDialogOpen(false);
  };

  const onTabKeyPropagation = (event) => {
    if (event.key === "Tab") {
      event.stopPropagation();
    }
  };

  const handleEdit = async () => {
    widgetElectronicSignatureRef.current?.setSignatureError(false);
    const { username, userSigned, userComment, site, onHold } = formik.values;

    let formData;
    if (site === "Global") {
      formData = { onHold: onHold };
    } else {
      formData = { site: site, onHold: onHold };
    }

    const electronicSignature = {
      ["user_signed_email"]: username,
      ["user_signed"]: userSigned,
      comment: userComment,
    };

    try {
      setIsSubmitting(true);
      let response;
      let payload;

      switch (buildFileStatus) {
        case "RESEARCH_AND_DEVELOPMENT":
          response = await serviceBfm.postResearchAndDevelopmentHoldByBuildFileId(buildFileId, formData);
          queryClient.invalidateQueries({ queryKey: ["dataRdCollection"] });
          break;
        case "UNDER_REVIEW":
          payload = { ...formData, electronic_signature: electronicSignature }; // eslint-disable-line
          response = await serviceBfm.postUnderReviewHoldByBuildFileId(buildFileId, payload);
          queryClient.invalidateQueries({ queryKey: ["dataUnderReview"] });
          break;
        case "RELEASED":
          payload = { ...formData, electronic_signature: electronicSignature }; // eslint-disable-line
          response = await serviceBfm.postReleasedHoldByBuildFileId(buildFileId, payload);
          queryClient.invalidateQueries({ queryKey: ["dataReleased"] });
          break;

        default:
          throw new Error("Build File status must be one of: 'RESEARCH_AND_DEVELOPMENT', 'UNDER_REVIEW','RELEASED'.");
      }

      const message = response?.data?.message ? response?.data?.message : "Build file edited successfully";
      showSnackbar(message, "info");

      onClose();
    } catch (e) {
      console.log(e);
      showSnackbar(e, "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  let handleNext;
  let buttonName;
  let validationSchema;
  let initialValues;

  if (hasSteps) {
    initialValues = initialValuesSteps;
    switch (activeStep) {
      case 0:
        handleNext = nextStep;
        buttonName = "Next";
        validationSchema = validationFirstStepSchema;
        break;
      case 1:
        handleNext = handleEdit;
        buttonName = "Save Data";
        validationSchema = combinedTwoStepSchema;
        break;
      default:
        handleNext = onClose;
        buttonName = "Close";
        validationSchema = null;
        break;
    }
  } else {
    initialValues = initialValuesSingle;
    validationSchema = validationFirstStepSchema;
    handleNext = handleEdit;
    buttonName = "Save Data";
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleNext,
  });

  const { values, setFieldValue, handleChange, handleBlur, touched, errors } = formik;

  useEffect(() => {
    if (values.site && !initialOnHoldSet) {
      setFieldValue("onHold", onHoldState.includes(values.site));
      setInitialOnHoldSet(true);
    }
  }, [values.site, onHoldState, setFieldValue, initialOnHoldSet]);

  let buildFileErrorMessage;

  if (hasSteps) {
    return (
      <DialogCommonWizardStepsAbove
        maxWidth="xs"
        fullWidth
        dataTag="edit-dialog-auth"
        stepsTitle={stepsTitle}
        nextStepTitle={buttonName}
        isOpen={isDialogOpen}
        onClose={() => onClose()}
        activeStep={activeStep}
        handleNext={() => formik.handleSubmit()}
        handleBack={!isLastStep ? previousStep : () => undefined}
        isBackDisabled={isLastStep}
        onKeyDown={onTabKeyPropagation}
        isNextDisabled={isSubmitting}
        showClose
        disableBackdropClick
      >
        <Grid container>
          <Grid item mt={1}>
            <Header buildFileName={buildFileName} buildFileId={buildFileId} buildFileStatus={buildFileStatus} />
          </Grid>
          <DialogNotification alert={buildFileErrorMessage} />
          <WidgetWizardActiveStepContainer activeStep={activeStep}>
            <Grid item mt={1}>
              <FormOnHold
                data={sitesData}
                values={values}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                bfmType={buildFileStatus}
                onHoldState={onHoldState}
                initialOnHoldSet={initialOnHoldSet}
                setInitialOnHoldSet={setInitialOnHoldSet}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Box sx={{ width: "100%", mt: 1 }}>
              <WidgetElectronicSignature
                ref={widgetElectronicSignatureRef}
                values={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </Box>
          </WidgetWizardActiveStepContainer>
        </Grid>
      </DialogCommonWizardStepsAbove>
    );
  } else {
    return (
      <DialogCommonConfirmation
        onConfirm={() => formik.submitForm()}
        showClose={false}
        onCancel={() => onClose()}
        onClose={() => onClose()}
        isOpen={isDialogOpen}
        onConfirmTitle={buttonName}
        fullWidth
        maxWidth="xs"
        dataTag="edit-dialog-single"
        isConfirmDisabled={isSubmitting}
      >
        <Grid container item>
          <Header buildFileName={buildFileName} buildFileId={buildFileId} buildFileStatus={buildFileStatus} />
          <DialogNotification alert={buildFileErrorMessage} />
          <Grid item mt={1}>
            <FormOnHold
              data={sitesData}
              values={values}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              bfmType={buildFileStatus}
              onHoldState={onHoldState}
              initialOnHoldSet={initialOnHoldSet}
              setInitialOnHoldSet={setInitialOnHoldSet}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </Grid>
      </DialogCommonConfirmation>
    );
  }
}

WidgetBfOnHoldDialog.propTypes = {
  isDialogOpen: PropTypes.bool,
  setIsDialogOpen: PropTypes.func,
  buildFileStatus: PropTypes.string,
  buildFileName: PropTypes.string,
  buildFileId: PropTypes.string,
  onHoldState: PropTypes.array,
};
WidgetBfOnHoldDialog.defaultProps = {
  isDialogOpen: false,
  setIsDialogOpen: () => {},
  buildFileStatus: "",
  buildFileName: "",
  buildFileId: "",
  onHoldState: [],
};

export default WidgetBfOnHoldDialog;
