import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Tabs, Tab, styled } from "syklone/ui";
import DriftUpdatesList from "./components/drift_history_list.jsx";
import DriftUpdateTool from "./components/drift_update_tool.jsx";

const CustomGrid = styled(Grid)({
  width: "100%",
  paddingBottom: "0px",
  marginBottom: "0px",
});

const TabBox = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.mode === "dark" ? "#2F2F2F" : "#E8E8E8"}`,
}));

function accessibilityProps(index) {
  return {
    id: `machine-drift-tab-${index}`,
    "aria-controls": `machine-drift-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, noBottomPad, ...other } = props;

  const paddingStyle = noBottomPad ? { pt: 3 } : { pt: 3, pb: 3 };

  return (
    <div role="tabpanel" id={`drift-tabpanel-${index}`} aria-labelledby={`drift-tab-${index}`} {...other}>
      {value === index && <Box sx={paddingStyle}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  noBottomPad: PropTypes.bool,
};

function DriftInterface(props) {
  const { machineData, saveMachineDetailsDriftFn } = props;
  const [, setDriftHistoryListData] = useState([]);

  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <CustomGrid item>
        <Box id="outerbox" style={{ paddingBottom: "0px" }}>
          <TabBox id="tabbox">
            <Tabs value={value} onChange={handleChange} aria-label="drift tabs">
              <Tab label="Update Drift" {...accessibilityProps(0)} data-syklone="tab-update-drift" />
              <Tab label="Drift History" {...accessibilityProps(1)} data-syklone="tab-goto-drift-history" />
            </Tabs>
          </TabBox>

          {value === 0 && (
            <TabPanel
              id="tabpanelfordriftupdatetool"
              value={value}
              index={0}
              noBottomPad={true}
              style={{ height: "100%", backgroundColor: "black", paddingBottom: "0" }}
            >
              <DriftUpdateTool machineData={machineData} saveMachineDetailsDriftFn={saveMachineDetailsDriftFn} />
            </TabPanel>
          )}

          {value === 1 && (
            <TabPanel id="tabpanelforhistory" value={value} index={1} noBottomPad={false}>
              <DriftUpdatesList setDriftHistoryListData={setDriftHistoryListData} machineData={machineData} />
            </TabPanel>
          )}
        </Box>
      </CustomGrid>
    </>
  );
}

DriftInterface.propTypes = {
  machineData: PropTypes.object,
  saveMachineDetailsDriftFn: PropTypes.func.isRequired,
};

export default DriftInterface;
