import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  TextField,
  Typography,
  Yup,
  useFormik,
} from "syklone/ui/index.js";

// imports: local
import {
  WidgetElectronicSignature,
  WidgetPasswordInput,
  WidgetWizardActiveStepContainer,
} from "../../widgets/index.js";
import { DialogCommonWizardStepsAbove, DialogElectronicSignature } from "../index.js";

const stepsTitle = ["Details", "Authenticate"];

const stepsCompleteStatus = {
  0: false,
  1: false,
};

const pendingDetailsStepSchema = Yup.object().shape({
  inputValues: Yup.array()
    .of(Yup.string().required("Field is required").max(1, "Max length exceeded"))
    .required("Field is required")
    .test("inputValidation", "Validation error", function (value) {
      const { inputValuesRepeat, lotId } = this.parent;

      if (value.join("") !== inputValuesRepeat.join("")) {
        return this.createError({ message: "Lot ID values must match" });
      }

      if (value.join("") !== lotId) {
        return this.createError({ message: "Lot ID does not match the provided ID" });
      }

      return true;
    }),
  inputValuesRepeat: Yup.array()
    .of(Yup.string().required("Field is required").max(1, "Max length exceeded"))
    .required("Field is required"),
});

const pendingAuthenticationStepSchema = Yup.object().shape({
  username: Yup.string().email("Invalid email").required("Required"),
  userSigned: Yup.boolean().required("Required").oneOf([true], "You must digitally sign the promotion"),
});

const schemaArray = [pendingDetailsStepSchema, pendingAuthenticationStepSchema];

const arePreviousStepsComplete = (stepsObject) => {
  return Object.values(stepsObject)
    .slice(0, -1)
    .every((v) => v);
};

const widgetName = "dialog-start-build-file";

const NotificationStep = ({ message }) => {
  return (
    <Box sx={{ mb: 2 }} data-syklone={`${widgetName}-message`}>
      {message}
    </Box>
  );
};

NotificationStep.propTypes = {
  message: PropTypes.string,
};

const DialogStartBuildFileTitle = ({ machine }) => {
  return (
    <Typography sx={{ margin: "16px 0px 8px 0px", fontWeight: 500, fontSize: 16 }}>
      Start machine - {`${machine.itemTitle}`}
    </Typography>
  );
};

DialogStartBuildFileTitle.propTypes = {
  machine: PropTypes.object,
};

const LotInput = ({ fields, title, values, handleChange, errors, touched, repeat, setMatch }) => {
  const handleInputFocus = (index) => {
    handleChange(`inputValues[${index}]`, "");
  };

  const handleInputChange = (event) => {
    let e = event;
    e.target.value = e.target.value.toUpperCase();
    return handleChange(e);
  };

  const getBackgroundColor = (values, repeat, index) => {
    if (repeat) {
      return values.inputValuesRepeat[index] === "" ? "black" : "#434343";
    } else {
      return values.inputValues[index] === "" ? "black" : "#434343";
    }
  };

  useEffect(() => {
    if (
      errors.inputValues === "Lot ID values must match" ||
      errors.inputValues === "Lot ID does not match the provided ID"
    ) {
      setMatch(errors.inputValues);
    } else {
      setMatch(null);
    }
  }, [errors.inputValues]);

  return (
    <div style={{ marginBottom: "10px" }}>
      <Typography sx={{ paddingBottom: "5px", fontWeight: "bold" }}>{title}</Typography>
      {Array.from({ length: fields }).map((_, index) => (
        <input
          key={index}
          type="text"
          maxLength={1}
          name={repeat ? `inputValuesRepeat[${index}]` : `inputValues[${index}]`}
          value={repeat ? values.inputValuesRepeat[index] : values.inputValues[index]}
          onChange={(event) => handleInputChange(event)}
          onFocus={() => handleInputFocus(index)}
          style={{
            backgroundColor: getBackgroundColor(values, repeat, index),
            color: "inherit",
            borderRadius: "6px",
            border: "none",
            outline: "none",
            fontSize: "16px",
            padding: "15px",
            marginRight: "8px",
            width: "50px",
            textAlign: "center",
          }}
          autoComplete="off"
        />
      ))}
      {!repeat && errors.inputValues && errors.inputValues !== "Lot ID values must match" && touched.inputValues && (
        <div style={{ color: "#f44336" }}>All fields are required.</div>
      )}
      {repeat && errors.inputValuesRepeat && touched.inputValuesRepeat && (
        <div style={{ color: "#f44336" }}>All fields are required.</div>
      )}
    </div>
  );
};

LotInput.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.number,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  repeat: PropTypes.bool,
  setMatch: PropTypes.func,
};

//step 1
const DialogStartBuildStep = ({ fields, values, handleChange, errors, touched }) => {
  const [match, setMatch] = useState(null);

  return (
    <Box sx={{ minWidth: 396 }}>
      {match && (
        <Alert severity="warning" sx={{ marginBottom: "8px" }}>
          {match}
        </Alert>
      )}
      <form>
        <LotInput
          fields={fields}
          title="Enter Lot ID"
          values={values}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          setMatch={setMatch}
        />

        <LotInput
          fields={fields}
          title="Repeat Lot ID"
          values={values}
          handleChange={handleChange}
          errors={errors}
          touched={touched}
          repeat={true}
          setMatch={setMatch}
        />
      </form>
    </Box>
  );
};

DialogStartBuildStep.propTypes = {
  fields: PropTypes.number,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

//step2
const DialogStartBuildStepAuth = ({ values, handleChange, errors, touched }) => {
  return (
    <form>
      <FormControl required sx={{ mb: 1.5 }} fullWidth>
        <TextField
          name="username"
          label="Email"
          variant="outlined"
          required
          value={values.username}
          onChange={handleChange}
          error={touched.username && Boolean(errors.username)}
          helperText={touched.username && errors.username}
        />
      </FormControl>

      <FormControl
        required
        variant="outlined"
        sx={{ mb: 1.5 }}
        fullWidth
        error={touched.password && Boolean(errors.password)}
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <WidgetPasswordInput
          label="Password"
          name="password"
          value={values.password}
          onChange={handleChange}
          error={touched.password && Boolean(errors.password)}
        />
        {touched.password && errors.password && <FormHelperText>{errors.password}</FormHelperText>}
      </FormControl>

      <FormControl required sx={{ mb: 1.5 }} fullWidth>
        <TextField
          name="userComment"
          label="Comment"
          variant="outlined"
          value={values.userComment}
          onChange={handleChange}
          error={touched.userComment && Boolean(errors.userComment)}
          helperText={touched.userComment && errors.userComment}
        />
      </FormControl>
    </form>
  );
};

DialogStartBuildStepAuth.propTypes = {
  values: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
};

DialogStartBuildStepAuth.displayName = "DialogStartBuildStepAuth";

const DialogStartBuildFile = React.forwardRef(
  (
    {
      isOpen,
      onClose,
      data,
      onApprovePart,
      onApproveApproved,
      isPlayDialogLoading,
      postBfmSwitchLockByMachineId,
      postBfmSwitchUnlockByMachineId,
    },
    ref
  ) => {
    if (!data) {
      return null;
    }

    const [activeStep, setActiveStep] = React.useState(0);
    const [messageStepText, setMessageStepText] = React.useState("");
    const approvalDialogSteps = React.useRef(stepsCompleteStatus);
    const isLastStep = stepsTitle.length === activeStep;
    const lotId = data?.buildFileData?.[0]?.metadata?.lotId;
    const [fields, setFields] = useState(0);

    const widgetElectronicSignatureRef = React.useRef(null);
    const dialogElectronicSignatureRef = React.useRef(null);

    const initializeFormValues = (fieldCount) => ({
      inputValues: Array(fieldCount).fill(""),
      inputValuesRepeat: Array(fieldCount).fill(""),
      username: "",
      userSigned: false,
      userComment: "",
      lotId: lotId,
    });

    const formik = useFormik({
      initialValues: initializeFormValues(fields),
      validationSchema: schemaArray[activeStep],
      onSubmit: async (values, { resetForm }) => {
        if (activeStep === 0) {
          nextStep();
        } else {
          await handleApprovePart();
        }
        resetForm({ values: initializeFormValues(fields) });
      },
      enableReinitialize: true,
    });

    const { values, setFieldValue, handleChange, handleBlur, errors, touched, resetForm } = formik;

    useEffect(() => {
      const lockMachine = async () => {
        if (isOpen) {
          const payload = {
            machineId: data.machine.id,
            machineName: data.machine.itemTitlem,
          };
          await postBfmSwitchLockByMachineId(payload);
        }
      };
      lockMachine();
    }, [isOpen]);

    useEffect(() => {
      if (isOpen && lotId === "undefined") {
        dialogElectronicSignatureRef.current?.setIsDialogOpen(isOpen);
      }
    }, [isOpen, lotId]);

    useEffect(() => {
      dialogElectronicSignatureRef.current?.setIsSubmitting(isPlayDialogLoading);
    }, [isPlayDialogLoading]);

    useEffect(() => {
      const fieldCount = lotId?.length || 0;
      setFields(fieldCount);
      // dialogElectronicSignatureRef.current?.resetSignature();
      resetForm({ values: initializeFormValues(fieldCount) });
      setActiveStep(0);
      setMessageStepText("");
      approvalDialogSteps.current = stepsCompleteStatus;
    }, [isOpen, lotId, resetForm]);

    useEffect(() => {
      if (isOpen) {
        setFields(lotId?.length || 0);
        setActiveStep(0);
        resetForm({ values: initializeFormValues(lotId?.length || 0) });
        setMessageStepText("");
        approvalDialogSteps.current = stepsCompleteStatus;
      }
    }, [isOpen, lotId]);

    useEffect(() => {
      if (lotId?.length) {
        setFields(lotId.length); // Update the number of fields based on lotId length first.
        resetForm({ values: initializeFormValues(lotId.length) }); // Then reset the form with the new field count.
      }
    }, [lotId, resetForm]);

    const nextStep = () => {
      approvalDialogSteps.current[activeStep] = true;
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    React.useImperativeHandle(
      ref,
      () => ({
        setStepMessage: (text) => setMessageStepText(text),
        nextStep: () => nextStep(),
      }),
      []
    );

    const previousStep = () => {
      setMessageStepText("");
      widgetElectronicSignatureRef.current?.resetSignature();
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleApprovePart = async () => {
      const firstPart = data.buildFileData[0];
      /* eslint-disable camelcase */
      let payload = {
        comment: values.userComment,
        machine_id: firstPart.metadata.machineId,
        meaning: "",
        reason: "",
        signee_email: values.username,
        user_signed: values.userSigned,
      };

      const payloadLockMachine = {
        machineId: data.machine.id,
        machineName: data.machine.itemTitlem,
      };

      const canSubmitForm = arePreviousStepsComplete(approvalDialogSteps.current);

      if (!canSubmitForm) {
        setMessageStepText("Some of the steps are not completed");
        return;
      }
      await onApproveApproved(payload);
      await postBfmSwitchUnlockByMachineId(payloadLockMachine);
    };

    // TODO: New flow may be defined with Conor for handling parts without lotId
    // eslint-disable-next-line
    const handleApprovePartNoLotId = async (erValues) => {
      const firstPart = data.buildFileData[0];
      let payload = {
        comment: erValues.comment,
        machine_id: firstPart.metadata.machineId,
        meaning: "",
        reason: "",
        signee_email: erValues.user_signed_email,
        user_signed: erValues.user_signed,
      };
      const payloadLockMachine = {
        machineId: data.machine.id,
        machineName: data.machine.itemTitlem,
      };

      await onApproveApproved(payload);
      await postBfmSwitchUnlockByMachineId(payloadLockMachine);

      dialogElectronicSignatureRef.current?.setIsDialogOpen(false);
    };

    const onTabKeyPropagation = (event) => {
      if (event.key === "Tab") {
        event.stopPropagation();
      }
    };

    if (lotId !== "undefined") {
      return (
        <DialogCommonWizardStepsAbove
          maxWidth={"xs"}
          dataTag={widgetName}
          stepsTitle={stepsTitle}
          nextStepTitle={isPlayDialogLoading ? "Loading..." : activeStep === 0 ? "Next" : "Schedule build file"}
          isNextDisabled={isPlayDialogLoading}
          isOpen={isOpen}
          onClose={async () => {
            const payload = {
              machineId: data.machine.id,
              machineName: data.machine.itemTitlem,
            };
            await postBfmSwitchUnlockByMachineId(payload);
            onClose(false);
          }}
          activeStep={activeStep}
          handleNext={() => formik.handleSubmit()}
          handleBack={!isLastStep ? previousStep : () => undefined}
          isBackDisabled={isLastStep}
          onKeyDown={onTabKeyPropagation}
          showClose
          disableBackdropClick
        >
          <DialogStartBuildFileTitle machine={data.machine} />
          <NotificationStep message={messageStepText} />
          <Alert variant="filled" severity="info" sx={{ marginBottom: "8px" }}>
            The machine is locked. Please complete the process or close the dialog to unlock it.
          </Alert>

          <WidgetWizardActiveStepContainer activeStep={activeStep}>
            <DialogStartBuildStep
              fields={fields}
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
            />
            <WidgetElectronicSignature
              ref={widgetElectronicSignatureRef}
              values={values}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          </WidgetWizardActiveStepContainer>
          {!isLastStep && <Typography sx={{ fontSize: 12, opacity: ".5" }}>* required</Typography>}
        </DialogCommonWizardStepsAbove>
      );
    } else {
      return (
        <DialogElectronicSignature
          ref={dialogElectronicSignatureRef}
          onSubmitSuccess={handleApprovePartNoLotId}
          onDialogClose={async () => {
            const payload = {
              machineId: data.machine.id,
              machineName: data.machine.itemTitlem,
            };
            await postBfmSwitchUnlockByMachineId(payload);
            onClose(false);
          }}
          onConfirmTitle={isPlayDialogLoading ? "Loading..." : "Ok"}
        />
      );
    }
  }
);

DialogStartBuildFile.displayName = "DialogStartBuildFile";

DialogStartBuildFile.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onApprovePart: PropTypes.func.isRequired,
  onApproveApproved: PropTypes.func,
  postBfmSwitchLockByMachineId: PropTypes.func,
  postBfmSwitchUnlockByMachineId: PropTypes.func,
  isPlayDialogLoading: PropTypes.bool,
};

export default DialogStartBuildFile;
