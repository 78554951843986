import React from "react";
import PropTypes from "prop-types";
import { Box } from "syklone/ui";

const OverlayImage = ({ leftPos, topPos, width, height, backgroundImage }) => {
  return (
    <Box
      sx={{
        ...styles.overlay,
        left: `${((leftPos + 125) / 250) * 100}%`,
        top: `${(1 - (topPos + 125) / 250) * 100}%`,
        width: `${width}%`,
        height: `${height}%`,
        backgroundImage: `url(${backgroundImage})`,
      }}
    />
  );
};

OverlayImage.propTypes = {
  leftPos: PropTypes.number.isRequired,
  topPos: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  backgroundImage: PropTypes.string.isRequired,
};

const styles = {
  overlay: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "white",
    "& .numberLabel": {
      fontSize: "2rem",
      color: "black",
      fontWeight: "bold",
    },
  },
};

export default OverlayImage;
