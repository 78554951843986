import React, { useEffect } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Button,
  FormControl,
  Stack,
  TextField,
  Yup,
  useFormik,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

// imports: local
import { DialogResponseMessage } from "../index.js";
// import { WidgetImageSelector } from "../../widgets/index.js";
// import { dataMachineImages } from "../../data/index.js";

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  site: Yup.string().required("Site is required"),
  domain: Yup.string().required("Domain is required"),
  endpoint: Yup.string().required("Endpoint is required"),
  machineDefinition: Yup.string().required("Machine definition is required"),
});

const DialogMachine = React.forwardRef(({ onDialogEdit, onDialogCreate, optionsData }, ref) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const dialogDataRef = React.useRef(null);
  const id = dialogDataRef.current?.id;
  const locations = optionsData.locations || [];
  const machineDefinitions = optionsData.machineDefinitions || [];

  const onSubmit = async (values) => {
    const payload = {
      name: values.name,
      ["domain_name"]: values.domain,
      description: values.description,
      site: values.site,
      endpoint: values.endpoint,
      ["is_qualified_for_production"]: values.status ? 1 : 0,
      ["machine_definition_id"]: values.machineDefinition,
    };
    if (isEditMode) {
      onDialogEdit(id, payload);
    } else {
      onDialogCreate(payload);
    }
  };

  const initialValues = {
    name: "",
    domain: "",
    description: "",
    site: "",
    endpoint: "",
    status: false,
    machineDefinition: "",
  };

  React.useImperativeHandle(ref, () => ({
    setResponseMessage: (errorMessage) => {
      setResponseMessage(errorMessage);
    },
    setDialogData: (data) => {
      setIsEditMode(data ? true : false);
      dialogDataRef.current = data;
    },
    setIsDialogOpen: (value) => {
      setIsOpen(value);
    },
    setIsSubmitting: (value) => {
      setIsSubmitting(value);
    },
  }));

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (isOpen && isEditMode) {
      const { name, domain, description, site, endpoint, status, machineDefinition } =
        dialogDataRef.current ?? initialValues;
      formik.setValues({
        name,
        domain,
        description: description,
        site,
        endpoint,
        status,
        machineDefinition,
      });
    } else {
      setResponseMessage(null);
      formik.resetForm();
    }
  }, [isEditMode, isOpen]);

  return (
    <DialogCommonBase
      title={isEditMode ? "Edit Machine" : "Create Machine"}
      isOpen={isOpen}
      dataSyklone="machine-dialog"
      onClose={() => {
        setIsOpen(false);
        formik.resetForm();
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Stack direction="column" spacing={2}>
          <DialogResponseMessage message={responseMessage} widgetName="dialog-machine" />
          <FormControl
            sx={{
              "& > :not(style)": { mt: 1, width: "450px" },
            }}
            noValidate
            autoComplete="off"
            data-syklone="dialog-user-email"
          >
            <TextField
              id="dialog-machine-name"
              label="Name"
              name="name"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.name }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </FormControl>

          <FormControl noValidate autoComplete="off" data-syklone="dialog-machine-domain">
            <TextField
              id="dialog-machine-domain"
              label="Domain"
              name="domain"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.domain }}
              value={formik.values.domain}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.domain && Boolean(formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
            />
          </FormControl>

          <FormControl data-syklone="dialog-machine-site" error={formik.touched.site && Boolean(formik.errors.site)}>
            <InputLabel id="dialog-machine-site-label">Site</InputLabel>
            <Select
              labelId="dialog-machine-site-label"
              id="dialog-machine-site"
              value={formik.values.site}
              label="Site"
              name="site"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {locations.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            {formik.touched.site && formik.errors.site && (
              <FormHelperText>{formik.errors.site}</FormHelperText> // Display the error message
            )}
          </FormControl>

          <FormControl noValidate autoComplete="off" data-syklone="dialog-machine-endpoint">
            <TextField
              id="dialog-machine-endpoint"
              label="Endpoint"
              name="endpoint"
              variant="outlined"
              InputLabelProps={{ shrink: !!formik.values.endpoint }}
              value={formik.values.endpoint}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.endpoint && Boolean(formik.errors.endpoint)}
              helperText={formik.touched.endpoint && formik.errors.endpoint}
            />
          </FormControl>

          <FormControl
            data-syklone="dialog-machine-definition"
            error={formik.touched.machineDefinition && Boolean(formik.errors.machineDefinition)}
          >
            <InputLabel id="dialog-machine-definition-label">Machine definition</InputLabel>
            <Select
              labelId="dialog-machine-definition-label"
              id="dialog-machine-definition"
              value={formik.values.machineDefinition}
              label="Machine definition"
              name="machineDefinition"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              {machineDefinitions.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            {formik.touched.machineDefinition && formik.errors.machineDefinition && (
              <FormHelperText>{formik.errors.machineDefinition}</FormHelperText> // Display the error message
            )}
          </FormControl>

          <FormControl noValidate autoComplete="off" data-syklone="dialog-machine-description">
            <TextField
              id="dialog-machine-description"
              label="Description"
              name="description"
              variant="outlined"
              multiline
              rows={4}
              InputLabelProps={{ shrink: !!formik.values.description }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </FormControl>
          {/* 
          <FormControl noValidate autoComplete="off" data-syklone="dialog-machine-image-selector">
            <WidgetImageSelector
              itemData={dataMachineImages}
              onImageUpload={(imageFile, imageName) => console.log("onImageUpload: ", imageFile, imageName)}
              onImageSelected={(img) => console.log("onImageSelected: ", img)}
              onCancel={() => console.log("onCancel")}
            />
          </FormControl>
          */}
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.status}
                onChange={formik.handleChange}
                name="status"
                data-syklone="dialog-machine-is-qualified-for-production"
              />
            }
            label="Is Qualified for production"
          />
          <Button
            variant="contained"
            size="large"
            type="submit"
            data-syklone="dialog-machine-button"
            disabled={isSubmitting}
          >
            {isEditMode ? "Edit Machine" : "Create Machine"}
          </Button>
        </Stack>
      </form>
    </DialogCommonBase>
  );
});

DialogMachine.displayName = "DialogMachine";

DialogMachine.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onDialogEdit: PropTypes.func,
  onDialogCreate: PropTypes.func,
  dialogData: PropTypes.object,
  message: PropTypes.object,
  optionsData: PropTypes.shape({
    locations: PropTypes.array.isRequired,
    machineDefinitions: PropTypes.array.isRequired,
  }).isRequired,
};

export default DialogMachine;
