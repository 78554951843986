import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Button, Drawer, styled, Typography } from "syklone/ui/index.js";

// imports: local
import { WidgetSystemAlertsItem } from "./components/index.js";

const CustomBoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "470px",
}));

const CustomBoxTitleWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "16px 23px",
  borderBottom: theme.palette.mode === "dark" ? "1px solid #434343" : "1px solid #EEEEEE",
}));

const BoxNotificationWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: 5,
  padding: "6px 13px",
});

function WidgetSystemAlerts({ data, isOpen, setIsOpen }) {
  const handleDrawerClose = () => {
    setIsOpen(false);
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleDrawerClose}
      PaperProps={{
        sx: (theme) => ({
          backgroundColor: theme.palette.mode === "dark" ? "#252525" : "#ffffff",
          backgroundImage: "none",
        }),
      }}
    >
      <CustomBoxWrapper data-syklone="notifications-wrapper">
        <CustomBoxTitleWrapper data-syklone="notifications-title">
          <Box>
            <Typography sx={{ fontSize: "1rem " }}>System alerts</Typography>
          </Box>
          <Box>
            <Button variant="text" size="small">
              Mark all as read
            </Button>
          </Box>
        </CustomBoxTitleWrapper>
        <BoxNotificationWrapper>
          {data && Array.isArray(data) && data.length > 0 ? (
            <>
              {data.map((item) => (
                <WidgetSystemAlertsItem key={item.message} data={item} />
              ))}
            </>
          ) : (
            <Box>No Data</Box>
          )}
        </BoxNotificationWrapper>
      </CustomBoxWrapper>
    </Drawer>
  );
}

WidgetSystemAlerts.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default WidgetSystemAlerts;
