import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Grid, IconButton, icons, styled, keyframes, Tooltip } from "syklone/ui/index.js";
import { moment } from "syklone/libraries/index.js";

const createDate = (str) => {
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

const CustomBox = styled(Box)({
  minWidth: "295px",
  display: "flex",
  backgroundColor: "#000000",
  padding: "14px 16px",
  borderRadius: "3px",
  justifyContent: "space-between",
  cursor: "pointer",
  transition: "0.2s linear",
  border: "1px solid transparent",
  "&:hover": {
    border: "1px dashed #FFB500",
  },
});

const BoxTitle = styled(Box)({
  fontSize: "11px",
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "1px",
  opacity: "0.6",
});

const BoxStatus = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "5px",
});

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const PulsingCircle = styled(Box)({
  width: "5px",
  height: "5px",
  background: "#4ECC6A",
  borderRadius: "50%",
  animation: `${pulseAnimation} 2s infinite`,
});

function WidgetMachineCard({ data, onClick }) {
  const { machineName, location, metadata, status } = data;

  function createMetaData(data) {
    return (
      <>
        <Box>Part number: {data.partNum}</Box>
        <Box>Date started: {createDate(data.dateStarted)}</Box>
        <Box>Expected build time: {data.buildTime}</Box>
      </>
    );
  }

  return (
    <CustomBox onClick={onClick}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item sx={{ width: "80%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} sx={{ width: "50%", maxWidth: "50%" }}>
              <BoxTitle>Name</BoxTitle>
              <Box>{machineName}</Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} sx={{ width: "50%", maxWidth: "50%" }}>
              <BoxTitle>Location</BoxTitle>
              <Box>{location}</Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} sx={{ width: "50%", maxWidth: "50%" }}>
              <BoxTitle>Status</BoxTitle>
              <BoxStatus>
                {status.type === 1 && <PulsingCircle />} {status.name}
              </BoxStatus>
            </Grid>
          </Grid>
        </Grid>
        {metadata && (
          <Grid item>
            <Box>
              <Tooltip title={createMetaData(metadata)}>
                <IconButton>
                  <icons.mui.Info fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </CustomBox>
  );
}

WidgetMachineCard.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default WidgetMachineCard;
