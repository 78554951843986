import React, { useLayoutEffect, useRef, useState } from "react";
import { styled, Box } from "syklone/ui";
import PropTypes from "prop-types";
import PlatformDriftViewer from "./platform_drift_viewer.jsx";

const MainDivBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#E8E8E8",
}));

const DriftOuterBox = styled(Box)({
  paddingBottom: "0",
});

function DriftUpdateTool({ machineData, isUpdate = true, saveMachineDetailsDriftFn }) {
  const ref = useRef(null);
  const [dynamicHeight, setDynamicHeight] = useState("300px");
  const [heightUpdated, setHeightUpdated] = useState(false);
  const widthMM = machineData && machineData.platform && machineData.platform.width ? machineData.platform.width : 250;
  const heightMM =
    machineData && machineData.platform && machineData.platform.height ? machineData.platform.height : 250;

  const updateHeight = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const topPosition = rect.top;
      const windowHeight = window.innerHeight;
      const remainingHeight = windowHeight - topPosition - 60;

      setDynamicHeight(`${remainingHeight}px`);
      setHeightUpdated(true);
    }
  };

  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);

    const handleFullscreenChange = () => {
      setTimeout(updateHeight, 200); // Add a slight delay
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      window.removeEventListener("resize", updateHeight);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <DriftOuterBox id="driftouterdiv">
      <MainDivBox ref={ref} id="maindivboxdrift" style={{ height: dynamicHeight }}>
        {heightUpdated && (
          <PlatformDriftViewer
            widthMM={widthMM}
            heightMM={heightMM}
            isUpdate={isUpdate}
            machineData={machineData}
            saveMachineDetailsDriftFn={saveMachineDetailsDriftFn}
          />
        )}
      </MainDivBox>
    </DriftOuterBox>
  );
}

DriftUpdateTool.propTypes = {
  machineData: PropTypes.object,
  isUpdate: PropTypes.bool,
  saveMachineDetailsDriftFn: PropTypes.func.isRequired,
};

export default DriftUpdateTool;
