import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { moment, pdf } from "syklone/libraries/index.js";
import { Button, icons } from "syklone/ui/index.js";

// imports: local
import { useBlobDownloader } from "../../hooks";

const createPdfDate = () => {
  let now = moment();
  return now.format("DD-MMM-yyyy_HH\uA789mm");
};

/**
 * WidgetButtonGeneratePdf Component
 * This component renders a button that, when clicked, generates a PDF using a React component
 * and optional dynamically fetched data. it also appends date and data if fetched dynamically
 *
 * @param {elementType} componentType - The React component type used for generating the PDF content.
 * @param {function} onError - Optional callback for handling errors during PDF generation.
 * @param {object} componentProps - Optional props to pass to the componentType when rendering it.
 * @param {function} fetchPdfSourceData - Optional async function for fetching data dynamically
 *                                        before generating the PDF.
 */

const WidgetButtonGeneratePdf = ({ componentType, onError, componentProps = {}, fetchPdfSourceData }) => {
  const [pdfLoading, setPdfLoading] = React.useState(false);
  const timerRef = React.useRef(null);
  const downloadBlob = useBlobDownloader();

  React.useEffect(() => {
    return () => {
      if (timerRef.current && (window === "undefined" || typeof document === "undefined"))
        clearTimeout(timerRef.current);
    };
  }, []);

  const handleGeneratePdf = React.useCallback(async () => {
    setPdfLoading(true);
    timerRef.current = setTimeout(async () => {
      try {
        let dataForPdfComponent = componentProps;

        // If fetchData is provided, use it to fetch data dynamically
        if (fetchPdfSourceData) {
          const dynamicData = await fetchPdfSourceData();
          dataForPdfComponent = { ...componentProps, data: dynamicData };
        }
        const date = createPdfDate();
        dataForPdfComponent = { ...dataForPdfComponent, date };

        const DynamicPdfComponent = React.createElement(componentType, dataForPdfComponent);
        const asPdf = pdf([]);
        asPdf.updateContainer(DynamicPdfComponent);
        const blob = await asPdf.toBlob();
        downloadBlob(blob, `audit-trail_${date}.pdf`);
      } catch (error) {
        console.error("Failed to generate or download PDF:", error);
        if (onError) onError(error);
      } finally {
        setPdfLoading(false);
      }
    }, 300);
  }, [componentType, componentProps, fetchPdfSourceData, downloadBlob]);

  return (
    <Button
      disabled={pdfLoading}
      variant="contained"
      startIcon={<icons.mui.PictureAsPdf />}
      onClick={handleGeneratePdf}
      data-syklone="pdf-download"
    >
      {pdfLoading ? "Downloading..." : "Download PDF"}
    </Button>
  );
};

WidgetButtonGeneratePdf.propTypes = {
  componentType: PropTypes.elementType.isRequired,
  componentProps: PropTypes.object,
  onError: PropTypes.func,
  fetchPdfSourceData: PropTypes.func,
};

export default WidgetButtonGeneratePdf;
