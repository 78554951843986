import React from "react";
import { useLocation } from "react-router-dom";

// imports: local
import WidgetPartViewerContainer from "./widget_part_viewer_container.jsx";

function WidgetPartViewer() {
  const location = useLocation();
  const { buildFileId, buildFileType } = location.state;

  return <WidgetPartViewerContainer buildFileId={buildFileId} buildFileType={buildFileType} />;
}

export default WidgetPartViewer;
