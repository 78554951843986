const extractSignatureInfo = (signature) => ({
  email: signature?.signed_email || "",
  name: signature?.signed_name || "",
  date: signature?.signed_date || "",
});

const extractContentType = (type) => ({
  id: type?.id || null,
  name: type?.name || "",
});

const extractUserInfo = (user) => ({
  name: user?.name || "",
  email: user?.email || "",
});

export const formatAuditTrailData = (data) => {
  return data.map((item) => {
    const {
      id,
      action,
      description: electronicRecord,
      date,
      location,
      new_values: newValues,
      object_id: objectId,
      old_values: oldValues,
      electronic_signature: electronicSignature,
      completed_by_id: completedBy,
      content_type: contentTypeData,
    } = item;

    return {
      id,
      action,
      electronicRecordType: extractContentType(contentTypeData),
      electronicRecord: electronicRecord,
      location,
      completedBy: electronicSignature
        ? extractSignatureInfo(electronicSignature)
        : { ...extractUserInfo(completedBy), date },
      completedOn: date,
      electronicSignature: electronicSignature ? extractSignatureInfo(electronicSignature) : null,
      newValues,
      objectId,
      oldValues,
    };
  });
};
