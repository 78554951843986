import React from "react";
import { useQuery } from "syklone/libraries/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { helperAuditTrail } from "../../helpers/index.js";

const AUDIT_TRAIL_ITEMS_PER_PAGE = 10;

const withAuditTrailData = (Component) => {
  const AuditTrailData = () => {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [queryParams, setQueryParams] = React.useState({});
    const [totalPages, setTotalPages] = React.useState(0);
    const [isFiltered, setIsFiltered] = React.useState(false);
    const apiInstance = useApiContext();
    const serviceAuditTrail = apiInstance.sykloneApi.serviceAuditTrail;

    React.useEffect(() => {
      const hasActiveFilters = Object.entries(queryParams).some(([_, value]) => {
        return value !== null && value !== undefined && value !== "";
      });
      setIsFiltered(hasActiveFilters);
    }, [queryParams]);

    const fetchAuditTrail = async (queryParams, page, limit) => {
      const params = { ...queryParams };

      if (page) {
        params.page = page - 1;
      }
      if (limit) {
        params.limit = limit;
      }
      return await serviceAuditTrail.getAuditTrail(params);
    };

    const handleAuditTrail = async (page, queryParams) => {
      const response = await fetchAuditTrail(queryParams, page, AUDIT_TRAIL_ITEMS_PER_PAGE);
      const data = response.data.data;
      const count = response.data.length;

      const totalPagesCalc = Math.ceil(count / AUDIT_TRAIL_ITEMS_PER_PAGE);
      setTotalPages(totalPagesCalc);

      const formattedData = helperAuditTrail.formatAuditTrailData(data);

      return { formattedData, count };
    };

    const serializedQueryParams = React.useMemo(() => JSON.stringify(queryParams), [queryParams]);

    const {
      data: auditTrailData = {
        formattedData: [],
        count: 0,
      },
      isLoading: isLoadingAuditTrailData,
      error: auditTrailDataError,
    } = useQuery(["auditTrail", currentPage, serializedQueryParams], () => handleAuditTrail(currentPage, queryParams), {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });

    const {
      data: filterSchema = [],
      isLoading: isLoadingFilterSchema,
      error: filterSchemaError,
    } = useQuery(
      ["auditTrailFilterSchema"],
      async () => {
        const response = await serviceAuditTrail.getFilterOptions();
        return response.data.data;
      },
      {
        refetchOnWindowFocus: false,
      }
    );

    const fetchPdfData = React.useCallback(async () => {
      const response = await fetchAuditTrail(queryParams);
      const data = response.data.data;
      return helperAuditTrail.formatAuditTrailData(data);
    }, [queryParams]);

    const goToPage = React.useCallback(
      (pageNumber) => {
        const validPageNumber = Math.max(1, Math.min(pageNumber, totalPages));
        setCurrentPage(validPageNumber);
      },
      [totalPages]
    );

    const resetData = React.useCallback(() => {
      setCurrentPage(1);
      setQueryParams({});
    }, []);

    return (
      <Component
        auditTrailData={auditTrailData.formattedData}
        auditTrailCount={auditTrailData.count}
        isLoadingAuditTrailData={isLoadingAuditTrailData}
        auditTrailError={auditTrailDataError}
        filterSchema={filterSchema}
        isLoadingFilterSchema={isLoadingFilterSchema}
        filterSchemaError={filterSchemaError}
        currentPage={currentPage}
        totalPages={totalPages}
        goToPage={goToPage}
        resetData={resetData}
        setQueryParams={setQueryParams}
        queryParams={queryParams}
        isAuditTrailFiltered={isFiltered}
        fetchPdfData={fetchPdfData}
      />
    );
  };

  AuditTrailData.displayName = "AuditTrailData";
  return AuditTrailData;
};

export default withAuditTrailData;
