import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, icons, IconButton, TextField } from "syklone/ui/index.js";

const QuickSearchToolbar = ({
  value,
  onChange,
  disabled,
  clearSearch,
  sx = { position: "absolute", left: -16, top: -70 },
}) => {
  return (
    <Box sx={sx}>
      <TextField
        variant="outlined"
        size="small"
        value={value}
        onChange={onChange}
        placeholder="Search…"
        disabled={disabled}
        data-syklone="table-filter"
        InputProps={{
          startAdornment: <icons.mui.Search fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? "visible" : "hidden" }}
              onClick={clearSearch}
            >
              <icons.mui.Cancel fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          "& .MuiInputBase-root": {
            padding: "0px 10px",
            "& > .MuiSvgIcon-root": {
              mr: 0.5,
            },
          },
        }}
      />
    </Box>
  );
};

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

export default QuickSearchToolbar;
