import React from "react";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

const withSiteConfigurationGroups = (Component) => {
  const SiteConfigurationGroups = () => {
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();
    const serviceAuth = apiInstance.sykloneApi.serviceAuth;

    const {
      data: adminGroups = [],
      isLoading: isLoadingGroups,
      error: adminGroupError,
    } = useQuery(["adminGroups"], async () => {
      const response = await serviceAuth.getAdminGroups();
      const data = response.data.data;
      const modifiedData = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          isActive: !!item["is_active"],
          tags: item.tags,
          permissions: "",
          site: "",
          users: 0,
          description: item.description ? item.description : "",
        };
      });
      return modifiedData;
    });

    const invalidateAdminGroups = () => {
      queryClient.invalidateQueries("adminGroups");
    };

    return (
      <Component
        data={adminGroups}
        isLoading={isLoadingGroups}
        handleRefreshData={invalidateAdminGroups}
        error={adminGroupError}
      />
    );
  };
  SiteConfigurationGroups.displayName = "SiteConfigurationGroups";
  SiteConfigurationGroups.propTypes = {};
  return SiteConfigurationGroups;
};

export default withSiteConfigurationGroups;
