import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Grid, Typography, styled } from "syklone/ui/index.js";

const RowFragment = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 5,
});

const RowTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "0.85rem",
  opacity: 0.6,
}));

const RowLabel = styled(Typography)({
  fontSize: "0.85rem",
});
function MachineControls(props) {
  const { buidFileData, machineData } = props;

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <RowFragment>
            <RowTitle>Current build:</RowTitle>
            <RowLabel>{buidFileData.name}</RowLabel>
          </RowFragment>
        </Grid>
        <Grid item>
          <RowFragment>
            <RowTitle>Status:</RowTitle>
            <RowLabel>{machineData.isActive ? "Active" : "Inactive"}</RowLabel>
          </RowFragment>
        </Grid>
      </Grid>
    </Box>
  );
}

MachineControls.propTypes = {
  buidFileData: PropTypes.object,
  machineData: PropTypes.object,
};

export default MachineControls;
