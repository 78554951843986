import PropTypes from "prop-types";
import React from "react";

// imports: syklone
import { useQuery } from "syklone/libraries/index.js";
import { Box, Grid, Typography } from "syklone/ui/index.js";
import { WidgetLoader } from "syklone/components/widgets/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { WidgetRecentBuildFileCard } from "../index.js";

function WidgetRecentBuildFileUpdates({ history, navigate }) {
  const apiInstance = useApiContext();

  const {
    isLoading,
    data: recentBuildFiles,
    isError,
  } = useQuery(["recentBuildFiles"], async () => {
    const response = await apiInstance.sykloneApi.serviceBfm.getRecentlyUpdatedByNumberOfBuildFiles(15);
    return response.data;
  });

  return (
    <Grid item>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          alignItems: "center",
          display: "flex",
          left: 0,
          marginBottom: "20px",
          position: "sticky",
          width: "280px",
        }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: "1.2rem!important",
              fontWeight: "500!important",
            }}
            variant="h1"
            component="h2"
          >
            Recent Build file updates
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={0}
        style={{
          flexWrap: "nowrap",
          gap: "20px",
          paddingBottom: "10px",
        }}
      >
        {isLoading ? (
          <Box sx={{ height: 375, position: "relative", width: "100%" }}>
            <WidgetLoader
              style={{
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 999999,
              }}
            >
              Loading...
            </WidgetLoader>
          </Box>
        ) : (
          <>
            {isError ? (
              <Box sx={{ height: 375, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                There was an error loading Build files
              </Box>
            ) : recentBuildFiles && recentBuildFiles.length ? (
              <>
                {recentBuildFiles.map((item) => {
                  return (
                    <WidgetRecentBuildFileCard
                      recentBuildFile={item}
                      key={item.id}
                      navigate={navigate}
                      history={history}
                    />
                  );
                })}
              </>
            ) : (
              <>
                <Box
                  sx={{ height: 375, width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
                >
                  There are no build files available
                </Box>
              </>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
}

WidgetRecentBuildFileUpdates.propTypes = {
  history: PropTypes.object,
  navigate: PropTypes.func,
};

export default WidgetRecentBuildFileUpdates;
