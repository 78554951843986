import React, { useState, useEffect } from "react";
import { Box, styled, Typography, IconButton, icons, Grid } from "syklone/ui";
import PropTypes from "prop-types";
import moment from "moment";
import DriftUpdateTool from "./drift_update_tool.jsx";
import { useApiContext } from "syklone/api/react/index.js";
import { useAlertSnackbar } from "syklone/components/hooks/index.js";

const BoxRow = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
  alignItems: "center",
  padding: 8,
  width: "100%",
  transition: "0.2s linear",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.mode === "dark" ? "#111111" : "#E0E0E0",
  },
}));

const RowFragment = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 5,
  flexWrap: "nowrap",
});

const CustomTypography = styled(Typography)({
  whiteSpace: "nowrap",
});

const RowTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  opacity: 0.6,
  whiteSpace: "nowrap",
}));

const niceDate = (str) => {
  if (!str) {
    return "No date";
  }
  let date = new Date(str);
  return moment(date).format("DD-MMM-yyyy, HH:mm");
};

function DriftUpdatesList({ machineData, setDriftHistoryListData }) {
  const apiInstance = useApiContext();
  const sykloneApi = apiInstance.sykloneApi;
  const serviceAuth = apiInstance.sykloneApi.serviceAuth;
  const [selectedItem, setSelectedItem] = useState(null);
  const { showSnackbar } = useAlertSnackbar();
  const [adminAccounts, setAdminAccounts] = useState([]);
  const [driftHistoryData, setDriftHistoryData] = useState([]);
  const [oldMachineData, setOldMachineData] = useState(machineData);

  const findAdminNameById = (id, adminAccounts) => {
    if (id === null || id === "") {
      console.warn("Invalid or missing id:", id);
      return "Unknown";
    }

    if (!Array.isArray(adminAccounts)) {
      console.warn("adminAccounts is not an array:", adminAccounts);
      return "Unknown";
    }

    const admin = adminAccounts.find((admin) => {
      return admin && admin.name === id.name;
    });
    if (!admin) {
      return "Unknown";
    }

    return admin.name;
  };

  useEffect(() => {
    const fetchAdminAccounts = async () => {
      try {
        const response = await serviceAuth.getAdminAccounts();
        const accounts = Array.isArray(response.data.data) ? response.data.data : [];
        setAdminAccounts(accounts);
      } catch (error) {
        console.error("Failed to fetch user accounts:", error);
        setAdminAccounts([]);
        showSnackbar("Failed to fetch user accounts.", "error");
      }
    };

    fetchAdminAccounts();
  }, []);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    let parsedDrift = item.new_values.drift;
    try {
      if (typeof parsedDrift === "string") {
        parsedDrift = JSON.parse(parsedDrift);
      }
    } catch (error) {
      console.error("Failed to parse drift data:", error);
      parsedDrift = {};
    }
    const updatedMachineData = {
      ...oldMachineData,
      drift: parsedDrift,
    };
    setOldMachineData(updatedMachineData);
  };

  const handleBackClick = () => {
    setSelectedItem(null);
  };

  const ButtonCloseElement = () => (
    <IconButton onClick={handleBackClick} data-syklone="button-close">
      <icons.mui.ArrowBack />
    </IconButton>
  );

  const getAuditTrailHistory = async (machineData, adminAccounts) => {
    const queryParams = {
      electronicRecordType: "MachineAccount",
      action: "Modify",
    };

    try {
      const response = await sykloneApi.bcGetAuditTrailHistory(queryParams);

      const filteredResults = response.data.data
        .filter((record) => {
          let hasDriftValues = false;
          let matchesObjectId = record.object_id === machineData.id;

          if (!matchesObjectId) {
            return false;
          }

          const matchesDescription = record.description === "Update Machine Account Drift";

          if (!record.new_values || !record.new_values.drift) {
            return false;
          }

          try {
            const driftValues = JSON.parse(record.new_values.drift);

            if (typeof driftValues === "object" && !Array.isArray(driftValues)) {
              const driftKeys = Object.keys(driftValues);

              for (let key of driftKeys) {
                if (Array.isArray(driftValues[key])) {
                  hasDriftValues = true;
                  break;
                }
              }
            }
          } catch (error) {
            console.log(`Error parsing new_values.drift: "${record.new_values.drift}"`, error);
            return false;
          }
          return matchesDescription && hasDriftValues && matchesObjectId;
        })
        .map((record) => {
          return {
            ...record,
            adminName: findAdminNameById(record.completed_by_id, adminAccounts),
          };
        });

      return filteredResults;
    } catch (error) {
      const message = error?.response?.data?.message ? error?.response?.data?.message : "Error getting data!";
      showSnackbar(message, "error");
      console.log("Error in getAuditTrailHistory:", error);
      return error;
    }
  };

  useEffect(() => {
    getAuditTrailHistory(machineData, adminAccounts)
      .then((result) => {
        setDriftHistoryListData(result);
        setDriftHistoryData(result);
      })
      .catch((error) => {
        console.error("Error fetching audit trail history:", error);
      });
  }, [machineData, adminAccounts]);

  return (
    <>
      {!selectedItem ? (
        <>
          {driftHistoryData.map((item, index) => (
            <BoxRow
              key={`history-${index}`}
              className="drift-history-list-row"
              onClick={() => handleItemClick(item)}
              data-syklone={`drift-historylist-row-history-${index}`}
            >
              <Box sx={{ marginRight: "16px" }}>
                <Grid container alignItems="center" spacing={2} sx={{ flexWrap: "nowrap" }}>
                  <Grid item>
                    <RowFragment>
                      <RowTitle>Author:</RowTitle>
                      <CustomTypography data-syklone={`drift-author-${index}`}> {item.adminName}</CustomTypography>
                    </RowFragment>
                  </Grid>
                </Grid>
              </Box>
              <RowFragment>
                <Box>
                  <Grid container alignItems="center" spacing={2} sx={{ flexWrap: "nowrap" }}>
                    <Grid item>
                      <RowFragment>
                        <RowTitle>Added:</RowTitle>
                        <CustomTypography data-syklone={`drift-date-added-${index}`}>
                          {niceDate(item.date)}
                        </CustomTypography>
                      </RowFragment>
                    </Grid>
                  </Grid>
                </Box>
              </RowFragment>
            </BoxRow>
          ))}
        </>
      ) : (
        <div>
          <Box>
            <ButtonCloseElement /> {selectedItem.adminName} &nbsp;&nbsp; {niceDate(selectedItem.date)}
          </Box>
          <DriftUpdateTool machineData={oldMachineData} isUpdate={false} data-syklone="drift-update-tool" />
        </div>
      )}
    </>
  );
}

DriftUpdatesList.propTypes = {
  machineData: PropTypes.object.isRequired,
  setDriftHistoryListData: PropTypes.func.isRequired,
};

export default DriftUpdatesList;
