import React from "react";
import PropTypes from "prop-types";

// imports: local
import { WidgetCustomChip } from "../../widgets/index.js";

const TableDataIsActiveCell = ({ isActive, type = "active" }) => {
  if (typeof isActive === "boolean") {
    let chipType;
    let title;

    if (type === "active") {
      chipType = isActive ? "success" : "error";
      title = isActive ? "active" : "inactive";
    } else {
      chipType = isActive ? "success" : "unlisted";
      title = isActive ? "visible" : "hidden";
    }

    return <WidgetCustomChip type={chipType} title={title} />;
  } else {
    return "";
  }
};

TableDataIsActiveCell.propTypes = {
  isActive: PropTypes.bool,
  type: PropTypes.string,
};

export default TableDataIsActiveCell;
