import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

// imports: syklone
import { Box } from "syklone/ui/index.js";

// imports: local
import * as img from "../../img/index.js";

const transition = { duration: 0.5, ease: "anticipate", delay: 0.3 };

function WidgetHeader({ setActive, pages, active }) {
  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "50px 50px 0px 50px",
        zIndex: 3,
        [theme.breakpoints.down("md")]: {
          padding: "30px 30px 0px 30px",
        },
      })}
    >
      <Box sx={styles.mainLogo}>
        <img src={img.SykloneLoginLogo} alt="Syklone logo" />
      </Box>
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={transition}
        sx={styles.mainCtrl}
      >
        <Box component="ul" sx={styles.ctrlList}>
          {pages.map((item) => (
            <Box
              component="li"
              sx={{
                ...styles.ctrlListItem,
                ...(active === item.id ? styles.active : {}),
              }}
              key={item.id}
            >
              <Link style={styles.ctrlLink} to={item.to} onClick={() => setActive(item.id)} id={item.id}></Link>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

WidgetHeader.propTypes = {
  classes: PropTypes.object,
  setActive: PropTypes.func,
  pages: PropTypes.array,
  active: PropTypes.string,
};

const styles = {
  ctrlList: {
    display: "flex",
    listStyle: "none",
    gap: "15px",
  },
  ctrlListItem: {
    "&:hover": {
      opacity: 1,
    },
    width: "20px",
    height: "20px",
    opacity: 0.3,
    borderRadius: "50%",
    border: "1px solid #fff",
    position: "relative",
    transition: "all 0.3s ease-in-out",
  },
  ctrlLink: {
    display: "block",
    width: "20px",
    height: "20px",
  },
  active: {
    opacity: 1,
    "&::before": {
      content: "''",
      width: "6px",
      height: "6px",
      background: "#fff",
      display: "block",
      borderRadius: "50%",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
};

export default WidgetHeader;
