import React from "react";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import { useQuery, useQueryClient } from "syklone/libraries/index.js";

const withSiteConfigurationPermissions = (Component) => {
  const SiteConfigurationPermissions = () => {
    const apiInstance = useApiContext();
    const queryClient = useQueryClient();

    const serviceAuth = apiInstance.sykloneApi.serviceAuth;
    const {
      data: adminPermissions = [],
      isLoading: isLoadingPermissions,
      error: adminPermissionsError,
    } = useQuery(["adminPermissions"], async () => {
      const response = await serviceAuth.getAdminPermissionsAll();
      const data = response.data.data;

      const modifiedData = data.map((item) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description ? item.description : "",
          contentTypeId: item["content_type_id"],
          isActive: !!item["is_active"],
          htmlId: item.html_id,
          route: item.route,
        };
      });
      return modifiedData;
    });

    const invalidateAdminPermissions = () => {
      queryClient.invalidateQueries("adminPermissions");
    };

    return (
      <Component
        data={adminPermissions}
        isLoading={isLoadingPermissions}
        handleRefreshData={invalidateAdminPermissions}
        error={adminPermissionsError}
      />
    );
  };
  SiteConfigurationPermissions.displayName = "SiteConfigurationPermissions";
  SiteConfigurationPermissions.propTypes = {};
  return SiteConfigurationPermissions;
};

export default withSiteConfigurationPermissions;
