import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button } from "syklone/ui/index.js";
import { DialogCommonBase } from "syklone/components/dialogs/index.js";

const withConfirmation = (DialogCommonBaseComponent) => {
  const DialogCommonConfirmation = ({
    onConfirm,
    onConfirmTitle = "Ok",
    onCancel,
    onCancelTitle = "Cancel",
    isConfirmDisabled = false,
    children,
    actions,
    ...rest
  }) => {
    return (
      <DialogCommonBaseComponent
        {...rest}
        actions={
          <>
            {actions}
            <Button autoFocus onClick={onCancel} color="primary">
              {onCancelTitle}
            </Button>
            <Button disabled={isConfirmDisabled} autoFocus onClick={onConfirm} color="primary">
              {onConfirmTitle}
            </Button>
          </>
        }
      >
        {children}
      </DialogCommonBaseComponent>
    );
  };
  DialogCommonConfirmation.displayName = "DialogCommonConfirmation";
  DialogCommonConfirmation.propTypes = {
    ...DialogCommonBase.propTypes,
    onConfirm: PropTypes.func,
    isConfirmDisabled: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirmTitle: PropTypes.string,
    onCancelTitle: PropTypes.string,
  };
  return DialogCommonConfirmation;
};

const DialogCommonConfirmation = withConfirmation(DialogCommonBase);

export default DialogCommonConfirmation;
